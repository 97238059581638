import React, { useEffect } from 'react';
import styled from 'styled-components';
import { 
  FaEdit, 
  FaTrash,
  FaTimes,
  FaThumbsUp,
  FaThumbsDown,
  FaFire,
  FaNewspaper,
  FaCalendarAlt
} from 'react-icons/fa';

const Panel = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: white;
`;

const FixedHeader = styled.div`
  padding: 16px;
  border-bottom: 1px solid #dadce0;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`;

const DateContainer = styled.div`
  margin-right: 16px;
  width: 64px;
  height: 64px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #e8f0fe;
  border-radius: 8px;
  border: 1px solid #d2e3fc;
`;

const DayNumber = styled.div`
  font-size: 24px;
  font-weight: bold;
  color: #1a73e8;
`;

const Month = styled.div`
  font-size: 12px;
  font-weight: 500;
  color: #1a73e8;
  text-transform: uppercase;
`;

const Title = styled.h3`
  margin: 0;
  font-size: 20px;
  color: #202124;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  color: #5f6368;
  font-size: 20px;
  cursor: pointer;
  position: absolute;
  right: 16px;
  top: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  
  &:hover {
    background-color: #f1f3f4;
  }
`;

const ScrollableContent = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 16px;
`;

const FixedActions = styled.div`
  padding: 16px;
  border-top: 1px solid #dadce0;
`;

const Section = styled.div`
  margin-bottom: 24px;
`;

const SectionTitle = styled.h4`
  font-size: 14px;
  color: #5f6368;
  margin-bottom: 12px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  display: flex;
  align-items: center;
  
  svg {
    margin-right: 8px;
  }
`;

const InfoGrid = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  row-gap: 12px;
  column-gap: 16px;
`;

const Label = styled.div`
  font-size: 14px;
  color: #5f6368;
  font-weight: 500;
`;

const Value = styled.div`
  font-size: 14px;
  color: #202124;
  word-break: break-word;
`;

const ActionButton = styled.button`
  display: flex;
  align-items: center;
  border: none;
  background-color: ${props => props.variant === 'danger' ? '#fce8e6' : '#f1f3f4'};
  color: ${props => props.variant === 'danger' ? '#c5221f' : '#202124'};
  border-radius: 4px;
  padding: 8px 12px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  margin-right: 8px;
  margin-bottom: 8px;
  
  &:hover {
    background-color: ${props => props.variant === 'danger' ? '#fad2cf' : '#e8eaed'};
  }
  
  & > svg {
    margin-right: 8px;
  }
`;

const SummaryCard = styled.div`
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 16px;
  border: 1px solid #e8eaed;
`;

const SummaryCardTitle = styled.h5`
  margin-top: 0;
  margin-bottom: 8px;
  font-size: 16px;
  font-weight: 500;
  color: #202124;
`;

const SummaryCardText = styled.p`
  margin-bottom: 16px;
  font-size: 14px;
  color: #5f6368;
`;

const NewsItemList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const NewsItem = styled.div`
  display: flex;
  background-color: white;
  border-radius: 8px;
  border: 1px solid #e8eaed;
  overflow: hidden;
`;

const NewsItemImage = styled.div`
  width: 80px;
  height: 80px;
  background-color: #e8eaed;
  background-size: cover;
  background-position: center;
  background-image: url(${props => props.src || ''});
`;

const NewsItemContent = styled.div`
  flex: 1;
  padding: 8px 12px;
  display: flex;
  flex-direction: column;
`;

const NewsItemTitle = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: #202124;
  margin-bottom: 4px;
`;

const NewsItemSource = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #5f6368;
  
  img {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    margin-right: 4px;
  }
`;

const NewsLink = styled.a`
  color: inherit;
  text-decoration: none;
  display: block;
  
  &:hover {
    text-decoration: none;
  }
`;

const StatCard = styled.div`
  background-color: white;
  border-radius: 8px;
  padding: 12px;
  margin-bottom: 16px;
  border: 1px solid #e8eaed;
  display: flex;
  align-items: center;
`;

const StatIcon = styled.div`
  width: 40px;
  height: 40px;
  background-color: ${props => props.color || '#e8f0fe'};
  color: ${props => props.iconColor || '#1a73e8'};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  margin-right: 12px;
`;

const StatContent = styled.div`
  flex: 1;
`;

const StatValue = styled.div`
  font-size: 18px;
  font-weight: 500;
  color: #202124;
`;

const StatLabel = styled.div`
  font-size: 12px;
  color: #5f6368;
`;

const formatDate = (dateString) => {
  if (!dateString) return { day: 'N/A', month: 'N/A' };
  
  // Extrae solo la parte de la fecha (YYYY-MM-DD)
  const datePart = dateString.split('T')[0];
  // Crea una fecha a mediodía para evitar problemas de zona horaria
  const date = new Date(`${datePart}T12:00:00`);
  
  return {
    day: date.getDate(),
    month: date.toLocaleString('default', { month: 'short' })
  };
};

export const DailySummarySlidingPanel = ({ 
  selectedSummary, 
  onClose,
  onEdit,
  onDelete
}) => {
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [onClose]);

  if (!selectedSummary) return null;

  const { day, month } = formatDate(selectedSummary.date);
  
  return (
    <Panel>
      <FixedHeader>
        <CloseButton onClick={onClose}>
          <FaTimes />
        </CloseButton>
        <Header>
          <DateContainer>
            <DayNumber>{day}</DayNumber>
            <Month>{month}</Month>
          </DateContainer>
          <div>
            <Title>Daily Summary</Title>
            <small className="text-muted">{selectedSummary.total_news} articles analyzed</small>
          </div>
        </Header>
      </FixedHeader>

      <ScrollableContent>
        <div className="mb-4">
          <div className="row">
            <div className="col-md-4">
              <StatCard>
                <StatIcon color="#e6f4ea" iconColor="#137333">
                  <FaNewspaper />
                </StatIcon>
                <StatContent>
                  <StatValue>{selectedSummary.total_news}</StatValue>
                  <StatLabel>Total Articles</StatLabel>
                </StatContent>
              </StatCard>
            </div>
            <div className="col-md-4">
              <StatCard>
                <StatIcon color="#fef7e0" iconColor="#b06000">
                  <FaFire />
                </StatIcon>
                <StatContent>
                  <StatValue>{selectedSummary.day_intensity}/10</StatValue>
                  <StatLabel>Day Intensity</StatLabel>
                </StatContent>
              </StatCard>
            </div>
            <div className="col-md-4">
              <StatCard>
                <StatIcon 
                  color={selectedSummary.sentiment_score > 0 ? "#e6f4ea" : selectedSummary.sentiment_score < 0 ? "#fce8e6" : "#f1f3f4"}
                  iconColor={selectedSummary.sentiment_score > 0 ? "#137333" : selectedSummary.sentiment_score < 0 ? "#c5221f" : "#5f6368"}
                >
                  {selectedSummary.sentiment_score > 0 ? <FaThumbsUp /> : 
                   selectedSummary.sentiment_score < 0 ? <FaThumbsDown /> : <FaCalendarAlt />}
                </StatIcon>
                <StatContent>
                  <StatValue>{selectedSummary.sentiment_score > 0 ? '+' : ''}{selectedSummary.sentiment_score}</StatValue>
                  <StatLabel>Sentiment Score</StatLabel>
                </StatContent>
              </StatCard>
            </div>
          </div>
        </div>

        <Section>
          <SectionTitle>
            <FaNewspaper /> Top Stories
          </SectionTitle>
          <NewsItemList>
            {selectedSummary.relevant_news && selectedSummary.relevant_news.map((news, index) => (
              <NewsItem key={index}>
                <NewsItemImage src={news.image} />
                <NewsItemContent>
                  <NewsLink href={news.link} target="_blank" rel="noopener noreferrer">
                    <NewsItemTitle>{news.title}</NewsItemTitle>
                    <NewsItemSource>
                      {news.site_logo && <img src={news.site_logo} alt={news.site_name || "Source"} />}
                      {news.site_name || "Source"}
                    </NewsItemSource>
                  </NewsLink>
                </NewsItemContent>
              </NewsItem>
            ))}
          </NewsItemList>
        </Section>

        <Section>
          <SectionTitle>
            <FaNewspaper /> Daily Summary Categories
          </SectionTitle>
          {selectedSummary.daily_summary && selectedSummary.daily_summary.map((summary, summaryIndex) => (
            <SummaryCard key={summaryIndex}>
              <SummaryCardTitle>{summary.headline}</SummaryCardTitle>
              <SummaryCardText>{summary.summary_text}</SummaryCardText>
              
              <SectionTitle style={{ fontSize: '13px' }}>Related News</SectionTitle>
              <NewsItemList>
                {summary.important_news && summary.important_news.map((news, newsIndex) => (
                  <NewsItem key={newsIndex}>
                    <NewsItemImage src={news.image} />
                    <NewsItemContent>
                      <NewsLink href={news.link} target="_blank" rel="noopener noreferrer">
                        <NewsItemTitle>{news.title}</NewsItemTitle>
                        <NewsItemSource>
                          {news.site_logo && <img src={news.site_logo} alt={news.site_name || "Source"} />}
                          {news.site_name || "Source"}
                        </NewsItemSource>
                      </NewsLink>
                    </NewsItemContent>
                  </NewsItem>
                ))}
              </NewsItemList>
            </SummaryCard>
          ))}
        </Section>
      </ScrollableContent>

      <FixedActions>
        <Section>
          <SectionTitle>Actions</SectionTitle>
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            <ActionButton onClick={() => onEdit(selectedSummary)}>
              <FaEdit /> Edit Summary
            </ActionButton>
            <ActionButton variant="danger" onClick={() => onDelete(selectedSummary)}>
              <FaTrash /> Delete Summary
            </ActionButton>
          </div>
        </Section>
      </FixedActions>
    </Panel>
  );
};