import React, { useState, useEffect, useRef } from 'react';
import { Card, Table, Badge, Spinner, Alert, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FaPlayCircle, FaStop, FaSync, FaInfoCircle } from 'react-icons/fa';
import { getDetailedRunningJobs, stopRunningJob } from './apiJobs';
import { JobTypeBadge } from './JobDashboard';

const RunningJobsPanel = ({ refreshTrigger, onRefresh }) => {
  const [runningJobs, setRunningJobs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [stoppingJobId, setStoppingJobId] = useState(null);
  
  // Referencias para controlar el polling
  const pollingIntervalRef = useRef(null);
  const isInitialLoad = useRef(true);
  
  // Usar ref para evitar actualizaciones excesivas
  const prevRunningJobsRef = useRef([]);

  const fetchRunningJobs = async (showLoading = true) => {
    if (showLoading) setLoading(true);
    setError(null);
    
    try {
      const response = await getDetailedRunningJobs();
      
      // Manejo robusto de la respuesta de la API
      let jobsData = [];
      
      if (response === null || response === undefined) {
        setError("API returned no data. Check server connection.");
        setLoading(false);
        return;
      }
      
      if (response.runningJobs) {
        jobsData = response.runningJobs;
      } else if (response.running_jobs) {
        jobsData = response.running_jobs;
      } else if (response.data && response.data.runningJobs) {
        jobsData = response.data.runningJobs;
      } else if (response.data && response.data.running_jobs) {
        jobsData = response.data.running_jobs;
      } else if (Array.isArray(response)) {
        jobsData = response;
      } else {
        if (typeof response === 'object' && response !== null) {
          const keys = Object.keys(response);
          if (keys.length > 0 && typeof response[keys[0]] === 'object') {
            jobsData = Object.values(response);
          }
        }
      }
      
      if (!Array.isArray(jobsData)) {
        setError("Invalid data format received from API");
        jobsData = [];
      }
      
      // Normalizar las propiedades para manejar diferentes formatos de nombres
      jobsData = jobsData.map(job => {
        const jobId = job.jobId || job.job_id;
        const jobName = job.jobName || job.job_name;
        const jobType = job.jobType || job.job_type;
        const executionId = job.executionLogId || job.execution_log_id || '';
        
        return {
          ...job,
          job_id: jobId,
          job_name: jobName,
          job_type: jobType,
          started_at: job.startedAt || job.started_at,
          elapsed_time_seconds: job.elapsedTimeSeconds || job.elapsed_time_seconds || 0,
          execution_log_id: executionId,
          can_be_interrupted: job.canBeInterrupted !== undefined ? job.canBeInterrupted : 
                            (job.can_be_interrupted !== undefined ? job.can_be_interrupted : true)
        };
      });
      
      // Comparar con el estado anterior para evitar actualizaciones innecesarias
      const currentJobsStr = JSON.stringify(jobsData);
      const prevJobsStr = JSON.stringify(prevRunningJobsRef.current);
      
      if (currentJobsStr !== prevJobsStr) {
        setRunningJobs(jobsData);
        prevRunningJobsRef.current = [...jobsData];
        
        // Notificar cambios solo si hay diferencias y no es la carga inicial
        if (onRefresh && !isInitialLoad.current) {
          onRefresh();
        }
      }
    } catch (error) {
      console.error('Error fetching running jobs:', error);
      setError(`Failed to load running jobs: ${error.message}`);
    } finally {
      if (showLoading) {
        setLoading(false);
        isInitialLoad.current = false;
      }
    }
  };

  useEffect(() => {
    fetchRunningJobs();
    
    // Limpiar cualquier intervalo existente
    if (pollingIntervalRef.current) {
      clearInterval(pollingIntervalRef.current);
    }
    
    // Configurar polling cada 5 segundos
    pollingIntervalRef.current = setInterval(() => {
      fetchRunningJobs(false);
    }, 5000);
    
    // Limpieza al desmontar
    return () => {
      if (pollingIntervalRef.current) {
        clearInterval(pollingIntervalRef.current);
      }
    };
  }, [refreshTrigger]);

  const handleStopJob = async (jobId) => {
    try {
      setStoppingJobId(jobId);
      await stopRunningJob(jobId);
      
      // Esperar un momento antes de refrescar la lista
      setTimeout(() => {
        fetchRunningJobs();
        setStoppingJobId(null);
      }, 1000);
    } catch (error) {
      console.error(`Error stopping job ${jobId}:`, error);
      setError(`Failed to stop job: ${error.message}`);
      setStoppingJobId(null);
    }
  };

  const confirmStopJob = (jobId, jobName) => {
    if (window.confirm(`Are you sure you want to stop the job "${jobName}"? This will interrupt its execution and save logs generated so far.`)) {
      handleStopJob(jobId);
    }
  };

  const formatDateTime = (dateTimeStr) => {
    if (!dateTimeStr) return 'N/A';
    try {
      return new Date(dateTimeStr).toLocaleString();
    } catch (e) {
      return 'Invalid date';
    }
  };

  const formatElapsedTime = (seconds) => {
    if (!seconds && seconds !== 0) return 'Just started';
    
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    
    if (hours > 0) {
      return `${hours}h ${minutes}m ${remainingSeconds}s`;
    } else if (minutes > 0) {
      return `${minutes}m ${remainingSeconds}s`;
    } else {
      return `${remainingSeconds}s`;
    }
  };

  const getExecutionLogLink = (jobId, executionLogId) => {
    if (!executionLogId) return null;
    
    return (
      <OverlayTrigger
        placement="top"
        overlay={<Tooltip>View execution logs</Tooltip>}
      >
        <span className="ms-2">
          <a href={`#log/${jobId}/${executionLogId}`} onClick={(e) => e.stopPropagation()}>
            <FaInfoCircle size={14} />
          </a>
        </span>
      </OverlayTrigger>
    );
  };

  return (
    <Card className="mb-4">
      <Card.Header className="d-flex justify-content-between align-items-center">
        <div>
          <FaPlayCircle className="me-2" />
          Running Jobs ({runningJobs.length})
        </div>
        <Button 
          variant="outline-secondary" 
          size="sm" 
          onClick={() => fetchRunningJobs(true)}
          disabled={loading}
          title="Refresh running jobs list"
        >
          <FaSync className={loading ? "fa-spin" : ""} />
        </Button>
      </Card.Header>
      <Card.Body>
        {error && <Alert variant="danger">{error}</Alert>}
        
        {loading && runningJobs.length === 0 && isInitialLoad.current ? (
          <div className="text-center p-3">
            <Spinner animation="border" size="sm" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : runningJobs.length === 0 ? (
          <div className="text-center text-muted">No jobs currently running</div>
        ) : (
          <div className="table-responsive">
            <Table hover size="sm">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Type</th>
                  <th>Started At</th>
                  <th>Elapsed</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {runningJobs.map((job) => (
                  <tr key={job.job_id}>
                    <td>
                      {job.job_name}
                      {getExecutionLogLink(job.job_id, job.execution_log_id)}
                    </td>
                    <td><JobTypeBadge type={job.job_type} /></td>
                    <td>{formatDateTime(job.started_at)}</td>
                    <td>
                      <Badge bg="primary" pill>
                        {formatElapsedTime(job.elapsed_time_seconds)}
                      </Badge>
                    </td>
                    <td>
                      {job.can_be_interrupted ? (
                        <Button
                          variant="outline-danger"
                          size="sm"
                          onClick={() => confirmStopJob(job.job_id, job.job_name)}
                          disabled={stoppingJobId === job.job_id}
                          title="Stop this job"
                        >
                          {stoppingJobId === job.job_id ? (
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />
                          ) : (
                            <FaStop />
                          )}
                        </Button>
                      ) : (
                        <OverlayTrigger
                          placement="top"
                          overlay={<Tooltip>This job cannot be interrupted</Tooltip>}
                        >
                          <span>
                            <Button
                              variant="outline-secondary"
                              size="sm"
                              disabled
                            >
                              <FaStop />
                            </Button>
                          </span>
                        </OverlayTrigger>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        )}
      </Card.Body>
    </Card>
  );
};

export default RunningJobsPanel;