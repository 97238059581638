import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Button } from 'react-bootstrap';
import { 
  FaUserEdit, 
  FaAddressCard, 
  FaBan, 
  FaKey,
  FaLock
} from 'react-icons/fa';

const Panel = styled.div`
  background-color: #fff;
  box-shadow: 0 1px 2px 0 rgba(60,64,67,0.3), 0 2px 6px 2px rgba(60,64,67,0.15);
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const FixedHeader = styled.div`
  padding: 24px 24px 0;
  background-color: #fff;
  border-bottom: 1px solid #dadce0;
`;

const ScrollableContent = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  padding: 24px;
`;

const FixedActions = styled.div`
  padding: 24px;
  background-color: #fff;
  border-top: 1px solid #dadce0;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
`;

const Avatar = styled.div`
  width: 64px;
  height: 64px;
  border-radius: 50%;
  background-color: #1a73e8;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  margin-right: 16px;
`;

const Name = styled.h2`
  margin: 0;
  font-size: 22px;
  font-weight: 400;
  color: #202124;
`;

const Section = styled.div`
  margin-bottom: 24px;
`;

const SectionTitle = styled.h3`
  font-size: 14px;
  font-weight: 500;
  color: #5f6368;
  margin-bottom: 12px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
`;

const InfoGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 16px;
`;

const InfoItem = styled.div`
  background-color: #f1f3f4;
  padding: 12px;
  border-radius: 8px;
  transition: background-color 0.3s ease;
  word-wrap: break-word;
  overflow-wrap: break-word;

  &:hover {
    background-color: #e8f0fe;
  }
`;

const InfoLabel = styled.div`
  font-size: 12px;
  color: #5f6368;
  margin-bottom: 4px;
`;

const InfoValue = styled.div`
  font-size: 14px;
  color: #202124;
  font-weight: 500;
  white-space: pre-wrap;
`;

const ActionButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 12px;
  background-color: #fff;
  border-color: #dadce0;
  color: #1a73e8;
  font-weight: 500;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  
  &:hover {
    background-color: #f1f3f4;
    border-color: #dadce0;
    color: #174ea6;
    box-shadow: 0 1px 2px 0 rgba(60,64,67,0.3), 0 1px 3px 1px rgba(60,64,67,0.15);
  }

  &:focus {
    box-shadow: 0 0 0 2px rgba(26,115,232,0.4);
  }

  svg {
    margin-right: 8px;
  }
`;

const RoleChip = styled.div`
  display: inline-block;
  margin: 2px;
  padding: 4px 8px;
  background-color: #e8f0fe;
  color: #1967d2;
  border-radius: 16px;
  font-size: 12px;
  font-weight: 500;
`;

const InfoItemWithFallback = ({ label, value }) => (
  <InfoItem>
    <InfoLabel>{label}</InfoLabel>
    <InfoValue>
      {value || 'No Data'}
    </InfoValue>
  </InfoItem>
);

export const UserManagementSlidingPanel = ({ 
  selectedUser, 
  openEditNameModal, 
  openManagePermissionsModal,
  openContactInfoModal,
  handleInvalidateAccount,
  onClose
}) => {
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [onClose]);

  if (!selectedUser) {
    return <Panel>No user selected</Panel>;
  }

  const getInitials = (name) => {
    if (!name) return 'U';
    return name.split(' ').map(n => n[0]).join('').toUpperCase();
  };

  return (
    <Panel>
      <FixedHeader>
        <Header>
          <Avatar>{getInitials(selectedUser.name || '')}</Avatar>
          <Name>{selectedUser.name || 'Unnamed User'}</Name>
        </Header>
        <SectionTitle>User Information</SectionTitle>
      </FixedHeader>

      <ScrollableContent>
        <InfoGrid>
          <InfoItemWithFallback label="Name" value={selectedUser.name} />
          <InfoItemWithFallback label="Email" value={selectedUser.email} />
          <InfoItemWithFallback 
            label="Created At" 
            value={selectedUser.created_at ? new Date(selectedUser.created_at).toLocaleString() : 'Unknown'} 
          />
          <InfoItemWithFallback 
            label="Updated At" 
            value={selectedUser.updated_at ? new Date(selectedUser.updated_at).toLocaleString() : 'Unknown'} 
          />
          <InfoItemWithFallback
            label="Account Status"
            value={selectedUser.accountDisable ? 'Disabled' : 'Active'}
          />
        </InfoGrid>

        <Section>
          <SectionTitle>Roles</SectionTitle>
          <InfoItem>
            {selectedUser.roles && selectedUser.roles.length > 0 ? (
              selectedUser.roles.map(role => (
                <RoleChip key={role}>{role}</RoleChip>
              ))
            ) : (
              <InfoValue>No roles assigned</InfoValue>
            )}
          </InfoItem>
        </Section>

        {selectedUser.contactInfo && (
          <Section>
            <SectionTitle>Contact Information</SectionTitle>
            <InfoGrid>
              <InfoItemWithFallback label="Contact Name" value={selectedUser.contactInfo.name} />
              <InfoItemWithFallback label="Contact Email" value={selectedUser.contactInfo.email} />
              <InfoItemWithFallback label="Contact Phones" value={selectedUser.contactInfo.phones} />
            </InfoGrid>
          </Section>
        )}
      </ScrollableContent>

      <FixedActions>
        <Section>
          <SectionTitle>Actions</SectionTitle>
          <ActionButton onClick={() => openEditNameModal()}>
            <FaUserEdit /> Edit Name
          </ActionButton>
          <ActionButton onClick={() => openManagePermissionsModal()}>
            <FaKey /> Manage Permissions
          </ActionButton>
          <ActionButton onClick={() => openContactInfoModal()}>
            <FaAddressCard /> Update Contact Info
          </ActionButton>
          <ActionButton onClick={() => handleInvalidateAccount(selectedUser)}>
            <FaBan /> Invalidate Account
          </ActionButton>
        </Section>
      </FixedActions>
    </Panel>
  );
};