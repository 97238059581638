import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Button, Spinner, Badge } from 'react-bootstrap';
import { FaTimes, FaPlay, FaStop, FaRocket, FaList, FaCog, FaTrash, FaSync, FaCalendarAlt, FaServer } from 'react-icons/fa';
import styled from 'styled-components';
import { getJobById, getJobQueueStatus, getProcessingCapacity } from './apiJobs';

// Styled components permanecen igual...
const Panel = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0;
  background-color: #f8f9fa;
  font-family: 'Google Sans', Roboto, Arial, sans-serif;
`;

const Header = styled.div`
  background-color: white;
  padding: 16px 24px;
  border-bottom: 1px solid #dadce0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 1px 2px rgba(60, 64, 67, 0.3);
`;

const HeaderContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const HeaderButtons = styled.div`
  display: flex;
  align-items: center;
`;

const CloseButton = styled(Button)`
  padding: 8px;
  border: none;
  background: transparent;
  color: #5f6368;
  &:hover {
    background-color: rgba(95, 99, 104, 0.1);
    color: #202124;
  }
`;

const RefreshButton = styled(Button)`
  padding: 8px;
  border: none;
  background: transparent;
  color: #5f6368;
  margin-right: 8px;
  &:hover {
    background-color: rgba(95, 99, 104, 0.1);
    color: #202124;
  }
`;

const Content = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 24px;
`;

const Title = styled.h4`
  color: #202124;
  margin-bottom: 4px;
  font-weight: 500;
`;

const Subtitle = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const Section = styled.div`
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3), 0 1px 3px 1px rgba(60, 64, 67, 0.15);
  padding: 20px;
  margin-bottom: 16px;
`;

const SectionTitle = styled.h5`
  color: #202124;
  font-weight: 500;
  margin-bottom: 16px;
  font-size: 16px;
`;

const InfoRow = styled.div`
  display: flex;
  margin-bottom: 12px;
  align-items: flex-start;
`;

const InfoLabel = styled.div`
  min-width: 140px;
  color: #5f6368;
  font-weight: 500;
  font-size: 14px;
`;

const InfoValue = styled.div`
  color: #202124;
  font-size: 14px;
  flex: 1;
`;

const ActionButton = styled(Button)`
  border-radius: 4px;
  padding: 8px 16px;
  font-weight: 500;
  transition: background-color 0.2s;
`;

const ActionGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
`;

const StyledBadge = styled(Badge)`
  font-weight: 500;
  padding: 6px 12px;
  border-radius: 16px;
  font-size: 13px;
  text-transform: lowercase;
  &::first-letter {
    text-transform: uppercase;
  }
`;

const ExecutionWarning = styled.div`
  background-color: #ea4335;
  color: white;
  padding: 12px 16px;
  border-radius: 4px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  font-weight: 500;
`;

const JobDetailsPanel = ({ jobId, onClose, onAction }) => {
  const [job, setJob] = useState(null);
  const [queueStatus, setQueueStatus] = useState(null);
  const [capacityInfo, setCapacityInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [stoppingJob, setStoppingJob] = useState(false);
  
  // Use ref to avoid excessive polling
  const pollingIntervalRef = useRef(null);
  const isInitialLoad = useRef(true);

  const loadJobDetails = useCallback(async (showLoading = true) => {
    if (showLoading) setLoading(true);
    setError(null);
    
    try {
      // Load job details
      const jobResponse = await getJobById(jobId);
      
      // Compare with current state to minimize unnecessary re-renders
      setJob(prevJob => {
        // If first load or different data, update
        if (!prevJob || prevJob.id !== jobResponse.data.id || 
            prevJob.status !== jobResponse.data.status ||
            prevJob.updatedAt !== jobResponse.data.updatedAt) {
          return jobResponse.data;
        }
        return prevJob;
      });
      
      // Load queue status
      const queueResponse = await getJobQueueStatus(jobId);
      setQueueStatus(prevStatus => {
        if (!prevStatus || 
            prevStatus.isQueued !== queueResponse.data.isQueued || 
            prevStatus.position !== queueResponse.data.position) {
          return queueResponse.data;
        }
        return prevStatus;
      });

      // Load system capacity
      const capacityResponse = await getProcessingCapacity();
      setCapacityInfo(prevCapacity => {
        if (!prevCapacity || 
            prevCapacity.currentRunningJobs !== capacityResponse.currentRunningJobs ||
            prevCapacity.queueSize !== capacityResponse.queueSize) {
          return capacityResponse;
        }
        return prevCapacity;
      });
    } catch (error) {
      console.error('Error loading job details:', error);
      setError('Failed to load job details');
    } finally {
      if (showLoading) setLoading(false);
      isInitialLoad.current = false;
    }
  }, [jobId]);

  useEffect(() => {
    if (jobId) {
      loadJobDetails();
      
      // Clear any existing interval
      if (pollingIntervalRef.current) {
        clearInterval(pollingIntervalRef.current);
      }
      
      // Start polling - but less frequently (every 10 seconds)
      pollingIntervalRef.current = setInterval(() => {
        if (jobId) loadJobDetails(false);
      }, 10000);
    }
    
    // Cleanup on unmount or when jobId changes
    return () => {
      if (pollingIntervalRef.current) {
        clearInterval(pollingIntervalRef.current);
      }
    };
  }, [jobId, loadJobDetails]);

  const formatDateTime = (dateTimeStr) => {
    if (!dateTimeStr) return 'Not available';
    return new Date(dateTimeStr).toLocaleString();
  };

  const getStatusVariant = (status) => {
    switch (status) {
      case 'ACTIVE': return 'success';
      case 'STOPPED': return 'secondary';
      case 'RUNNING': return 'primary';
      case 'QUEUED': return 'warning';
      default: return 'info';
    }
  };

  const handleAction = (action, params = {}) => {
    if (onAction) {
      if (action === 'stopRunning') {
        setStoppingJob(true);
      }
      
      onAction(action, jobId, params);
      
      // Refresh job details after action with a small delay
      setTimeout(() => {
        loadJobDetails();
        if (action === 'stopRunning') {
          setStoppingJob(false);
        }
      }, 1000);
    }
  };

  // El resto del componente es igual al anterior...
  if (loading && isInitialLoad.current) {
    return (
      <Panel>
        <Header>
          <Title>Job Details</Title>
          <CloseButton variant="light" onClick={onClose}>
            <FaTimes />
          </CloseButton>
        </Header>
        <div className="d-flex justify-content-center align-items-center h-100">
          <Spinner animation="border" role="status" style={{ color: '#1a73e8' }}>
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      </Panel>
    );
  }

  if (error) {
    return (
      <Panel>
        <Header>
          <Title>Job Details</Title>
          <CloseButton variant="light" onClick={onClose}>
            <FaTimes />
          </CloseButton>
        </Header>
        <Content>
          <Section>
            <div className="alert alert-danger">{error}</div>
          </Section>
        </Content>
      </Panel>
    );
  }

  if (!job) {
    return (
      <Panel>
        <Header>
          <Title>Job Details</Title>
          <CloseButton variant="light" onClick={onClose}>
            <FaTimes />
          </CloseButton>
        </Header>
        <Content>
          <Section>
            <div className="text-center p-4">Job not found</div>
          </Section>
        </Content>
      </Panel>
    );
  }

  // Handle both camelCase and snake_case property names for compatibility
  const jobType = job.jobType || job.job_type;
  const cronExpression = job.cronExpression || job.cron_expression;
  const lastRun = job.lastRun || job.last_run;
  const nextRun = job.nextRun || job.next_run;
  const createdAt = job.createdAt || job.created_at;
  const updatedAt = job.updatedAt || job.updated_at;
  const isRecurring = job.isRecurring !== undefined ? job.isRecurring : job.is_recurring;
  const priority = job.priority || 0;
  const allowMultipleQueue = job.allowMultipleQueue || job.allow_multiple_queue || false;

  const isQueued = queueStatus && queueStatus.isQueued;
  const isRunning = job.status === 'RUNNING';

  return (
    <Panel>
      <Header>
        <HeaderContent>
          <Title>{job.name}</Title>
          <Subtitle>
            <StyledBadge bg={getStatusVariant(job.status)}>{job.status}</StyledBadge>
            <StyledBadge bg="info">{jobType.replace(/_/g, ' ').toLowerCase()}</StyledBadge>
          </Subtitle>
        </HeaderContent>
        <HeaderButtons>
          <RefreshButton variant="light" onClick={() => loadJobDetails(true)} title="Refresh">
            <FaSync />
          </RefreshButton>
          <CloseButton variant="light" onClick={onClose} title="Close">
            <FaTimes />
          </CloseButton>
        </HeaderButtons>
      </Header>
      
      <Content>
        {/* Execution Status Section - NEW */}
        {isRunning && (
          <Section style={{ backgroundColor: '#f8d7da', borderLeft: '4px solid #dc3545' }}>
            <SectionTitle style={{ color: '#dc3545' }}>
              <FaRocket className="me-2" style={{ color: '#dc3545' }} />
              Execution Status
            </SectionTitle>
            
            <ExecutionWarning>
              <FaRocket className="me-2" />
              This job is currently executing
            </ExecutionWarning>
            
            <ActionButton 
              variant="danger" 
              style={{ width: '100%' }}
              onClick={() => handleAction('stopRunning')}
              disabled={stoppingJob}
            >
              {stoppingJob ? (
                <>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    className="me-2"
                  />
                  Stopping Job...
                </>
              ) : (
                <>
                  <FaStop className="me-2" /> Stop Execution
                </>
              )}
            </ActionButton>
            
            <div className="mt-3" style={{ fontSize: '14px', color: '#5f6368' }}>
              Note: Stopping a job will save all logs generated so far.
            </div>
          </Section>
        )}

        {/* System Status Section */}
        {capacityInfo && (
          <Section>
            <SectionTitle>
              <FaServer className="me-2" style={{ color: '#1a73e8' }} />
              System Status
            </SectionTitle>
            
            <InfoRow>
              <InfoLabel>Processing</InfoLabel>
              <InfoValue>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <span className="me-2">{capacityInfo.currentRunningJobs} of {capacityInfo.maxParallelJobs} jobs running</span>
                  <div 
                    style={{ 
                      width: '100px', 
                      height: '8px', 
                      backgroundColor: '#e0e0e0', 
                      borderRadius: '4px',
                      marginLeft: '10px'
                    }}
                  >
                    <div 
                      style={{ 
                        width: `${(capacityInfo.currentRunningJobs / capacityInfo.maxParallelJobs) * 100}%`, 
                        height: '100%', 
                        backgroundColor: capacityInfo.currentRunningJobs === capacityInfo.maxParallelJobs ? '#ea4335' : '#1a73e8', 
                        borderRadius: '4px'
                      }}
                    />
                  </div>
                </div>
              </InfoValue>
            </InfoRow>
            
            <InfoRow>
              <InfoLabel>Queue Status</InfoLabel>
              <InfoValue>
                {capacityInfo.queueSize === 0 ? (
                  <span className="text-success">Empty</span>
                ) : (
                  <span className={capacityInfo.queueSize > 5 ? "text-warning" : "text-info"}>
                    {capacityInfo.queueSize} job{capacityInfo.queueSize !== 1 ? 's' : ''} waiting
                  </span>
                )}
              </InfoValue>
            </InfoRow>
          </Section>
        )}

        <Section>
          <SectionTitle>
            <FaCalendarAlt className="me-2" style={{ color: '#1a73e8' }} />
            Schedule
          </SectionTitle>
          
          <InfoRow>
            <InfoLabel>Cron Expression</InfoLabel>
            <InfoValue>
              <code style={{ backgroundColor: '#f1f3f4', padding: '2px 4px', borderRadius: '4px' }}>
                {cronExpression}
              </code>
            </InfoValue>
          </InfoRow>
          
          <InfoRow>
            <InfoLabel>Last Run</InfoLabel>
            <InfoValue>{formatDateTime(lastRun)}</InfoValue>
          </InfoRow>
          
          <InfoRow>
            <InfoLabel>Next Run</InfoLabel>
            <InfoValue>{formatDateTime(nextRun)}</InfoValue>
          </InfoRow>
          
          <InfoRow>
            <InfoLabel>Recurring</InfoLabel>
            <InfoValue>
              {isRecurring ? 'Yes' : 'No'}
              {!isRecurring && (
                <span className="ms-2" style={{ color: '#5f6368', fontStyle: 'italic' }}>
                  (Runs once per day max)
                </span>
              )}
            </InfoValue>
          </InfoRow>
        </Section>

        <Section style={{ backgroundColor: isQueued ? '#e8f0fe' : 'white' }}>
          <SectionTitle>
            <FaList className="me-2" style={{ color: '#1a73e8' }} />
            Queue Status
          </SectionTitle>
          
          {isQueued ? (
            <>
              <div style={{ 
                backgroundColor: '#1a73e8', 
                color: 'white', 
                padding: '12px 16px', 
                borderRadius: '4px',
                marginBottom: '16px'
              }}>
                <strong>Currently in queue</strong> at position {queueStatus.position || 'unknown'}
              </div>
              
              <InfoRow>
                <InfoLabel>Queued At</InfoLabel>
                <InfoValue>{formatDateTime(queueStatus.queuedAt || queueStatus.queued_at)}</InfoValue>
              </InfoRow>
              
              <InfoRow>
                <InfoLabel>Est. Execution</InfoLabel>
                <InfoValue>
                  {formatDateTime(queueStatus.estimatedExecutionTime || queueStatus.estimated_execution_time)}
                </InfoValue>
              </InfoRow>
              
              <div className="mt-3">
                <Button 
                  variant="outline-danger" 
                  size="sm"
                  onClick={() => handleAction('removeFromQueue')}
                >
                  <FaTimes className="me-2" /> Remove from Queue
                </Button>
              </div>
            </>
          ) : (
            <div style={{ color: '#5f6368' }}>This job is not currently in the queue.</div>
          )}
        </Section>

        <Section>
          <SectionTitle>
            <FaCog className="me-2" style={{ color: '#1a73e8' }} />
            Configuration
          </SectionTitle>
          
          <InfoRow>
            <InfoLabel>Priority</InfoLabel>
            <InfoValue>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {priority}
                <div 
                  style={{ 
                    width: '100px', 
                    height: '6px', 
                    backgroundColor: '#e0e0e0', 
                    borderRadius: '3px',
                    marginLeft: '10px'
                  }}
                >
                  <div 
                    style={{ 
                      width: `${Math.min(100, priority)}%`, 
                      height: '100%', 
                      backgroundColor: '#1a73e8', 
                      borderRadius: '3px'
                    }}
                  />
                </div>
              </div>
            </InfoValue>
          </InfoRow>
          
          <InfoRow>
            <InfoLabel>Multiple Queue</InfoLabel>
            <InfoValue>
              {allowMultipleQueue ? 'Allowed' : 'Not allowed'}
            </InfoValue>
          </InfoRow>
          
          <InfoRow>
            <InfoLabel>Description</InfoLabel>
            <InfoValue>
              {job.description || <em style={{ color: '#5f6368' }}>No description provided</em>}
            </InfoValue>
          </InfoRow>
        </Section>

        <Section>
          <SectionTitle>
            <FaRocket className="me-2" style={{ color: '#1a73e8' }} />
            Actions
          </SectionTitle>
          
          <ActionGrid>
            {job.status === 'ACTIVE' ? (
              <ActionButton 
                variant="outline-secondary"
                onClick={() => handleAction('toggleStatus')}
                disabled={isRunning}
              >
                <FaStop className="me-2" /> Stop Job
              </ActionButton>
            ) : (
              <ActionButton 
                variant="outline-success"
                onClick={() => handleAction('toggleStatus')}
                disabled={isRunning}
              >
                <FaPlay className="me-2" /> Activate Job
              </ActionButton>
            )}
            
            {isRunning ? (
              <ActionButton 
                variant="danger" 
                onClick={() => handleAction('stopRunning')}
                disabled={stoppingJob}
              >
                {stoppingJob ? (
                  <>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      className="me-2"
                    />
                    Stopping...
                  </>
                ) : (
                  <>
                    <FaStop className="me-2" /> Stop Execution
                  </>
                )}
              </ActionButton>
            ) : (
              <ActionButton 
                variant="primary" 
                style={{ backgroundColor: '#1a73e8', borderColor: '#1a73e8' }}
                onClick={() => handleAction('execute', { forceRun: true })}
                disabled={capacityInfo && capacityInfo.currentRunningJobs >= capacityInfo.maxParallelJobs}
              >
                <FaRocket className="me-2" /> Execute Now
              </ActionButton>
            )}
            
            <ActionButton 
              variant="outline-primary" 
              disabled={isQueued || isRunning}
              onClick={() => handleAction('enqueue')}
            >
              <FaList className="me-2" /> Add to Queue
            </ActionButton>
            
            <ActionButton 
              variant="outline-info" 
              onClick={() => handleAction('viewLogs')}
            >
              <FaList className="me-2" /> View Logs
            </ActionButton>
            
            <ActionButton 
              variant="outline-secondary" 
              onClick={() => handleAction('edit')}
              disabled={isRunning}
            >
              <FaCog className="me-2" /> Edit Job
            </ActionButton>
            
            <ActionButton 
              variant="outline-danger" 
              onClick={() => handleAction('delete')}
              disabled={isRunning}
            >
              <FaTrash className="me-2" /> Delete Job
            </ActionButton>
          </ActionGrid>
        </Section>

        <Section>
          <SectionTitle>
            <FaCog className="me-2" style={{ color: '#1a73e8' }} />
            System Information
          </SectionTitle>
          
          <InfoRow>
            <InfoLabel>ID</InfoLabel>
            <InfoValue style={{ fontFamily: 'monospace' }}>{job.id}</InfoValue>
          </InfoRow>
          
          <InfoRow>
            <InfoLabel>Created</InfoLabel>
            <InfoValue>{formatDateTime(createdAt)}</InfoValue>
          </InfoRow>
          
          <InfoRow>
            <InfoLabel>Last Updated</InfoLabel>
            <InfoValue>{formatDateTime(updatedAt)}</InfoValue>
          </InfoRow>
        </Section>
      </Content>
    </Panel>
  );
};

export default JobDetailsPanel;