import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, ListGroup } from 'react-bootstrap';
import styled from 'styled-components';
import { getModulesByClientId, getModuleById } from './permissionsApi';

import {
  getClientModules
} from './permissionsApi';

const AccessLevelBadge = styled.span`
  display: inline-block;
  padding: 4px 8px;
  border-radius: 16px;
  font-size: 12px;
  font-weight: 500;
  margin-right: 8px;
  background-color: ${props => {
    switch(props.level) {
      case 'READ': return '#e8f0fe';
      case 'WRITE': return '#fef7e0';
      case 'ADMIN': return '#fce8e6';
      default: return '#f1f3f4';
    }
  }};
  color: ${props => {
    switch(props.level) {
      case 'READ': return '#1967d2';
      case 'WRITE': return '#ea8600';
      case 'ADMIN': return '#c5221f';
      default: return '#5f6368';
    }
  }};
`;

const GoogleButton = styled(Button)`
  background-color: #fff;
  border-color: #dadce0;
  color: #3c4043;
  font-weight: 500;
  text-transform: none;
  letter-spacing: normal;
  padding: 8px 16px;
  font-size: 14px;
  
  &:hover, &:focus {
    background-color: #f1f3f4;
    border-color: #dadce0;
    color: #3c4043;
    box-shadow: 0 1px 2px 0 rgba(60,64,67,0.3), 0 1px 3px 1px rgba(60,64,67,0.15);
  }

  &.primary {
    background-color: #1a73e8;
    border-color: #1a73e8;
    color: white;
    
    &:hover, &:focus {
      background-color: #174ea6;
      border-color: #174ea6;
      color: white;
    }
  }

  &.danger {
    background-color: #ea4335;
    border-color: #ea4335;
    color: white;
    
    &:hover, &:focus {
      background-color: #d93025;
      border-color: #d93025;
      color: white;
    }
  }
`;

const CircularProgress = styled.div`
  width: 30px;
  height: 30px;
  border: 3px solid #f3f3f3;
  border-top: 3px solid #1a73e8;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

export const CreateUserModal = ({ show, onHide, handleCreateUser }) => {
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState({});

  const validateForm = () => {
    const newErrors = {};
    if (!email) newErrors.email = 'Email is required';
    if (!name) newErrors.name = 'Name is required';
    if (!password) newErrors.password = 'Password is required';
    if (password && password.length < 6) newErrors.password = 'Password must be at least 6 characters';
    
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      handleCreateUser({ email, name, password });
      resetForm();
    }
  };

  const resetForm = () => {
    setEmail('');
    setName('');
    setPassword('');
    setErrors({});
  };

  const handleClose = () => {
    resetForm();
    onHide();
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Create New User</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Email</Form.Label>
            <Form.Control 
              type="email" 
              value={email} 
              onChange={(e) => setEmail(e.target.value)} 
              isInvalid={!!errors.email}
            />
            <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
          </Form.Group>
          
          <Form.Group className="mb-3">
            <Form.Label>Name</Form.Label>
            <Form.Control 
              type="text" 
              value={name} 
              onChange={(e) => setName(e.target.value)} 
              isInvalid={!!errors.name}
            />
            <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
          </Form.Group>
          
          <Form.Group className="mb-3">
            <Form.Label>Password</Form.Label>
            <Form.Control 
              type="password" 
              value={password} 
              onChange={(e) => setPassword(e.target.value)} 
              isInvalid={!!errors.password}
            />
            <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
          </Form.Group>
          
          <div className="d-flex justify-content-end mt-4">
            <GoogleButton type="button" onClick={handleClose} className="me-2">
              Cancel
            </GoogleButton>
            <GoogleButton type="submit" className="primary">
              Create User
            </GoogleButton>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export const EditNameModal = ({ show, onHide, currentName, handleUpdateName }) => {
  const [name, setName] = useState('');
  
  useEffect(() => {
    if (show && currentName) {
      setName(currentName);
    }
  }, [show, currentName]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (name.trim()) {
      handleUpdateName(name);
    }
  };

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>Edit User Name</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Name</Form.Label>
            <Form.Control 
              type="text" 
              value={name} 
              onChange={(e) => setName(e.target.value)} 
            />
          </Form.Group>
          
          <div className="d-flex justify-content-end mt-4">
            <GoogleButton type="button" onClick={onHide} className="me-2">
              Cancel
            </GoogleButton>
            <GoogleButton type="submit" className="primary">
              Update Name
            </GoogleButton>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export const ContactInfoModal = ({ show, onHide, currentContactInfo, handleUpdateContactInfo }) => {
  const [contactName, setContactName] = useState('');
  const [contactEmail, setContactEmail] = useState('');
  const [contactPhones, setContactPhones] = useState('');
  
  useEffect(() => {
    if (show && currentContactInfo) {
      setContactName(currentContactInfo.name || '');
      setContactEmail(currentContactInfo.email || '');
      setContactPhones(currentContactInfo.phones || '');
    } else if (show) {
      setContactName('');
      setContactEmail('');
      setContactPhones('');
    }
  }, [show, currentContactInfo]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const contactInfo = {
      name: contactName.trim(),
      email: contactEmail.trim(),
      phones: contactPhones.trim()
    };
    handleUpdateContactInfo(contactInfo);
  };

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>Update Contact Information</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Contact Name</Form.Label>
            <Form.Control 
              type="text" 
              value={contactName} 
              onChange={(e) => setContactName(e.target.value)} 
            />
          </Form.Group>
          
          <Form.Group className="mb-3">
            <Form.Label>Contact Email</Form.Label>
            <Form.Control 
              type="email" 
              value={contactEmail} 
              onChange={(e) => setContactEmail(e.target.value)} 
            />
          </Form.Group>
          
          <Form.Group className="mb-3">
            <Form.Label>Contact Phones</Form.Label>
            <Form.Control 
              type="text" 
              value={contactPhones} 
              onChange={(e) => setContactPhones(e.target.value)} 
            />
            <Form.Text className="text-muted">
              Multiple phone numbers can be separated by commas
            </Form.Text>
          </Form.Group>
          
          <div className="d-flex justify-content-end mt-4">
            <GoogleButton type="button" onClick={onHide} className="me-2">
              Cancel
            </GoogleButton>
            <GoogleButton type="submit" className="primary">
              Update Contact Info
            </GoogleButton>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export const InvalidateAccountModal = ({ show, onHide, onConfirm, userName }) => {
  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>Invalidate Account</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Are you sure you want to invalidate the account for <strong>{userName}</strong>?</p>
        <p>This action cannot be undone and will disable the user's ability to log in.</p>
      </Modal.Body>
      <Modal.Footer>
        <GoogleButton onClick={onHide}>
          Cancel
        </GoogleButton>
        <GoogleButton onClick={onConfirm} className="danger">
          Invalidate Account
        </GoogleButton>
      </Modal.Footer>
    </Modal>
  );
};