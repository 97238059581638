import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Button, Form, Dropdown } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import styled, { createGlobalStyle } from 'styled-components';
import { FaFilter, FaSync, FaEye, FaPlus, FaFacebookF } from 'react-icons/fa';
import { 
  getAllLotteries,
  fetchLottery,
  deleteLottery,
  generateLotteryTemplate,
  publishLotteryToFacebook,
  publishLotteryToFacebookStory,
  addLotteryManually
} from './apiLottery';

import { 
  LotterySlidingPanel,
  CreateLotteryModal,
  DeleteLotteryModal,
  PublishToFacebookModal,
  AlertModal
} from './LotteryModals';

// Estilos sin cambios
const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    background-color: #f1f3f4;
    color: #202124;
    margin: 0;
    padding: 0;
    overflow: hidden;
  }
`;

const AppContainer = styled.div`
  height: 100vh;
  max-height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

const ContentContainer = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
`;

const HeaderSection = styled.div`
  margin-bottom: 16px;
`;

const TableContainer = styled.div`
  flex: 1;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 1px 2px 0 rgba(60,64,67,0.3);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  min-height: 0;
  position: relative;
`;

const StyledDataTable = styled(DataTable)`
  height: 100%;

  .rdt_Wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .rdt_TableWrapper {
    flex: 1;
    overflow: auto;
  }

  .rdt_TableHead {
    font-weight: 500;
    color: #5f6368;
    background-color: #f1f3f4;
    position: sticky !important;
    top: 0 !important;
    z-index: 1;
  }

  .rdt_TableBody {
    overflow-y: auto;
  }

  .rdt_TableCol {
    padding: 16px;
  }

  .rdt_TableCell {
    font-size: 14px;
  }

  .rdt_Pagination {
    color: #5f6368;
    font-size: 14px;
    font-weight: 500;
    position: sticky;
    bottom: 0;
    background-color: #fff;
    z-index: 1;
    border-top: 1px solid #dadce0;
  }
`;

const SlidingPanel = styled.div`
  position: fixed;
  top: 0;
  right: ${props => props.isOpen ? '0' : '-100%'};
  width: 400px;
  height: 100%;
  background-color: white;
  box-shadow: -2px 0 8px rgba(0, 0, 0, 0.15);
  transition: right 0.3s ease-in-out;
  overflow-y: auto;
  z-index: 1000;
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: ${props => props.isOpen ? 'block' : 'none'};
  z-index: 999;
`;

const GoogleButton = styled(Button)`
  background-color: #fff;
  border-color: #dadce0;
  color: #3c4043;
  font-weight: 500;
  padding: 8px 16px;
  font-size: 14px;
  
  &:hover, &:focus {
    background-color: #f1f3f4;
    border-color: #dadce0;
    box-shadow: 0 1px 2px 0 rgba(60,64,67,0.3), 0 1px 3px 1px rgba(60,64,67,0.15);
  }

  &:disabled {
    background-color: #f1f3f4;
    border-color: #dadce0;
    color: #80868b;
  }
`;

const IconButton = styled(GoogleButton)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  margin-right: 8px;
  
  svg {
    margin-right: 8px;
  }
`;

const FilterDropdown = styled(Dropdown)`
  .dropdown-toggle {
    background-color: #fff;
    border-color: #dadce0;
    color: #3c4043;
    font-weight: 500;
    
    &:hover, &:focus {
      background-color: #f1f3f4;
      border-color: #dadce0;
      box-shadow: 0 1px 2px 0 rgba(60,64,67,0.3), 0 1px 3px 1px rgba(60,64,67,0.15);
    }
  }

  .dropdown-menu {
    box-shadow: 0 2px 4px -1px rgba(0,0,0,0.2), 0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12);
    border: none;
    border-radius: 4px;
  }
`;

const LoadingOverlay = styled.div`
  width: 100%;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CircularProgress = styled.div`
  width: 40px;
  height: 40px;
  border: 3px solid #f3f3f3;
  border-top: 3px solid #1a73e8;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

const LotteryManager = () => {
  const [lotteries, setLotteries] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedLottery, setSelectedLottery] = useState(null);
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const [alertModal, setAlertModal] = useState({ show: false, title: '', message: '', variant: 'success' });
  const [pagination, setPagination] = useState({ page: 0, size: 10, total: 0 });
  const [filterConfig, setFilterConfig] = useState(null);
  
  // Modal states
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showPublishModal, setShowPublishModal] = useState(false);
  
  // Filter state
  const [filters, setFilters] = useState({
    date: '',
    pick: '',
    daytime: ''
  });

  const fetchLotteries = useCallback(async () => {
    setLoading(true);
    try {
      const response = await getAllLotteries(
        pagination.page,
        pagination.size,
        null, // Ya no enviamos sort_by porque el backend siempre ordena por fecha
        filters
      );
      
      setLotteries(response.items || []);
      setPagination(prev => ({
        ...prev,
        total: response.total || 0
      }));
      
      // Set filter config if available
      if (response.filterConfig) {
        setFilterConfig(response.filterConfig);
      } else if (response.filter_config) {
        // API puede devolver snake_case en lugar de camelCase
        setFilterConfig(response.filter_config);
      }
    } catch (error) {
      console.error('Error fetching lotteries:', error);
      showAlert('Error', 'Failed to load lottery data', 'error');
    } finally {
      setLoading(false);
    }
  }, [pagination.page, pagination.size, filters]);

  useEffect(() => {
    fetchLotteries();
  }, [fetchLotteries]);

  const handleFilterChange = (filterName, value) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      [filterName]: value
    }));
    
    // Reset to first page when filter changes
    setPagination(prev => ({
      ...prev,
      page: 0
    }));
  };

  const clearFilters = () => {
    setFilters({
      date: '',
      pick: '',
      daytime: ''
    });
  };

  const handlePageChange = (page) => {
    setPagination(prev => ({
      ...prev,
      page: page - 1 // DataTable es 1-indexed, nuestra API es 0-indexed
    }));
  };

  const handlePerRowsChange = (newPerPage) => {
    setPagination(prev => ({
      ...prev,
      size: newPerPage,
      page: 0
    }));
  };

  const showAlert = useCallback((title, message, variant = 'success') => {
    setAlertModal({ show: true, title, message, variant });
  }, []);

  const closeAlert = useCallback(() => {
    setAlertModal({ show: false, title: '', message: '', variant: 'success' });
  }, []);

  const handleViewDetails = (row) => {
    setSelectedLottery(row);
    setIsPanelOpen(true);
  };

  const closeSlidingPanel = () => {
    setIsPanelOpen(false);
    setSelectedLottery(null);
  };

  const handleFetchLottery = async () => {
    try {
      setLoading(true);
      const response = await fetchLottery();
      if (response.data && response.data.length > 0) {
        showAlert('Success', `Successfully fetched ${response.data.length} new lottery results`, 'success');
        fetchLotteries();
      } else {
        showAlert('Info', 'No new lottery data available', 'info');
      }
    } catch (error) {
      console.error('Error fetching lottery data:', error);
      showAlert('Error', 'Failed to fetch lottery data', 'error');
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteLottery = async (id) => {
    try {
      await deleteLottery(id);
      fetchLotteries();
      setShowDeleteModal(false);
      closeSlidingPanel();
      showAlert('Success', 'Lottery record deleted successfully', 'success');
    } catch (error) {
      console.error('Error deleting lottery:', error);
      showAlert('Error', 'Failed to delete lottery record', 'error');
    }
  };

  const handlePublishToFacebook = async (date, daytime, customMessage) => {
    try {
      const response = await publishLotteryToFacebook(date, daytime, customMessage);
      setShowPublishModal(false);
      showAlert('Success', 'Published to Facebook successfully', 'success');
      return response;
    } catch (error) {
      console.error('Error publishing to Facebook:', error);
      showAlert('Error', 'Failed to publish to Facebook', 'error');
      throw error;
    }
  };

  const handlePublishToFacebookStory = async (date, daytime, customMessage) => {
    try {
      const response = await publishLotteryToFacebookStory(date, daytime, customMessage);
      setShowPublishModal(false);
      showAlert('Success', 'Published to Facebook Story successfully', 'success');
      return response;
    } catch (error) {
      console.error('Error publishing to Facebook Story:', error);
      showAlert('Error', 'Failed to publish to Facebook Story', 'error');
      throw error;
    }
  };

  const openDeleteModal = (lottery) => {
    setSelectedLottery(lottery);
    setShowDeleteModal(true);
  };

  const openPublishModal = (lottery) => {
    setSelectedLottery(lottery);
    setShowPublishModal(true);
  };

  const handleCreateLottery = async (lottery) => {
    try {
      setLoading(true);
      const response = await addLotteryManually(lottery);
      if (response.data) {
        fetchLotteries();
        setShowCreateModal(false);
        showAlert('Success', 'Lottery result added successfully', 'success');
      } else {
        showAlert('Warning', response.message || 'Failed to add lottery result', 'warning');
      }
    } catch (error) {
      console.error('Error adding lottery:', error);
      showAlert('Error', 'Failed to add lottery result', 'error');
    } finally {
      setLoading(false);
    }
  };

  const columns = useMemo(() => [
    {
      name: 'Pick',
      selector: row => row.pick,
      sortable: true,
      width: '80px',
      cell: row => (
        <div style={{ 
          borderRadius: '50%',
          backgroundColor: row.pick === '3' ? '#1a73e8' : '#ea4335',
          color: 'white',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '36px',
          height: '36px',
          fontWeight: 'bold'
        }}>
          {row.pick}
        </div>
      )
    },
    {
      name: 'Date',
      selector: row => row.date,
      sortable: true,
                      format: row => row.date
    },
    {
      name: 'Time',
      selector: row => row.daytime,
      sortable: true
    },
    {
      name: 'Numbers',
      cell: row => {
        const numbers = [row.first, row.second, row.third];
        if (row.fourth !== null) numbers.push(row.fourth);
        if (row.fireball !== null) numbers.push(row.fireball);
        
        return (
          <div style={{ display: 'flex', gap: '5px' }}>
            {numbers.map((num, idx) => (
              num !== null ? (
                <div key={idx} style={{ 
                  width: '30px', 
                  height: '30px', 
                  borderRadius: '50%', 
                  backgroundColor: '#f1f3f4', 
                  display: 'flex', 
                  alignItems: 'center', 
                  justifyContent: 'center',
                  fontWeight: row.fireball === num ? 'bold' : 'normal',
                  border: row.fireball === num ? '2px solid #ea4335' : '1px solid #dadce0'
                }}>
                  {num}
                </div>
              ) : null
            ))}
          </div>
        );
      }
    },
    {
      name: 'Actions',
      cell: row => (
        <div>
          <IconButton onClick={() => handleViewDetails(row)}>
            <FaEye /> View
          </IconButton>
          <IconButton onClick={() => openPublishModal(row)}>
            <FaFacebookF /> Publish
          </IconButton>
        </div>
      )
    }
  ], []);

  const handleOutsideClick = useCallback((event) => {
    if (isPanelOpen && !event.target.closest(".sliding-panel-content") && 
        !event.target.closest(".modal")) {
      closeSlidingPanel();
    }
  }, [isPanelOpen]);

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [handleOutsideClick]);

  const customStyles = {
    headRow: {
      style: {
        borderTop: 'none',
        borderBottom: '1px solid #dadce0',
      },
    },
    headCells: {
      style: {
        paddingLeft: '16px',
        paddingRight: '16px',
      },
    },
    rows: {
      style: {
        minHeight: '56px',
        borderBottom: '1px solid #f1f3f4',
        '&:hover': {
          backgroundColor: '#f8f9fa',
        },
      },
    },
    noData: {
      style: {
        padding: '24px',
        color: '#5f6368',
        backgroundColor: 'white',
      },
    },
  };

  // Renderizar los filtros basados en la configuración recibida del backend
  const renderDynamicFilters = () => {
    if (!filterConfig || (!filterConfig.availableFilters && !filterConfig.available_filters)) {
      // Si no hay configuración, mostrar filtros predeterminados
      return (
        <>
          <Form.Group className="mb-2">
            <Form.Label>Date</Form.Label>
            <Form.Control
              type="date"
              value={filters.date || ''}
              onChange={(e) => handleFilterChange('date', e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-2">
            <Form.Label>Pick</Form.Label>
            <Form.Control
              as="select"
              value={filters.pick || ''}
              onChange={(e) => handleFilterChange('pick', e.target.value)}
            >
              <option value="">All</option>
              <option value="3">Pick 3</option>
              <option value="4">Pick 4</option>
            </Form.Control>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Time of Day</Form.Label>
            <Form.Control
              as="select"
              value={filters.daytime || ''}
              onChange={(e) => handleFilterChange('daytime', e.target.value)}
            >
              <option value="">All</option>
              <option value="Midday">Midday</option>
              <option value="Evening">Evening</option>
            </Form.Control>
          </Form.Group>
        </>
      );
    }

    const availableFilters = filterConfig.availableFilters || filterConfig.available_filters || [];
    return availableFilters.map((filter) => {
      switch (filter.type) {
        case 'DATE':
          return (
            <Form.Group className="mb-2" key={filter.key}>
              <Form.Label>{filter.label}</Form.Label>
              <Form.Control
                type="date"
                value={filters[filter.key] || ''}
                onChange={(e) => handleFilterChange(filter.key, e.target.value)}
              />
            </Form.Group>
          );
        case 'DROPDOWN':
          return (
            <Form.Group className="mb-2" key={filter.key}>
              <Form.Label>{filter.label}</Form.Label>
              <Form.Control
                as="select"
                value={filters[filter.key] || ''}
                onChange={(e) => handleFilterChange(filter.key, e.target.value)}
              >
                <option value="">All</option>
                {filter.possibleValues?.map((value) => (
                  <option key={value} value={value}>{value}</option>
                ))}
              </Form.Control>
            </Form.Group>
          );
        case 'TEXT':
          return (
            <Form.Group className="mb-2" key={filter.key}>
              <Form.Label>{filter.label}</Form.Label>
              <Form.Control
                type="text"
                value={filters[filter.key] || ''}
                onChange={(e) => handleFilterChange(filter.key, e.target.value)}
              />
            </Form.Group>
          );
        default:
          return null;
      }
    });
  };

  return (
    <AppContainer>
      <GlobalStyle />
      <ContentContainer>
        <HeaderSection className="d-flex justify-content-between align-items-center">
          <h2>Lottery Results</h2>
          <div className="d-flex gap-3">
            <IconButton onClick={handleFetchLottery} disabled={loading}>
              <FaSync /> Fetch Latest
            </IconButton>
            <IconButton onClick={() => setShowCreateModal(true)}>
              <FaPlus /> Add Result
            </IconButton>
            <FilterDropdown>
              <Dropdown.Toggle variant="outline-secondary" id="dropdown-filters">
                <FaFilter /> Filters
              </Dropdown.Toggle>
              <Dropdown.Menu className="p-3" style={{ width: '300px' }}>
                <Form>
                  {/* Filtros dinámicos basados en la configuración del backend */}
                  {renderDynamicFilters()}
                  <div className="d-flex justify-content-end">
                    <GoogleButton onClick={clearFilters}>Clear Filters</GoogleButton>
                  </div>
                </Form>
              </Dropdown.Menu>
            </FilterDropdown>
          </div>
        </HeaderSection>

        <TableContainer>
          <StyledDataTable
            columns={columns}
            data={lotteries}
            pagination
            paginationServer
            paginationTotalRows={pagination.total}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handlePerRowsChange}
            highlightOnHover
            pointerOnHover
            progressPending={loading}
            customStyles={customStyles}
            fixedHeader
            fixedHeaderScrollHeight="calc(100vh - 200px)"
            progressComponent={
              <LoadingOverlay>
                <CircularProgress />
              </LoadingOverlay>
            }
            noDataComponent={<div>No lottery results available</div>}
          />
        </TableContainer>

        <Overlay isOpen={isPanelOpen} />
        <SlidingPanel isOpen={isPanelOpen} className="sliding-panel-content">
          <LotterySlidingPanel
            selectedLottery={selectedLottery}
            onClose={closeSlidingPanel}
            onDelete={openDeleteModal}
            onPublish={openPublishModal}
            generateLotteryTemplate={generateLotteryTemplate}
          />
        </SlidingPanel>

        <CreateLotteryModal
          show={showCreateModal}
          onHide={() => setShowCreateModal(false)}
          onCreate={handleCreateLottery}
        />

        <DeleteLotteryModal
          show={showDeleteModal}
          onHide={() => setShowDeleteModal(false)}
          lottery={selectedLottery}
          onDelete={handleDeleteLottery}
        />

        <PublishToFacebookModal
          show={showPublishModal}
          onHide={() => setShowPublishModal(false)}
          lottery={selectedLottery}
          onPublishToFacebook={handlePublishToFacebook}
          onPublishToFacebookStory={handlePublishToFacebookStory}
        />

        <AlertModal
          show={alertModal.show}
          onHide={closeAlert}
          title={alertModal.title}
          message={alertModal.message}
          variant={alertModal.variant}
        />
      </ContentContainer>
    </AppContainer>
  );
};

export default LotteryManager;