import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Button } from 'react-bootstrap';
import { FaEdit, FaImages, FaPencilAlt, FaTrash } from 'react-icons/fa';
import { StatusChangeModal, ImagesModal, CreateEditModal } from './publicationModals';
import { fetchPublicationTypes, fetchPublicationStatuses, deletePublication } from './apiPublication';

const Panel = styled.div`
  background-color: #fff;
  box-shadow: 0 1px 2px 0 rgba(60,64,67,0.3), 0 2px 6px 2px rgba(60,64,67,0.15);
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const FixedHeader = styled.div`
  padding: 24px 24px 0;
  background-color: #fff;
  border-bottom: 1px solid #dadce0;
`;

const ScrollableContent = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  padding: 24px;
`;

const FixedActions = styled.div`
  padding: 24px;
  background-color: #fff;
  border-top: 1px solid #dadce0;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
`;

const Title = styled.h2`
  margin: 0;
  font-size: 22px;
  font-weight: 400;
  color: #202124;
`;

const Section = styled.div`
  margin-bottom: 24px;
`;

const SectionTitle = styled.h3`
  font-size: 14px;
  font-weight: 500;
  color: #5f6368;
  margin-bottom: 12px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
`;

const InfoGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 16px;
`;

const InfoItem = styled.div`
  background-color: #f1f3f4;
  padding: 12px;
  border-radius: 8px;
  transition: background-color 0.3s ease;
  word-wrap: break-word;
  overflow-wrap: break-word;

  &:hover {
    background-color: #e8f0fe;
  }
`;

const InfoLabel = styled.div`
  font-size: 12px;
  color: #5f6368;
  margin-bottom: 4px;
`;

const InfoValue = styled.div`
  font-size: 14px;
  color: #202124;
  font-weight: 500;
  white-space: pre-wrap;
`;

const ActionButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 12px;
  background-color: #fff;
  border-color: #dadce0;
  color: #1a73e8;
  font-weight: 500;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  
  &:hover {
    background-color: #f1f3f4;
    border-color: #dadce0;
    color: #174ea6;
    box-shadow: 0 1px 2px 0 rgba(60,64,67,0.3), 0 1px 3px 1px rgba(60,64,67,0.15);
  }

  svg {
    margin-right: 8px;
  }
`;

const DeleteButton = styled(ActionButton)`
  color: #d93025;
  
  &:hover {
    color: #ae1614;
  }
`;

const ImageGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 12px;
  margin-top: 12px;
`;

const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 150px;
  border-radius: 8px;
  overflow: hidden;

  &:hover {
    cursor: pointer;
    
    img {
      transform: scale(1.05);
    }
  }
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
  transition: transform 0.3s ease;
`;

const RemainingImagesOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 20px;
  font-weight: 500;
  cursor: pointer;
  border-radius: 8px;
`;

const StatusBadge = styled.span`
  padding: 4px 8px;
  border-radius: 12px;
  font-size: 12px;
  font-weight: 500;
  margin-left: 12px;
  background-color: ${props => props.color || '#e8f0fe'};
  color: ${props => props.textColor || 'white'};
`;

const TagsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 8px;
`;

const Tag = styled.div`
  background-color: #e8f0fe;
  color: #1a73e8;
  padding: 4px 12px;
  border-radius: 16px;
  font-size: 14px;
  display: flex;
  align-items: center;
`;

const LinkTag = styled(Tag)`
  background-color: #e6f4ea;
  color: #137333;
`;

const ContentArea = styled.div`
  background-color: #f1f3f4;
  padding: 16px;
  border-radius: 8px;
  margin-top: 12px;
  
  p {
    margin: 0;
    font-size: 14px;
    line-height: 1.5;
    color: #202124;
  }
`;

const InfoItemWithFallback = ({ label, value }) => (
  <InfoItem>
    <InfoLabel>{label}</InfoLabel>
    <InfoValue>{value || 'No data'}</InfoValue>
  </InfoItem>
);

export const PublicationSlidingPanel = ({ selectedPublication, onClose, onUpdate, types = [], statuses = [], organizations = [] }) => {
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [showImagesModal, setShowImagesModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [onClose]);

  const handleSuccess = () => {
    onUpdate();
  };

  const handleDelete = async () => {
    if (confirmDelete) {
      try {
        await deletePublication(selectedPublication.id);
        onUpdate();
        onClose();
      } catch (error) {
        console.error('Error deleting publication:', error);
        alert('Error deleting publication. Please try again.');
      }
    } else {
      setConfirmDelete(true);
      setTimeout(() => setConfirmDelete(false), 3000);
    }
  };

  if (!selectedPublication) return null;

  const formatDate = (dateString) => {
    if (!dateString) return "N/A";
    return new Date(dateString).toLocaleString();
  };

  const getStatusBadge = (statusCode) => {
    if (!statuses || !Array.isArray(statuses)) return <StatusBadge>{statusCode}</StatusBadge>;
    
    const status = statuses.find(s => s && s.code === statusCode);
    if (!status) return <StatusBadge>{statusCode}</StatusBadge>;
    return <StatusBadge color={status.color} textColor="white">{status.name}</StatusBadge>;
  };

  const getPublicationType = (typeCode) => {
    if (!types || !Array.isArray(types)) return typeCode;
    
    const type = types.find(t => t && t.code === typeCode);
    return type ? type.name : typeCode;
  };

  const getOrganizationName = (orgId) => {
    if (!organizations || !Array.isArray(organizations)) return orgId;
    
    const org = organizations.find(o => o && o.id === orgId);
    return org ? org.name : orgId;
  };

  return (
    <Panel>
      <FixedHeader>
        <Header>
          <Title>
            Publicación
            {selectedPublication.status && getStatusBadge(selectedPublication.status)}
          </Title>
        </Header>
      </FixedHeader>

      <ScrollableContent>
        <Section>
          <SectionTitle>Información Básica</SectionTitle>
          <InfoGrid>
            <InfoItemWithFallback 
              label="Título" 
              value={selectedPublication.title} 
            />
            <InfoItemWithFallback 
              label="Tipo" 
              value={getPublicationType(selectedPublication.type)} 
            />
            <InfoItemWithFallback 
              label="Autor" 
              value={selectedPublication.author_name} 
            />
            <InfoItemWithFallback 
              label="Organización" 
              value={getOrganizationName(selectedPublication.organization_id)} 
            />
            <InfoItemWithFallback 
              label="Fecha de Publicación" 
              value={formatDate(selectedPublication.published_at)} 
            />
            <InfoItemWithFallback 
              label="Última Actualización" 
              value={formatDate(selectedPublication.updated_at)} 
            />
          </InfoGrid>
        </Section>

        {selectedPublication.description && (
          <Section>
            <SectionTitle>Descripción</SectionTitle>
            <ContentArea>
              <p>{selectedPublication.description}</p>
            </ContentArea>
          </Section>
        )}

        {selectedPublication.body && (
          <Section>
            <SectionTitle>Contenido</SectionTitle>
            <ContentArea>
              {selectedPublication.body}
            </ContentArea>
          </Section>
        )}

        {selectedPublication.hashtags && selectedPublication.hashtags.length > 0 && (
          <Section>
            <SectionTitle>Etiquetas</SectionTitle>
            <TagsContainer>
              {selectedPublication.hashtags.map((tag, index) => (
                <Tag key={index}>#{tag}</Tag>
              ))}
            </TagsContainer>
          </Section>
        )}

        {selectedPublication.links && selectedPublication.links.length > 0 && (
          <Section>
            <SectionTitle>Enlaces</SectionTitle>
            <TagsContainer>
              {selectedPublication.links.map((link, index) => (
                <LinkTag key={index}>
                  <a href={link} target="_blank" rel="noopener noreferrer" style={{ color: 'inherit', textDecoration: 'none' }}>
                    {link.length > 30 ? `${link.substring(0, 30)}...` : link}
                  </a>
                </LinkTag>
              ))}
            </TagsContainer>
          </Section>
        )}

        {selectedPublication.images && selectedPublication.images.length > 0 && (
          <Section>
            <SectionTitle>Imágenes</SectionTitle>
            <ImageGrid>
              {selectedPublication.images.slice(0, 4).map((image, index) => (
                <ImageWrapper 
                  key={index}
                  onClick={() => setShowImagesModal(true)}
                >
                  <Image src={image} alt={`Imagen de publicación ${index + 1}`} />
                  {index === 3 && selectedPublication.images.length > 4 && (
                    <RemainingImagesOverlay>
                      +{selectedPublication.images.length - 4}
                    </RemainingImagesOverlay>
                  )}
                </ImageWrapper>
              ))}
            </ImageGrid>
          </Section>
        )}
      </ScrollableContent>

      <FixedActions>
        <Section>
          <SectionTitle>Acciones</SectionTitle>
          <ActionButton onClick={() => setShowEditModal(true)}>
            <FaPencilAlt /> Editar Publicación
          </ActionButton>
          <ActionButton onClick={() => setShowStatusModal(true)}>
            <FaEdit /> Cambiar Estado
          </ActionButton>
          <ActionButton onClick={() => setShowImagesModal(true)}>
            <FaImages /> Gestionar Imágenes
          </ActionButton>
          <DeleteButton onClick={handleDelete}>
            <FaTrash /> {confirmDelete ? 'Haz clic de nuevo para confirmar' : 'Eliminar Publicación'}
          </DeleteButton>
        </Section>
      </FixedActions>

      <StatusChangeModal
        show={showStatusModal}
        onHide={() => setShowStatusModal(false)}
        publication={selectedPublication}
        onSuccess={handleSuccess}
        statuses={statuses}
      />

      <ImagesModal
        show={showImagesModal}
        onHide={() => setShowImagesModal(false)}
        publication={selectedPublication}
        onSuccess={handleSuccess}
      />

      <CreateEditModal
        show={showEditModal}
        onHide={() => setShowEditModal(false)}
        publication={selectedPublication}
        onSuccess={handleSuccess}
        types={types}
        organizations={organizations}
      />
    </Panel>
  );
};