import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Button, Form, Dropdown } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import styled, { createGlobalStyle } from 'styled-components';
import { FaFilter, FaEye, FaPlus } from 'react-icons/fa';
import { 
  getCreatorProfiles, 
  getCreatorProfilesBySourceType,
  getCreatorProfilesBySourceTypes,
  createCreatorProfile,
  updateCreatorProfile,
  deleteCreatorProfile,
  addContentSource,
  updateContentSource,
  removeContentSource
} from './apiCreatorProfile';

import { CreatorProfileSlidingPanel } from './CreatorProfileSlidingPanel';
import { 
  CreateProfileModal,
  EditProfileModal,
  DeleteProfileModal,
  AddSourceModal,
  EditSourceModal,
  DeleteSourceModal,
  AlertModal
} from './CreatorProfileModals';

const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    background-color: #f1f3f4;
    color: #202124;
    margin: 0;
    padding: 0;
    overflow: hidden;
  }
`;

const StyledDataTable = styled(DataTable)`
  .rdt_Table {
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  }

  .rdt_TableHead {
    font-weight: 500;
    color: #5f6368;
    background-color: #f1f3f4;
  }

  .rdt_TableCol {
    padding: 16px;
  }

  .rdt_TableCell {
    font-size: 14px;
  }

  .rdt_Pagination {
    color: #5f6368;
    font-size: 14px;
    font-weight: 500;
    position: sticky;
    bottom: 0;
    background-color: #fff;
    z-index: 1;
  }
`;

const SlidingPanel = styled.div`
  position: fixed;
  top: 0;
  right: ${props => props.isOpen ? '0' : '-100%'};
  width: 400px;
  height: 100%;
  background-color: white;
  box-shadow: -2px 0 8px rgba(0, 0, 0, 0.15);
  transition: right 0.3s ease-in-out;
  overflow-y: auto;
  z-index: 1000;
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: ${props => props.isOpen ? 'block' : 'none'};
  z-index: 999;
`;

const GoogleButton = styled(Button)`
  background-color: #fff;
  border-color: #dadce0;
  color: #3c4043;
  font-weight: 500;
  padding: 8px 16px;
  font-size: 14px;
  
  &:hover, &:focus {
    background-color: #f1f3f4;
    border-color: #dadce0;
    box-shadow: 0 1px 2px 0 rgba(60,64,67,0.3), 0 1px 3px 1px rgba(60,64,67,0.15);
  }

  &:disabled {
    background-color: #f1f3f4;
    border-color: #dadce0;
    color: #80868b;
  }
`;

const IconButton = styled(GoogleButton)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  margin-right: 8px;
  
  svg {
    margin-right: 8px;
  }
`;

const FilterDropdown = styled(Dropdown)`
  .dropdown-toggle {
    background-color: #fff;
    border-color: #dadce0;
    color: #3c4043;
    font-weight: 500;
    
    &:hover, &:focus {
      background-color: #f1f3f4;
      border-color: #dadce0;
      box-shadow: 0 1px 2px 0 rgba(60,64,67,0.3), 0 1px 3px 1px rgba(60,64,67,0.15);
    }
  }

  .dropdown-menu {
    box-shadow: 0 2px 4px -1px rgba(0,0,0,0.2), 0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12);
    border: none;
    border-radius: 4px;
  }
`;

const LoadingOverlay = styled.div`
  width: 100%;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CircularProgress = styled.div`
  width: 40px;
  height: 40px;
  border: 3px solid #f3f3f3;
  border-top: 3px solid #1a73e8;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

const CreatorProfileManager = () => {
  const [profiles, setProfiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedProfile, setSelectedProfile] = useState(null);
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const [alertModal, setAlertModal] = useState({ show: false, title: '', message: '', variant: 'success' });
  
  // Filter state
  const [filters, setFilters] = useState({
    name: '',
    country: '',
    sourceType: ''
  });

  // Modal states
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showAddSourceModal, setShowAddSourceModal] = useState(false);
  const [showEditSourceModal, setShowEditSourceModal] = useState(false);
  const [showDeleteSourceModal, setShowDeleteSourceModal] = useState(false);
  
  // Content source state
  const [selectedSourceIndex, setSelectedSourceIndex] = useState(null);
  const [selectedSource, setSelectedSource] = useState(null);

  const fetchProfiles = useCallback(async () => {
    setLoading(true);
    try {
      let data;
      
      // If source type filter is applied
      if (filters.sourceType) {
        data = await getCreatorProfilesBySourceType(filters.source_type);
      } else {
        data = await getCreatorProfiles();
      }
      
      // Apply client-side filtering for name and country
      let filteredData = data.data || [];
      
      if (filters.name) {
        filteredData = filteredData.filter(profile => 
          profile.name && profile.name.toLowerCase().includes(filters.name.toLowerCase())
        );
      }
      
      if (filters.country) {
        filteredData = filteredData.filter(profile => 
          profile.country && profile.country.toLowerCase().includes(filters.country.toLowerCase())
        );
      }
      
      setProfiles(filteredData);
    } catch (error) {
      console.error('Error fetching profiles:', error);
      showAlert('Error', 'Failed to load creator profiles', 'error');
    } finally {
      setLoading(false);
    }
  }, [filters]);

  useEffect(() => {
    fetchProfiles();
  }, [fetchProfiles]);

  const handleFilterChange = (filterName, value) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      [filterName]: value
    }));
  };

  const clearFilters = () => {
    setFilters({
      name: '',
      country: '',
      sourceType: ''
    });
  };

  const showAlert = useCallback((title, message, variant = 'success') => {
    setAlertModal({ show: true, title, message, variant });
  }, []);

  const closeAlert = useCallback(() => {
    setAlertModal({ show: false, title: '', message: '', variant: 'success' });
  }, []);

  const handleViewDetails = (row) => {
    setSelectedProfile(row);
    setIsPanelOpen(true);
  };

  const closeSlidingPanel = () => {
    setIsPanelOpen(false);
    setSelectedProfile(null);
  };

  const handleCreateProfile = async (profile) => {
    try {
      await createCreatorProfile(profile);
      fetchProfiles();
      setShowCreateModal(false);
      showAlert('Success', 'Creator profile created successfully', 'success');
    } catch (error) {
      console.error('Error creating profile:', error);
      showAlert('Error', 'Failed to create creator profile', 'error');
    }
  };

  const handleUpdateProfile = async (id, profile) => {
    try {
      await updateCreatorProfile(id, profile);
      fetchProfiles();
      setShowEditModal(false);
      showAlert('Success', 'Creator profile updated successfully', 'success');
      
      // Update the selected profile if panel is open
      if (selectedProfile && selectedProfile.id === id) {
        setSelectedProfile({...selectedProfile, ...profile});
      }
    } catch (error) {
      console.error('Error updating profile:', error);
      showAlert('Error', 'Failed to update creator profile', 'error');
    }
  };

  const handleDeleteProfile = async (id) => {
    try {
      await deleteCreatorProfile(id);
      fetchProfiles();
      setShowDeleteModal(false);
      closeSlidingPanel();
      showAlert('Success', 'Creator profile deleted successfully', 'success');
    } catch (error) {
      console.error('Error deleting profile:', error);
      showAlert('Error', 'Failed to delete creator profile', 'error');
    }
  };

  const handleAddSource = async (id, source) => {
    try {
      const response = await addContentSource(id, source);
      fetchProfiles();
      setShowAddSourceModal(false);
      showAlert('Success', 'Content source added successfully', 'success');
      
      // Update the selected profile if panel is open
      if (selectedProfile && selectedProfile.id === id) {
        setSelectedProfile(response.data);
      }
    } catch (error) {
      console.error('Error adding source:', error);
      showAlert('Error', 'Failed to add content source', 'error');
    }
  };

  const handleUpdateSource = async (id, sourceIndex, source) => {
    try {
      const response = await updateContentSource(id, sourceIndex, source);
      fetchProfiles();
      setShowEditSourceModal(false);
      showAlert('Success', 'Content source updated successfully', 'success');
      
      // Update the selected profile if panel is open
      if (selectedProfile && selectedProfile.id === id) {
        setSelectedProfile(response.data);
      }
    } catch (error) {
      console.error('Error updating source:', error);
      showAlert('Error', 'Failed to update content source', 'error');
    }
  };

  const handleRemoveSource = async (id, sourceIndex) => {
    try {
      const response = await removeContentSource(id, sourceIndex);
      fetchProfiles();
      setShowDeleteSourceModal(false);
      showAlert('Success', 'Content source removed successfully', 'success');
      
      // Update the selected profile if panel is open
      if (selectedProfile && selectedProfile.id === id) {
        setSelectedProfile(response.data);
      }
    } catch (error) {
      console.error('Error removing source:', error);
      showAlert('Error', 'Failed to remove content source', 'error');
    }
  };

  const openEditModal = (profile) => {
    setSelectedProfile(profile);
    setShowEditModal(true);
  };

  const openDeleteModal = (profile) => {
    setSelectedProfile(profile);
    setShowDeleteModal(true);
  };

  const openAddSourceModal = (profile) => {
    setSelectedProfile(profile);
    setShowAddSourceModal(true);
  };

  const openEditSourceModal = (profile, source, index) => {
    setSelectedProfile(profile);
    setSelectedSource(source);
    setSelectedSourceIndex(index);
    setShowEditSourceModal(true);
  };

  const openDeleteSourceModal = (profile, index) => {
    setSelectedProfile(profile);
    setSelectedSourceIndex(index);
    setShowDeleteSourceModal(true);
  };
  
  const columns = useMemo(() => [
    {
      name: '',
      width: '60px',
      cell: row => (
        <div style={{ 
          borderRadius: '50%',
          backgroundColor: '#1a73e8',
          color: 'white',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          overflow: 'hidden'
        }}>
          {row.avatar ? (
            <img 
              src={row.avatar} 
              alt={row.name} 
              style={{ width: '100%', height: '100%', objectFit: 'cover' }} 
            />
          ) : (
            row.name?.split(' ').map(n => n[0]).join('').toUpperCase() || '?'
          )}
        </div>
      )
    },
    {
      name: 'Name',
      selector: row => row.name || 'N/A',
      sortable: true,
      wrap: true,
    },
    {
      name: 'Country',
      selector: row => row.country || 'N/A',
      sortable: true,
      wrap: true,
    },
    {
      name: 'Sources',
      selector: row => row.contentSources ? row.contentSources.length : 0,
      sortable: true,
    },
    {
      name: 'Status',
      selector: row => row.active ? 'Active' : 'Inactive',
      sortable: true,
      cell: row => (
        <div style={{ 
          backgroundColor: row.active ? '#e6f4ea' : '#fce8e6',
          color: row.active ? '#137333' : '#c5221f',
          padding: '4px 8px',
          borderRadius: '4px',
          fontWeight: '500',
          fontSize: '12px'
        }}>
          {row.active ? 'Active' : 'Inactive'}
        </div>
      )
    },
    {
      name: 'Created',
      selector: row => row.created_at ? new Date(row.created_at).toLocaleDateString() : 'N/A',
      sortable: true,
    },
    {
      name: 'Details',
      cell: row => (
        <IconButton onClick={() => handleViewDetails(row)}>
          <FaEye /> View
        </IconButton>
      )
    }
  ], []);

  const handleOutsideClick = (event) => {
    if (isPanelOpen && !event.target.closest(".sliding-panel-content") && 
        !event.target.closest(".modal")) {
      closeSlidingPanel();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [isPanelOpen]);

  return (
    <>
      <GlobalStyle />
      <div className="container-fluid py-4">
        <div className="d-flex justify-content-between align-items-center mb-4">
          <h2>Creator Profiles</h2>
          <div className="d-flex gap-3">
            <IconButton onClick={() => setShowCreateModal(true)}>
              <FaPlus /> New Creator
            </IconButton>
            <FilterDropdown>
              <Dropdown.Toggle variant="outline-secondary" id="dropdown-filters">
                <FaFilter /> Filters
              </Dropdown.Toggle>
              <Dropdown.Menu className="p-3" style={{ width: '300px' }}>
                <Form>
                  <Form.Group className="mb-2">
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      type="text"
                      value={filters.name}
                      onChange={(e) => handleFilterChange('name', e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group className="mb-2">
                    <Form.Label>Country</Form.Label>
                    <Form.Control
                      type="text"
                      value={filters.country}
                      onChange={(e) => handleFilterChange('country', e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Source Type</Form.Label>
                    <Form.Control
                      as="select"
                      value={filters.sourceType}
                      onChange={(e) => handleFilterChange('sourceType', e.target.value)}
                    >
                      <option value="">All</option>
                      <option value="YOUTUBE">YouTube</option>
                      <option value="RSS">RSS</option>
                    </Form.Control>
                  </Form.Group>
                  <div className="d-flex justify-content-end">
                    <GoogleButton onClick={clearFilters}>Clear Filters</GoogleButton>
                  </div>
                </Form>
              </Dropdown.Menu>
            </FilterDropdown>
          </div>
        </div>

        <StyledDataTable
          columns={columns}
          data={profiles}
          pagination
          highlightOnHover
          pointerOnHover
          progressPending={loading}
          progressComponent={
            <LoadingOverlay>
              <CircularProgress />
            </LoadingOverlay>
          }
          fixedHeader
          noDataComponent={<div style={{ padding: '24px', textAlign: 'center', color: '#5f6368' }}>No creator profiles available</div>}
        />

        <Overlay isOpen={isPanelOpen} />
        <SlidingPanel isOpen={isPanelOpen} className="sliding-panel-content">
          <CreatorProfileSlidingPanel
            selectedProfile={selectedProfile}
            onClose={closeSlidingPanel}
            onEdit={openEditModal}
            onDelete={openDeleteModal}
            onAddSource={openAddSourceModal}
            onEditSource={openEditSourceModal}
            onDeleteSource={openDeleteSourceModal}
          />
        </SlidingPanel>

        <CreateProfileModal
          show={showCreateModal}
          onHide={() => setShowCreateModal(false)}
          onCreate={handleCreateProfile}
        />

        <EditProfileModal
          show={showEditModal}
          onHide={() => setShowEditModal(false)}
          profile={selectedProfile}
          onUpdate={handleUpdateProfile}
        />

        <DeleteProfileModal
          show={showDeleteModal}
          onHide={() => setShowDeleteModal(false)}
          profile={selectedProfile}
          onDelete={handleDeleteProfile}
        />

        <AddSourceModal
          show={showAddSourceModal}
          onHide={() => setShowAddSourceModal(false)}
          profile={selectedProfile}
          onAdd={handleAddSource}
        />

        <EditSourceModal
          show={showEditSourceModal}
          onHide={() => setShowEditSourceModal(false)}
          profile={selectedProfile}
          source={selectedSource}
          sourceIndex={selectedSourceIndex}
          onUpdate={handleUpdateSource}
        />

        <DeleteSourceModal
          show={showDeleteSourceModal}
          onHide={() => setShowDeleteSourceModal(false)}
          profile={selectedProfile}
          sourceIndex={selectedSourceIndex}
          onDelete={handleRemoveSource}
        />

        <AlertModal
          show={alertModal.show}
          onHide={closeAlert}
          title={alertModal.title}
          message={alertModal.message}
          variant={alertModal.variant}
        />
      </div>
    </>
  );
};

export default CreatorProfileManager;