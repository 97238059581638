import api from '../apiBase';

export const fetchIncidents = async (params) => {
  try {
    const response = await api.get('/api/incidents', { params });
    return response.data;
  } catch (error) {
    console.error('Error fetching incidents:', error);
    throw error;
  }
};

export const createIncident = async (formData) => {
  try {
    const response = await api.post('/api/incidents', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Accept': 'application/json; charset=utf-8'
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error creating incident:', error);
    throw error;
  }
};

export const updateIncident = async (id, formData) => {
  try {
    const response = await api.put(`/api/incidents/${id}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Accept': 'application/json; charset=utf-8'
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error updating incident:', error);
    throw error;
  }
};

export const deleteIncident = async (id) => {
  try {
    await api.delete(`/api/incidents/${id}`);
  } catch (error) {
    console.error('Error deleting incident:', error);
    throw error;
  }
};

export const updateIncidentType = async (id, type) => {
  try {
    const response = await api.put(`/api/incidents/${id}/type`, { type }, {
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        'Accept': 'application/json; charset=utf-8'
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error updating incident type:', error);
    throw error;
  }
};

export const fetchIncidentPeople = async (params) => {
  try {
    const response = await api.get('/api/incident-people', { params });
    return response.data;
  } catch (error) {
    console.error('Error fetching incident people:', error);
    throw error;
  }
};

export const fetchProvinces = async () => {
  try {
    const response = await api.get('/api/locations/v1/provinces', {
      headers: {
        'Accept': 'application/json; charset=utf-8'
      },
      transformResponse: [(data) => {
        // Try to parse the data with proper UTF-8 handling
        try {
          // Parse JSON manually to handle UTF-8 encoding issues
          const parsedData = JSON.parse(data);
          return parsedData;
        } catch (e) {
          console.error('Error parsing province data:', e);
          return data; // Return raw data as fallback
        }
      }]
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching provinces:', error);
    throw error;
  }
};

export const fetchMunicipalities = async () => {
  try {
    const response = await api.get('/api/locations/v1/municipalities', {
      headers: {
        'Accept': 'application/json; charset=utf-8'
      },
      transformResponse: [(data) => {
        // Try to parse the data with proper UTF-8 handling
        try {
          // Parse JSON manually to handle UTF-8 encoding issues
          const parsedData = JSON.parse(data);
          return parsedData;
        } catch (e) {
          console.error('Error parsing municipality data:', e);
          return data; // Return raw data as fallback
        }
      }]
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching municipalities:', error);
    throw error;
  }
};

export const fetchIncidentTypes = async () => {
  try {
    const response = await api.get('/api/incidents/types');
    return response.data;
  } catch (error) {
    console.error('Error fetching incident types:', error);
    throw error;
  }
};

export const fetchIncidentActivities = async (parentId) => {
  try {
    const response = await api.get(`/api/incident-activities/parent/${parentId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching incident activities:', error);
    throw error;
  }
};

export const createIncidentActivity = async (data) => {
  try {
    const response = await api.post('/api/incident-activities', data, {
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        'Accept': 'application/json; charset=utf-8'
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error creating incident activity:', error);
    throw error;
  }
};

export const updateIncidentActivity = async (id, data) => {
  try {
    const response = await api.put(`/api/incident-activities/${id}`, data, {
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        'Accept': 'application/json; charset=utf-8'
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error updating incident activity:', error);
    throw error;
  }
};

export const deleteIncidentActivity = async (id) => {
  try {
    await api.delete(`/api/incident-activities/${id}`);
  } catch (error) {
    console.error('Error deleting incident activity:', error);
    throw error;
  }
};

export const getPeopleByIncident = async (incidentId, type = null) => {
  try {
    const response = await api.get(`/api/incident-people/by-incident/${incidentId}`, {
      params: { type }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching people by incident:', error);
    throw error;
  }
};

export const addIncidentToPerson = async (personId, incidentId) => {
  try {
    const response = await api.put(`/api/incident-people/${personId}/incidents/${incidentId}`);
    return response.data;
  } catch (error) {
    console.error('Error adding incident to person:', error);
    throw error;
  }
};

export const removeIncidentFromPerson = async (personId, incidentId) => {
  try {
    const response = await api.delete(`/api/incident-people/${personId}/incidents/${incidentId}`);
    return response.data;
  } catch (error) {
    console.error('Error removing incident from person:', error);
    throw error;
  }
};