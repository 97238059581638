import React, { useState, useEffect, useCallback } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { Button, Card, Form, Row, Col, Spinner, Alert } from 'react-bootstrap';
import { FaEdit, FaCheck, FaTimes, FaImage, FaUser, FaUserPlus, FaFileAlt, FaFileUpload, FaDownload, FaUserEdit, FaTrashAlt } from 'react-icons/fa';
import { 
  getPoliticalOrganizationByMemberId, 
  updatePoliticalOrganization,
  uploadPoliticalOrganizationLogo,
  getSocialMediaTypes,
  getOrganizationMembers,
  addOrganizationMember,
  updateOrganizationMember,
  updateMemberAvatar,
  updatePoliticalOrganizationLogo,
  deleteOrganizationMember,
  getOrganizationDocuments,
  addOrganizationDocument,
  deleteOrganizationDocument
} from './apiPoliticalOrganization';
import { LogoModal, MemberModal, AvatarModal, DocumentModal } from './PoliticalOrganizationModals';

const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    background-color: #f1f3f4;
    color: #202124;
    margin: 0;
    padding: 0;
  }
`;

const MainContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 24px;
`;

const ProfileHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  flex-wrap: wrap;
  gap: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const LogoContainer = styled.div`
  position: relative;
  width: 120px;
  height: 120px;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
`;

const Logo = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const LogoOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s ease;

  &:hover {
    opacity: 1;
    cursor: pointer;
  }
`;

const HeaderInfo = styled.div`
  flex: 1;
`;

const OrganizationName = styled.h1`
  margin: 0 0 8px 0;
  font-size: 32px;
  font-weight: 400;
  color: #202124;
`;

const OrganizationType = styled.div`
  font-size: 16px;
  color: #5f6368;
  margin-bottom: 8px;
`;

const ProfileSection = styled(Card)`
  margin-bottom: 24px;
  border: none;
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3), 0 1px 3px 1px rgba(60, 64, 67, 0.15);
  border-radius: 8px;
  overflow: hidden;
`;

const SectionHeader = styled(Card.Header)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
  background-color: #f8f9fa;
  border-bottom: 1px solid #dadce0;
`;

const SectionTitle = styled.h2`
  margin: 0;
  font-size: 18px;
  font-weight: 500;
  color: #202124;
`;

const SectionContent = styled(Card.Body)`
  padding: 24px;
`;

const InfoGroup = styled.div`
  margin-bottom: 20px;
  
  &:last-child {
    margin-bottom: 0;
  }
`;

const InfoLabel = styled.div`
  font-size: 14px;
  color: #5f6368;
  margin-bottom: 8px;
`;

const InfoValue = styled.div`
  font-size: 16px;
  color: #202124;
`;

const EditButton = styled(Button)`
  background-color: transparent;
  border: none;
  color: #1a73e8;
  font-size: 14px;
  padding: 8px 12px;
  
  &:hover, &:focus {
    background-color: rgba(26, 115, 232, 0.1);
    color: #1a73e8;
    box-shadow: none;
  }
`;

const ActionButton = styled(Button)`
  margin-right: 8px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  padding: 6px 12px;
  
  &.confirm {
    background-color: #1a73e8;
    border-color: #1a73e8;
    
    &:hover {
      background-color: #1765cc;
      border-color: #1765cc;
    }
  }
  
  &.cancel {
    background-color: transparent;
    border-color: #dadce0;
    color: #5f6368;
    
    &:hover {
      background-color: #f1f3f4;
      border-color: #dadce0;
      color: #202124;
    }
  }
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 400px;
`;

const MemberCard = styled(Card)`
  border: none;
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3);
  margin-bottom: 16px;
  border-radius: 8px;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  
  &:hover {
    box-shadow: 0 4px 8px 0 rgba(60, 64, 67, 0.25);
    transform: translateY(-2px);
  }
`;

const MemberCardBody = styled(Card.Body)`
  display: flex;
  align-items: center;
  padding: 16px;
`;

// First, make sure MemberAvatar has proper positioning
const MemberAvatar = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #e0e0e0;
  margin-right: 16px;
  background-image: ${props => props.image ? `url(${props.image})` : 'none'};
  background-size: cover;
  background-position: center;
  flex-shrink: 0;
  position: relative; /* This ensures the overlay positions correctly */
  overflow: hidden; /* This hides any overflow from the avatar image */
  
  /* Remove any default styling that might be causing the checkbox appearance */
  &::before, &::after {
    display: none;
    content: none;
  }
  
  &:hover .avatar-overlay {
    opacity: 1;
  }
`;

// Then ensure the AvatarOverlay covers the entire avatar area exactly
const AvatarOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%; /* Match the avatar's border-radius */
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s ease;
  cursor: pointer;
  z-index: 1; /* Ensure it's above any other content */
`;

const MemberInfo = styled.div`
  flex: 1;
`;

const MemberName = styled.div`
  font-weight: 500;
  color: #202124;
  font-size: 16px;
  margin-bottom: 4px;
`;

const MemberPosition = styled.div`
  font-size: 14px;
  color: #5f6368;
  margin-bottom: 4px;
`;

const MemberDescription = styled.div`
  font-size: 14px;
  color: #5f6368;
`;

const MemberActions = styled.div`
  display: flex;
  gap: 8px;
`;

const IconButton = styled(Button)`
  background-color: transparent;
  border: none;
  color: ${props => props.color || '#1a73e8'};
  padding: 6px;
  
  &:hover, &:focus {
    background-color: rgba(0, 0, 0, 0.05);
    color: ${props => props.color || '#1a73e8'};
    box-shadow: none;
  }
`;

const DocumentCard = styled(Card)`
  border: none;
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3);
  margin-bottom: 16px;
  border-radius: 8px;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  
  &:hover {
    box-shadow: 0 4px 8px 0 rgba(60, 64, 67, 0.25);
    transform: translateY(-2px);
  }
`;

const DocumentCardBody = styled(Card.Body)`
  display: flex;
  align-items: center;
  padding: 16px;
`;

const DocumentIcon = styled.div`
  width: 40px;
  height: 40px;
  min-width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e8eaed;
  border-radius: 4px;
  margin-right: 16px;
  color: #5f6368;
`;

const DocumentInfo = styled.div`
  flex: 1;
`;

const DocumentTitle = styled.div`
  font-weight: 500;
  color: #202124;
  font-size: 16px;
  margin-bottom: 4px;
`;

const DocumentDescription = styled.div`
  font-size: 14px;
  color: #5f6368;
`;

const DocumentActions = styled.div`
  display: flex;
  gap: 8px;
`;

const SocialMediaCard = styled(Card)`
  margin-bottom: 15px;
  border: none;
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3);
  border-radius: 8px;
`;

const SocialMediaCardBody = styled(Card.Body)`
  display: flex;
  align-items: center;
  padding: 16px;
`;

const SocialMediaIcon = styled.div`
  width: 40px;
  height: 40px;
  min-width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e8eaed;
  border-radius: 4px;
  margin-right: 16px;
`;

const SocialMediaUrl = styled.div`
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  a {
    color: #1a73e8;
    text-decoration: none;
    
    &:hover {
      text-decoration: underline;
    }
  }
`;

const ActionButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 16px;
`;

const PoliticalOrganizationProfile = () => {
  const [organization, setOrganization] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  
  // Datos de miembros y documentos
  const [members, setMembers] = useState([]);
  const [documents, setDocuments] = useState([]);
  
  // Editing state
  const [editMode, setEditMode] = useState({
    basicInfo: false,
    contact_info: false,
    socialMedia: false
  });
  
  // Form state
  const [formData, setFormData] = useState({
    type: '',
    typeName: '',
    name: '',
    description: '',
    foundation_date: '',
    contact_info: { email: '', phones: [] },
    website: '',
    social_media_info: []
  });
  
  // Modales
  const [showLogoModal, setShowLogoModal] = useState(false);
  const [showMemberModal, setShowMemberModal] = useState(false);
  const [showAvatarModal, setShowAvatarModal] = useState(false);
  const [showDocumentModal, setShowDocumentModal] = useState(false);
  const [selectedMember, setSelectedMember] = useState(null);
  const [isEditingMember, setIsEditingMember] = useState(false);
  
  // Estados para operaciones
  const [modalLoading, setModalLoading] = useState(false);
  const [socialMediaTypes, setSocialMediaTypes] = useState([]);

  // Fetch political organization data
  const fetchOrganizationData = useCallback(async () => {
    try {
      setLoading(true);
      setError(null);
      const response = await getPoliticalOrganizationByMemberId();
      
      if (response.data) {
        // Procesar las redes sociales para añadir el tipo basado en el logo
        const socialMediaWithTypes = response.data.social_media_info?.map(social => {
          // Intentamos detectar el tipo basado en la URL del logo
          let type = '';
          if (social.logo) {
            const logoUrl = social.logo.toLowerCase();
            // Extraemos el nombre del archivo sin extensión
            const fileName = logoUrl.split('/').pop()?.split('.')[0];
            if (fileName) {
              type = fileName.toUpperCase();
            }
          }
          
          return {
            ...social,
            type
          };
        }) || [];
        
        setOrganization(response.data);
        setFormData({
          type: response.data.type,
          typeName: response.data.typeName,
          name: response.data.name,
          description: response.data.description,
          foundation_date: formatDateForInput(response.data.foundation_date),
          contact_info: response.data.contact_info || { email: '', phones: [] },
          website: response.data.website || '',
          social_media_info: socialMediaWithTypes
        });
        
        // Cargar miembros y documentos si el ID está disponible
        if (response.data.id) {
          fetchMembers(response.data.id);
          fetchDocuments(response.data.id);
        }
      } else if (response.error) {
        setError(response.error.message);
      }
    } catch (err) {
      setError('Error al cargar la organización política');
      console.error(err);
    } finally {
      setLoading(false);
    }
  }, []);

  const fetchSocialMediaTypes = useCallback(async () => {
    try {
      const response = await getSocialMediaTypes();
      if (response.data) {
        setSocialMediaTypes(response.data);
      }
    } catch (err) {
      console.error("Error al cargar tipos de redes sociales:", err);
    }
  }, []);
  
  const fetchMembers = useCallback(async (organizationId) => {
    try {
      const response = await getOrganizationMembers(organizationId);
      if (response.data) {
        setMembers(response.data);
      }
    } catch (err) {
      console.error("Error al cargar miembros:", err);
    }
  }, []);
  
  const fetchDocuments = useCallback(async (organizationId) => {
    try {
      const response = await getOrganizationDocuments(organizationId);
      if (response.data) {
        setDocuments(response.data);
      }
    } catch (err) {
      console.error("Error al cargar documentos:", err);
    }
  }, []);

  useEffect(() => {
    fetchOrganizationData();
    fetchSocialMediaTypes();
  }, [fetchOrganizationData, fetchSocialMediaTypes]);

  // Utility function to format dates for input fields
  const formatDateForInput = (dateString) => {
    if (!dateString) return '';
    
    try {
      const date = new Date(dateString);
      return date.toISOString().split('T')[0];
    } catch (error) {
      console.error("Error formatting date:", error);
      return '';
    }
  };

  // Handle form input changes
  const handleInputChange = (section, field, value) => {
    if (section) {
      setFormData(prev => ({
        ...prev,
        [section]: {
          ...prev[section],
          [field]: value
        }
      }));
    } else {
      setFormData(prev => ({
        ...prev,
        [field]: value
      }));
    }
  };

  // Handle phone number changes
  const handlePhoneChange = (index, value) => {
    const updatedPhones = [...formData.contact_info.phones];
    updatedPhones[index] = value;
    
    setFormData(prev => ({
      ...prev,
      contact_info: {
        ...prev.contact_info,
        phones: updatedPhones
      }
    }));
  };

  // Add new phone field
  const addPhone = () => {
    setFormData(prev => ({
      ...prev,
      contact_info: {
        ...prev.contact_info,
        phones: [...prev.contact_info.phones, '']
      }
    }));
  };

  // Remove phone field
  const removePhone = (index) => {
    const updatedPhones = [...formData.contact_info.phones];
    updatedPhones.splice(index, 1);
    
    setFormData(prev => ({
      ...prev,
      contact_info: {
        ...prev.contact_info,
        phones: updatedPhones
      }
    }));
  };

  // Handle social media changes
  const handleSocialMediaChange = (index, field, value) => {
    const updatedSocialMedia = [...formData.social_media_info];
    
    if (field === 'type' && value) {
      // Si cambia el tipo, actualizamos el logo con el ícono correspondiente
      const selectedType = socialMediaTypes.find(type => type.code === value);
      updatedSocialMedia[index] = {
        ...updatedSocialMedia[index],
        logo: selectedType ? selectedType.icon : '',
        type: value
      };
    } else {
      updatedSocialMedia[index] = {
        ...updatedSocialMedia[index],
        [field]: value
      };
    }
    
    setFormData(prev => ({
      ...prev,
      social_media_info: updatedSocialMedia
    }));
  };

  // Add new social media field
  const addSocialMedia = () => {
    setFormData(prev => ({
      ...prev,
      social_media_info: [
        ...prev.social_media_info, 
        { 
          logo: socialMediaTypes.length > 0 ? socialMediaTypes[0].icon : '',
          url: '',
          type: socialMediaTypes.length > 0 ? socialMediaTypes[0].code : ''
        }
      ]
    }));
  };

  // Remove social media field
  const removeSocialMedia = (index) => {
    const updatedSocialMedia = [...formData.social_media_info];
    updatedSocialMedia.splice(index, 1);
    
    setFormData(prev => ({
      ...prev,
      social_media_info: updatedSocialMedia
    }));
  };

  // Toggle edit mode for a section
  const toggleEditMode = (section) => {
    // If canceling edit mode, reset form data for that section
    if (editMode[section]) {
      if (organization) {
        if (section === 'basicInfo') {
          setFormData(prev => ({
            ...prev,
            type: organization.type,
            typeName: organization.typeName,
            name: organization.name,
            description: organization.description,
            foundation_date: formatDateForInput(organization.foundation_date),
          }));
        } else if (section === 'contact_info') {
          setFormData(prev => ({
            ...prev,
            contact_info: organization.contact_info || { email: '', phones: [] },
            website: organization.website || ''
          }));
        } else if (section === 'socialMedia') {
          // Procesar las redes sociales para añadir el tipo
          const socialMediaWithTypes = organization.social_media_info?.map(social => {
            let type = '';
            if (social.logo) {
              const logoUrl = social.logo.toLowerCase();
              const fileName = logoUrl.split('/').pop()?.split('.')[0];
              if (fileName) {
                type = fileName.toUpperCase();
              }
            }
            
            return {
              ...social,
              type
            };
          }) || [];
          
          setFormData(prev => ({
            ...prev,
            social_media_info: socialMediaWithTypes
          }));
        }
      }
    }
    
    setEditMode(prev => ({
      ...prev,
      [section]: !prev[section]
    }));
  };

  // Save changes for a section
  const saveChanges = async (section) => {
    if (!organization) return;
    
    try {
      setLoading(true);
      
      // Crear copia profunda de los datos para no modificar el estado directamente
      let updateData = JSON.parse(JSON.stringify(formData));
      
      // Manejar formateo especial para la fecha de fundación
      if (updateData.foundation_date) {
        updateData.foundation_date = new Date(updateData.foundation_date).toISOString();
      }
      
      // Si estamos editando redes sociales, eliminar la propiedad 'type' que no necesita el backend
      if (section === 'socialMedia') {
        updateData.social_media_info = updateData.social_media_info.map(({type, ...rest}) => rest);
      }
      
      // Enviar los datos actualizados al backend
      const response = await updatePoliticalOrganization(organization.id, updateData);
      
      if (response.data) {
        // Procesar las redes sociales en la respuesta para añadir nuevamente el tipo
        if (response.data.social_media_info) {
          const socialMediaWithTypes = response.data.social_media_info.map(social => {
            // Intentamos detectar el tipo basado en la URL del logo
            let type = '';
            if (social.logo) {
              const logoUrl = social.logo.toLowerCase();
              const fileName = logoUrl.split('/').pop()?.split('.')[0];
              if (fileName) {
                type = fileName.toUpperCase();
              }
            }
            
            return {
              ...social,
              type
            };
          });
          
          // Crear una copia de los datos recibidos con los tipos añadidos
          const updatedOrganization = {
            ...response.data,
            social_media_info: socialMediaWithTypes
          };
          
          setOrganization(updatedOrganization);
        } else {
          setOrganization(response.data);
        }
        
        // Desactivar el modo de edición para la sección actual
        toggleEditMode(section);
      } else if (response.error) {
        setError(response.error.message);
      }
    } catch (err) {
      setError('Error al actualizar la organización política');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  // Handle logo update
  const handleLogoSave = async (logoInput) => {
    if (!organization) return;
    
    try {
      setModalLoading(true);
      let response;
      
      if (typeof logoInput === 'string') {
        // Es una URL
        const logoData = { logo: logoInput };
        response = await updatePoliticalOrganizationLogo(organization.id, logoData);
      } else {
        // Es un archivo
        response = await uploadPoliticalOrganizationLogo(organization.id, logoInput);
      }
      
      if (response.data) {
        setOrganization(response.data);
        setShowLogoModal(false);
      } else if (response.error) {
        setError(response.error.message);
      }
    } catch (err) {
      setError('Error al actualizar el logo');
      console.error(err);
    } finally {
      setModalLoading(false);
    }
  };

  // Métodos para gestionar miembros
  const handleAddMember = async (memberData, avatarFile) => {
    if (!organization) return;
    
    try {
      setModalLoading(true);
      const response = await addOrganizationMember(organization.id, memberData, avatarFile);
      
      if (response.data) {
        // Refrescar la lista de miembros
        fetchMembers(organization.id);
        setShowMemberModal(false);
      } else if (response.error) {
        setError(response.error.message);
      }
    } catch (err) {
      setError('Error al añadir el miembro');
      console.error(err);
    } finally {
      setModalLoading(false);
    }
  };

  const handleUpdateMember = async (memberData) => {
    if (!organization || !selectedMember) return;
    
    try {
      setModalLoading(true);
      const response = await updateOrganizationMember(organization.id, selectedMember.id, memberData);
      
      if (response.data) {
        // Refrescar la lista de miembros
        fetchMembers(organization.id);
        setShowMemberModal(false);
      } else if (response.error) {
        setError(response.error.message);
      }
    } catch (err) {
      setError('Error al actualizar el miembro');
      console.error(err);
    } finally {
      setModalLoading(false);
    }
  };

  const handleUpdateAvatar = async (avatarFile) => {
    if (!organization || !selectedMember) return;
    
    try {
      setModalLoading(true);
      const response = await updateMemberAvatar(organization.id, selectedMember.id, avatarFile);
      
      if (response.data) {
        // Refrescar la lista de miembros
        fetchMembers(organization.id);
        setShowAvatarModal(false);
      } else if (response.error) {
        setError(response.error.message);
      }
    } catch (err) {
      setError('Error al actualizar el avatar');
      console.error(err);
    } finally {
      setModalLoading(false);
    }
  };

  const handleDeleteMember = async (memberId) => {
    if (!organization) return;
    
    if (!window.confirm('¿Está seguro de que desea eliminar este miembro?')) {
      return;
    }
    
    try {
      setLoading(true);
      const response = await deleteOrganizationMember(organization.id, memberId);
      
      if (response.data) {
        // Refrescar la lista de miembros
        fetchMembers(organization.id);
      } else if (response.error) {
        setError(response.error.message);
      }
    } catch (err) {
      setError('Error al eliminar el miembro');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  // Métodos para gestionar documentos
  const handleAddDocument = async (documentData, documentFile) => {
    if (!organization) return;
    
    try {
      setModalLoading(true);
      const response = await addOrganizationDocument(organization.id, documentData, documentFile);
      
      if (response.data) {
        // Refrescar la lista de documentos
        fetchDocuments(organization.id);
        setShowDocumentModal(false);
      } else if (response.error) {
        setError(response.error.message);
      }
    } catch (err) {
      setError('Error al añadir el documento');
      console.error(err);
    } finally {
      setModalLoading(false);
    }
  };

  const handleDeleteDocument = async (documentId) => {
    if (!organization) return;
    
    if (!window.confirm('¿Está seguro de que desea eliminar este documento?')) {
      return;
    }
    
    try {
      setLoading(true);
      const response = await deleteOrganizationDocument(organization.id, documentId);
      
      if (response.data) {
        // Refrescar la lista de documentos
        fetchDocuments(organization.id);
      } else if (response.error) {
        setError(response.error.message);
      }
    } catch (err) {
      setError('Error al eliminar el documento');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  if (loading && !organization) {
    return (
      <LoadingContainer>
        <Spinner animation="border" role="status" variant="primary">
          <span className="visually-hidden">Cargando...</span>
        </Spinner>
      </LoadingContainer>
    );
  }

  if (error && !organization) {
    return (
      <MainContainer>
        <GlobalStyle />
        <Alert variant="danger">
          {error}
        </Alert>
        <Button variant="primary" onClick={fetchOrganizationData}>
          Reintentar
        </Button>
      </MainContainer>
    );
  }

  return (
    <MainContainer>
      <GlobalStyle />
      
      {error && (
        <Alert variant="danger" className="mb-4" dismissible onClose={() => setError(null)}>
          {error}
        </Alert>
      )}
      
      <ProfileHeader>
        <LogoContainer>
          <Logo src={organization?.logo || 'https://via.placeholder.com/120'} alt="Logo" />
          <LogoOverlay onClick={() => setShowLogoModal(true)}>
            <FaImage size={24} color="white" />
          </LogoOverlay>
        </LogoContainer>
        
        <HeaderInfo>
          <OrganizationName>{organization?.name}</OrganizationName>
          <OrganizationType>{organization?.typeName}</OrganizationType>
        </HeaderInfo>
      </ProfileHeader>
      
      {/* Basic Information Section */}
      <ProfileSection>
        <SectionHeader>
          <SectionTitle>Información Básica</SectionTitle>
          
          {!editMode.basicInfo ? (
            <EditButton onClick={() => toggleEditMode('basicInfo')}>
              <FaEdit size={16} /> Editar
            </EditButton>
          ) : (
            <div>
              <ActionButton 
                className="confirm" 
                onClick={() => saveChanges('basicInfo')}
                disabled={loading}
              >
                <FaCheck size={14} /> Guardar
              </ActionButton>
              <ActionButton 
                className="cancel" 
                onClick={() => toggleEditMode('basicInfo')}
                disabled={loading}
              >
                <FaTimes size={14} /> Cancelar
              </ActionButton>
            </div>
          )}
        </SectionHeader>
        
        <SectionContent>
          {!editMode.basicInfo ? (
            <Row>
              <Col md={6}>
                <InfoGroup>
                  <InfoLabel>Tipo</InfoLabel>
                  <InfoValue>{organization?.typeName}</InfoValue>
                </InfoGroup>
                
                <InfoGroup>
                  <InfoLabel>Nombre</InfoLabel>
                  <InfoValue>{organization?.name}</InfoValue>
                </InfoGroup>
                
                <InfoGroup>
                  <InfoLabel>Fecha de Fundación</InfoLabel>
                  <InfoValue>
                    {organization?.foundation_date ? new Date(organization.foundation_date).toLocaleDateString() : 'No especificada'}
                  </InfoValue>
                </InfoGroup>
              </Col>
              
              <Col md={6}>
                <InfoGroup>
                  <InfoLabel>Descripción</InfoLabel>
                  <InfoValue>{organization?.description}</InfoValue>
                </InfoGroup>
              </Col>
            </Row>
          ) : (
            <Form>
              <Row>
                <Col md={6}>                  
                  <Form.Group className="mb-3">
                    <Form.Label>Nombre</Form.Label>
                    <Form.Control
                      type="text"
                      value={formData.name}
                      onChange={(e) => handleInputChange(null, 'name', e.target.value)}
                    />
                  </Form.Group>
                  
                  <Form.Group className="mb-3">
                    <Form.Label>Fecha de Fundación</Form.Label>
                    <Form.Control
                      type="date"
                      value={formData.foundation_date}
                      onChange={(e) => handleInputChange(null, 'foundation_date', e.target.value)}
                    />
                  </Form.Group>
                </Col>
                
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>Descripción</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      value={formData.description}
                      onChange={(e) => handleInputChange(null, 'description', e.target.value)}
                    />
                  </Form.Group>

                </Col>
              </Row>
            </Form>
          )}
        </SectionContent>
      </ProfileSection>
      
      {/* Contact Information Section */}
      <ProfileSection>
        <SectionHeader>
          <SectionTitle>Información de Contacto</SectionTitle>
          
          {!editMode.contact_info ? (
            <EditButton onClick={() => toggleEditMode('contact_info')}>
              <FaEdit size={16} /> Editar
            </EditButton>
          ) : (
            <div>
              <ActionButton 
                className="confirm" 
                onClick={() => saveChanges('contact_info')}
                disabled={loading}
              >
                <FaCheck size={14} /> Guardar
              </ActionButton>
              <ActionButton 
                className="cancel" 
                onClick={() => toggleEditMode('contact_info')}
                disabled={loading}
              >
                <FaTimes size={14} /> Cancelar
              </ActionButton>
            </div>
          )}
        </SectionHeader>
        
        <SectionContent>
          {!editMode.contact_info ? (
            <Row>
              <Col md={6}>
                <InfoGroup>
                  <InfoLabel>Email</InfoLabel>
                  <InfoValue>{organization?.contact_info?.email || 'No especificado'}</InfoValue>
                </InfoGroup>
                
                <InfoGroup>
                  <InfoLabel>Sitio Web</InfoLabel>
                  <InfoValue>
                    {organization?.website ? (
                      <a href={organization.website} target="_blank" rel="noopener noreferrer">
                        {organization.website}
                      </a>
                    ) : (
                      'No especificado'
                    )}
                  </InfoValue>
                </InfoGroup>
              </Col>
              
              <Col md={6}>
                <InfoGroup>
                  <InfoLabel>Teléfonos</InfoLabel>
                  {organization?.contact_info?.phones?.length > 0 ? (
                    organization.contact_info.phones.map((phone, index) => (
                      <InfoValue key={index}>{phone}</InfoValue>
                    ))
                  ) : (
                    <InfoValue>No especificados</InfoValue>
                  )}
                </InfoGroup>
              </Col>
            </Row>
          ) : (
            <Form>
              <Row>
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="email"
                      value={formData.contact_info.email}
                      onChange={(e) => handleInputChange('contact_info', 'email', e.target.value)}
                    />
                  </Form.Group>
                  
                  <Form.Group className="mb-3">
                    <Form.Label>Sitio Web</Form.Label>
                    <Form.Control
                      type="url"
                      value={formData.website}
                      onChange={(e) => handleInputChange(null, 'website', e.target.value)}
                    />
                  </Form.Group>
                </Col>
                
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>Teléfonos</Form.Label>
                    {formData.contact_info.phones.map((phone, index) => (
                      <div key={index} className="d-flex mb-2">
                        <Form.Control
                          type="text"
                          value={phone}
                          onChange={(e) => handlePhoneChange(index, e.target.value)}
                          className="me-2"
                        />
                        <Button 
                          variant="outline-danger" 
                          size="sm"
                          onClick={() => removePhone(index)}
                        >
                          <FaTimes />
                        </Button>
                      </div>
                    ))}
                    <Button 
                      variant="outline-primary" 
                      size="sm" 
                      onClick={addPhone}
                    >
                      + Agregar teléfono
                    </Button>
                  </Form.Group>
                </Col>
              </Row>
            </Form>
          )}
        </SectionContent>
      </ProfileSection>
      
      {/* Social Media Section */}
      <ProfileSection>
        <SectionHeader>
          <SectionTitle>Redes Sociales</SectionTitle>
          
          {!editMode.socialMedia ? (
            <EditButton onClick={() => toggleEditMode('socialMedia')}>
              <FaEdit size={16} /> Editar
            </EditButton>
          ) : (
            <div>
              <ActionButton 
                className="confirm" 
                onClick={() => saveChanges('socialMedia')}
                disabled={loading}
              >
                <FaCheck size={14} /> Guardar
              </ActionButton>
              <ActionButton 
                className="cancel" 
                onClick={() => toggleEditMode('socialMedia')}
                disabled={loading}
              >
                <FaTimes size={14} /> Cancelar
              </ActionButton>
            </div>
          )}
        </SectionHeader>
        
        <SectionContent>
          {!editMode.socialMedia ? (
            <Row>
              {organization?.social_media_info?.length > 0 ? (
                organization.social_media_info.map((social, index) => (
                  <Col md={4} key={index} className="mb-3">
                    <SocialMediaCard>
                      <SocialMediaCardBody>
                        <SocialMediaIcon>
                          <img 
                            src={social.logo} 
                            alt="Social Icon" 
                            style={{ maxWidth: '24px', maxHeight: '24px' }}
                            onError={(e) => { e.target.src = 'https://via.placeholder.com/24'; }}
                          />
                        </SocialMediaIcon>
                        <SocialMediaUrl>
                          <a href={social.url} target="_blank" rel="noopener noreferrer" title={social.url}>
                            {social.url}
                          </a>
                        </SocialMediaUrl>
                      </SocialMediaCardBody>
                    </SocialMediaCard>
                  </Col>
                ))
              ) : (
                <Col>
                  <InfoValue>No hay redes sociales configuradas</InfoValue>
                </Col>
              )}
            </Row>
          ) : (
            <Form>
              {formData.social_media_info.map((social, index) => (
              <div key={index} className="mb-3">
                <Row>
                  <Col md={5}>
                    <Form.Group>
                      <Form.Label>Red Social</Form.Label>
                      <Form.Select
                        value={social.type || ''}
                        onChange={(e) => handleSocialMediaChange(index, 'type', e.target.value)}
                      >
                        {socialMediaTypes.map(type => (
                          <option key={type.code} value={type.code}>
                            {type.name}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group>
                      <Form.Label>URL de la red social</Form.Label>
                      <Form.Control
                        type="url"
                        value={social.url}
                        onChange={(e) => handleSocialMediaChange(index, 'url', e.target.value)}
                        placeholder="https://..."
                      />
                    </Form.Group>
                  </Col>
                  <Col md={1} className="d-flex align-items-end">
                    <Button 
                      variant="outline-danger" 
                      size="sm"
                      onClick={() => removeSocialMedia(index)}
                      className="mb-2"
                    >
                      <FaTimes />
                    </Button>
                  </Col>
                </Row>
                {social.logo && (
                  <Row className="mt-1">
                    <Col>
                      <div className="d-flex align-items-center">
                        <img 
                          src={social.logo} 
                          alt={social.type || "Ícono"} 
                          style={{ width: '24px', height: '24px', marginRight: '8px' }} 
                        />
                        <small className="text-muted">Ícono seleccionado</small>
                      </div>
                    </Col>
                  </Row>
                )}
              </div>
            ))}
              <Button 
                variant="outline-primary" 
                size="sm" 
                onClick={addSocialMedia}
              >
                + Agregar red social
              </Button>
            </Form>
          )}
        </SectionContent>
      </ProfileSection>
      
      {/* Members Section */}
      <ProfileSection>
        <SectionHeader>
          <SectionTitle>Miembros</SectionTitle>
          <EditButton onClick={() => {
            setSelectedMember(null);
            setIsEditingMember(false);
            setShowMemberModal(true);
          }}>
            <FaUserPlus size={16} /> Añadir Miembro
          </EditButton>
        </SectionHeader>
        
        <SectionContent>
          <Row>
            {members.length > 0 ? (
              members.map((member) => (
                <Col md={6} key={member.id} className="mb-3">
                  <MemberCard>
                    <MemberCardBody>
                    <MemberAvatar>
                      {member.avatar ? (
                        <img 
                          src={member.avatar} 
                          alt={`${member.name}'s avatar`}
                          style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '50%' }}
                          onError={(e) => {
                            // If image fails to load, show a default icon
                            e.target.style.display = 'none';
                            e.target.parentNode.innerHTML = '<FaUser size={24} color="#8f8f8f" />';
                          }}
                        />
                      ) : (
                        <FaUser size={24} color="#8f8f8f" />
                      )}
                      <AvatarOverlay 
                        className="avatar-overlay"
                        onClick={() => {
                          setSelectedMember(member);
                          setShowAvatarModal(true);
                        }}
                      >
                        <FaImage size={16} color="white" />
                      </AvatarOverlay>
                    </MemberAvatar>
                      <MemberInfo>
                        <MemberName>{member.name}</MemberName>
                        <MemberPosition>{member.position}</MemberPosition>
                        <MemberDescription>{member.description}</MemberDescription>
                      </MemberInfo>
                      <MemberActions>
                        <IconButton
                          onClick={() => {
                            setSelectedMember(member);
                            setIsEditingMember(true);
                            setShowMemberModal(true);
                          }}
                        >
                          <FaUserEdit size={16} />
                        </IconButton>
                        <IconButton 
                          color="#dc3545"
                          onClick={() => handleDeleteMember(member.id)}
                        >
                          <FaTrashAlt size={16} />
                        </IconButton>
                      </MemberActions>
                    </MemberCardBody>
                  </MemberCard>
                </Col>
              ))
            ) : (
              <Col>
                <InfoValue>No hay miembros registrados</InfoValue>
              </Col>
            )}
          </Row>
        </SectionContent>
      </ProfileSection>
      
      {/* Documents Section */}
      <ProfileSection>
        <SectionHeader>
          <SectionTitle>Documentos</SectionTitle>
          <EditButton onClick={() => setShowDocumentModal(true)}>
            <FaFileUpload size={16} /> Subir Documento
          </EditButton>
        </SectionHeader>
        
        <SectionContent>
          <Row>
            {documents.length > 0 ? (
              documents.map((document) => (
                <Col md={6} key={document.id} className="mb-3">
                  <DocumentCard>
                    <DocumentCardBody>
                      <DocumentIcon>
                        <FaFileAlt size={20} />
                      </DocumentIcon>
                      <DocumentInfo>
                        <DocumentTitle>{document.title}</DocumentTitle>
                        <DocumentDescription>{document.description}</DocumentDescription>
                      </DocumentInfo>
                      <DocumentActions>
                        <IconButton
                          onClick={() => window.open(document.file_url, '_blank')}
                        >
                          <FaDownload size={16} />
                        </IconButton>
                        <IconButton 
                          color="#dc3545"
                          onClick={() => handleDeleteDocument(document.id)}
                        >
                          <FaTrashAlt size={16} />
                        </IconButton>
                      </DocumentActions>
                    </DocumentCardBody>
                  </DocumentCard>
                </Col>
              ))
            ) : (
              <Col>
                <InfoValue>No hay documentos registrados</InfoValue>
              </Col>
            )}
          </Row>
        </SectionContent>
      </ProfileSection>
      
      {/* Modales */}
      <LogoModal 
        show={showLogoModal} 
        onClose={() => setShowLogoModal(false)} 
        onSave={handleLogoSave}
        loading={modalLoading}
      />
      
      <MemberModal
        show={showMemberModal}
        onClose={() => {
          setShowMemberModal(false);
          setSelectedMember(null);
          setIsEditingMember(false);
        }}
        onSave={isEditingMember ? handleUpdateMember : handleAddMember}
        loading={modalLoading}
        member={selectedMember}
        isEditing={isEditingMember}
      />
      
      <AvatarModal
        show={showAvatarModal}
        onClose={() => {
          setShowAvatarModal(false);
          setSelectedMember(null);
        }}
        onSave={handleUpdateAvatar}
        loading={modalLoading}
        currentAvatar={selectedMember?.avatar}
      />
      
      <DocumentModal
        show={showDocumentModal}
        onClose={() => setShowDocumentModal(false)}
        onSave={handleAddDocument}
        loading={modalLoading}
      />
      
      {loading && (
        <div className="position-fixed top-0 start-0 w-100 h-100 d-flex justify-content-center align-items-center" style={{ backgroundColor: 'rgba(255,255,255,0.7)', zIndex: 1050 }}>
          <Spinner animation="border" variant="primary" />
        </div>
      )}
    </MainContainer>
  );
};

export default PoliticalOrganizationProfile;