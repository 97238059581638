import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Button } from 'react-bootstrap';
import { FaEdit, FaImages, FaPencilAlt, FaUsers } from 'react-icons/fa';
import { TypeModal, ImagesModal, CreateEditModal, IncidentActivitiesModal, IncidentPeopleManageModal } from './incidentModals';
import { fetchIncidentTypes } from './apiIncident';

const Panel = styled.div`
  background-color: #fff;
  box-shadow: 0 1px 2px 0 rgba(60,64,67,0.3), 0 2px 6px 2px rgba(60,64,67,0.15);
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const FixedHeader = styled.div`
  padding: 24px 24px 0;
  background-color: #fff;
  border-bottom: 1px solid #dadce0;
`;

const ScrollableContent = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  padding: 24px;
`;

const FixedActions = styled.div`
  padding: 24px;
  background-color: #fff;
  border-top: 1px solid #dadce0;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
`;

const Title = styled.h2`
  margin: 0;
  font-size: 22px;
  font-weight: 400;
  color: #202124;
`;

const Section = styled.div`
  margin-bottom: 24px;
`;

const SectionTitle = styled.h3`
  font-size: 14px;
  font-weight: 500;
  color: #5f6368;
  margin-bottom: 12px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
`;

const InfoGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 16px;
`;

const InfoItem = styled.div`
  background-color: #f1f3f4;
  padding: 12px;
  border-radius: 8px;
  transition: background-color 0.3s ease;
  word-wrap: break-word;
  overflow-wrap: break-word;

  &:hover {
    background-color: #e8f0fe;
  }
`;

const InfoLabel = styled.div`
  font-size: 12px;
  color: #5f6368;
  margin-bottom: 4px;
`;

const InfoValue = styled.div`
  font-size: 14px;
  color: #202124;
  font-weight: 500;
  white-space: pre-wrap;
`;

const ActionButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 12px;
  background-color: #fff;
  border-color: #dadce0;
  color: #1a73e8;
  font-weight: 500;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  
  &:hover {
    background-color: #f1f3f4;
    border-color: #dadce0;
    color: #174ea6;
    box-shadow: 0 1px 2px 0 rgba(60,64,67,0.3), 0 1px 3px 1px rgba(60,64,67,0.15);
  }

  svg {
    margin-right: 8px;
  }
`;

const ImageGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 12px;
  margin-top: 12px;
`;

const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 150px;
  border-radius: 8px;
  overflow: hidden;

  &:hover {
    cursor: pointer;
    
    img {
      transform: scale(1.05);
    }
  }
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
  transition: transform 0.3s ease;
`;

const RemainingImagesOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 20px;
  font-weight: 500;
  cursor: pointer;
  border-radius: 8px;
`;

const StatusBadge = styled.span`
  padding: 4px 8px;
  border-radius: 12px;
  font-size: 12px;
  font-weight: 500;
  margin-left: 12px;
  background-color: ${props => props.active ? '#e6f4ea' : '#fce8e6'};
  color: ${props => props.active ? '#137333' : '#c5221f'};
`;

const InvolvedGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  gap: 16px;
  margin-bottom: 12px;
`;

const InvolvedCard = styled.div`
  background: white;
  border-radius: 8px;
  padding: 12px;
  text-align: center;
  box-shadow: 0 1px 2px 0 rgba(60,64,67,0.3);
  transition: box-shadow 0.3s ease;

  &:hover {
    box-shadow: 0 2px 4px 0 rgba(60,64,67,0.3);
  }
`;

const InvolvedImage = styled.div`
  width: 64px;
  height: 64px;
  border-radius: 50%;
  background-image: url(${props => props.src || '/default-avatar.png'});
  background-size: cover;
  background-position: center;
  margin: 0 auto 8px auto;
`;

const InvolvedName = styled.div`
  font-size: 13px;
  color: #202124;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const RemainingCount = styled.div`
  color: #5f6368;
  font-size: 13px;
  margin-top: 8px;
  text-align: center;
  font-weight: 500;
`;

const InfoItemWithFallback = ({ label, value }) => (
  <InfoItem>
    <InfoLabel>{label}</InfoLabel>
    <InfoValue>{value || 'No data'}</InfoValue>
  </InfoItem>
);

export const IncidentSlidingPanel = ({ selectedIncident, onClose, onUpdate }) => {
  const [showTypeModal, setShowTypeModal] = useState(false);
  const [showImagesModal, setShowImagesModal] = useState(false);
  const [showActivitiesModal, setShowActivitiesModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showPeopleManageModal, setShowPeopleManageModal] = useState(false);
  const [types, setTypes] = useState([]);
  const [activityCount, setActivityCount] = useState(0);
  const [selectedPersonType, setSelectedPersonType] = useState(null);

  useEffect(() => {
    const loadTypes = async () => {
      try {
        const typesData = await fetchIncidentTypes();
        setTypes(typesData);
      } catch (error) {
        console.error('Error loading types:', error);
      }
    };

    loadTypes();
  }, []);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [onClose]);

  const handleSuccess = () => {
    onUpdate();
  };

  const handleActivityUpdate = (count) => {
    setActivityCount(count);
  };

  if (!selectedIncident) return null;

  return (
    <Panel>
      <FixedHeader>
        <Header>
          <Title>
            Incident
          </Title>
        </Header>
      </FixedHeader>

      <ScrollableContent>
        <Section>
          <SectionTitle>Basic Information</SectionTitle>
          <InfoGrid>
            <InfoItemWithFallback label="Description" value={selectedIncident.description} />
            <InfoItemWithFallback label="Type" value={selectedIncident.type} />
            <InfoItemWithFallback label="Province" value={selectedIncident.province} />
            <InfoItemWithFallback label="Municipality" value={selectedIncident.municipality} />
            <InfoItemWithFallback 
              label="Start Date" 
              value={new Date(selectedIncident.startDate).toLocaleDateString()} 
            />
            <InfoItemWithFallback 
              label="End Date" 
              value={selectedIncident.endDate ? 
                new Date(selectedIncident.endDate).toLocaleDateString() : 
                'Ongoing'} 
            />
            <InfoItemWithFallback label="Protest Magnitude" value={selectedIncident.protestMagnitude} />
          </InfoGrid>
        </Section>

        <Section>
          <SectionTitle>Images</SectionTitle>
          <ImageGrid>
            {(selectedIncident.images || []).slice(0, 4).map((image, index) => (
              <ImageWrapper 
                key={index}
                onClick={() => setShowImagesModal(true)}
              >
                <Image src={image} alt={`Incident image ${index + 1}`} />
                {index === 3 && selectedIncident.images.length > 4 && (
                  <RemainingImagesOverlay>
                    +{selectedIncident.images.length - 4}
                  </RemainingImagesOverlay>
                )}
              </ImageWrapper>
            ))}
          </ImageGrid>
        </Section>
      </ScrollableContent>

      <FixedActions>
        <Section>
          <SectionTitle>Actions</SectionTitle>
          <ActionButton onClick={() => setShowEditModal(true)}>
            <FaPencilAlt /> Edit Incident
          </ActionButton>
          <ActionButton onClick={() => setShowActivitiesModal(true)}>
            <FaPencilAlt /> Activities
          </ActionButton>
          <ActionButton 
            onClick={() => {
              setSelectedPersonType('INCIDENT_VICTIM');
              setShowPeopleManageModal(true);
            }}
          >
            <FaUsers /> Manage Victims
          </ActionButton>
          <ActionButton 
            onClick={() => {
              setSelectedPersonType('REPRESSOR');
              setShowPeopleManageModal(true);
            }}
          >
            <FaUsers /> Manage Repressors
          </ActionButton>
          <ActionButton onClick={() => setShowTypeModal(true)}>
            <FaEdit /> Change Type
          </ActionButton>
          <ActionButton onClick={() => setShowImagesModal(true)}>
            <FaImages /> Manage Images
          </ActionButton>
        </Section>
      </FixedActions>

      <CreateEditModal
        show={showEditModal}
        onHide={() => setShowEditModal(false)}
        incident={selectedIncident}
        onSuccess={handleSuccess}
        types={types}
      />

      <TypeModal
        show={showTypeModal}
        onHide={() => setShowTypeModal(false)}
        incident={selectedIncident}
        onSuccess={handleSuccess}
        types={types}
      />

      <ImagesModal
        show={showImagesModal}
        onHide={() => setShowImagesModal(false)}
        incident={selectedIncident}
        onSuccess={handleSuccess}
      />

      <IncidentActivitiesModal
        show={showActivitiesModal}
        onHide={() => setShowActivitiesModal(false)}
        selectedPerson={selectedIncident}
        onActivityCountUpdate={handleActivityUpdate}
      />

      <IncidentPeopleManageModal
        show={showPeopleManageModal}
        onHide={() => {
          setShowPeopleManageModal(false);
          setSelectedPersonType(null);
        }}
        incident={selectedIncident}
        onUpdate={handleSuccess}
        personType={selectedPersonType}
      />

    </Panel>
  );
};

const ExpandableTitle = ({ text, maxWords = 5 }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  
  const words = text.split(' ');
  const shouldTruncate = words.length > maxWords;
  const truncatedText = shouldTruncate ? words.slice(0, maxWords).join(' ') : text;
  
  return (
    <div className="flex flex-col">
      <div className="flex items-center gap-2">
        <h2 className="text-[#202124] text-2xl font-normal m-0">
          {isExpanded ? text : truncatedText}
          {shouldTruncate && !isExpanded && '...'}
        </h2>
        {shouldTruncate && (
          <button
            onClick={() => setIsExpanded(!isExpanded)}
            className="text-blue-600 hover:text-blue-800 text-sm font-medium px-2 py-1 rounded-md hover:bg-blue-50 transition-colors duration-200"
          >
            {isExpanded ? 'Ver menos' : 'Ver más'}
          </button>
        )}
      </div>
    </div>
  );
};