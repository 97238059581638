import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Tab, Tabs, Alert } from 'react-bootstrap';
import styled from 'styled-components';
import { FaPlus, FaTrash } from 'react-icons/fa';

// Styled components for modals
const StyledModal = styled(Modal)`
  .modal-header {
    border-bottom: 1px solid #dadce0;
    padding: 16px 24px;
  }
  
  .modal-title {
    font-size: 18px;
    font-weight: 500;
    color: #202124;
  }
  
  .modal-body {
    padding: 24px;
  }
  
  .modal-footer {
    border-top: 1px solid #dadce0;
    padding: 16px 24px;
  }
`;

const GoogleButton = styled(Button)`
  background-color: ${props => props.variant === 'primary' ? '#1a73e8' : '#fff'};
  border-color: ${props => props.variant === 'primary' ? '#1a73e8' : '#dadce0'};
  color: ${props => props.variant === 'primary' ? '#fff' : '#3c4043'};
  font-weight: 500;
  padding: 8px 16px;
  font-size: 14px;
  
  &:hover, &:focus {
    background-color: ${props => props.variant === 'primary' ? '#1765cc' : '#f1f3f4'};
    border-color: ${props => props.variant === 'primary' ? '#1765cc' : '#dadce0'};
    box-shadow: 0 1px 2px 0 rgba(60,64,67,0.3), 0 1px 3px 1px rgba(60,64,67,0.15);
  }

  &:disabled {
    background-color: ${props => props.variant === 'primary' ? '#8ab4f8' : '#f1f3f4'};
    border-color: ${props => props.variant === 'primary' ? '#8ab4f8' : '#dadce0'};
    color: ${props => props.variant === 'primary' ? '#fff' : '#80868b'};
  }
`;

const DangerButton = styled(GoogleButton)`
  background-color: ${props => props.variant === 'danger' ? '#ea4335' : '#fce8e6'};
  border-color: ${props => props.variant === 'danger' ? '#ea4335' : '#fce8e6'};
  color: ${props => props.variant === 'danger' ? '#fff' : '#c5221f'};
  
  &:hover, &:focus {
    background-color: ${props => props.variant === 'danger' ? '#d93025' : '#fad2cf'};
    border-color: ${props => props.variant === 'danger' ? '#d93025' : '#fad2cf'};
  }
`;

const ActionButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #dadce0;
  background-color: #fff;
  color: #3c4043;
  border-radius: 4px;
  padding: 6px 12px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  
  &:hover {
    background-color: #f1f3f4;
    border-color: #dadce0;
    box-shadow: 0 1px 2px 0 rgba(60,64,67,0.3), 0 1px 3px 1px rgba(60,64,67,0.15);
  }
  
  & > svg {
    margin-right: 6px;
  }
`;

const SocialNetworkItem = styled.div`
  display: flex;
  align-items: center;
  background-color: #f1f3f4;
  border-radius: 8px;
  padding: 8px 12px;
  margin-bottom: 8px;
  
  & > div {
    flex: 1;
  }
  
  & > button {
    margin-left: 8px;
  }
`;

// Alert Modal
export const AlertModal = ({ show, onHide, title, message, variant = 'success' }) => (
  <StyledModal show={show} onHide={onHide} centered>
    <Modal.Header closeButton>
      <Modal.Title>{title}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <Alert variant={variant}>{message}</Alert>
    </Modal.Body>
    <Modal.Footer>
      <GoogleButton variant="primary" onClick={onHide}>Close</GoogleButton>
    </Modal.Footer>
  </StyledModal>
);

// Create Profile Modal
export const CreateProfileModal = ({ show, onHide, onCreate }) => {
  const [profile, setProfile] = useState({
    name: '',
    description: '',
    avatar: '',
    country: '',
    social_networks: [],
    content_sources: []
  });
  
  const [newSocialNetwork, setNewSocialNetwork] = useState({
    platform: '',
    url: '',
    icon: ''
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProfile({ ...profile, [name]: value });
  };

  const handleSocialNetworkChange = (e) => {
    const { name, value } = e.target;
    setNewSocialNetwork({ ...newSocialNetwork, [name]: value });
  };

  const addSocialNetwork = () => {
    if (newSocialNetwork.platform && newSocialNetwork.url) {
      setProfile({
        ...profile,
        socialNetworks: [...profile.social_networks, { ...newSocialNetwork }]
      });
      setNewSocialNetwork({ platform: '', url: '', icon: '' });
    }
  };

  const removeSocialNetwork = (index) => {
    const updatedNetworks = [...profile.social_networks];
    updatedNetworks.splice(index, 1);
    setProfile({ ...profile, social_networks: updatedNetworks });
  };

  const handleSubmit = () => {
    onCreate(profile);
  };

  const resetForm = () => {
    setProfile({
      name: '',
      description: '',
      avatar: '',
      country: '',
      social_networks: [],
      content_sources: []
    });
    setNewSocialNetwork({ platform: '', url: '', icon: '' });
  };

  useEffect(() => {
    if (show) {
      resetForm();
    }
  }, [show]);

  return (
    <StyledModal show={show} onHide={onHide} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Create Creator Profile</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3">
            <Form.Label>Name *</Form.Label>
            <Form.Control
              type="text"
              name="name"
              value={profile.name}
              onChange={handleInputChange}
              required
              placeholder="Creator name"
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Country *</Form.Label>
            <Form.Control
              type="text"
              name="country"
              value={profile.country}
              onChange={handleInputChange}
              required
              placeholder="Country"
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Description *</Form.Label>
            <Form.Control
              as="textarea"
              name="description"
              value={profile.description}
              onChange={handleInputChange}
              required
              placeholder="Creator description"
              rows={3}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Avatar URL</Form.Label>
            <Form.Control
              type="text"
              name="avatar"
              value={profile.avatar}
              onChange={handleInputChange}
              placeholder="URL to avatar image"
            />
          </Form.Group>

          <hr className="my-4" />
          
          <h5>Social Networks</h5>
          
          {profile.social_networks.length > 0 && (
            <div className="mb-3">
              {profile.social_networks.map((network, index) => (
                <SocialNetworkItem key={index}>
                  <div>
                    <strong>{network.platform}</strong>: {network.url}
                  </div>
                  <Button 
                    variant="outline-danger" 
                    size="sm" 
                    onClick={() => removeSocialNetwork(index)}
                  >
                    <FaTrash />
                  </Button>
                </SocialNetworkItem>
              ))}
            </div>
          )}
          
          <div className="d-flex gap-2 mb-3">
            <Form.Group className="flex-grow-1">
              <Form.Label>Platform</Form.Label>
              <Form.Control
                type="text"
                name="platform"
                value={newSocialNetwork.platform}
                onChange={handleSocialNetworkChange}
                placeholder="e.g. Twitter, Instagram"
              />
            </Form.Group>
            
            <Form.Group className="flex-grow-1">
              <Form.Label>URL</Form.Label>
              <Form.Control
                type="text"
                name="url"
                value={newSocialNetwork.url}
                onChange={handleSocialNetworkChange}
                placeholder="https://..."
              />
            </Form.Group>
            
            <Form.Group className="flex-grow-1">
              <Form.Label>Icon (optional)</Form.Label>
              <Form.Control
                type="text"
                name="icon"
                value={newSocialNetwork.icon}
                onChange={handleSocialNetworkChange}
                placeholder="Icon URL or class name"
              />
            </Form.Group>
            
            <div className="d-flex align-items-end">
              <ActionButton onClick={addSocialNetwork}>
                <FaPlus /> Add
              </ActionButton>
            </div>
          </div>
          
          <div className="mt-3">
            <Alert variant="info">
              Content sources can be added after creating the profile.
            </Alert>
          </div>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <GoogleButton onClick={onHide}>Cancel</GoogleButton>
        <GoogleButton 
          variant="primary" 
          onClick={handleSubmit}
          disabled={!profile.name || !profile.description || !profile.country}
        >
          Create
        </GoogleButton>
      </Modal.Footer>
    </StyledModal>
  );
};

// Edit Profile Modal
export const EditProfileModal = ({ show, onHide, profile, onUpdate }) => {
  const [editedProfile, setEditedProfile] = useState({
    name: '',
    description: '',
    avatar: '',
    country: '',
    social_networks: [],
    active: true
  });
  
  const [newSocialNetwork, setNewSocialNetwork] = useState({
    platform: '',
    url: '',
    icon: ''
  });

  useEffect(() => {
    if (profile) {
      setEditedProfile({
        name: profile.name || '',
        description: profile.description || '',
        avatar: profile.avatar || '',
        country: profile.country || '',
        social_networks: profile.social_networks || [],
        active: profile.active !== false
      });
    }
  }, [profile, show]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedProfile({ ...editedProfile, [name]: value });
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setEditedProfile({ ...editedProfile, [name]: checked });
  };

  const handleSocialNetworkChange = (e) => {
    const { name, value } = e.target;
    setNewSocialNetwork({ ...newSocialNetwork, [name]: value });
  };

  const addSocialNetwork = () => {
    if (newSocialNetwork.platform && newSocialNetwork.url) {
      setEditedProfile({
        ...editedProfile,
        social_networks: [...editedProfile.social_networks, { ...newSocialNetwork }]
      });
      setNewSocialNetwork({ platform: '', url: '', icon: '' });
    }
  };

  const removeSocialNetwork = (index) => {
    const updatedNetworks = [...editedProfile.social_networks];
    updatedNetworks.splice(index, 1);
    setEditedProfile({ ...editedProfile, social_networks: updatedNetworks });
  };

  const handleSubmit = () => {
    if (profile?.id) {
      onUpdate(profile.id, editedProfile);
    }
  };

  return (
    <StyledModal show={show} onHide={onHide} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Edit Creator Profile</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              name="name"
              value={editedProfile.name}
              onChange={handleInputChange}
              placeholder="Creator name"
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Country</Form.Label>
            <Form.Control
              type="text"
              name="country"
              value={editedProfile.country}
              onChange={handleInputChange}
              placeholder="Country"
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Description</Form.Label>
            <Form.Control
              as="textarea"
              name="description"
              value={editedProfile.description}
              onChange={handleInputChange}
              placeholder="Creator description"
              rows={3}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Avatar URL</Form.Label>
            <Form.Control
              type="text"
              name="avatar"
              value={editedProfile.avatar}
              onChange={handleInputChange}
              placeholder="URL to avatar image"
            />
          </Form.Group>
          
          <Form.Group className="mb-3">
            <Form.Check
              type="checkbox"
              label="Active"
              name="active"
              checked={editedProfile.active}
              onChange={handleCheckboxChange}
            />
          </Form.Group>

          <hr className="my-4" />
          
          <h5>Social Networks</h5>
          
          {editedProfile.social_networks.length > 0 && (
            <div className="mb-3">
              {editedProfile.social_networks.map((network, index) => (
                <SocialNetworkItem key={index}>
                  <div>
                    <strong>{network.platform}</strong>: {network.url}
                  </div>
                  <Button 
                    variant="outline-danger" 
                    size="sm" 
                    onClick={() => removeSocialNetwork(index)}
                  >
                    <FaTrash />
                  </Button>
                </SocialNetworkItem>
              ))}
            </div>
          )}
          
          <div className="d-flex gap-2 mb-3">
            <Form.Group className="flex-grow-1">
              <Form.Label>Platform</Form.Label>
              <Form.Control
                type="text"
                name="platform"
                value={newSocialNetwork.platform}
                onChange={handleSocialNetworkChange}
                placeholder="e.g. Twitter, Instagram"
              />
            </Form.Group>
            
            <Form.Group className="flex-grow-1">
              <Form.Label>URL</Form.Label>
              <Form.Control
                type="text"
                name="url"
                value={newSocialNetwork.url}
                onChange={handleSocialNetworkChange}
                placeholder="https://..."
              />
            </Form.Group>
            
            <Form.Group className="flex-grow-1">
              <Form.Label>Icon (optional)</Form.Label>
              <Form.Control
                type="text"
                name="icon"
                value={newSocialNetwork.icon}
                onChange={handleSocialNetworkChange}
                placeholder="Icon URL or class name"
              />
            </Form.Group>
            
            <div className="d-flex align-items-end">
              <ActionButton onClick={addSocialNetwork}>
                <FaPlus /> Add
              </ActionButton>
            </div>
          </div>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <GoogleButton onClick={onHide}>Cancel</GoogleButton>
        <GoogleButton 
          variant="primary" 
          onClick={handleSubmit}
        >
          Update
        </GoogleButton>
      </Modal.Footer>
    </StyledModal>
  );
};

// Delete Profile Modal
export const DeleteProfileModal = ({ show, onHide, profile, onDelete }) => {
  const handleDelete = () => {
    if (profile?.id) {
      onDelete(profile.id);
    }
  };

  return (
    <StyledModal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>Delete Creator Profile</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Are you sure you want to delete the creator profile for <strong>{profile?.name}</strong>?</p>
        <Alert variant="danger">
          This action cannot be undone. All content sources associated with this profile will also be removed.
        </Alert>
      </Modal.Body>
      <Modal.Footer>
        <GoogleButton onClick={onHide}>Cancel</GoogleButton>
        <DangerButton variant="danger" onClick={handleDelete}>Delete</DangerButton>
      </Modal.Footer>
    </StyledModal>
  );
};

// Add Source Modal
export const AddSourceModal = ({ show, onHide, profile, onAdd }) => {
  const [activeTab, setActiveTab] = useState('youtube');
  const [youtubeSource, setYoutubeSource] = useState({
    type: 'YOUTUBE',
    name: '',
    active: true,
    target_countries: [],
    language: '',
    channel_id: '',
    description: '',
    thumbnail_url: ''
  });
  
  const [rssSource, setRssSource] = useState({
    type: 'RSS',
    name: '',
    active: true,
    target_countries: [],
    language: '',
    feed_url: '',
    site_url: '',
    site_logo: '',
    selector: '',
    remove_selectors: []
  });
  
  const [newCountry, setNewCountry] = useState('');
  const [newSelector, setNewSelector] = useState('');

  useEffect(() => {
    if (show) {
      // Reset the form
      setYoutubeSource({
        type: 'YOUTUBE',
        name: '',
        active: true,
        target_countries: [],
        language: '',
        channel_id: '',
        description: '',
        thumbnail_url: ''
      });
      
      setRssSource({
        type: 'RSS',
        name: '',
        active: true,
        target_countries: [],
        language: '',
        feed_url: '',
        site_url: '',
        site_logo: '',
        selector: '',
        remove_selectors: []
      });
      
      setNewCountry('');
      setNewSelector('');
      setActiveTab('youtube');
    }
  }, [show]);

  const handleYoutubeChange = (e) => {
    const { name, value, type, checked } = e.target;
    setYoutubeSource({
      ...youtubeSource,
      [name]: type === 'checkbox' ? checked : value
    });
  };

  const handleRssChange = (e) => {
    const { name, value, type, checked } = e.target;
    setRssSource({
      ...rssSource,
      [name]: type === 'checkbox' ? checked : value
    });
  };

  const addCountryToYoutube = () => {
    if (newCountry && !youtubeSource.target_countries.includes(newCountry)) {
      setYoutubeSource({
        ...youtubeSource,
        target_countries: [...youtubeSource.target_countries, newCountry]
      });
      setNewCountry('');
    }
  };

  const removeCountryFromYoutube = (country) => {
    setYoutubeSource({
      ...youtubeSource,
      target_countries: youtubeSource.target_countries.filter(c => c !== country)
    });
  };

  const addCountryToRss = () => {
    if (newCountry && !rssSource.target_countries.includes(newCountry)) {
      setRssSource({
        ...rssSource,
        target_countries: [...rssSource.target_countries, newCountry]
      });
      setNewCountry('');
    }
  };

  const removeCountryFromRss = (country) => {
    setRssSource({
      ...rssSource,
      target_countries: rssSource.target_countries.filter(c => c !== country)
    });
  };

  const addRemoveSelector = () => {
    if (newSelector && !rssSource.remove_selectors.includes(newSelector)) {
      setRssSource({
        ...rssSource,
        remove_selectors: [...rssSource.remove_selectors, newSelector]
      });
      setNewSelector('');
    }
  };

  const removeSelector = (selector) => {
    setRssSource({
      ...rssSource,
      remove_selectors: rssSource.remove_selectors.filter(s => s !== selector)
    });
  };

  const handleSubmit = () => {
    if (profile?.id) {
      const source = activeTab === 'youtube' ? youtubeSource : rssSource;
      onAdd(profile.id, source);
    }
  };

  const isFormValid = () => {
    if (activeTab === 'youtube') {
      return youtubeSource.name && 
             youtubeSource.language && 
             youtubeSource.target_countries.length > 0 &&
             youtubeSource.channel_id;
    } else {
      return rssSource.name && 
             rssSource.language && 
             rssSource.target_countries.length > 0 &&
             rssSource.feed_url &&
             rssSource.selector;
    }
  };

  return (
    <StyledModal show={show} onHide={onHide} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Add Content Source to {profile?.name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Tabs
          activeKey={activeTab}
          onSelect={(k) => setActiveTab(k)}
          className="mb-3"
        >
          <Tab eventKey="youtube" title="YouTube Source">
            <Form>
              <Form.Group className="mb-3">
                <Form.Label>Name *</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  value={youtubeSource.name}
                  onChange={handleYoutubeChange}
                  required
                  placeholder="Source name"
                />
              </Form.Group>
              
              <Form.Group className="mb-3">
                <Form.Label>Language *</Form.Label>
                <Form.Control
                  type="text"
                  name="language"
                  value={youtubeSource.language}
                  onChange={handleYoutubeChange}
                  required
                  placeholder="e.g. en-US, es"
                />
              </Form.Group>
              
              <Form.Group className="mb-3">
                <Form.Label>Channel ID *</Form.Label>
                <Form.Control
                  type="text"
                  name="channel_id"
                  value={youtubeSource.channel_id}
                  onChange={handleYoutubeChange}
                  required
                  placeholder="YouTube channel ID"
                />
              </Form.Group>
              
              <Form.Group className="mb-3">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  as="textarea"
                  name="description"
                  value={youtubeSource.description}
                  onChange={handleYoutubeChange}
                  rows={2}
                  placeholder="Source description"
                />
              </Form.Group>
              
              <Form.Group className="mb-3">
                <Form.Label>Thumbnail URL</Form.Label>
                <Form.Control
                  type="text"
                  name="thumbnail_url"
                  value={youtubeSource.thumbnail_url}
                  onChange={handleYoutubeChange}
                  placeholder="Thumbnail image URL"
                />
              </Form.Group>
              
              <Form.Group className="mb-3">
                <Form.Label>Target Countries *</Form.Label>
                {youtubeSource.target_countries.length > 0 && (
                  <div className="mb-2">
                    {youtubeSource.target_countries.map((country, index) => (
                      <span 
                        key={index} 
                        className="badge bg-light text-dark me-1 p-2 mb-1"
                        style={{ border: '1px solid #dadce0' }}
                      >
                        {country}
                        <button
                          type="button"
                          className="btn-close ms-2"
                          style={{ fontSize: '0.5rem' }}
                          onClick={() => removeCountryFromYoutube(country)}
                        ></button>
                      </span>
                    ))}
                  </div>
                )}
                <div className="d-flex gap-2">
                  <Form.Control
                    type="text"
                    value={newCountry}
                    onChange={(e) => setNewCountry(e.target.value)}
                    placeholder="e.g. US, UK, ES"
                  />
                  <ActionButton onClick={addCountryToYoutube}>
                    <FaPlus /> Add
                  </ActionButton>
                </div>
              </Form.Group>
              
              <Form.Group className="mb-3">
                <Form.Check
                  type="checkbox"
                  label="Active"
                  name="active"
                  checked={youtubeSource.active}
                  onChange={handleYoutubeChange}
                />
              </Form.Group>
            </Form>
          </Tab>
          
          <Tab eventKey="rss" title="RSS Source">
            <Form>
              <Form.Group className="mb-3">
                <Form.Label>Name *</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  value={rssSource.name}
                  onChange={handleRssChange}
                  required
                  placeholder="Source name"
                />
              </Form.Group>
              
              <Form.Group className="mb-3">
                <Form.Label>Language *</Form.Label>
                <Form.Control
                  type="text"
                  name="language"
                  value={rssSource.language}
                  onChange={handleRssChange}
                  required
                  placeholder="e.g. en-US, es"
                />
              </Form.Group>
              
              <Form.Group className="mb-3">
                <Form.Label>Feed URL *</Form.Label>
                <Form.Control
                  type="text"
                  name="feed_url"
                  value={rssSource.feed_url}
                  onChange={handleRssChange}
                  required
                  placeholder="RSS feed URL"
                />
              </Form.Group>
              
              <Form.Group className="mb-3">
                <Form.Label>Site URL</Form.Label>
                <Form.Control
                  type="text"
                  name="site_url"
                  value={rssSource.site_url}
                  onChange={handleRssChange}
                  placeholder="Website URL"
                />
              </Form.Group>
              
              <Form.Group className="mb-3">
                <Form.Label>Site Logo</Form.Label>
                <Form.Control
                  type="text"
                  name="site_logo"
                  value={rssSource.site_logo}
                  onChange={handleRssChange}
                  placeholder="Site logo URL"
                />
              </Form.Group>
              
              <Form.Group className="mb-3">
                <Form.Label>CSS Selector *</Form.Label>
                <Form.Control
                  type="text"
                  name="selector"
                  value={rssSource.selector}
                  onChange={handleRssChange}
                  required
                  placeholder="CSS selector for content"
                />
              </Form.Group>
              
              <Form.Group className="mb-3">
                <Form.Label>Remove Selectors</Form.Label>
                {rssSource.remove_selectors.length > 0 && (
                  <div className="mb-2">
                    {rssSource.remove_selectors.map((selector, index) => (
                      <span 
                        key={index} 
                        className="badge bg-light text-dark me-1 p-2 mb-1"
                        style={{ border: '1px solid #dadce0' }}
                      >
                        {selector}
                        <button
                          type="button"
                          className="btn-close ms-2"
                          style={{ fontSize: '0.5rem' }}
                          onClick={() => removeSelector(selector)}
                        ></button>
                      </span>
                    ))}
                  </div>
                )}
                <div className="d-flex gap-2">
                  <Form.Control
                    type="text"
                    value={newSelector}
                    onChange={(e) => setNewSelector(e.target.value)}
                    placeholder="CSS selector to remove"
                  />
                  <ActionButton onClick={addRemoveSelector}>
                    <FaPlus /> Add
                  </ActionButton>
                </div>
              </Form.Group>
              
              <Form.Group className="mb-3">
                <Form.Label>Target Countries *</Form.Label>
                {rssSource.target_countries.length > 0 && (
                  <div className="mb-2">
                    {rssSource.target_countries.map((country, index) => (
                      <span 
                        key={index} 
                        className="badge bg-light text-dark me-1 p-2 mb-1"
                        style={{ border: '1px solid #dadce0' }}
                      >
                        {country}
                        <button
                          type="button"
                          className="btn-close ms-2"
                          style={{ fontSize: '0.5rem' }}
                          onClick={() => removeCountryFromRss(country)}
                        ></button>
                      </span>
                    ))}
                  </div>
                )}
                <div className="d-flex gap-2">
                  <Form.Control
                    type="text"
                    value={newCountry}
                    onChange={(e) => setNewCountry(e.target.value)}
                    placeholder="e.g. US, UK, ES"
                  />
                  <ActionButton onClick={addCountryToRss}>
                    <FaPlus /> Add
                  </ActionButton>
                </div>
              </Form.Group>
              
              <Form.Group className="mb-3">
                <Form.Check
                  type="checkbox"
                  label="Active"
                  name="active"
                  checked={rssSource.active}
                  onChange={handleRssChange}
                />
              </Form.Group>
            </Form>
          </Tab>
        </Tabs>
      </Modal.Body>
      <Modal.Footer>
        <GoogleButton onClick={onHide}>Cancel</GoogleButton>
        <GoogleButton 
          variant="primary" 
          onClick={handleSubmit}
          disabled={!isFormValid()}
        >
          Add Source
        </GoogleButton>
      </Modal.Footer>
    </StyledModal>
  );
};

// Edit Source Modal
export const EditSourceModal = ({ show, onHide, profile, source, sourceIndex, onUpdate }) => {
  const [editedSource, setEditedSource] = useState(null);
  const [newCountry, setNewCountry] = useState('');
  const [newSelector, setNewSelector] = useState('');

  useEffect(() => {
    if (show && source) {
      setEditedSource(JSON.parse(JSON.stringify(source))); // Deep clone
      setNewCountry('');
      setNewSelector('');
    }
  }, [show, source]);

  if (!editedSource) return null;

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setEditedSource({
      ...editedSource,
      [name]: type === 'checkbox' ? checked : value
    });
  };

  const addCountry = () => {
    if (newCountry && !editedSource.target_countries.includes(newCountry)) {
      setEditedSource({
        ...editedSource,
        target_countries: [...editedSource.target_countries, newCountry]
      });
      setNewCountry('');
    }
  };

  const removeCountry = (country) => {
    setEditedSource({
      ...editedSource,
      target_countries: editedSource.target_countries.filter(c => c !== country)
    });
  };

  const addRemoveSelector = () => {
    if (newSelector && editedSource.type === 'RSS' && !editedSource.remove_selectors.includes(newSelector)) {
      setEditedSource({
        ...editedSource,
        remove_selectors: [...editedSource.remove_selectors, newSelector]
      });
      setNewSelector('');
    }
  };

  const removeSelector = (selector) => {
    if (editedSource.type === 'RSS') {
      setEditedSource({
        ...editedSource,
        remove_selectors: editedSource.remove_selectors.filter(s => s !== selector)
      });
    }
  };

  const handleSubmit = () => {
    if (profile?.id && sourceIndex !== null) {
      onUpdate(profile.id, sourceIndex, editedSource);
    }
  };

  const isYoutubeSource = editedSource.type === 'YOUTUBE';
  const isRssSource = editedSource.type === 'RSS';

  const isFormValid = () => {
    if (isYoutubeSource) {
      return editedSource.name && 
             editedSource.language && 
             editedSource.target_countries.length > 0 &&
             editedSource.channel_id;
    } else {
      return editedSource.name && 
             editedSource.language && 
             editedSource.target_countries.length > 0 &&
             editedSource.feed_url &&
             editedSource.selector;
    }
  };

  return (
    <StyledModal show={show} onHide={onHide} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Edit {isYoutubeSource ? 'YouTube' : 'RSS'} Source</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3">
            <Form.Label>Name *</Form.Label>
            <Form.Control
              type="text"
              name="name"
              value={editedSource.name}
              onChange={handleChange}
              required
              placeholder="Source name"
            />
          </Form.Group>
          
          <Form.Group className="mb-3">
            <Form.Label>Language *</Form.Label>
            <Form.Control
              type="text"
              name="language"
              value={editedSource.language}
              onChange={handleChange}
              required
              placeholder="e.g. en-US, es"
            />
          </Form.Group>
          
          {isYoutubeSource && (
            <>
              <Form.Group className="mb-3">
                <Form.Label>Channel ID *</Form.Label>
                <Form.Control
                  type="text"
                  name="channel_id"
                  value={editedSource.channel_id}
                  onChange={handleChange}
                  required
                  placeholder="YouTube channel ID"
                />
              </Form.Group>
              
              <Form.Group className="mb-3">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  as="textarea"
                  name="description"
                  value={editedSource.description || ''}
                  onChange={handleChange}
                  rows={2}
                  placeholder="Source description"
                />
              </Form.Group>
              
              <Form.Group className="mb-3">
                <Form.Label>Thumbnail URL</Form.Label>
                <Form.Control
                  type="text"
                  name="thumbnail_url"
                  value={editedSource.thumbnail_url || ''}
                  onChange={handleChange}
                  placeholder="Thumbnail image URL"
                />
              </Form.Group>
            </>
          )}
          
          {isRssSource && (
            <>
              <Form.Group className="mb-3">
                <Form.Label>Feed URL *</Form.Label>
                <Form.Control
                  type="text"
                  name="feed_url"
                  value={editedSource.feed_url || ''}
                  onChange={handleChange}
                  required
                  placeholder="RSS feed URL"
                />
              </Form.Group>
              
              <Form.Group className="mb-3">
                <Form.Label>Site URL</Form.Label>
                <Form.Control
                  type="text"
                  name="site_url"
                  value={editedSource.site_url || ''}
                  onChange={handleChange}
                  placeholder="Website URL"
                />
              </Form.Group>
              
              <Form.Group className="mb-3">
                <Form.Label>Site Logo</Form.Label>
                <Form.Control
                  type="text"
                  name="site_logo"
                  value={editedSource.site_logo || ''}
                  onChange={handleChange}
                  placeholder="Site logo URL"
                />
              </Form.Group>
              
              <Form.Group className="mb-3">
                <Form.Label>CSS Selector *</Form.Label>
                <Form.Control
                  type="text"
                  name="selector"
                  value={editedSource.selector || ''}
                  onChange={handleChange}
                  required
                  placeholder="CSS selector for content"
                />
              </Form.Group>
              
              <Form.Group className="mb-3">
                <Form.Label>Remove Selectors</Form.Label>
                {editedSource.remove_selectors && editedSource.remove_selectors.length > 0 && (
                  <div className="mb-2">
                    {editedSource.remove_selectors.map((selector, index) => (
                      <span 
                        key={index} 
                        className="badge bg-light text-dark me-1 p-2 mb-1"
                        style={{ border: '1px solid #dadce0' }}
                      >
                        {selector}
                        <button
                          type="button"
                          className="btn-close ms-2"
                          style={{ fontSize: '0.5rem' }}
                          onClick={() => removeSelector(selector)}
                        ></button>
                      </span>
                    ))}
                  </div>
                )}
                <div className="d-flex gap-2">
                  <Form.Control
                    type="text"
                    value={newSelector}
                    onChange={(e) => setNewSelector(e.target.value)}
                    placeholder="CSS selector to remove"
                  />
                  <ActionButton onClick={addRemoveSelector}>
                    <FaPlus /> Add
                  </ActionButton>
                </div>
              </Form.Group>
            </>
          )}
          
          <Form.Group className="mb-3">
            <Form.Label>Target Countries *</Form.Label>
            {editedSource.target_countries && editedSource.target_countries.length > 0 && (
              <div className="mb-2">
                {editedSource.target_countries.map((country, index) => (
                  <span 
                    key={index} 
                    className="badge bg-light text-dark me-1 p-2 mb-1"
                    style={{ border: '1px solid #dadce0' }}
                  >
                    {country}
                    <button
                      type="button"
                      className="btn-close ms-2"
                      style={{ fontSize: '0.5rem' }}
                      onClick={() => removeCountry(country)}
                    ></button>
                  </span>
                ))}
              </div>
            )}
            <div className="d-flex gap-2">
              <Form.Control
                type="text"
                value={newCountry}
                onChange={(e) => setNewCountry(e.target.value)}
                placeholder="e.g. US, UK, ES"
              />
              <ActionButton onClick={addCountry}>
                <FaPlus /> Add
              </ActionButton>
            </div>
          </Form.Group>
          
          <Form.Group className="mb-3">
            <Form.Check
              type="checkbox"
              label="Active"
              name="active"
              checked={editedSource.active}
              onChange={handleChange}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <GoogleButton onClick={onHide}>Cancel</GoogleButton>
        <GoogleButton 
          variant="primary" 
          onClick={handleSubmit}
          disabled={!isFormValid()}
        >
          Update Source
        </GoogleButton>
      </Modal.Footer>
    </StyledModal>
  );
};

// Delete Source Modal
export const DeleteSourceModal = ({ show, onHide, profile, sourceIndex, onDelete }) => {
  const source = profile?.content_sources?.[sourceIndex];
  
  const handleDelete = () => {
    if (profile?.id && sourceIndex !== null) {
      onDelete(profile.id, sourceIndex);
    }
  };

  return (
    <StyledModal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>Delete Content Source</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Are you sure you want to delete the content source <strong>{source?.name}</strong> from {profile?.name}?</p>
        <Alert variant="danger">
          This action cannot be undone.
        </Alert>
      </Modal.Body>
      <Modal.Footer>
        <GoogleButton onClick={onHide}>Cancel</GoogleButton>
        <DangerButton variant="danger" onClick={handleDelete}>Delete</DangerButton>
      </Modal.Footer>
    </StyledModal>
  );
};