import React, { useState, useEffect } from 'react';
import { Modal, Button, Badge, Table, Spinner, Pagination, Form } from 'react-bootstrap';
import { FaStream } from 'react-icons/fa';
import { getJobLogs, deleteJobExecutionLog, deleteJobExecutionLogs } from './apiJobs';
import RealtimeLogsModal from './RealtimeLogsModal'; // Importamos el componente de logs en tiempo real

// Componente para mostrar el badge del estado de la ejecución
const ExecutionStatusBadge = ({ status, className = '' }) => {
  const getBadgeVariant = () => {
    switch (status) {
      case 'COMPLETED': return 'success';
      case 'RUNNING': return 'primary';
      case 'FAILED': return 'danger';
      default: return 'warning';
    }
  };

  return <Badge bg={getBadgeVariant()} className={className}>{status}</Badge>;
};

// Componente para el control de paginación
const PaginationControl = ({ currentPage, totalPages, onPageChange }) => {
  if (totalPages <= 1) return null;
  
  const getPageItems = () => {
    let items = [];
    
    // Siempre muestra la primera página
    items.push(
      <Pagination.Item 
        key="first" 
        active={currentPage === 0} 
        onClick={() => onPageChange(0)}
      >
        1
      </Pagination.Item>
    );
    
    // Muestra el elipsis si no se inicia en la página 1
    if (currentPage > 2) {
      items.push(<Pagination.Ellipsis key="ellipsis-start" />);
    }
    
    // Muestra las páginas alrededor de la actual
    for (let i = Math.max(1, currentPage); i <= Math.min(currentPage + 1, totalPages - 2); i++) {
      items.push(
        <Pagination.Item 
          key={i} 
          active={currentPage === i} 
          onClick={() => onPageChange(i)}
        >
          {i + 1}
        </Pagination.Item>
      );
    }
    
    // Muestra el elipsis si no se termina en la última página
    if (currentPage < totalPages - 3) {
      items.push(<Pagination.Ellipsis key="ellipsis-end" />);
    }
    
    // Siempre muestra la última página
    if (totalPages > 1) {
      items.push(
        <Pagination.Item 
          key="last" 
          active={currentPage === totalPages - 1} 
          onClick={() => onPageChange(totalPages - 1)}
        >
          {totalPages}
        </Pagination.Item>
      );
    }
    
    return items;
  };
  
  return (
    <div className="d-flex justify-content-center mt-3">
      <Pagination>
        <Pagination.Prev 
          onClick={() => onPageChange(Math.max(0, currentPage - 1))}
          disabled={currentPage === 0}
        />
        {getPageItems()}
        <Pagination.Next 
          onClick={() => onPageChange(Math.min(totalPages - 1, currentPage + 1))}
          disabled={currentPage === totalPages - 1}
        />
      </Pagination>
    </div>
  );
};

// Componente completo de JobLogsModal
const JobLogsModal = ({ show, onHide, jobId, jobName }) => {
  const [executionLogs, setExecutionLogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [selectedExecution, setSelectedExecution] = useState(null);
  const [selectedExecutions, setSelectedExecutions] = useState([]);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [executionToDelete, setExecutionToDelete] = useState(null);
  
  // Nuevo estado para el modal de logs en tiempo real
  const [showRealtimeLogsModal, setShowRealtimeLogsModal] = useState(false);
  const [selectedLogExecution, setSelectedLogExecution] = useState(null);
  
  const pageSize = 10;

  useEffect(() => {
    if (show && jobId) {
      loadExecutionLogs();
    }
  }, [show, jobId, currentPage]);

  const loadExecutionLogs = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await getJobLogs(jobId, currentPage, pageSize);
      
      // Manejo del formato PaginatedResponse
      if (response.data && response.data.items) {
        setExecutionLogs(response.data.items);
        setTotalItems(response.data.total || 0);
        setTotalPages(Math.ceil((response.data.total || 0) / pageSize));
      } else {
        // Fallback para formato antiguo de API
        setExecutionLogs(response.data || []);
        setTotalItems(response.data ? response.data.length : 0);
        setTotalPages(1);
      }
    } catch (error) {
      console.error('Failed to load execution logs:', error);
      setError('Failed to load execution logs');
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleViewDetails = (execution) => {
    setSelectedExecution(execution);
  };

  // Nueva función para ver logs en tiempo real
  const handleViewRealtimeLogs = (execution) => {
    setSelectedLogExecution(execution);
    setShowRealtimeLogsModal(true);
  };

  const handleSelectExecution = (executionId) => {
    setSelectedExecutions(prev => {
      if (prev.includes(executionId)) {
        return prev.filter(id => id !== executionId);
      } else {
        return [...prev, executionId];
      }
    });
  };

  // Función para seleccionar o deseleccionar todas las ejecuciones desde la cabecera
  const handleSelectAll = (e) => {
    if (e.target.checked) {
      const allIds = executionLogs.map(execution => execution.runId || execution.run_id);
      setSelectedExecutions(allIds);
    } else {
      setSelectedExecutions([]);
    }
  };

  const handleDeleteExecution = (executionId) => {
    setExecutionToDelete(executionId);
    setShowDeleteConfirmation(true);
  };

  const handleDeleteSelected = () => {
    if (selectedExecutions.length > 0) {
      setExecutionToDelete(selectedExecutions);
      setShowDeleteConfirmation(true);
    }
  };

  const confirmDelete = async () => {
    try {
      setLoading(true);
      // Uncomment these API calls and import the functions
      if (Array.isArray(executionToDelete)) {
        // Eliminar múltiples ejecuciones
        await deleteJobExecutionLogs(jobId, executionToDelete);
      } else {
        // Eliminar una sola ejecución
        await deleteJobExecutionLog(jobId, executionToDelete);
      }
      
      // Reiniciar selección y recargar datos
      setSelectedExecutions([]);
      loadExecutionLogs();
      setShowDeleteConfirmation(false);
    } catch (error) {
      console.error('Failed to delete execution(s):', error);
      setError('Failed to delete execution(s)');
    } finally {
      setLoading(false);
    }
  };

  const formatDateTime = (dateTimeStr) => {
    if (!dateTimeStr) return 'N/A';
    return new Date(dateTimeStr).toLocaleString();
  };

  return (
    <>
      <Modal show={show} onHide={onHide} size="xl" backdrop="static" dialogClassName="modal-90w">
        <Modal.Header closeButton className="position-sticky top-0 bg-white" style={{ zIndex: 1020 }}>
          <Modal.Title>
            Job Execution History: {jobName || jobId}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ maxHeight: 'calc(100vh - 150px)', overflowY: 'auto' }}>
          {loading ? (
            <div className="text-center p-4">
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          ) : error ? (
            <div className="alert alert-danger">{error}</div>
          ) : executionLogs.length === 0 ? (
            <div className="text-center p-4">No execution history available</div>
          ) : (
            <>
              <div className="mb-3">
                <Button
                  variant="outline-danger"
                  disabled={selectedExecutions.length === 0}
                  onClick={handleDeleteSelected}
                >
                  Delete Selected ({selectedExecutions.length})
                </Button>
              </div>

              <div className="table-responsive">
                <Table striped hover>
                  <thead className="position-sticky top-0 bg-white" style={{ zIndex: 1010 }}>
                    <tr>
                      <th style={{ width: '50px' }}>
                        <Form.Check
                          type="checkbox"
                          id="select-all"
                          checked={
                            executionLogs.length > 0 &&
                            selectedExecutions.length === executionLogs.length
                          }
                          onChange={handleSelectAll}
                        />
                      </th>
                      <th>Run ID</th>
                      <th>Status</th>
                      <th>Start Time</th>
                      <th>End Time</th>
                      <th>Duration</th>
                      <th>Summary</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {executionLogs.map((execution) => {
                      const execId = execution.runId || execution.run_id;
                      return (
                        <tr key={execId}>
                          <td>
                            <Form.Check
                              type="checkbox"
                              checked={selectedExecutions.includes(execId)}
                              onChange={() => handleSelectExecution(execId)}
                            />
                          </td>
                          <td>
                            <span className="text-monospace">
                              {(execId || '').substring(0, 8)}...
                            </span>
                          </td>
                          <td>
                            <ExecutionStatusBadge status={execution.status} />
                          </td>
                          <td>{formatDateTime(execution.startTime || execution.start_time)}</td>
                          <td>{formatDateTime(execution.endTime || execution.end_time)}</td>
                          <td>
                            {execution.duration 
                              ? `${(execution.duration / 1000).toFixed(2)}s` 
                              : 'N/A'}
                          </td>
                          <td>
                            <div style={{ maxWidth: '300px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                              {execution.summary || '-'}
                            </div>
                          </td>
                          <td>
                            <div className="d-flex">
                              
                              {/* Nuevo botón para ver logs en tiempo real */}
                              <Button 
                                variant="outline-info" 
                                size="sm"
                                className="me-2"
                                onClick={() => handleViewRealtimeLogs(execution)}
                              >
                                <FaStream />
                              </Button>
                              
                              <Button 
                                variant="outline-danger" 
                                size="sm"
                                onClick={() => handleDeleteExecution(execId)}
                              >
                                Delete
                              </Button>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>

              <PaginationControl 
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
              />
            </>
          )}
        </Modal.Body>
        <Modal.Footer className="position-sticky bottom-0 bg-white" style={{ zIndex: 1020 }}>
          <Button variant="secondary" onClick={onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showDeleteConfirmation} onHide={() => setShowDeleteConfirmation(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {Array.isArray(executionToDelete) ? (
            <p>Are you sure you want to delete {executionToDelete.length} selected execution logs? This action cannot be undone.</p>
          ) : (
            <p>Are you sure you want to delete this execution log? This action cannot be undone.</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteConfirmation(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={confirmDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      
      {/* Modal para ver logs en tiempo real */}
      <RealtimeLogsModal
        show={showRealtimeLogsModal}
        onHide={() => setShowRealtimeLogsModal(false)}
        executionId={selectedLogExecution?.runId || selectedLogExecution?.run_id}
        jobName={selectedLogExecution?.jobName || selectedLogExecution?.job_name}
      />
    </>
  );
};

export default JobLogsModal;