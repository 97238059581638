import api from '../../apiBase';

export const fetchPublications = async (params) => {
  try {
    const response = await api.get('/api/publications', { params });
    return response.data;
  } catch (error) {
    console.error('Error fetching publications:', error);
    throw error;
  }
};

export const createPublication = async (formData, files = []) => {
  try {
    // Crear un objeto FormData para enviar tanto el JSON como las imágenes
    const multipartFormData = new FormData();
    
    // Agregar el objeto JSON como parte 'data'
    multipartFormData.append('data', JSON.stringify(formData));
    
    // Agregar cada archivo como parte 'files' si hay archivos
    if (files && files.length > 0) {
      files.forEach(file => {
        multipartFormData.append('files', file);
      });
    }
    
    const response = await api.post('/api/publications', multipartFormData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Accept': 'application/json; charset=utf-8'
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error creating publication:', error);
    throw error;
  }
};

export const updatePublication = async (id, formData, files = []) => {
  try {
    // Crear un objeto FormData para enviar tanto el JSON como las imágenes
    const multipartFormData = new FormData();
    
    // Agregar el objeto JSON como parte 'data'
    multipartFormData.append('data', JSON.stringify(formData));
    
    // Agregar cada archivo como parte 'files' si hay archivos
    if (files && files.length > 0) {
      files.forEach(file => {
        multipartFormData.append('files', file);
      });
    }
    
    const response = await api.post(`/api/publications/${id}/update`, multipartFormData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Accept': 'application/json; charset=utf-8'
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error updating publication:', error);
    throw error;
  }
};

export const deletePublication = async (id) => {
  try {
    await api.delete(`/api/publications/${id}`);
  } catch (error) {
    console.error('Error deleting publication:', error);
    throw error;
  }
};

export const changePublicationStatus = async (id, status) => {
  try {
    const response = await api.patch(`/api/publications/${id}/status?status=${status}`, {}, {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json; charset=utf-8'
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error changing publication status:', error);
    throw error;
  }
};

export const uploadImage = async (id, file) => {
  try {
    const formData = new FormData();
    formData.append('file', file);

    const response = await api.post(`/api/publications/${id}/images`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Accept': 'application/json; charset=utf-8'
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error uploading image:', error);
    throw error;
  }
};

export const removeImage = async (id, imageUrl) => {
  try {
    await api.delete(`/api/publications/${id}/images?imageUrl=${encodeURIComponent(imageUrl)}`);
  } catch (error) {
    console.error('Error removing image:', error);
    throw error;
  }
};

export const fetchPublicationTypes = async () => {
  try {
    const response = await api.get('/api/publications/types/details');
    return response.data;
  } catch (error) {
    console.error('Error fetching publication types:', error);
    throw error;
  }
};

export const fetchPublicationStatuses = async () => {
  try {
    const response = await api.get('/api/publications/statuses/details');
    return response.data;
  } catch (error) {
    console.error('Error fetching publication statuses:', error);
    throw error;
  }
};

export const enhanceTextWithAI = async (text, enhancementType = 'IMPROVE_TEXT') => {
  try {
    const response = await api.post('/api/publications/enhance-text', 
      { 
        text, 
        enhancement_type: enhancementType
      }, 
      {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json; charset=utf-8'
        }
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error enhancing text with AI:', error);
    throw error;
  }
};