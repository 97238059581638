import api from '../apiBase';

// Get all jobs with pagination
export const getAllJobs = async (page = 0, size = 10) => {
  try {
    const response = await api.get(`/api/jobs?page=${page}&size=${size}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching jobs:', error);
    throw error;
  }
};

// Get job by ID
export const getJobById = async (jobId) => {
  try {
    const response = await api.get(`/api/jobs/${jobId}`);
    return response.data;
  } catch (error) {
    console.error(`Error fetching job ${jobId}:`, error);
    throw error;
  }
};

// Create a new job
export const createJob = async (jobData) => {
  try {
    const response = await api.post('/api/jobs', jobData);
    return response.data;
  } catch (error) {
    console.error('Error creating job:', error);
    throw error;
  }
};

// Update an existing job
export const updateJob = async (jobId, jobData) => {
  try {
    const response = await api.put(`/api/jobs/${jobId}`, jobData);
    return response.data;
  } catch (error) {
    console.error(`Error updating job ${jobId}:`, error);
    throw error;
  }
};

// Delete a job
export const deleteJob = async (jobId) => {
  try {
    const response = await api.delete(`/api/jobs/${jobId}`);
    return response.data;
  } catch (error) {
    console.error(`Error deleting job ${jobId}:`, error);
    throw error;
  }
};

// Get all logs for a job with pagination
export const getJobLogs = async (jobId, page = 0, size = 10) => {
  try {
    const response = await api.get(`/api/jobs/${jobId}/logs?page=${page}&size=${size}`);
    return response.data;
  } catch (error) {
    console.error(`Error fetching logs for job ${jobId}:`, error);
    throw error;
  }
};

// Get recent (latest) logs for a job
export const getRecentJobLogs = async (jobId) => {
  try {
    const response = await api.get(`/api/jobs/${jobId}/logs/recent`);
    return response.data;
  } catch (error) {
    console.error(`Error fetching recent logs for job ${jobId}:`, error);
    throw error;
  }
};

// Delete a single execution log
export const deleteJobExecutionLog = async (jobId, executionId) => {
  try {
    const response = await api.delete(`/api/jobs/${jobId}/logs/${executionId}`);
    return response.data;
  } catch (error) {
    console.error(`Error deleting execution log ${executionId}:`, error);
    throw error;
  }
};

// Delete multiple execution logs
export const deleteJobExecutionLogs = async (jobId, executionIds) => {
  try {
    const response = await api.post(`/api/jobs/${jobId}/logs/delete`, { execution_ids: executionIds });
    return response.data;
  } catch (error) {
    console.error(`Error deleting execution logs:`, error);
    throw error;
  }
};

// Get all queued jobs with pagination
export const getQueuedJobs = async (page = 0, size = 10) => {
  try {
    const response = await api.get(`/api/jobs/queue?page=${page}&size=${size}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching queued jobs:', error);
    throw error;
  }
};

// Get queue size
export const getQueueSize = async () => {
  try {
    const response = await api.get('/api/jobs/queue/size');
    return response.data;
  } catch (error) {
    console.error('Error fetching queue size:', error);
    throw error;
  }
};

// Add a job to the queue
export const enqueueJob = async (jobId, priority = 0) => {
  try {
    const response = await api.post(`/api/jobs/queue/${jobId}?priority=${priority}`);
    return response.data;
  } catch (error) {
    console.error(`Error enqueueing job ${jobId}:`, error);
    throw error;
  }
};

// Remove a job from the queue
export const removeFromQueue = async (jobId) => {
  try {
    const response = await api.delete(`/api/jobs/queue/${jobId}`);
    return response.data;
  } catch (error) {
    console.error(`Error removing job ${jobId} from queue:`, error);
    throw error;
  }
};

// Clear the entire queue
export const clearQueue = async () => {
  try {
    const response = await api.delete('/api/jobs/queue');
    return response.data;
  } catch (error) {
    console.error('Error clearing job queue:', error);
    throw error;
  }
};

// Execute a job (with optional force parameter)
export const executeJob = async (jobId, forceRun = false) => {
  try {
    const response = await api.post(`/api/jobs/${jobId}/execute?forceRun=${forceRun}`);
    return response.data;
  } catch (error) {
    console.error(`Error executing job ${jobId}:`, error);
    throw error;
  }
};

// Get job queue status
export const getJobQueueStatus = async (jobId) => {
  try {
    const response = await api.get(`/api/jobs/${jobId}/queue-status`);
    return response.data;
  } catch (error) {
    console.error(`Error fetching queue status for job ${jobId}:`, error);
    throw error;
  }
};

// Get processing capacity information
export const getProcessingCapacity = async () => {
  try {
    const response = await api.get('/api/jobs/processing-capacity');
    return response.data;
  } catch (error) {
    console.error('Error fetching processing capacity:', error);
    throw error;
  }
};

// Get complete system status
export const getSystemStatus = async () => {
  try {
    const response = await api.get('/api/jobs/system-status');
    return response.data;
  } catch (error) {
    console.error('Error fetching system status:', error);
    throw error;
  }
};

// Get detailed information about running jobs
export const getDetailedRunningJobs = async () => {
  try {
    const response = await api.get('/api/jobs/running/detailed');
    console.log('Detailed running jobs API response:', response);
    return response.data; // Asegúrate de que estés devolviendo response.data
  } catch (error) {
    console.error('Error fetching detailed running jobs:', error);
    throw error;
  }
};

// Stop a running job
export const stopRunningJob = async (jobId) => {
  try {
    const response = await api.post(`/api/jobs/${jobId}/stop`);
    return response.data;
  } catch (error) {
    console.error(`Error stopping job ${jobId}:`, error);
    throw error;
  }
};

export default api;