import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Button, Form, Dropdown } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import styled, { createGlobalStyle } from 'styled-components';
import { FaFilter, FaEye, FaPlusCircle, FaEdit, FaTrash } from 'react-icons/fa';
import { 
    fetchClients, 
    fetchClientWithModules, 
    createClient, 
    updateClient, 
    deleteClient,
    createModule,
    updateModule,
    deleteModule
} from './apiClient';
import { 
    ClientModal, 
    ModuleModal, 
    ConfirmDeleteModal, 
    AlertModal 
} from './clientModals';
import ModulesModal from './ModulesModal';

const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    background-color: #f1f3f4;
    color: #202124;
    margin: 0;
    padding: 0;
  }
`;

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  max-height: 100vh;
  overflow: hidden;
`;

const TableContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  min-height: 0;
  position: relative;
`;

const StyledDataTable = styled(DataTable)`
  height: 100%;

  .rdt_Wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .rdt_TableWrapper {
    flex: 1;
    overflow: auto;
  }

  .rdt_Table {
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  }

  .rdt_TableHead {
    font-weight: 500;
    color: #5f6368;
    background-color: #f1f3f4;
    position: sticky !important;
    top: 0 !important;
    z-index: 1;
  }

  .rdt_TableCol {
    padding: 16px;
  }

  .rdt_TableCell {
    font-size: 14px;
  }

  .rdt_Pagination {
    color: #5f6368;
    font-size: 14px;
    font-weight: 500;
    position: sticky;
    bottom: 0;
    background-color: #fff;
    z-index: 1;
  }
`;

const LoadingOverlay = styled.div`
  width: 100%;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CircularProgress = styled.div`
  width: 40px;
  height: 40px;
  border: 3px solid #f3f3f3;
  border-top: 3px solid #1a73e8;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

const GoogleButton = styled(Button)`
  background-color: #fff;
  border-color: #dadce0;
  color: #3c4043;
  font-weight: 500;
  padding: 8px 16px;
  font-size: 14px;
  
  &:hover, &:focus {
    background-color: #f1f3f4;
    border-color: #dadce0;
    box-shadow: 0 1px 2px 0 rgba(60,64,67,0.3), 0 1px 3px 1px rgba(60,64,67,0.15);
  }

  &:disabled {
    background-color: #f1f3f4;
    border-color: #dadce0;
    color: #80868b;
  }
`;

const IconButton = styled(GoogleButton)`
  display: inline-flex;
  height: 40px;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  margin-right: 8px;
  
  svg {
    margin-right: 8px;
  }
`;

const FilterDropdown = styled(Dropdown)`
  .dropdown-toggle {
    background-color: #fff;
    border-color: #dadce0;
    color: #3c4043;
    font-weight: 500;
    
    &:hover, &:focus {
      background-color: #f1f3f4;
      border-color: #dadce0;
      box-shadow: 0 1px 2px 0 rgba(60,64,67,0.3), 0 1px 3px 1px rgba(60,64,67,0.15);
    }
  }

  .dropdown-menu {
    box-shadow: 0 2px 4px -1px rgba(0,0,0,0.2), 0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12);
    border: none;
    border-radius: 4px;
  }
`;

const ClientModuleManager = () => {
  const [clients, setClients] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [selectedClient, setSelectedClient] = useState(null);
  const [showClientModal, setShowClientModal] = useState(false);
  const [showModuleModal, setShowModuleModal] = useState(false);
  const [showModulesModal, setShowModulesModal] = useState(false);
  const [clientFormData, setClientFormData] = useState({ name: '', client_id: '' });
  const [moduleFormData, setModuleFormData] = useState({ 
    name: '', 
    module_id: '', 
    icon: '',
    route: '',
    group: '',
    client_id: ''
  });
  const [isEditMode, setIsEditMode] = useState(false);
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
  const [deleteType, setDeleteType] = useState('');
  const [itemToDelete, setItemToDelete] = useState(null);
  const [alertModal, setAlertModal] = useState({ show: false, title: '', message: '', variant: 'success' });
  const [filters, setFilters] = useState({
    name: '',
    client_id: '',
  });

  const fetchClientsData = useCallback(async () => {
    setLoading(true);
    try {
      const params = {
        ...filters
      };

      const data = await fetchClients(params);
      setClients(data);
      setTotalRows(data.length);
      setPage(0);
    } catch (error) {
      console.error('Error fetching clients:', error);
      showAlert('Error', 'Failed to fetch clients', 'danger');
    } finally {
      setLoading(false);
    }
  }, [filters]);

  useEffect(() => {
    fetchClientsData();
  }, [fetchClientsData]);

  const handleFilterChange = (filterName, value) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      [filterName]: value
    }));
  };

  const clearFilters = () => {
    setFilters({
      name: '',
      client_id: '',
    });
    setPage(0);
  };

  const handlePageChange = (page) => {
    setPage(page - 1);
  };

  const handlePerRowsChange = async (newPerPage, newPage) => {
    setPerPage(newPerPage);
  };

  const showAlert = useCallback((title, message, variant = 'success') => {
    setAlertModal({ show: true, title, message, variant });
  }, []);

  const closeAlert = useCallback(() => {
    setAlertModal({ show: false, title: '', message: '', variant: 'success' });
  }, []);

  const openClientModal = (isEdit = false, client = null) => {
    if (isEdit && client) {
      setClientFormData({
        id: client.id,
        name: client.name,
        client_id: client.client_id
      });
      setIsEditMode(true);
    } else {
      setClientFormData({ name: '', client_id: '' });
      setIsEditMode(false);
    }
    setShowClientModal(true);
  };

  const closeClientModal = () => {
    setShowClientModal(false);
    setClientFormData({ name: '', client_id: '' });
  };

  const openModuleModal = (isEdit = false, module = null, clientId = null) => {
    if (isEdit && module) {
      setModuleFormData({
        id: module.id,
        module_id: module.module_id,
        name: module.name,
        icon: module.icon || '',
        route: module.route || '',
        group: module.group,
        client_id: module.client_id
      });
      setIsEditMode(true);
    } else {
      setModuleFormData({ 
        module_id: '', 
        name: '', 
        icon: '', 
        route: '', 
        group: '', 
        client_id: clientId || selectedClient.clientId 
      });
      setIsEditMode(false);
    }
    setShowModuleModal(true);
  };

  const closeModuleModal = () => {
    setShowModuleModal(false);
    setModuleFormData({ 
      module_id: '', 
      name: '', 
      icon: '', 
      route: '', 
      group: '', 
      client_id: '' 
    });
  };

  const handleSaveClient = async () => {
    try {
      if (isEditMode) {
        await updateClient(clientFormData);
        showAlert('Success', 'Client updated successfully', 'success');
      } else {
        await createClient(clientFormData);
        showAlert('Success', 'Client created successfully', 'success');
      }
      fetchClientsData();
      closeClientModal();
    } catch (error) {
      console.error('Error saving client:', error);
      showAlert('Error', 'Failed to save client', 'danger');
    }
  };

  const handleSaveModule = async () => {
    try {
      if (isEditMode) {
        await updateModule(moduleFormData);
        showAlert('Success', 'Module updated successfully', 'success');
      } else {
        await createModule(moduleFormData);
        showAlert('Success', 'Module created successfully', 'success');
      }
      // If ModulesModal is open, refresh selected client data
      if (showModulesModal && selectedClient) {
        const clientWithModules = await fetchClientWithModules(selectedClient.clientId);
        setSelectedClient(clientWithModules);
      }
      closeModuleModal();
    } catch (error) {
      console.error('Error saving module:', error);
      showAlert('Error', 'Failed to save module', 'danger');
    }
  };

  const handleViewDetails = async (client) => {
    try {
      setLoading(true);
      const clientWithModules = await fetchClientWithModules(client.client_id);
      setSelectedClient(clientWithModules);
      setShowModulesModal(true);
    } catch (error) {
      console.error('Error fetching client details:', error);
      showAlert('Error', 'Failed to fetch client details', 'danger');
    } finally {
      setLoading(false);
    }
  };

  const confirmDelete = (type, item) => {
    setDeleteType(type);
    setItemToDelete(item);
    setShowDeleteConfirmModal(true);
  };

  const handleDelete = async () => {
    try {
      if (deleteType === 'client') {
        await deleteClient(itemToDelete.id);
        showAlert('Success', 'Client deleted successfully', 'success');
        fetchClientsData();
        if (showModulesModal && selectedClient && selectedClient.id === itemToDelete.id) {
          closeModulesModal();
        }
      } else if (deleteType === 'module') {
        await deleteModule(itemToDelete.id);
        showAlert('Success', 'Module deleted successfully', 'success');
        // Refresh selected client modules
        if (showModulesModal && selectedClient) {
          const clientWithModules = await fetchClientWithModules(selectedClient.clientId);
          setSelectedClient(clientWithModules);
        }
      }
    } catch (error) {
      console.error('Error deleting item:', error);
      showAlert('Error', `Failed to delete ${deleteType}`, 'danger');
    } finally {
      setShowDeleteConfirmModal(false);
      setItemToDelete(null);
    }
  };

  const closeModulesModal = () => {
    setShowModulesModal(false);
    setSelectedClient(null);
  };

  const columns = useMemo(() => [
    {
      name: 'Client ID',
      selector: row => row.client_id,
      sortable: true,
      wrap: true,
    },
    {
      name: 'Name',
      selector: row => row.name,
      sortable: true,
      wrap: true,
    },
    {
      name: 'Actions',
      cell: row => (
        <div className="d-flex">
          <IconButton
            onClick={() => handleViewDetails(row)}
            className="me-2"
          >
            <FaEye /> View Modules
          </IconButton>
          <IconButton
            onClick={() => openClientModal(true, row)}
            className="me-2"
          >
            <FaEdit /> Edit
          </IconButton>
          <IconButton
            onClick={() => confirmDelete('client', row)}
            variant="danger"
          >
            <FaTrash /> Delete
          </IconButton>
        </div>
      ),
      ignoreRowClick: true,
      width: '300px'
    }
  ], []);
  
  return (
    <>
      <GlobalStyle />
      <MainContainer>
        <div className="d-flex justify-content-between mb-4">
          <div className="d-flex">
            <GoogleButton onClick={fetchClientsData} className="me-3">
              Refresh
            </GoogleButton>
            <GoogleButton onClick={() => openClientModal()}>
              <FaPlusCircle className="me-2" /> Add Client
            </GoogleButton>
          </div>
          <FilterDropdown>
            <Dropdown.Toggle variant="outline-secondary" id="dropdown-filters">
              <FaFilter /> Filters
            </Dropdown.Toggle>
            <Dropdown.Menu className="p-3" style={{ width: '300px' }}>
              <Form>
                <Form.Group className="mb-2">
                  <Form.Label>Client ID</Form.Label>
                  <Form.Control
                    type="text"
                    value={filters.clientId}
                    onChange={(e) => handleFilterChange('clientId', e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-2">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    value={filters.name}
                    onChange={(e) => handleFilterChange('name', e.target.value)}
                  />
                </Form.Group>
                <div className="d-flex justify-content-end">
                  <GoogleButton onClick={clearFilters}>Clear Filters</GoogleButton>
                </div>
              </Form>
            </Dropdown.Menu>
          </FilterDropdown>
        </div>
        
        <TableContainer>
          <StyledDataTable
            columns={columns}
            data={clients}
            pagination
            paginationTotalRows={totalRows}
            paginationPerPage={perPage}
            paginationDefaultPage={page + 1}
            paginationRowsPerPageOptions={[10, 25, 50, 100]}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handlePerRowsChange}
            progressPending={loading}
            progressComponent={
              <LoadingOverlay>
                <CircularProgress />
              </LoadingOverlay>
            }
            fixedHeader
            fixedHeaderScrollHeight="calc(100vh - 130px)"
            paginationComponentOptions={{
              rowsPerPageText: 'Rows per page:',
              rangeSeparatorText: 'of',
              noRowsPerPage: false,
              selectAllRowsItem: false,
              selectAllRowsItemText: 'All'
            }}
            noDataComponent={<div style={{ padding: '24px', textAlign: 'center', color: '#5f6368' }}>No clients available</div>}
            highlightOnHover
            pointerOnHover
          />
        </TableContainer>
      </MainContainer>

      <ModulesModal
        show={showModulesModal}
        onHide={closeModulesModal}
        selectedClient={selectedClient}
        onAddModule={() => openModuleModal(false, null, selectedClient?.clientId)}
        onEditModule={(module) => openModuleModal(true, module)}
        onDeleteModule={(module) => confirmDelete('module', module)}
      />

      <ClientModal 
        show={showClientModal}
        onHide={closeClientModal}
        formData={clientFormData}
        setFormData={setClientFormData}
        handleSave={handleSaveClient}
        isEditMode={isEditMode}
      />

      <ModuleModal 
        show={showModuleModal}
        onHide={closeModuleModal}
        formData={moduleFormData}
        setFormData={setModuleFormData}
        handleSave={handleSaveModule}
        isEditMode={isEditMode}
      />

      <ConfirmDeleteModal 
        show={showDeleteConfirmModal}
        onHide={() => setShowDeleteConfirmModal(false)}
        onConfirm={handleDelete}
        type={deleteType}
        item={itemToDelete}
      />

      <AlertModal
        show={alertModal.show}
        onHide={closeAlert}
        title={alertModal.title}
        message={alertModal.message}
        variant={alertModal.variant}
      />
    </>
  );
};

export default ClientModuleManager;