import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import styled from 'styled-components';
import { Nav, Card, Button, Dropdown } from 'react-bootstrap';
import { getClientModules } from './admin/apiAdmin';
import config from '../config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faSignOutAlt, 
  faBars, 
  faCube, 
  faClipboardList,
  faUserMd,
  faFileAlt,
  faUsers,
  faTasks,
  faBook,
  faCalculator,
  faExclamationTriangle,
  faUserTie,
  faNewspaper,
  faChevronDown,
  faSearch
} from '@fortawesome/free-solid-svg-icons';
import HealthInsuranceAgent from './insurance/healthInsuranceAgent'
import HealthInsuranceAllocator from './insurance/HealthInsuranceAllocator';
import NotaryOrderManagement from './notary/NotaryOrderManagement';
import NotaryAllocator from './notary/NotaryAllocator';
import NotaryStatistics from './notary/NotaryStatistics';
import UserManagement from './admin/UserManagement';
import BillboardManager from './billboard/BillboardManager';
import CookBookManager from '../components/cookbook/CookBookManager';
import TaxesAgent from '../components/taxes/TaxesAgent';
import TaxesAllocator from '../components/taxes/TaxesAllocator';
import IncidentReport from '../components/incidentReport/IncidentReport';
import IncidentPeople from '../components/incidentPeople/IncidentPeople';
import Incident from '../components/incident/Incident';
import JobDashboard from '../components/jobs/JobDashboard';
import CreatorProfileManager from '../components/creatorProfile/CreatorProfileManager';
import ArticleManager from '../components/articles/ArticleManager';
import LotteryManager from '../components/lottery/LotteryManager';
import CurrencyManager from '../components/currency/CurrencyManager';
import DailySummaryManager from '../components/dailySummary/DailySummaryManager';
import ClientModuleManager from '../components/modules/ClientModuleManager';
import Publication from '../components/organizations/publications/Publication';
import PoliticalOrganizationProfile from '../components/organizations/politicalOrganizations/PoliticalOrganizationProfile';
import neoMambiLogo from "../assets/images/neo_mambi_logo.png"

const ModuleGroupContainer = styled.div`
  overflow: hidden;
  transition: max-height 0.3s ease;
`;

// Modern Google-style wrapper
const GoogleStyleWrapper = styled.div`
  font-family: 'Google Sans', 'Roboto', 'Helvetica', 'Arial', sans-serif;
  background-color: #f8f9fa;
  color: #202124;
  height: 100vh;
  display: flex;
  flex-direction: column;
`;

// Modern header with elevated design
const Header = styled.header`
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
  padding: 8px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 64px;
  z-index: 10;
`;

// Sidebar search box
const SidebarSearchContainer = styled.div`
  padding: 8px 16px;
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 5;
  border-bottom: 1px solid #e0e0e0;
`;

const SidebarSearchInput = styled.input`
  width: 100%;
  height: 40px;
  border-radius: 24px;
  border: 1px solid #dfe1e5;
  padding: 0 40px 0 16px;
  font-size: 14px;
  background-color: #f1f3f4;
  transition: background-color 0.2s, box-shadow 0.2s;
  
  &:focus {
    outline: none;
    background-color: #fff;
    box-shadow: 0 1px 6px rgba(32,33,36,0.28);
    border-color: rgba(223,225,229,0);
  }
  
  &::placeholder {
    color: #5f6368;
  }
`;

const SidebarSearchIcon = styled.div`
  position: absolute;
  right: 24px;
  top: 18px;
  color: #5f6368;
  font-size: 16px;
`;

const HeaderTitle = styled.div`
  display: flex;
  align-items: center;
  h1 {
    font-size: 20px;
    font-weight: 500;
    margin: 0;
    margin-left: 16px;
    color: #202124;
  }
`;

const HeaderActions = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const Logo = styled.img`
  width: 28px;
  height: auto;
`;

const SidebarToggle = styled.button`
  background: transparent;
  border: none;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #5f6368;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.2s;
  
  &:hover {
    background-color: rgba(0,0,0,0.05);
  }
`;

// Modern Google-style sidebar
const Sidebar = styled(Nav)`
  background-color: #fff;
  width: 280px;
  transition: all 0.3s;
  padding-top: 0; /* Changed to 0 to accommodate search bar at top */
  border-right: 1px solid #e0e0e0;
  overflow-y: auto;
  height: calc(100vh - 64px);
  box-shadow: 0 1px 2px rgba(0,0,0,0.08);
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
`;

// Sidebar content container for proper scrolling
const SidebarContent = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  flex: 1;
`;

const SidebarLink = styled(Nav.Link)`
  color: #3c4043;
  padding: 0 24px;
  height: 48px;
  display: flex;
  align-items: center;
  font-size: 14px;
  border-radius: 0 24px 24px 0;
  margin: 4px 12px 4px 0;
  transition: all 0.2s;
  position: relative;
  
  &:hover, &:focus {
    background-color: #f1f3f4;
    color: #202124;
  }
  
  &.active {
    color: #1a73e8;
    background-color: #e8f0fe;
    font-weight: 500;
  }
  
  &.active::before {
    content: '';
    position: absolute;
    left: 0;
    top: 8px;
    bottom: 8px;
    width: 3px;
    background-color: #1a73e8;
    border-radius: 0 4px 4px 0;
  }
  
  svg {
    width: 20px;
    margin-right: 16px;
    opacity: 0.7;
  }
  
  &.active svg {
    color: #1a73e8;
    opacity: 1;
  }
`;

const SidebarGroupTitle = styled.div`
  font-size: 12px;
  font-weight: 500;
  color: #5f6368;
  padding: 8px 24px;
  margin-top: 8px;
  margin-bottom: 4px;
  letter-spacing: 0.07em;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  
  &:hover {
    background-color: rgba(0,0,0,0.03);
  }
  
  .chevron {
    transition: transform 0.2s ease;
    
    &.collapsed {
      transform: rotate(-90deg);
    }
  }
`;

// User dropdown with material design
const UserButton = styled(Dropdown.Toggle)`
  background-color: transparent;
  border: none;
  display: flex;
  align-items: center;
  color: #5f6368;
  font-size: 14px;
  
  &:hover, &:focus, &:active {
    background-color: rgba(0,0,0,0.05) !important;
    box-shadow: none !important;
    color: #5f6368 !important;
    border: none !important;
  }
  
  &::after {
    display: none;
  }
`;

const UserAvatar = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 8px;
`;

const UserDropdownMenu = styled(Dropdown.Menu)`
  padding: 16px 0;
  border-radius: 8px;
  border: none;
  box-shadow: 0 2px 10px rgba(0,0,0,0.2);
  width: 320px;
  
  .dropdown-item {
    padding: 12px 16px;
    font-size: 14px;
    
    &:hover {
      background-color: #f1f3f4;
    }
    
    svg {
      margin-right: 12px;
      width: 16px;
    }
  }
`;

const UserProfile = styled.div`
  padding: 16px;
  border-bottom: 1px solid #e0e0e0;
  margin-bottom: 8px;
  
  h6 {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 4px;
  }
  
  p {
    font-size: 14px;
    color: #5f6368;
    margin-bottom: 0;
  }
`;

// Content area
const ContentWrapper = styled.div`
  flex: 1;
  display: flex;
  overflow: hidden;
`;

const MainContent = styled.div`
  flex: 1;
  padding: 24px;
  overflow-y: auto;
  background-color: #f8f9fa;
`;

// Welcome card with Google Material Design
const WelcomeCard = styled(Card)`
  border: none;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
  overflow: hidden;
  max-width: 600px;
  margin: 48px auto;
  
  .card-body {
    padding: 32px;
  }
  
  .card-title {
    font-size: 22px;
    font-weight: 500;
    color: #202124;
    margin-bottom: 16px;
  }
  
  .card-text {
    color: #5f6368;
    font-size: 16px;
    line-height: 1.5;
  }
`;

const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
  
  img {
    width: 80px;
    height: 80px;
    object-fit: contain;
  }
`;

// Modern loading spinner with Google colors
const GoogleSpinner = styled.div`
  width: 40px;
  height: 40px;
  position: relative;
  margin: 100px auto;
  
  .dot {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    position: absolute;
    animation-duration: 2s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
  }
  
  .dot1 {
    background-color: #4285F4;
    animation-name: dotOneAnimation;
    left: 0;
    top: 14px;
  }
  
  .dot2 {
    background-color: #EA4335;
    animation-name: dotTwoAnimation;
    right: 0;
    top: 14px;
  }
  
  .dot3 {
    background-color: #FBBC05;
    animation-name: dotThreeAnimation;
    left: 14px;
    bottom: 0;
  }
  
  .dot4 {
    background-color: #34A853;
    animation-name: dotFourAnimation;
    right: 14px;
    bottom: 0;
  }
  
  @keyframes dotOneAnimation {
    0% { transform: scale(1); }
    25% { transform: scale(1.5); }
    50% { transform: scale(1); }
    75% { transform: scale(1); }
    100% { transform: scale(1); }
  }
  
  @keyframes dotTwoAnimation {
    0% { transform: scale(1); }
    25% { transform: scale(1); }
    50% { transform: scale(1.5); }
    75% { transform: scale(1); }
    100% { transform: scale(1); }
  }
  
  @keyframes dotThreeAnimation {
    0% { transform: scale(1); }
    25% { transform: scale(1); }
    50% { transform: scale(1); }
    75% { transform: scale(1.5); }
    100% { transform: scale(1); }
  }
  
  @keyframes dotFourAnimation {
    0% { transform: scale(1); }
    25% { transform: scale(1); }
    50% { transform: scale(1); }
    75% { transform: scale(1); }
    100% { transform: scale(1.5); }
  }
`;

// Error card with Google styling
const ErrorCard = styled(Card)`
  border: none;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
  max-width: 400px;
  overflow: hidden;
  
  .card-header {
    background-color: #EA4335;
    color: white;
    font-weight: 500;
    font-size: 16px;
    padding: 16px;
    border-bottom: none;
  }
  
  .card-body {
    padding: 24px;
  }
  
  .card-text {
    color: #5f6368;
    margin-bottom: 24px;
  }
`;

// Google style button
const GoogleButton = styled(Button)`
  background-color: #1a73e8;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 8px 24px;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.25px;
  box-shadow: none;
  transition: background-color 0.2s, box-shadow 0.2s;
  text-transform: none;
  
  &:hover, &:focus {
    background-color: #1765cc;
    box-shadow: 0 1px 3px rgba(0,0,0,0.3);
  }
  
  &:active {
    background-color: #155db9;
  }
`;

// Function to get appropriate icon for each module
const getModuleIcon = (route) => {
  switch (route) {
    case '/health_insurance_agent':
    case '/health_insurance_allocator':
      return faUserMd;
    case '/notary_agent':
    case '/notary_allocator':
    case '/notary_statistics':
      return faFileAlt;
    case '/workers_manager':
      return faUsers;
    case '/billboard_manager':
      return faClipboardList;
    case '/cookbook_manager':
      return faBook;
    case '/taxes':
    case '/taxes_allocator':
      return faCalculator;
    case '/incident_report':
    case '/incident_people':
    case '/incident':
      return faExclamationTriangle;
    case '/job_dashboard':
      return faTasks;
    case '/creator_profile':
      return faUserTie;
    case '/articles_manager':
      return faNewspaper;
    default:
      return faCube;
  }
};

const DashboardComponent = ({ onLogout }) => {
  const [client, setClient] = useState(null);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);
  const [activeModule, setActiveModule] = useState(null);
  const [isSidebarVisible, setIsSidebarVisible] = useState(true);
  const [groupedModules, setGroupedModules] = useState({});
  const [userEmail, setUserEmail] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [collapsedGroups, setCollapsedGroups] = useState({});

  useEffect(() => {
    const fetchClientModules = async () => {
      try {
        const response = await getClientModules(config.clientId);
        setClient(response);
        if (response && response.modules && response.modules.length > 0) {
          organizeModulesByGroup(response.modules);
        }
        const savedEmail = localStorage.getItem('lastUsedEmail');
        setUserEmail(savedEmail || 'usuario@ejemplo.com');
      } catch (error) {
        setError(error.message || 'Error al obtener los módulos del cliente');
      } finally {
        setLoading(false);
      }
    };

    fetchClientModules();
  }, []);

  const organizeModulesByGroup = (modules) => {
    const grouped = modules.reduce((acc, module) => {
      const group = module.group || 'General';
      if (!acc[group]) {
        acc[group] = [];
      }
      acc[group].push(module);
      return acc;
    }, {});

    const sortedGroups = Object.keys(grouped).sort();
    const sortedGroupedModules = {};
    sortedGroups.forEach(group => {
      sortedGroupedModules[group] = grouped[group];
    });

    setGroupedModules(sortedGroupedModules);
    
    // Initialize collapsed state for all groups (false = expanded)
    const initialCollapsedState = {};
    sortedGroups.forEach(group => {
      initialCollapsedState[group] = false;
    });
    setCollapsedGroups(initialCollapsedState);
  };

  const toggleSidebar = () => {
    setIsSidebarVisible(!isSidebarVisible);
  };
  
  const toggleGroup = (group) => {
    setCollapsedGroups(prev => ({
      ...prev,
      [group]: !prev[group]
    }));
  };
  
  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };
  
  const filterModules = (modules) => {
    if (!searchQuery) return modules;
    
    return modules.filter(module => 
      module.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
  };

  if (loading) {
    return (
      <GoogleStyleWrapper className="d-flex justify-content-center align-items-center">
        <GoogleSpinner>
          <div className="dot dot1"></div>
          <div className="dot dot2"></div>
          <div className="dot dot3"></div>
          <div className="dot dot4"></div>
        </GoogleSpinner>
      </GoogleStyleWrapper>
    );
  }
  
  if (error) {
    return (
      <GoogleStyleWrapper className="d-flex justify-content-center align-items-center">
        <ErrorCard>
          <Card.Header>Error</Card.Header>
          <Card.Body>
            <Card.Text>{error}</Card.Text>
            <GoogleButton onClick={onLogout}>
              Cerrar sesión
            </GoogleButton>
          </Card.Body>
        </ErrorCard>
      </GoogleStyleWrapper>
    );
  }

  return (
    <Router>
      <GoogleStyleWrapper>
        <Header>
          <HeaderTitle>
            <SidebarToggle onClick={toggleSidebar}>
              <FontAwesomeIcon icon={faBars} />
            </SidebarToggle>
            <Logo src={neoMambiLogo} alt="Neo Mambi Logo" />
            <h1>Neo Dashboard</h1>
          </HeaderTitle>
          
          <HeaderActions>
            <Dropdown>
              <UserButton variant="success" id="dropdown-basic">
                <UserAvatar src={neoMambiLogo} alt="User Avatar" />
                <span style={{ marginLeft: '8px' }}>{userEmail}</span>
              </UserButton>

              <UserDropdownMenu>
                <UserProfile>
                  <UserAvatar src={neoMambiLogo} alt="User Avatar" style={{ width: 48, height: 48 }} />
                  <h6>Usuario</h6>
                  <p>{userEmail}</p>
                </UserProfile>
                <Dropdown.Item onClick={onLogout}>
                  <FontAwesomeIcon icon={faSignOutAlt} />
                  Cerrar sesión
                </Dropdown.Item>
              </UserDropdownMenu>
            </Dropdown>
          </HeaderActions>
        </Header>

        <ContentWrapper>
          {isSidebarVisible && (
            <Sidebar className="flex-column">
              <SidebarSearchContainer>
                <SidebarSearchInput 
                  type="text" 
                  placeholder="Buscar módulos..." 
                  value={searchQuery}
                  onChange={handleSearch}
                />
                <SidebarSearchIcon>
                  <FontAwesomeIcon icon={faSearch} />
                </SidebarSearchIcon>
              </SidebarSearchContainer>
              
              <SidebarContent>
                {Object.entries(groupedModules).map(([group, modules]) => {
                  const filteredModules = filterModules(modules);
                  // Skip rendering groups with no matching modules when searching
                  if (searchQuery && filteredModules.length === 0) return null;
                  
                  return (
                    <div key={group}>
                      <SidebarGroupTitle onClick={() => toggleGroup(group)}>
                        <span>{group}</span>
                        <FontAwesomeIcon 
                          icon={faChevronDown} 
                          className={`chevron ${collapsedGroups[group] ? 'collapsed' : ''}`}
                        />
                      </SidebarGroupTitle>
                      <ModuleGroupContainer
                        style={{ 
                          maxHeight: collapsedGroups[group] ? '0' : `${filteredModules.length * 56}px`
                        }}
                      >
                        {filteredModules.map((module) => (
                          <Nav.Item key={module.module_id}>
                            <SidebarLink
                              as={Link}
                              to={module.route}
                              className={activeModule === module.module_id ? 'active' : ''}
                              onClick={() => setActiveModule(module.module_id)}
                            >
                              <FontAwesomeIcon icon={getModuleIcon(module.route)} />
                              <span>{module.name}</span>
                            </SidebarLink>
                          </Nav.Item>
                        ))}
                      </ModuleGroupContainer>
                    </div>
                  );
                })}
              </SidebarContent>
            </Sidebar>
          )}

          <MainContent>
            <Routes>
              <Route path="/" element={
                <WelcomeCard>
                  <Card.Body className="text-center">
                    <LogoContainer>
                      <Logo src={neoMambiLogo} alt="Neo Mambi Logo" />
                    </LogoContainer>
                    <Card.Title>Bienvenido al Dashboard</Card.Title>
                    <Card.Text>Selecciona un módulo del menú lateral para comenzar a trabajar.</Card.Text>
                  </Card.Body>
                </WelcomeCard>
              } />
              {client && client.modules.map((module) => (
                <Route 
                  key={module.module_id} 
                  path={module.route} 
                  element={<ModuleContent module={module} />} 
                />
              ))}
            </Routes>
          </MainContent>
        </ContentWrapper>
      </GoogleStyleWrapper>
    </Router>
  );
};

const ModuleContent = ({ module }) => {
  return (
    <div className="h-100 d-flex flex-column">
      {renderModuleContent(module)}
    </div>
  );
};

const renderModuleContent = (module) => {
  console.log(module)
  switch (module.route) {
    case '/health_insurance_agent':
      return <HealthInsuranceAgent />;
    case '/health_insurance_allocator':
      return <HealthInsuranceAllocator />;
    case '/notary_agent':
      return <NotaryOrderManagement />;
    case '/notary_allocator':
        return <NotaryAllocator />;
    case '/notary_statistics':
      return <NotaryStatistics />;
    case '/workers_manager':
      return <UserManagement />;
    case '/billboard_manager':
      return <BillboardManager />;
    case '/cookbook_manager':
      return <CookBookManager />;
    case '/taxes':
      return <TaxesAgent />;
    case '/taxes_allocator':
      return <TaxesAllocator />;
    case '/incident_report':
      return <IncidentReport />;
    case '/incident_people':
      return <IncidentPeople />;
    case '/incident':
      return <Incident />;
    case '/job_dashboard':
      return <JobDashboard />;
    case '/creator_profile':
      return <CreatorProfileManager />;
    case '/articles_manager':
      return <ArticleManager />;
    case '/lottery_manager':
      return <LotteryManager />;
    case '/currency_manager':
      return <CurrencyManager />;
    case '/daily_summary':
      return <DailySummaryManager />;
    case '/client_module_manager':
      return <ClientModuleManager />;
    case '/political_organization_publications':
      return <Publication />;
    case '/political_organization_profile':
      return <PoliticalOrganizationProfile />;
    default:
      return (
        <div className="h-100 d-flex justify-content-center align-items-center">
          <p>Contenido del módulo {module.module_id}</p>
        </div>
      );
  }
};

export default DashboardComponent;