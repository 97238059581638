import React, { useEffect } from 'react';
import styled from 'styled-components';
import { 
  FaEdit, 
  FaTrash, 
  FaPlus, 
  FaTimes, 
  FaTwitter, 
  FaFacebook, 
  FaInstagram, 
  FaYoutube, 
  FaLinkedin, 
  FaTiktok, 
  FaGlobe 
} from 'react-icons/fa';

const Panel = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: white;
`;

const FixedHeader = styled.div`
  padding: 16px;
  border-bottom: 1px solid #dadce0;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`;

const AvatarContainer = styled.div`
  margin-right: 16px;
`;

const Avatar = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #1a73e8;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 24px;
  overflow: hidden;
`;

const AvatarImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const Name = styled.h3`
  margin: 0;
  font-size: 20px;
  color: #202124;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  color: #5f6368;
  font-size: 20px;
  cursor: pointer;
  position: absolute;
  right: 16px;
  top: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  
  &:hover {
    background-color: #f1f3f4;
  }
`;

const ScrollableContent = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 16px;
`;

const FixedActions = styled.div`
  padding: 16px;
  border-top: 1px solid #dadce0;
`;

const Section = styled.div`
  margin-bottom: 24px;
`;

const SectionTitle = styled.h4`
  font-size: 14px;
  color: #5f6368;
  margin-bottom: 12px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
`;

const InfoGrid = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  row-gap: 12px;
  column-gap: 16px;
`;

const Label = styled.div`
  font-size: 14px;
  color: #5f6368;
  font-weight: 500;
`;

const Value = styled.div`
  font-size: 14px;
  color: #202124;
  word-break: break-word;
`;

const MultilineValue = styled(Value)`
  white-space: pre-wrap;
  overflow-wrap: break-word;
`;

const ActionButton = styled.button`
  display: flex;
  align-items: center;
  border: none;
  background-color: ${props => props.variant === 'danger' ? '#fce8e6' : '#f1f3f4'};
  color: ${props => props.variant === 'danger' ? '#c5221f' : '#202124'};
  border-radius: 4px;
  padding: 8px 12px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  margin-right: 8px;
  margin-bottom: 8px;
  
  &:hover {
    background-color: ${props => props.variant === 'danger' ? '#fad2cf' : '#e8eaed'};
  }
  
  & > svg {
    margin-right: 8px;
  }
`;

const SourceItem = styled.div`
  background-color: #f1f3f4;
  border-radius: 8px;
  padding: 12px;
  margin-bottom: 16px;
  position: relative;
`;

const SourceHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
`;

const SourceTitle = styled.div`
  font-weight: 500;
  font-size: 16px;
  color: #202124;
`;

const SourceBadge = styled.span`
  display: inline-block;
  background-color: ${props => props.active ? '#e6f4ea' : '#fce8e6'};
  color: ${props => props.active ? '#137333' : '#c5221f'};
  padding: 2px 8px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;
  margin-left: 8px;
`;

const SourceTypeBadge = styled.span`
  display: inline-block;
  background-color: ${props => props.type === 'YOUTUBE' ? '#fef7e0' : '#e8f0fe'};
  color: ${props => props.type === 'YOUTUBE' ? '#b06000' : '#174ea6'};
  padding: 2px 8px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;
`;

const SourceActions = styled.div`
  position: absolute;
  top: 12px;
  right: 12px;
  display: flex;
  gap: 8px;
`;

const SourceActionButton = styled.button`
  background: none;
  border: none;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  color: #5f6368;
  
  &:hover {
    background-color: #e8eaed;
  }
  
  &.danger:hover {
    background-color: #fce8e6;
    color: #c5221f;
  }
`;

const SocialNetworksSection = styled(Section)`
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 16px;
  margin-top: 20px;
  border: 1px solid #e8eaed;
`;

const SocialNetworkList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  margin-top: 12px;
`;

const SocialNetworkBadge = styled.a`
  display: flex;
  align-items: center;
  background-color: #e8f0fe;
  color: #1a73e8;
  padding: 8px 14px;
  border-radius: 20px;
  font-size: 13px;
  text-decoration: none;
  transition: all 0.2s ease;
  margin-bottom: 8px;
  font-weight: 500;
  
  &:hover {
    background-color: #d2e3fc;
    text-decoration: none;
    color: #174ea6;
    box-shadow: 0 1px 2px rgba(60,64,67,0.3);
    transform: translateY(-1px);
  }
  
  & > svg {
    margin-right: 8px;
    font-size: 16px;
  }
`;

const BadgesList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
  margin-top: 4px;
`;

const Badge = styled.span`
  display: inline-block;
  background-color: #e8f0fe;
  color: #174ea6;
  padding: 4px 10px;
  border-radius: 16px;
  font-size: 12px;
  margin-bottom: 4px;
  font-family: monospace;
  border: 1px solid #d2e3fc;
`;

const InfoItemWithFallback = ({ label, value, multiline = false, isLink = false }) => {
  if (isLink && value) {
    return (
      <>
        <Label>{label}</Label>
        <Value>
          <a 
            href={value.startsWith('http') ? value : `https://${value}`} 
            target="_blank" 
            rel="noopener noreferrer"
            style={{ color: '#1a73e8', textDecoration: 'none' }}
          >
            {value}
          </a>
        </Value>
      </>
    );
  }
  
  return (
    <>
      <Label>{label}</Label>
      {multiline ? (
        <MultilineValue>{value || 'Not provided'}</MultilineValue>
      ) : (
        <Value>{value || 'Not provided'}</Value>
      )}
    </>
  );
};

export const CreatorProfileSlidingPanel = ({ 
  selectedProfile, 
  onClose,
  onEdit,
  onDelete,
  onAddSource,
  onEditSource,
  onDeleteSource
}) => {
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [onClose]);

  if (!selectedProfile) return null;

  const getInitials = (name) => {
    return name?.split(' ').map(n => n[0]).join('').toUpperCase() || '?';
  };

  const renderSourceDetails = (source) => {
    if (source.type === 'YOUTUBE') {
      return (
        <>
          <InfoItemWithFallback label="Channel ID" value={source.channel_id} />
          <InfoItemWithFallback label="Description" value={source.description} multiline={true} />
          <InfoItemWithFallback label="Thumbnail" value={source.thumbnail_url} />
        </>
      );
    } else if (source.type === 'RSS') {
      return (
        <>
          <InfoItemWithFallback label="Feed URL" value={source.feed_url} isLink={true} />
          <InfoItemWithFallback label="Site URL" value={source.site_url} isLink={true} />
          <InfoItemWithFallback label="Site Logo" value={source.site_logo} />
          
          <Label>CSS Selector</Label>
          {source.selector ? (
            <BadgesList>
              <Badge>{source.selector}</Badge>
            </BadgesList>
          ) : (
            <Value>Not provided</Value>
          )}
          
          <Label>Remove Selectors</Label>
          {source.remove_selectors?.length > 0 ? (
            <BadgesList>
              {source.remove_selectors.map((selector, idx) => (
                <Badge key={idx}>{selector}</Badge>
              ))}
            </BadgesList>
          ) : (
            <Value>Not provided</Value>
          )}
        </>
      );
    }
    return null;
  };

  return (
    <Panel>
      <FixedHeader>
        <CloseButton onClick={onClose}>
          <FaTimes />
        </CloseButton>
        <Header>
          <AvatarContainer>
            <Avatar>
              {selectedProfile.avatar ? (
                <AvatarImage src={selectedProfile.avatar} alt={selectedProfile.name} />
              ) : (
                getInitials(selectedProfile.name)
              )}
            </Avatar>
          </AvatarContainer>
          <div>
            <Name>{selectedProfile.name}</Name>
            {selectedProfile.active ? (
              <SourceBadge active={true}>Active</SourceBadge>
            ) : (
              <SourceBadge active={false}>Inactive</SourceBadge>
            )}
          </div>
        </Header>
        <SectionTitle>Creator Information</SectionTitle>
      </FixedHeader>

      <ScrollableContent>
        <InfoGrid>
          <InfoItemWithFallback label="Name" value={selectedProfile.name} />
          <InfoItemWithFallback label="Country" value={selectedProfile.country} />
          <InfoItemWithFallback label="Description" value={selectedProfile.description} multiline={true} />
          <InfoItemWithFallback 
            label="Created At" 
            value={selectedProfile.created_at ? new Date(selectedProfile.created_at).toLocaleString() : null} 
          />
          <InfoItemWithFallback 
            label="Updated At" 
            value={selectedProfile.updated_at ? new Date(selectedProfile.updated_at).toLocaleString() : null} 
          />
        </InfoGrid>

        {selectedProfile.social_networks && selectedProfile.social_networks.length > 0 && (
          <SocialNetworksSection>
            <SectionTitle>Social Networks</SectionTitle>
            <div style={{ marginBottom: '8px', fontSize: '13px', color: '#5f6368' }}>
              Click on any network to visit the profile
            </div>
            <SocialNetworkList>
              {selectedProfile.social_networks.map((network, index) => (
                <SocialNetworkBadge 
                  key={index} 
                  href={network.url} 
                  target="_blank" 
                  rel="noopener noreferrer"
                >
                  {(() => {
                    const platform = network.platform.toLowerCase();
                    if (platform.includes('twitter') || platform.includes('x.com')) {
                      return <FaTwitter />;
                    } else if (platform.includes('facebook')) {
                      return <FaFacebook />;
                    } else if (platform.includes('instagram')) {
                      return <FaInstagram />;
                    } else if (platform.includes('youtube')) {
                      return <FaYoutube />;
                    } else if (platform.includes('linkedin')) {
                      return <FaLinkedin />;
                    } else if (platform.includes('tiktok')) {
                      return <FaTiktok />;
                    } else {
                      return <FaGlobe />;
                    }
                  })()}
                  {network.platform}
                </SocialNetworkBadge>
              ))}
            </SocialNetworkList>
          </SocialNetworksSection>
        )}

        <Section>
          <SectionTitle>Content Sources</SectionTitle>
          {selectedProfile.content_sources && selectedProfile.content_sources.length > 0 ? (
            selectedProfile.content_sources.map((source, index) => (
              <SourceItem key={index}>
                <SourceActions>
                  <SourceActionButton 
                    onClick={() => onEditSource(selectedProfile, source, index)}
                    title="Edit source"
                  >
                    <FaEdit />
                  </SourceActionButton>
                  <SourceActionButton 
                    className="danger"
                    onClick={() => onDeleteSource(selectedProfile, index)}
                    title="Delete source"
                  >
                    <FaTrash />
                  </SourceActionButton>
                </SourceActions>
                
                <SourceHeader>
                  <div>
                    <SourceTitle>{source.name}</SourceTitle>
                    <SourceBadge active={source.active}>{source.active ? 'Active' : 'Inactive'}</SourceBadge>
                    <SourceTypeBadge type={source.type}>{source.type}</SourceTypeBadge>
                  </div>
                </SourceHeader>
                
                <InfoGrid style={{ marginTop: '12px' }}>
                  <InfoItemWithFallback label="Language" value={source.language} />
                  
                  <Label>Target Countries</Label>
                  {source.target_countries?.length > 0 ? (
                    <BadgesList>
                      {source.target_countries.map((country, idx) => (
                        <Badge key={idx}>{country}</Badge>
                      ))}
                    </BadgesList>
                  ) : (
                    <Value>Not provided</Value>
                  )}
                  
                  {renderSourceDetails(source)}
                </InfoGrid>
              </SourceItem>
            ))
          ) : (
            <div style={{ color: '#5f6368', padding: '12px', textAlign: 'center' }}>
              No content sources added yet
            </div>
          )}
          
          <ActionButton onClick={() => onAddSource(selectedProfile)}>
            <FaPlus /> Add Content Source
          </ActionButton>
        </Section>
      </ScrollableContent>

      <FixedActions>
        <Section>
          <SectionTitle>Actions</SectionTitle>
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            <ActionButton onClick={() => onEdit(selectedProfile)}>
              <FaEdit /> Edit Profile
            </ActionButton>
            <ActionButton variant="danger" onClick={() => onDelete(selectedProfile)}>
              <FaTrash /> Delete Profile
            </ActionButton>
          </div>
        </Section>
      </FixedActions>
    </Panel>
  );
};