import React, { useState, useEffect, useRef } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { Form, Button, Container, Card, Alert, Spinner } from 'react-bootstrap';
import { login, setAuthToken } from './apiAuth';
import neoMambiLogo from "../../assets/images/neo_mambi_logo.png";

const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'Google Sans', 'Roboto', 'Helvetica', 'Arial', sans-serif;
    background-color: #f8f9fa;
    color: #202124;
  }
`;

const StyledContainer = styled(Container)`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
`;

const StyledCard = styled(Card)`
  border: none;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  max-width: 400px;
  width: 100%;
  padding: 12px;
`;

const Logo = styled.img`
  width: 80px;
  height: auto;
  margin-bottom: 16px;
`;

const StyledCardTitle = styled(Card.Title)`
  font-size: 22px;
  color: #202124;
  margin-bottom: 24px;
  font-weight: 400;
`;

const StyledForm = styled(Form)`
  .form-label {
    font-weight: 500;
    color: #5f6368;
  }

  .form-control {
    border-radius: 4px;
    border: 1px solid #dadce0;
    padding: 12px 16px;
    font-size: 16px;
    margin-bottom: 16px;
    
    &:focus {
      border-color: #1a73e8;
      box-shadow: 0 0 0 2px rgba(26,115,232,0.2);
    }
  }
`;

const GoogleButton = styled(Button)`
  background-color: #1a73e8;
  border-color: #1a73e8;
  color: #fff;
  font-weight: 500;
  letter-spacing: 0.25px;
  padding: 10px 24px;
  font-size: 15px;
  border-radius: 4px;
  transition: all 0.2s ease;
  
  &:hover, &:focus {
    background-color: #0b5cdb;
    border-color: #0b5cdb;
    box-shadow: 0 1px 3px rgba(60,64,67,0.3);
  }
  
  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
`;

const StyledAlert = styled(Alert)`
  font-size: 14px;
  border-radius: 4px;
  padding: 8px 12px;
  margin-bottom: 16px;
  border-left: 3px solid #d93025;
  background-color: #fce8e6;
  color: #d93025;
`;

const StyledSpinner = styled(Spinner)`
  width: 16px;
  height: 16px;
  margin-right: 8px;
`;

const AuthComponent = ({ onLoginSuccess }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  
  const emailRef = useRef(null);
  const passwordRef = useRef(null);

  useEffect(() => {
    const savedEmail = localStorage.getItem('lastUsedEmail');
    if (savedEmail) {
      setEmail(savedEmail);
      passwordRef.current?.focus();
    } else {
      emailRef.current?.focus();
    }
  }, []);

  const handleLogin = async (e) => {
    e.preventDefault();
    setError('');
    setIsLoading(true);
    try {
      const response = await login(email, password);
      if (response.data) {
        localStorage.setItem('lastUsedEmail', email);
        setAuthToken(response.data.firebase_auth_data.id_token);
        onLoginSuccess(response);
      } else {
        setError(response.message || 'Error de autenticación');
      }
    } catch (error) {
      setError(error.message || 'Error al intentar iniciar sesión');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <GlobalStyle />
      <StyledContainer>
        <StyledCard>
          <Card.Body className="text-center">
            <Logo src={neoMambiLogo} alt="Neo Mambi Logo" />
            <StyledCardTitle>Neo Mambi Admin</StyledCardTitle>
            {error && <StyledAlert variant="danger">{error}</StyledAlert>}
            <StyledForm onSubmit={handleLogin}>
              <Form.Group controlId="formBasicEmail">
                <Form.Control
                  type="email"
                  placeholder="Correo electrónico"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  ref={emailRef}
                />
              </Form.Group>

              <Form.Group controlId="formBasicPassword">
                <Form.Control
                  type="password"
                  placeholder="Contraseña"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                  ref={passwordRef}
                />
              </Form.Group>

              <GoogleButton variant="primary" type="submit" className="w-100" disabled={isLoading}>
                {isLoading ? (
                  <>
                    <StyledSpinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                    <span>Iniciando sesión...</span>
                  </>
                ) : (
                  'Iniciar sesión'
                )}
              </GoogleButton>
            </StyledForm>
          </Card.Body>
        </StyledCard>
      </StyledContainer>
    </>
  );
};

export default AuthComponent;