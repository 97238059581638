import React, { useState, useEffect } from 'react';
import { Modal, Form, Button, Badge, InputGroup } from 'react-bootstrap';
import styled from 'styled-components';
import { FaPlusCircle, FaEdit, FaTrash, FaSearch } from 'react-icons/fa';

const GoogleModal = styled(Modal)`
  .modal-content {
    border-radius: 8px;
    border: none;
    box-shadow: 0 1px 3px 0 rgba(60,64,67,0.3), 0 4px 8px 3px rgba(60,64,67,0.15);
  }

  .modal-header {
    border-bottom: 1px solid #e8eaed;
    padding: 24px 24px 20px;
  }

  .modal-title {
    font-size: 22px;
    color: #202124;
    font-weight: 400;
  }

  .modal-body {
    padding: 24px;
    max-height: 70vh;
    overflow-y: auto;
  }

  .modal-footer {
    border-top: 1px solid #e8eaed;
    padding: 20px 24px 24px;
  }
`;

const ClientInfoSection = styled.div`
  margin-bottom: 24px;
`;

const Avatar = styled.div`
  width: 64px;
  height: 64px;
  border-radius: 50%;
  background-color: #1a73e8;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  margin-right: 16px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
`;

const Name = styled.h2`
  margin: 0;
  font-size: 22px;
  font-weight: 400;
  color: #202124;
`;

const InfoItem = styled.div`
  background-color: #f1f3f4;
  padding: 12px;
  border-radius: 8px;
  transition: background-color 0.3s ease;
  margin-bottom: 12px;

  &:hover {
    background-color: #e8f0fe;
  }
`;

const InfoLabel = styled.div`
  font-size: 12px;
  color: #5f6368;
  margin-bottom: 4px;
`;

const InfoValue = styled.div`
  font-size: 14px;
  color: #202124;
  font-weight: 500;
`;

const SectionTitle = styled.h3`
  font-size: 14px;
  font-weight: 500;
  color: #5f6368;
  margin-bottom: 12px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const GroupBadge = styled(Badge)`
  background-color: #e8f0fe;
  color:rgb(255, 255, 255);
  font-weight: 500;
  font-size: 12px;
  padding: 6px 10px;
  border-radius: 16px;
  margin-right: 8px;
`;

const ModuleCard = styled.div`
  border: 1px solid #dadce0;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 16px;
  transition: box-shadow 0.3s ease;
  
  &:hover {
    box-shadow: 0 1px 2px 0 rgba(60,64,67,0.3), 0 1px 3px 1px rgba(60,64,67,0.15);
  }
`;

const ModuleHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
`;

const ModuleName = styled.h4`
  margin: 0;
  font-size: 16px;
  font-weight: 500;
  color: #202124;
`;

const ModuleActions = styled.div`
  display: flex;
`;

const ModuleDetails = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;
`;

const ModuleDetail = styled.div`
  font-size: 13px;
  color: #5f6368;
  
  span {
    font-weight: 500;
    color: #202124;
  }
`;

const GoogleButton = styled(Button)`
  border-radius: 4px;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 14px;
  padding: 8px 24px;

  &.btn-primary {
    background-color: #1a73e8;
    border-color: #1a73e8;
    color: #fff;
    
    &:hover, &:focus {
      background-color: #1765cc;
      border-color: #1765cc;
    }

    &:disabled {
      background-color: #8ab4f8;
      border-color: #8ab4f8;
    }
  }

  &.btn-secondary {
    background-color: #fff;
    border-color: #dadce0;
    color: #3c4043;
    
    &:hover, &:focus {
      background-color: #f1f3f4;
      border-color: #dadce0;
    }
  }
`;

const IconButton = styled(Button)`
  background-color: transparent;
  border: none;
  color: #5f6368;
  padding: 4px 8px;
  
  &:hover {
    background-color: #f1f3f4;
    color: #1a73e8;
  }
`;

const AddButton = styled(Button)`
  display: inline-flex;
  align-items: center;
  background-color: transparent;
  border: none;
  color: #1a73e8;
  padding: 0;
  font-size: 14px;
  
  &:hover {
    background-color: transparent;
    color: #174ea6;
    text-decoration: underline;
  }
  
  svg {
    margin-right: 4px;
  }
`;

const SearchBox = styled(InputGroup)`
  margin-bottom: 16px;
  
  .form-control {
    border-radius: 4px;
    border: 1px solid #dadce0;
    padding: 8px 12px;
    font-size: 14px;
    
    &:focus {
      border-color: #1a73e8;
      box-shadow: 0 0 0 2px rgba(26,115,232,0.25);
    }
  }
`;

const FilterContainer = styled.div`
  display: flex;
  gap: 8px;
  margin-bottom: 16px;
  flex-wrap: wrap;
`;

const FilterSelect = styled(Form.Select)`
  max-width: 200px;
  flex: 1;
  border-radius: 4px;
  border: 1px solid #dadce0;
  padding: 8px 12px;
  font-size: 14px;
  
  &:focus {
    border-color: #1a73e8;
    box-shadow: 0 0 0 2px rgba(26,115,232,0.25);
  }
`;

const NoModulesMessage = styled.div`
  text-align: center;
  padding: 24px;
  color: #5f6368;
  background-color: #f8f9fa;
  border-radius: 8px;
`;

const ModulesModal = ({ 
  show, 
  onHide, 
  selectedClient,
  onAddModule,
  onEditModule,
  onDeleteModule
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [filterGroup, setFilterGroup] = useState('');
  const [filteredGroups, setFilteredGroups] = useState([]);
  
  useEffect(() => {
    // Extract unique groups from modules
    if (selectedClient?.modules) {
      const uniqueGroups = [...new Set(selectedClient.modules.map(module => module.group || 'Other'))];
      setFilteredGroups(uniqueGroups);
    }
  }, [selectedClient]);
  
  const filteredModules = selectedClient?.modules?.filter(module => {
    const matchesSearch = searchTerm === '' || 
      (module.name && module.name.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (module.moduleId && module.moduleId.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (module.route && module.route.toLowerCase().includes(searchTerm.toLowerCase()));
    
    const matchesGroup = filterGroup === '' || (module.group === filterGroup);
    
    return matchesSearch && matchesGroup;
  });
  
  // Group modules by their group property after filtering
  const groupedModules = filteredModules?.reduce((groups, module) => {
    const group = module.group || 'Other';
    if (!groups[group]) {
      groups[group] = [];
    }
    groups[group].push(module);
    return groups;
  }, {}) || {};
  
  const getInitials = (name) => {
    return name.split(' ').map(n => n[0]).join('').toUpperCase();
  };
  
  const clearFilters = () => {
    setSearchTerm('');
    setFilterGroup('');
  };
  
  return (
    <GoogleModal show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Client Modules</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {selectedClient && (
          <>
            <ClientInfoSection>
              <Header>
                <Avatar>{getInitials(selectedClient.name)}</Avatar>
                <Name>{selectedClient.name}</Name>
              </Header>
              
              <InfoItem>
                <InfoLabel>Client ID</InfoLabel>
                <InfoValue>{selectedClient.clientId}</InfoValue>
              </InfoItem>
            </ClientInfoSection>
            
            <SectionTitle>
              Modules
              <AddButton onClick={onAddModule}>
                <FaPlusCircle /> Add Module
              </AddButton>
            </SectionTitle>
            
            <SearchBox>
              <InputGroup.Text>
                <FaSearch />
              </InputGroup.Text>
              <Form.Control
                placeholder="Search modules..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </SearchBox>
            
            <FilterContainer>
              <FilterSelect 
                value={filterGroup}
                onChange={(e) => setFilterGroup(e.target.value)}
              >
                <option value="">All Groups</option>
                {filteredGroups.map(group => (
                  <option key={group} value={group}>{group}</option>
                ))}
              </FilterSelect>
              
              <GoogleButton 
                variant="secondary" 
                onClick={clearFilters}
                size="sm"
              >
                Clear Filters
              </GoogleButton>
            </FilterContainer>
            
            {!selectedClient.modules || selectedClient.modules.length === 0 ? (
              <NoModulesMessage>
                No modules available for this client
              </NoModulesMessage>
            ) : Object.keys(groupedModules).length === 0 ? (
              <NoModulesMessage>
                No modules match your search criteria
              </NoModulesMessage>
            ) : (
              Object.entries(groupedModules).map(([group, modules]) => (
                <div key={group}>
                  <GroupBadge>{group}</GroupBadge>
                  <div className="mt-2 mb-3">
                    {modules.map(module => (
                      <ModuleCard key={module.id}>
                        <ModuleHeader>
                          <ModuleName>
                            {module.icon && (
                              <span className="me-2" dangerouslySetInnerHTML={{ __html: module.icon }} />
                            )}
                            {module.name}
                          </ModuleName>
                          <ModuleActions>
                            <IconButton onClick={() => onEditModule(module)} title="Edit module">
                              <FaEdit />
                            </IconButton>
                            <IconButton onClick={() => onDeleteModule(module)} title="Delete module">
                              <FaTrash />
                            </IconButton>
                          </ModuleActions>
                        </ModuleHeader>
                        <ModuleDetails>
                          <ModuleDetail>
                            Module ID: <span>{module.moduleId}</span>
                          </ModuleDetail>
                          {module.route && (
                            <ModuleDetail>
                              Route: <span>{module.route}</span>
                            </ModuleDetail>
                          )}
                        </ModuleDetails>
                      </ModuleCard>
                    ))}
                  </div>
                </div>
              ))
            )}
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <GoogleButton variant="secondary" onClick={onHide}>Close</GoogleButton>
      </Modal.Footer>
    </GoogleModal>
  );
};

export default ModulesModal;