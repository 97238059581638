import api from '../apiBase';

// Get paginated currency data
export const getAllCurrencies = async (page = 0, size = 20, filters = {}) => {
  try {
    const queryParams = {
      page,
      size
    };
    
    // Add filters if they exist
    if (filters.currency) queryParams.currency = filters.currency;
    if (filters.startDate) queryParams.startDate = filters.startDate;
    if (filters.endDate) queryParams.endDate = filters.endDate;
    
    const response = await api.get('/currency_manager', { params: queryParams });
    return response.data;
  } catch (error) {
    console.error('Error fetching all currencies:', error);
    throw error;
  }
};

// Get simplified current currency data
export const getCurrencySimpleValue = async () => {
  try {
    // Añadir timestamp para evitar caché del navegador
    const timestamp = new Date().getTime();
    const response = await api.get(`/currency_manager/simple?_=${timestamp}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching simple currency data:', error);
    throw error;
  }
};

// Get all currency data with variations
export const getCurrencyAllData = async () => {
  try {
    // Añadir timestamp para evitar caché del navegador
    const timestamp = new Date().getTime();
    const response = await api.get(`/currency_manager/v1?_=${timestamp}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching all currency data:', error);
    throw error;
  }
};

// Fetch new currency data from external source
export const fetchCurrency = async () => {
  try {
    const response = await api.post('/currency_manager/fetch');
    
    // Si hay datos nuevos, esperamos un momento y luego limpiamos cualquier caché
    if (response.data && response.data.data && response.data.data.length > 0) {
      await new Promise(resolve => setTimeout(resolve, 500));
      
      // Llamadas para refrescar los datos en el cliente
      try {
        await getCurrencySimpleValue();
        await getCurrencyAllData();
      } catch (e) {
        console.error('Error refreshing cache after fetch:', e);
      }
    }
    
    return response.data;
  } catch (error) {
    console.error('Error fetching new currency data:', error);
    throw error;
  }
};

// Add new currency
export const createCurrency = async (currencyData) => {
  try {
    const response = await api.post('/currency_manager', currencyData);
    return response.data;
  } catch (error) {
    console.error('Error creating currency:', error);
    throw error;
  }
};

// Update existing currency
export const updateCurrency = async (id, currencyData) => {
  try {
    const response = await api.put(`/currency_manager/${id}`, currencyData);
    return response.data;
  } catch (error) {
    console.error('Error updating currency:', error);
    throw error;
  }
};

// Delete currency
export const deleteCurrency = async (id) => {
  try {
    const response = await api.delete(`/currency_manager/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting currency:', error);
    throw error;
  }
};

// Get currency image template
export const generateCurrencyTemplate = async () => {
  try {
    const timestamp = new Date().getTime();
    const response = await api.get(`/currency_manager/image?_=${timestamp}`, { responseType: 'blob' });
    return URL.createObjectURL(response.data);
  } catch (error) {
    console.error('Error generating currency template:', error);
    throw error;
  }
};

export default api;