import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Alert, Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import { FaTrash, FaEdit, FaTimes, FaInfoCircle } from 'react-icons/fa';

// Styled components for modals
const StyledModal = styled(Modal)`
  .modal-header {
    border-bottom: 1px solid #dadce0;
    padding: 16px 24px;
  }
  
  .modal-title {
    font-size: 18px;
    font-weight: 500;
    color: #202124;
  }
  
  .modal-body {
    padding: 24px;
  }
  
  .modal-footer {
    border-top: 1px solid #dadce0;
    padding: 16px 24px;
  }
`;

const GoogleButton = styled(Button)`
  background-color: ${props => props.variant === 'primary' ? '#1a73e8' : '#fff'};
  border-color: ${props => props.variant === 'primary' ? '#1a73e8' : '#dadce0'};
  color: ${props => props.variant === 'primary' ? '#fff' : '#3c4043'};
  font-weight: 500;
  padding: 8px 16px;
  font-size: 14px;
  
  &:hover, &:focus {
    background-color: ${props => props.variant === 'primary' ? '#1765cc' : '#f1f3f4'};
    border-color: ${props => props.variant === 'primary' ? '#1765cc' : '#dadce0'};
    box-shadow: 0 1px 2px 0 rgba(60,64,67,0.3), 0 1px 3px 1px rgba(60,64,67,0.15);
  }

  &:disabled {
    background-color: ${props => props.variant === 'primary' ? '#8ab4f8' : '#f1f3f4'};
    border-color: ${props => props.variant === 'primary' ? '#8ab4f8' : '#dadce0'};
    color: ${props => props.variant === 'primary' ? '#fff' : '#80868b'};
  }
`;

const DangerButton = styled(GoogleButton)`
  background-color: ${props => props.variant === 'danger' ? '#ea4335' : '#fce8e6'};
  border-color: ${props => props.variant === 'danger' ? '#ea4335' : '#fce8e6'};
  color: ${props => props.variant === 'danger' ? '#fff' : '#c5221f'};
  
  &:hover, &:focus {
    background-color: ${props => props.variant === 'danger' ? '#d93025' : '#fad2cf'};
    border-color: ${props => props.variant === 'danger' ? '#d93025' : '#fad2cf'};
  }
`;

const PreviewImage = styled.img`
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  margin-top: 16px;
`;

// Alert Modal
export const AlertModal = ({ show, onHide, title, message, variant = 'success' }) => (
  <StyledModal show={show} onHide={onHide} centered>
    <Modal.Header closeButton>
      <Modal.Title>{title}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <Alert variant={variant}>{message}</Alert>
    </Modal.Body>
    <Modal.Footer>
      <GoogleButton variant="primary" onClick={onHide}>Cerrar</GoogleButton>
    </Modal.Footer>
  </StyledModal>
);

// Create/Edit Currency Modal
export const CurrencyFormModal = ({ show, onHide, onSubmit, currencyData = null, isEditing = false }) => {
  const defaultFormState = {
    date: new Date().toISOString().split('T')[0],
    currency: "usd",
    open: 0,
    high: 0,
    low: 0,
    close: 0,
    volume: 0,
    count: 0
  };

  const [currency, setCurrency] = useState(defaultFormState);
  
  useEffect(() => {
    if (show) {
      if (isEditing && currencyData) {
        // Convert date to YYYY-MM-DD format for the form
        const date = new Date(currencyData.date);
        const formattedDate = date.toISOString().split('T')[0];
        
        setCurrency({
          ...currencyData,
          date: formattedDate
        });
      } else {
        setCurrency(defaultFormState);
      }
    }
  }, [show, currencyData, isEditing]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    
    if (['open', 'high', 'low', 'close', 'volume', 'count'].includes(name)) {
      // For numeric inputs, parse as float or int as appropriate
      const numValue = name === 'count' ? parseInt(value) : parseFloat(value);
      setCurrency({ ...currency, [name]: isNaN(numValue) ? '' : numValue });
    } else {
      setCurrency({ ...currency, [name]: value });
    }
  };

  const handleSubmit = () => {
    onSubmit(currency);
  };

  const isFormValid = () => {
    const { date, currency: currencyType, open, high, low, close } = currency;
    
    return (
      date && 
      currencyType && 
      open !== '' && 
      high !== '' && 
      low !== '' && 
      close !== ''
    );
  };

  return (
    <StyledModal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>
          {isEditing ? 'Editar Moneda' : 'Agregar Moneda'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Row className="mb-3">
            <Col md={6}>
              <Form.Group>
                <Form.Label>Tipo de Moneda</Form.Label>
                <Form.Control
                  as="select"
                  name="currency"
                  value={currency.currency}
                  onChange={handleInputChange}
                >
                  <option value="usd">USD</option>
                  <option value="eur">EUR</option>
                  <option value="mlc">MLC</option>
                </Form.Control>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Label>Fecha</Form.Label>
                <Form.Control
                  type="date"
                  name="date"
                  value={currency.date}
                  onChange={handleInputChange}
                  required
                />
              </Form.Group>
            </Col>
          </Row>
          
          <Row className="mb-3">
            <Col md={6}>
              <Form.Group>
                <Form.Label>Apertura (Open)</Form.Label>
                <Form.Control
                  type="number"
                  name="open"
                  value={currency.open}
                  onChange={handleInputChange}
                  step="0.01"
                  required
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Label>Máximo (High)</Form.Label>
                <Form.Control
                  type="number"
                  name="high"
                  value={currency.high}
                  onChange={handleInputChange}
                  step="0.01"
                  required
                />
              </Form.Group>
            </Col>
          </Row>
          
          <Row className="mb-3">
            <Col md={6}>
              <Form.Group>
                <Form.Label>Mínimo (Low)</Form.Label>
                <Form.Control
                  type="number"
                  name="low"
                  value={currency.low}
                  onChange={handleInputChange}
                  step="0.01"
                  required
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Label>Cierre (Close)</Form.Label>
                <Form.Control
                  type="number"
                  name="close"
                  value={currency.close}
                  onChange={handleInputChange}
                  step="0.01"
                  required
                />
              </Form.Group>
            </Col>
          </Row>
          
          <Row className="mb-3">
            <Col md={6}>
              <Form.Group>
                <Form.Label>Volumen</Form.Label>
                <Form.Control
                  type="number"
                  name="volume"
                  value={currency.volume}
                  onChange={handleInputChange}
                  step="0.01"
                  required
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Label>Conteo</Form.Label>
                <Form.Control
                  type="number"
                  name="count"
                  value={currency.count}
                  onChange={handleInputChange}
                  step="1"
                  required
                />
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <GoogleButton onClick={onHide}>Cancelar</GoogleButton>
        <GoogleButton 
          variant="primary" 
          onClick={handleSubmit}
          disabled={!isFormValid()}
        >
          {isEditing ? 'Actualizar' : 'Agregar'}
        </GoogleButton>
      </Modal.Footer>
    </StyledModal>
  );
};

// Delete Currency Modal
export const DeleteCurrencyModal = ({ show, onHide, currency, onDelete }) => {
  const handleDelete = () => {
    if (currency?.id) {
      onDelete(currency.id);
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString();
  };

  return (
    <StyledModal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>Eliminar Moneda</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>¿Está seguro que desea eliminar este registro de moneda?</p>
        {currency && (
          <div className="p-3 bg-light rounded">
            <p className="mb-1"><strong>Fecha:</strong> {formatDate(currency.date)}</p>
            <p className="mb-1"><strong>Moneda:</strong> {currency.currency?.toUpperCase()}</p>
            <p className="mb-1"><strong>Valor Alto:</strong> {currency.high}</p>
            <p className="mb-0"><strong>Valor Bajo:</strong> {currency.low}</p>
          </div>
        )}
        <Alert variant="danger" className="mt-3">
          Esta acción no se puede deshacer.
        </Alert>
      </Modal.Body>
      <Modal.Footer>
        <GoogleButton onClick={onHide}>Cancelar</GoogleButton>
        <DangerButton variant="danger" onClick={handleDelete}>Eliminar</DangerButton>
      </Modal.Footer>
    </StyledModal>
  );
};

export const CurrencySlidingPanel = ({ selectedCurrency, onClose, onDelete, onEdit, generateCurrencyTemplate }) => {
  const [previewImage, setPreviewImage] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (selectedCurrency) {
      loadPreviewImage();
    }
  }, [selectedCurrency]);

  const loadPreviewImage = async () => {
    if (!selectedCurrency) return;
    
    try {
      setLoading(true);
      const imageUrl = await generateCurrencyTemplate();
      setPreviewImage(imageUrl);
    } catch (error) {
      console.error('Error loading preview:', error);
    } finally {
      setLoading(false);
    }
  };

  if (!selectedCurrency) return null;

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('es-ES', { 
      year: 'numeric', 
      month: 'short', 
      day: 'numeric' 
    });
  };
  
  const formatCurrency = (value) => {
    return new Intl.NumberFormat('es-ES', {
      style: 'currency',
      currency: 'CUP'
    }).format(value);
  };

  const getCurrencyColor = (currency) => {
    const colors = {
      'usd': '#4285F4', // Google Blue
      'eur': '#34A853', // Google Green
      'cup': '#FBBC05', // Google Yellow
      'default': '#EA4335' // Google Red
    };
    
    return colors[currency?.toLowerCase()] || colors.default;
  };

  const adjustedDate = new Date(selectedCurrency.date);
  adjustedDate.setDate(adjustedDate.getDate() + 1);

  return (
    <div className="h-100 d-flex flex-column bg-white shadow-sm">
      {/* Header */}
      <div className="p-3 border-bottom" style={{ borderBottomColor: '#f1f3f4' }}>
        <div className="d-flex justify-content-between align-items-center">
          <h5 className="mb-0 fw-normal" style={{ color: '#202124' }}>Detalles de la Moneda</h5>
          <Button 
            variant="link" 
            className="p-1 rounded-circle" 
            onClick={onClose}
            aria-label="Cerrar"
            style={{ color: '#5f6368' }}
          >
            <FaTimes size={20} />
          </Button>
        </div>
      </div>
      
      {/* Content */}
      <div className="flex-grow-1 overflow-auto p-3">
        <div className="mb-4">
          {/* Currency Header */}
          <div className="d-flex align-items-center mb-4">
            <div 
              className="me-3 rounded-circle d-flex align-items-center justify-content-center"
              style={{ 
                width: '48px', 
                height: '48px', 
                backgroundColor: getCurrencyColor(selectedCurrency.currency),
                color: 'white',
                fontSize: '16px',
                fontWeight: '500'
              }}
            >
              {selectedCurrency.currency?.toUpperCase()}
            </div>
            <div>
              <h5 className="mb-1 fw-normal" style={{ color: '#202124' }}>{formatDate(adjustedDate)}</h5>
              <div style={{ color: '#5f6368', fontSize: '14px' }}>
                Valor actual: <span style={{ color: '#202124', fontWeight: '500' }}>{formatCurrency(selectedCurrency.high)}</span>
              </div>
            </div>
          </div>
          
          {/* Currency Info Card */}
          <div 
            className="p-3 rounded mb-4" 
            style={{ 
              backgroundColor: '#f8f9fa', 
              border: '1px solid #f1f3f4',
              borderRadius: '8px'
            }}
          >
            <h6 className="mb-3 d-flex align-items-center" style={{ color: '#202124', fontSize: '15px' }}>
              <FaInfoCircle className="me-2" size={16} style={{ color: '#5f6368' }} />
              Información de Cambio
            </h6>
            <Row className="g-3">
              <Col xs={6} className="mb-1">
                <div style={{ fontSize: '13px', color: '#5f6368' }}>Apertura</div>
                <div style={{ fontSize: '14px', color: '#202124' }}>{formatCurrency(selectedCurrency.open)}</div>
              </Col>
              <Col xs={6} className="mb-1">
                <div style={{ fontSize: '13px', color: '#5f6368' }}>Cierre</div>
                <div style={{ fontSize: '14px', color: '#202124' }}>{formatCurrency(selectedCurrency.close)}</div>
              </Col>
              <Col xs={6} className="mb-1">
                <div style={{ fontSize: '13px', color: '#5f6368' }}>Máximo</div>
                <div style={{ fontSize: '14px', color: '#202124' }}>{formatCurrency(selectedCurrency.high)}</div>
              </Col>
              <Col xs={6} className="mb-1">
                <div style={{ fontSize: '13px', color: '#5f6368' }}>Mínimo</div>
                <div style={{ fontSize: '14px', color: '#202124' }}>{formatCurrency(selectedCurrency.low)}</div>
              </Col>
              <Col xs={6} className="mb-1">
                <div style={{ fontSize: '13px', color: '#5f6368' }}>Volumen</div>
                <div style={{ fontSize: '14px', color: '#202124' }}>{selectedCurrency.volume.toLocaleString()}</div>
              </Col>
              <Col xs={6} className="mb-1">
                <div style={{ fontSize: '13px', color: '#5f6368' }}>Conteo</div>
                <div style={{ fontSize: '14px', color: '#202124' }}>{selectedCurrency.count.toLocaleString()}</div>
              </Col>
              {selectedCurrency.valueFormat && (
                <Col xs={12} className="mb-1">
                  <div style={{ fontSize: '13px', color: '#5f6368' }}>Valor Formateado</div>
                  <div style={{ fontSize: '14px', color: '#202124' }}>{formatCurrency(selectedCurrency.valueFormat)}</div>
                </Col>
              )}
            </Row>
          </div>
          
          {/* Preview Section */}
          {previewImage && (
            <div className="mb-3">
              <h6 className="mb-3 d-flex align-items-center" style={{ color: '#202124', fontSize: '15px' }}>
                Vista Previa de la Plantilla
              </h6>
              <div className="text-center">
                <img 
                  src={previewImage} 
                  alt="Plantilla de moneda" 
                  className="img-fluid rounded"
                  style={{ 
                    maxWidth: '100%', 
                    boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
                    borderRadius: '8px'
                  }}
                />
              </div>
            </div>
          )}
          
          {loading && (
            <div className="text-center py-4">
              <div className="spinner-border spinner-border-sm text-primary" role="status">
                <span className="visually-hidden">Cargando...</span>
              </div>
              <div className="mt-2" style={{ fontSize: '14px', color: '#5f6368' }}>
                Generando vista previa...
              </div>
            </div>
          )}
        </div>
      </div>
      
      {/* Footer */}
      <div className="p-3" style={{ borderTop: '1px solid #f1f3f4' }}>
        <div className="d-flex">
          <Button 
            className="me-2"
            onClick={() => onEdit(selectedCurrency)}
            style={{ 
              backgroundColor: 'transparent', 
              color: '#1a73e8', 
              border: '1px solid #dadce0',
              borderRadius: '24px',
              padding: '8px 16px',
              fontSize: '14px',
              fontWeight: '500',
              boxShadow: 'none'
            }}
          >
            <FaEdit className="me-1" size={14} /> Editar
          </Button>
          <Button 
            className="me-2"
            onClick={() => onDelete(selectedCurrency)}
            style={{ 
              backgroundColor: 'transparent', 
              color: '#d93025', 
              border: '1px solid #dadce0',
              borderRadius: '24px',
              padding: '8px 16px',
              fontSize: '14px',
              fontWeight: '500',
              boxShadow: 'none'
            }}
          >
            <FaTrash className="me-1" size={14} /> Eliminar
          </Button>
        </div>
      </div>
    </div>
  );
};