import api from '../apiBase';

const API_BASE_URL = 'api/v1/cookbook_manager';

export const getAllCookBooks = async (page = 0, size = 10, filters = {}) => {
  try {
    const params = { page, size, ...filters };
    const response = await api.get(API_BASE_URL, { params });
    return response.data;
  } catch (error) {
    console.error('Error fetching cookbooks:', error);
    throw error;
  }
};

export const createCookBook = async (cookBook, images) => {
  try {

    console.log(cookBook)
    console.log(images)

    const formData = new FormData();
    formData.append('cookbook', new Blob([JSON.stringify(cookBook)], {
      type: 'application/json'
    }));
    images.forEach((image, index) => {
      formData.append(`pictures`, image);
    });
    const response = await api.post(API_BASE_URL, formData, {
      headers: { 
        'Content-Type': 'multipart/form-data',
        'Accept': 'application/json'
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error creating cookbook:', error);
    throw error;
  }
};

export const updateCookBook = async (updatedCookBook, images) => {
  try {
    const formData = new FormData();
    
    Object.keys(updatedCookBook).forEach(key => {
      if (key !== 'pictures') {
        formData.append(key, updatedCookBook[key]);
      }
    });

    if (updatedCookBook.pictures && Array.isArray(updatedCookBook.pictures)) {
      updatedCookBook.pictures = updatedCookBook.pictures.filter(pic => typeof pic === 'string');
      
      updatedCookBook.pictures.forEach((pic, index) => {
        formData.append(`pictures`, pic);
      });
    }

    images.forEach((image, index) => {
      formData.append(`pictures`, image);
    });

    formData.append('cookbook', new Blob([JSON.stringify(updatedCookBook)], {
      type: 'application/json'
    }));

    const response = await api.put(`${API_BASE_URL}/${updatedCookBook.id}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error updating cookbook:', error);
    throw error;
  }
};


export default api;