import React, { useState } from 'react';
import { Modal, Form, Button, Row, Col, Tab, Tabs } from 'react-bootstrap';
import styled from 'styled-components';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

const GoogleModal = styled(Modal)`
  .modal-content {
    border-radius: 8px;
    border: none;
    box-shadow: 0 1px 3px 0 rgba(60,64,67,0.3), 0 4px 8px 3px rgba(60,64,67,0.15);
  }

  .modal-header {
    border-bottom: 1px solid #e8eaed;
    padding: 24px 24px 20px;
  }

  .modal-title {
    font-size: 22px;
    color: #202124;
    font-weight: 400;
  }

  .modal-body {
    padding: 24px;
  }

  .modal-footer {
    border-top: 1px solid #e8eaed;
    padding: 20px 24px 24px;
  }
`;

const GoogleForm = styled(Form)`
  .form-label {
    font-weight: 500;
    color: #5f6368;
    margin-bottom: 8px;
  }

  .form-control, .form-select {
    border-radius: 4px;
    border: 1px solid #dadce0;
    padding: 8px 12px;
    font-size: 14px;
    
    &:focus {
      border-color: #1a73e8;
      box-shadow: 0 0 0 2px rgba(26,115,232,0.25);
    }
  }

  .form-check-input {
    &:checked {
      background-color: #1a73e8;
      border-color: #1a73e8;
    }
    
    &:focus {
      border-color: #1a73e8;
      box-shadow: 0 0 0 2px rgba(26,115,232,0.25);
    }
  }
`;

const GoogleButton = styled(Button)`
  border-radius: 4px;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 14px;
  padding: 8px 24px;

  &.btn-primary {
    background-color: #1a73e8;
    border-color: #1a73e8;
    color: #fff;
    
    &:hover, &:focus {
      background-color: #1765cc;
      border-color: #1765cc;
    }

    &:disabled {
      background-color: #8ab4f8;
      border-color: #8ab4f8;
    }
  }

  &.btn-secondary {
    background-color: #fff;
    border-color: #dadce0;
    color: #3c4043;
    
    &:hover, &:focus {
      background-color: #f1f3f4;
      border-color: #dadce0;
    }
  }

  &.btn-danger {
    background-color: #ea4335;
    border-color: #ea4335;
    color: #fff;
    
    &:hover, &:focus {
      background-color: #d93025;
      border-color: #d93025;
    }
  }
`;

const StyledDatePicker = styled(DatePicker)`
  width: 100%;
  border-radius: 4px;
  border: 1px solid #dadce0;
  padding: 8px 12px;
  font-size: 14px;
  
  &:focus {
    border-color: #1a73e8;
    outline: none;
    box-shadow: 0 0 0 2px rgba(26,115,232,0.25);
  }
`;

const StyledTabs = styled(Tabs)`
  margin-bottom: 20px;
  
  .nav-item .nav-link {
    color: #5f6368;
    
    &.active {
      color: #1a73e8;
      font-weight: 500;
    }
  }
`;

export const ArticleFormModal = ({ 
  show, 
  onHide, 
  article, 
  setArticle, 
  handleSave, 
  isCreating = false 
}) => {
  const [validated, setValidated] = useState(false);
  
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setArticle(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    
    if (form.checkValidity() === false) {
      e.stopPropagation();
      setValidated(true);
      return;
    }
    
    handleSave();
  };

  const handleDateChange = (date) => {
    setArticle(prev => ({
      ...prev,
      publication_date: date
    }));
  };

  const handleCategoriesChange = (e) => {
    const categories = e.target.value.split(',').map(cat => cat.trim()).filter(Boolean);
    setArticle(prev => ({
      ...prev,
      categories
    }));
  };

  return (
    <GoogleModal 
      show={show} 
      onHide={onHide} 
      size="lg" 
      centered
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title>{isCreating ? 'Create New Article' : 'Edit Article'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <GoogleForm noValidate validated={validated} onSubmit={handleSubmit}>
          <StyledTabs defaultActiveKey="basic" id="article-form-tabs">
            <Tab eventKey="basic" title="Basic Information">
              <Row className="mb-3">
                <Form.Group as={Col} md="6" controlId="title">
                  <Form.Label>Title</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    name="title"
                    value={article.title || ''}
                    onChange={handleChange}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide a title.
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="6" controlId="author">
                  <Form.Label>Author</Form.Label>
                  <Form.Control
                    type="text"
                    name="author"
                    value={article.author || ''}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Row>

              <Row className="mb-3">
                <Form.Group as={Col} md="6" controlId="siteName">
                  <Form.Label>Site Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="site_name"
                    value={article.site_name || ''}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group as={Col} md="6" controlId="siteId">
                  <Form.Label>Site ID</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    name="site_id"
                    value={article.site_id || ''}
                    onChange={handleChange}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide a site ID.
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>

              <Row className="mb-3">
                <Form.Group as={Col} md="6" controlId="publicationDate">
                  <Form.Label>Publication Date</Form.Label>
                  <StyledDatePicker
                    selected={article.publication_date ? new Date(article.publication_date) : null}
                    onChange={handleDateChange}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    dateFormat="MMMM d, yyyy h:mm aa"
                    className="form-control"
                  />
                </Form.Group>
                <Form.Group as={Col} md="6" controlId="categories">
                  <Form.Label>Categories (comma separated)</Form.Label>
                  <Form.Control
                    type="text"
                    name="categories"
                    value={article.categories?.join(', ') || ''}
                    onChange={handleCategoriesChange}
                  />
                </Form.Group>
              </Row>

              <Form.Group className="mb-3" controlId="description">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  name="description"
                  value={article.description || ''}
                  onChange={handleChange}
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Check
                  type="checkbox"
                  id="visible"
                  name="visible"
                  label="Visible"
                  checked={article.visible === undefined ? true : article.visible}
                  onChange={handleChange}
                />
              </Form.Group>
            </Tab>

            <Tab eventKey="links" title="Links & Media">
              <Row className="mb-3">
                <Form.Group as={Col} md="12" controlId="link">
                  <Form.Label>Original Link</Form.Label>
                  <Form.Control
                    required
                    type="url"
                    name="link"
                    value={article.link || ''}
                    onChange={handleChange}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide a valid URL.
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>

              <Row className="mb-3">
                <Form.Group as={Col} md="12" controlId="sharedLink">
                  <Form.Label>Shared Link</Form.Label>
                  <Form.Control
                    type="url"
                    name="shared_link"
                    value={article.shared_link || ''}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Row>

              <Row className="mb-3">
                <Form.Group as={Col} md="12" controlId="image">
                  <Form.Label>Image URL</Form.Label>
                  <Form.Control
                    required
                    type="url"
                    name="image"
                    value={article.image || ''}
                    onChange={handleChange}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide an image URL.
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>

              <Row className="mb-3">
                <Form.Group as={Col} md="6" controlId="audio">
                  <Form.Label>Audio URL</Form.Label>
                  <Form.Control
                    type="url"
                    name="audio"
                    value={article.audio || ''}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group as={Col} md="6" controlId="video">
                  <Form.Label>Video URL</Form.Label>
                  <Form.Control
                    type="url"
                    name="video"
                    value={article.video || ''}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Row>
            </Tab>

            <Tab eventKey="advanced" title="Advanced">
              <Row className="mb-3">
                <Form.Group as={Col} md="6" controlId="siteLogo">
                  <Form.Label>Site Logo URL</Form.Label>
                  <Form.Control
                    type="url"
                    name="site_logo"
                    value={article.site_logo || ''}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group as={Col} md="6" controlId="articleRssId">
                  <Form.Label>Article RSS ID</Form.Label>
                  <Form.Control
                    type="text"
                    name="article_rss_id"
                    value={article.article_rss_id || ''}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Row>

              <Form.Group className="mb-3" controlId="commentSetting">
                <Form.Label>Comment Settings</Form.Label>
                <Row>
                  <Col md="6">
                    <Form.Check
                      type="checkbox"
                      id="canComment"
                      name="comment_setting.can_comment"
                      label="Allow Comments"
                      checked={article.comment_setting?.can_comment !== false}
                      onChange={(e) => {
                        setArticle(prev => ({
                          ...prev,
                          comment_setting: {
                            ...prev.comment_setting,
                            can_comment: e.target.checked
                          }
                        }));
                      }}
                    />
                  </Col>
                  <Col md="6">
                    <Form.Control
                      type="number"
                      placeholder="Comment Limit"
                      name="comment_setting.comment_limit"
                      value={article.comment_setting?.comment_limit || ''}
                      onChange={(e) => {
                        setArticle(prev => ({
                          ...prev,
                          comment_setting: {
                            ...prev.comment_setting,
                            comment_limit: e.target.value ? parseInt(e.target.value) : null
                          }
                        }));
                      }}
                    />
                  </Col>
                </Row>
              </Form.Group>

              <Form.Group className="mb-3" controlId="body">
                <Form.Label>Article Body</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={10}
                  name="body"
                  value={article.body || ''}
                  onChange={handleChange}
                />
              </Form.Group>
            </Tab>
          </StyledTabs>
        </GoogleForm>
      </Modal.Body>
      <Modal.Footer>
        <GoogleButton variant="secondary" onClick={onHide}>
          Cancel
        </GoogleButton>
        <GoogleButton variant="primary" onClick={handleSubmit}>
          {isCreating ? 'Create' : 'Save Changes'}
        </GoogleButton>
      </Modal.Footer>
    </GoogleModal>
  );
};

// ScrapeModal actualizado para manejar correctamente los casos nulos
export const ScrapeModal = ({ show, onHide, article, handleScrape }) => {
  // Use articleRssId instead of id
  const articleRssId = article?.articleRssId;
  
  const handleSubmit = () => {
    if (!articleRssId) {
      console.error('No valid article RSS ID available for scraping');
      onHide();
      return;
    }
    
    // Pass articleRssId to the handler
    handleScrape(articleRssId);
  };
  
  return (
    <GoogleModal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>Scrape Article Content</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          This will automatically scrape the article content using the selectors configured in the 
          associated RSS source. The system will find the right RSS source based on the article link.
        </p>
        {article && (
          <div>
            <p><strong>Article:</strong> {article.title}</p>
            {articleRssId && <p><strong>RSS ID:</strong> {articleRssId}</p>}
          </div>
        )}
        {!articleRssId && (
          <div className="alert alert-warning">
            No valid RSS ID found for this article. Scraping may not work correctly.
          </div>
        )}
        <p>
          Do you want to proceed with the content scraping?
        </p>
      </Modal.Body>
      <Modal.Footer>
        <GoogleButton variant="secondary" onClick={onHide}>
          Cancel
        </GoogleButton>
        <GoogleButton 
          variant="primary" 
          onClick={handleSubmit}
          disabled={!articleRssId}
        >
          Scrape Content
        </GoogleButton>
      </Modal.Footer>
    </GoogleModal>
  );
};

export const DateRangeModal = ({ show, onHide, handleProcess }) => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [processing, setProcessing] = useState(false);
  
  const handleSubmit = async () => {
    setProcessing(true);
    try {
      await handleProcess(startDate, endDate);
      onHide();
    } catch (error) {
      console.error('Error processing articles:', error);
    } finally {
      setProcessing(false);
    }
  };
  
  return (
    <GoogleModal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>Process Articles by Date Range</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <GoogleForm>
          <Row className="mb-3">
            <Form.Group as={Col} md="6" controlId="startDate">
              <Form.Label>Start Date</Form.Label>
              <StyledDatePicker
                selected={startDate}
                onChange={date => setStartDate(date)}
                selectsStart
                startDate={startDate}
                endDate={endDate}
                maxDate={endDate}
                dateFormat="yyyy-MM-dd"
                className="form-control"
              />
            </Form.Group>
            
            <Form.Group as={Col} md="6" controlId="endDate">
              <Form.Label>End Date</Form.Label>
              <StyledDatePicker
                selected={endDate}
                onChange={date => setEndDate(date)}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
                dateFormat="yyyy-MM-dd"
                className="form-control"
              />
            </Form.Group>
          </Row>
          
          <Form.Text className="text-muted">
            This will process all articles published between the selected dates for AI analysis.
          </Form.Text>
        </GoogleForm>
      </Modal.Body>
      <Modal.Footer>
        <GoogleButton variant="secondary" onClick={onHide}>
          Cancel
        </GoogleButton>
        <GoogleButton 
          variant="primary" 
          onClick={handleSubmit}
          disabled={processing}
        >
          {processing ? 'Processing...' : 'Process Articles'}
        </GoogleButton>
      </Modal.Footer>
    </GoogleModal>
  );
};

export const ConfirmationModal = ({ show, onHide, title, message, confirmText, handleConfirm, variant = "danger" }) => {
  return (
    <GoogleModal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{message}</p>
      </Modal.Body>
      <Modal.Footer>
        <GoogleButton variant="secondary" onClick={onHide}>
          Cancel
        </GoogleButton>
        <GoogleButton variant={variant} onClick={handleConfirm}>
          {confirmText}
        </GoogleButton>
      </Modal.Footer>
    </GoogleModal>
  );
};

export const AlertModal = ({ show, onHide, title, message, variant = "success" }) => {
  return (
    <GoogleModal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{message}</p>
      </Modal.Body>
      <Modal.Footer>
        <GoogleButton variant="secondary" onClick={onHide}>
          Close
        </GoogleButton>
      </Modal.Footer>
    </GoogleModal>
  );
};