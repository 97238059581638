// incidentModals.jsx
import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Modal, Form, Button, Card } from 'react-bootstrap';
import { FaPlus, FaTrash, FaEdit, FaUpload } from 'react-icons/fa';
import { 
  createIncident, 
  updateIncident, 
  updateIncidentType,
  fetchProvinces,
  fetchMunicipalities,
  fetchIncidentActivities,
  createIncidentActivity, 
  updateIncidentActivity,
  deleteIncidentActivity,
  getPeopleByIncident,
  addIncidentToPerson,
  removeIncidentFromPerson
} from './apiIncident';
import { fetchIncidentPeople } from '../incidentPeople/apiIncidentPeople';

const GoogleModal = styled(Modal)`
  .modal-content {
    border-radius: 8px;
    border: none;
    box-shadow: 0 1px 3px 0 rgba(60,64,67,0.3), 0 4px 8px 3px rgba(60,64,67,0.15);
  }

  .modal-header {
    border-bottom: 1px solid #e8eaed;
    padding: 24px 24px 20px;
  }

  .modal-title {
    font-size: 22px;
    color: #202124;
    font-weight: 400;
  }

  .modal-body {
    padding: 24px;
  }

  .modal-footer {
    border-top: 1px solid #e8eaed;
    padding: 20px 24px 24px;
  }
`;

const GoogleForm = styled(Form)`
  .form-label {
    font-weight: 500;
    color: #5f6368;
    margin-bottom: 8px;
  }

  .form-control {
    border-radius: 4px;
    border: 1px solid #dadce0;
    padding: 8px 12px;
    font-size: 14px;
    
    &:focus {
      border-color: #1a73e8;
      box-shadow: 0 0 0 2px rgba(26,115,232,0.25);
    }
  }
`;

const GoogleButton = styled(Button)`
  background-color: #1a73e8;
  border-color: #1a73e8;
  color: #fff;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  padding: 8px 24px;
  font-size: 14px;
  
  &:hover, &:focus {
    background-color: #1765cc;
    border-color: #1765cc;
    box-shadow: 0 1px 2px 0 rgba(60,64,67,0.3), 0 1px 3px 1px rgba(60,64,67,0.15);
  }
`;

const ImageInputGroup = styled.div`
  display: flex;
  gap: 8px;
  align-items: flex-start;
  margin-bottom: 8px;
`;

const ImageContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 12px;
  margin-top: 16px;
`;

const ImageWrapper = styled.div`
  position: relative;
`;

const Image = styled.img`
  width: 100%;
  height: 150px;
  object-fit: cover;
  border-radius: 4px;
`;

const DeleteButton = styled(Button)`
  position: absolute;
  top: 8px;
  right: 8px;
  padding: 4px 8px;
  background-color: rgba(255, 0, 0, 0.8);
  border: none;
  border-radius: 4px;
`;

const ModalContainer = styled(Modal)`
  .modal-dialog {
    max-width: 1200px;
    margin: 1.75rem auto;
  }

  .modal-content {
    min-height: 600px;
  }

  .modal-body {
    display: flex;
    gap: 24px;
    padding: 24px;
  }
`;

const FormContainer = styled.div`
  flex: 0 0 400px;
`;

const ListContainer = styled.div`
  flex: 1;
  position: relative;
  min-height: 400px;
  border-left: 1px solid #e8eaed;
  padding-left: 24px;
`;

const LoadingOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const LoadingSpinner = styled.div`
  width: 50px;
  height: 50px;
  border: 3px solid #f3f3f3;
  border-radius: 50%;
  border-top: 3px solid #1a73e8;
  animation: spin 1s linear infinite;
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

const ActivityList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-height: calc(100vh - 250px);
  overflow-y: auto;
`;

const ActivityCard = styled(Card)`
  border: none;
  box-shadow: 0 1px 2px 0 rgba(60,64,67,0.3);
  
  &:hover {
    box-shadow: 0 1px 3px 0 rgba(60,64,67,0.3), 0 4px 8px 3px rgba(60,64,67,0.15);
  }
`;

const ImagesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
  gap: 8px;
  margin-top: 12px;
`;

const ImageThumbnail = styled.img`
  width: 100%;
  height: 80px;
  object-fit: cover;
  border-radius: 4px;
`;

const UploadIcon = styled(FaUpload)`
  font-size: 32px;
  color: #5f6368;
  margin-bottom: 12px;
`;

const UploadText = styled.div`
  color: #5f6368;
  margin-bottom: 8px;
`;

const UploadSubText = styled.div`
  color: #80868b;
  font-size: 12px;
`;

const PreviewImage = styled.div`
  position: relative;
  width: 100%;
  height: 150px;
  border-radius: 4px;
  overflow: hidden;
  
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const DropArea = styled.div`
  border: 2px dashed ${props => props.isDragActive ? '#1a73e8' : '#dadce0'};
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  background-color: ${props => props.isDragActive ? 'rgba(26,115,232,0.1)' : '#f8f9fa'};
  transition: all 0.3s ease;
  cursor: pointer;
  margin-top: 10px;
  
  &:hover {
    border-color: #1a73e8;
    background-color: rgba(26,115,232,0.05);
  }
`;

const SubmitButton = styled(GoogleButton)`
  min-width: 120px;
  
  &:disabled {
    background-color: #c1d1f0;
    border-color: #c1d1f0;
  }
`;

export const CreateEditModal = ({ show, onHide, incident, onSuccess, types }) => {
  const [formData, setFormData] = useState({
    type: '',
    startDate: '',
    endDate: '',
    active: true,
    province: '',
    municipality: '',
    protestMagnitude: '',
    description: '',
  });
  
  const [provinces, setProvinces] = useState([]);
  const [municipalities, setMunicipalities] = useState([]);
  const [validated, setValidated] = useState(false);
  const [isDragActive, setIsDragActive] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);
  const [imageFiles, setImageFiles] = useState([]);
  const [imagesToDelete, setImagesToDelete] = useState([]);
  const fileInputRef = useRef(null);

  // Función para formatear fechas de manera segura
  const formatDateForInput = (dateString) => {
    if (!dateString) return '';
    
    try {
      const date = new Date(dateString);
      // Verificar si la fecha es válida
      if (isNaN(date.getTime())) return '';
      
      return date.toISOString().split('T')[0];
    } catch (error) {
      console.error("Error formatting date:", error);
      return '';
    }
  };

  useEffect(() => {
    if (incident) {
      setFormData({
        type: incident.type || '',
        startDate: formatDateForInput(incident.startDate || incident.start_date),
        endDate: formatDateForInput(incident.endDate || incident.end_date),
        active: incident.active !== undefined ? incident.active : true,
        province: incident.province || '',
        municipality: incident.municipality || '',
        protestMagnitude: incident.protestMagnitude || incident.protest_magnitude || '',
        description: incident.description || '',
      });
      
      // Si hay imágenes existentes, mostrarlas en la vista previa
      if (incident.images && incident.images.length > 0) {
        setSelectedImages(incident.images.map(url => ({
          url,
          isExisting: true
        })));
      } else {
        setSelectedImages([]);
      }
    } else {
      resetForm();
    }
    loadProvinces();
  }, [incident, show]);

  const resetForm = () => {
    setFormData({
      type: '',
      startDate: '',
      endDate: '',
      active: true,
      province: '',
      municipality: '',
      protestMagnitude: '',
      description: '',
    });
    setSelectedImages([]);
    setImageFiles([]);
    setImagesToDelete([]);
  };

  const loadProvinces = async () => {
    try {
      const data = await fetchProvinces();
      setProvinces(data);
    } catch (error) {
      console.error('Error loading provinces:', error);
    }
  };

  useEffect(() => {
    const loadMunicipalities = async () => {
      if (formData.province) {
        try {
          const data = await fetchMunicipalities();
          const filteredMunicipalities = data.filter(
            mun => mun.province === formData.province
          );
          setMunicipalities(filteredMunicipalities);
        } catch (error) {
          console.error('Error loading municipalities:', error);
        }
      } else {
        setMunicipalities([]);
      }
    };

    loadMunicipalities();
  }, [formData.province]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    
    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(true);
      return;
    }

    try {
      // Crear un objeto FormData para enviar archivos
      const formDataToSend = new FormData();
      
      // Convertir las fechas a objetos Date válidos
      const startDate = formData.startDate ? new Date(formData.startDate) : null;
      const endDate = formData.endDate ? new Date(formData.endDate) : null;
      
      // Verificar que las fechas sean válidas
      if (startDate && isNaN(startDate.getTime())) {
        console.error("Invalid start date");
        alert("La fecha de inicio no es válida. Por favor, verifique el formato.");
        return;
      }
      
      if (endDate && isNaN(endDate.getTime())) {
        console.error("Invalid end date");
        alert("La fecha de fin no es válida. Por favor, verifique el formato.");
        return;
      }
      
      // Convertir el objeto formData a JSON con los nombres de propiedades que espera el backend
      const incidentData = {
        type: formData.type,
        start_date: startDate ? startDate.toISOString() : null,
        end_date: endDate ? endDate.toISOString() : null,
        active: formData.active,
        province: formData.province,
        municipality: formData.municipality,
        protest_magnitude: formData.protestMagnitude,
        description: formData.description,
        images: [] // Las imágenes ahora se manejan como archivos separados
      };
      
      // Adjuntar los datos del incidente como parte JSON
      const incidentBlob = new Blob([JSON.stringify(incidentData)], {
        type: 'application/json'
      });
      formDataToSend.append('incident', incidentBlob);
      
      // Adjuntar las imágenes como archivos
      if (imageFiles.length > 0) {
        imageFiles.forEach(file => {
          formDataToSend.append('images', file);
        });
      }

      // Para actualización, incluir las imágenes a eliminar si hay alguna
      if (incident && imagesToDelete.length > 0) {
        const deleteBlob = new Blob([JSON.stringify(imagesToDelete)], {
          type: 'application/json'
        });
        formDataToSend.append('imagesToDelete', deleteBlob);
      }

      if (incident) {
        await updateIncident(incident.id, formDataToSend);
      } else {
        await createIncident(formDataToSend);
      }
      
      onSuccess();
      onHide();
    } catch (error) {
      console.error('Error saving incident:', error);
      alert("Ocurrió un error al guardar. Por favor, intente de nuevo.");
    }
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragActive(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragActive(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!isDragActive) {
      setIsDragActive(true);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragActive(false);
    
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      handleImageFiles(e.dataTransfer.files);
    }
  };

  const handleImageFiles = (files) => {
    const newFiles = Array.from(files).filter(file => file.type.startsWith('image/'));
    
    if (newFiles.length === 0) return;
    
    // Agregar los nuevos archivos al estado
    setImageFiles(prevFiles => [...prevFiles, ...newFiles]);
    
    // Crear vistas previas para los nuevos archivos
    const newPreviews = newFiles.map(file => ({
      url: URL.createObjectURL(file),
      file: file,
      isExisting: false
    }));
    
    setSelectedImages(prevImages => [...prevImages, ...newPreviews]);
  };

  const handleFileInputChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      handleImageFiles(e.target.files);
    }
  };

  const handleClickUpload = () => {
    fileInputRef.current.click();
  };

  const handleRemoveImage = (index) => {
    const imageToRemove = selectedImages[index];
    
    // Si es una imagen existente, añadirla a la lista de imágenes para eliminar
    if (imageToRemove.isExisting) {
      setImagesToDelete(prev => [...prev, imageToRemove.url]);
    }
    
    // Si es una imagen nueva (archivo), eliminarla de la lista de archivos
    if (!imageToRemove.isExisting && imageToRemove.file) {
      setImageFiles(prev => prev.filter(file => file !== imageToRemove.file));
    }
    
    // Eliminar la imagen de la vista previa
    setSelectedImages(prev => prev.filter((_, i) => i !== index));
  };

  return (
    <GoogleModal show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{incident ? 'Edit Incident' : 'Create New Incident'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <GoogleForm noValidate validated={validated} onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Type</Form.Label>
            <Form.Select
              required
              value={formData.type}
              onChange={(e) => setFormData(prev => ({ ...prev, type: e.target.value }))}
            >
              <option value="">Select type</option>
              {types.map((type) => (
                <option key={type.code} value={type.code}>
                  {type.name}
                </option>
              ))}
            </Form.Select>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Description</Form.Label>
            <Form.Control
              required
              type="text"
              value={formData.description}
              onChange={(e) => setFormData(prev => ({ ...prev, description: e.target.value }))}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Province</Form.Label>
            <Form.Select
              required
              value={formData.province}
              onChange={(e) => setFormData(prev => ({ 
                ...prev, 
                province: e.target.value,
                municipality: ''
              }))}
            >
              <option value="">Select province</option>
              {provinces.map((province) => (
                <option key={province.province} value={province.province}>
                  {province.province}
                </option>
              ))}
            </Form.Select>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Municipality</Form.Label>
            <Form.Select
              required
              value={formData.municipality}
              onChange={(e) => setFormData(prev => ({ ...prev, municipality: e.target.value }))}
              disabled={!formData.province}
            >
              <option value="">Select municipality</option>
              {municipalities.map((municipality) => (
                <option key={municipality.municipality} value={municipality.municipality}>
                  {municipality.municipality}
                </option>
              ))}
            </Form.Select>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Start Date</Form.Label>
            <Form.Control
              required
              type="date"
              value={formData.startDate}
              onChange={(e) => setFormData(prev => ({ ...prev, startDate: e.target.value }))}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>End Date</Form.Label>
            <Form.Control
              type="date"
              value={formData.endDate}
              onChange={(e) => setFormData(prev => ({ ...prev, endDate: e.target.value }))}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Protest Magnitude</Form.Label>
            <Form.Select
              required
              value={formData.protestMagnitude}
              onChange={(e) => setFormData(prev => ({ ...prev, protestMagnitude: e.target.value }))}
            >
              <option value="">Seleccionar magnitud</option>
              <option value="SE DESCONOCE">Se desconoce</option>
              <option value="FAMILIAR">Familiar</option>
              <option value="UNA PERSONA">Una persona</option>
              <option value="MASIVA">Masiva</option>
              <option value="GRUPAL">Grupal</option>
            </Form.Select>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Check
              type="checkbox"
              label="Active"
              checked={formData.active}
              onChange={(e) => setFormData(prev => ({ ...prev, active: e.target.checked }))}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Images</Form.Label>
            
            {/* Área para arrastrar y soltar imágenes */}
            <DropArea 
              isDragActive={isDragActive}
              onDragEnter={handleDragEnter}
              onDragLeave={handleDragLeave}
              onDragOver={handleDragOver}
              onDrop={handleDrop}
              onClick={handleClickUpload}
            >
              <UploadIcon />
              <UploadText>Drag and drop images here</UploadText>
              <UploadSubText>or click to select files</UploadSubText>
              
              <input 
                type="file"
                ref={fileInputRef}
                style={{ display: 'none' }}
                multiple
                accept="image/*"
                onChange={handleFileInputChange}
              />
            </DropArea>
            
            {/* Vista previa de imágenes */}
            {selectedImages.length > 0 && (
              <ImageContainer>
                {selectedImages.map((image, index) => (
                  <ImageWrapper key={index}>
                    <PreviewImage>
                      <img 
                        src={image.url} 
                        alt={`Preview ${index + 1}`} 
                      />
                    </PreviewImage>
                    <DeleteButton
                      variant="danger"
                      size="sm"
                      onClick={() => handleRemoveImage(index)}
                      type="button"
                    >
                      <FaTrash />
                    </DeleteButton>
                  </ImageWrapper>
                ))}
              </ImageContainer>
            )}
          </Form.Group>

          <div className="d-flex justify-content-end gap-2">
            <Button variant="secondary" onClick={onHide}>
              Cancel
            </Button>
            <GoogleButton type="submit">
              {incident ? 'Update' : 'Create'}
            </GoogleButton>
          </div>
        </GoogleForm>
      </Modal.Body>
    </GoogleModal>
  );
};

export const TypeModal = ({ show, onHide, incident, onSuccess, types }) => {
  const [selectedType, setSelectedType] = useState('');

  useEffect(() => {
    if (incident) {
      setSelectedType(incident.type);
    }
  }, [incident]);

  const handleSubmit = async () => {
    try {
      await updateIncidentType(incident.id, selectedType);
      onSuccess();
      onHide();
    } catch (error) {
      console.error('Error updating type:', error);
    }
  };

  return (
    <GoogleModal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Change Type</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <GoogleForm>
          <Form.Group>
            <Form.Label>Current Type: {incident?.type}</Form.Label>
            <Form.Select
              value={selectedType}
              onChange={(e) => setSelectedType(e.target.value)}
            >
              <option value="">Select type</option>
              {types.map((type) => (
                <option 
                  key={type.code} 
                  value={type.code}
                  disabled={type.code === incident?.type}
                >
                  {type.name}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </GoogleForm>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Cancel
        </Button>
        <GoogleButton 
          onClick={handleSubmit}
          disabled={!selectedType || selectedType === incident?.type}
        >
          Update Type
        </GoogleButton>
      </Modal.Footer>
    </GoogleModal>
  );
};

export const ImagesModal = ({ show, onHide, incident, onSuccess }) => {
  const [existingImages, setExistingImages] = useState([]);
  const [newImageFiles, setNewImageFiles] = useState([]);
  const [newImagePreviews, setNewImagePreviews] = useState([]);
  const [imagesToDelete, setImagesToDelete] = useState([]);
  const [isDragActive, setIsDragActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const fileInputRef = useRef(null);

  useEffect(() => {
    if (show && incident) {
      if (incident.images && Array.isArray(incident.images)) {
        setExistingImages(incident.images);
      } else {
        setExistingImages([]);
      }
      // Limpiar los estados cuando se abre/cierra el modal
      setNewImageFiles([]);
      setNewImagePreviews([]);
      setImagesToDelete([]);
    }
  }, [incident, show]);

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragActive(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragActive(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!isDragActive) {
      setIsDragActive(true);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragActive(false);
    
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      handleFileSelect(e.dataTransfer.files);
    }
  };
  
  const handleFileSelect = (files) => {
    const imageFiles = Array.from(files).filter(file => file.type.startsWith('image/'));
    if (imageFiles.length > 0) {
      setNewImageFiles(prevFiles => [...prevFiles, ...imageFiles]);
      
      // Crear vista previa para las nuevas imágenes
      const newPreviews = imageFiles.map(file => ({
        url: URL.createObjectURL(file),
        file: file
      }));
      
      setNewImagePreviews(prev => [...prev, ...newPreviews]);
    }
  };
  
  const handleFileInputChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      handleFileSelect(e.target.files);
    }
  };
  
  const handleClickUpload = () => {
    fileInputRef.current.click();
  };
  
  const handleRemoveExisting = (imageUrl) => {
    setExistingImages(prev => prev.filter(url => url !== imageUrl));
    setImagesToDelete(prev => [...prev, imageUrl]);
  };
  
  const handleRemoveNew = (index) => {
    // Crear nuevas matrices sin el elemento en el índice
    const updatedFiles = [...newImageFiles];
    const updatedPreviews = [...newImagePreviews];
    
    // Liberar URL de objeto para evitar fugas de memoria
    URL.revokeObjectURL(updatedPreviews[index].url);
    
    // Eliminar elementos
    updatedFiles.splice(index, 1);
    updatedPreviews.splice(index, 1);
    
    setNewImageFiles(updatedFiles);
    setNewImagePreviews(updatedPreviews);
  };

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      
      // Crear un objeto FormData para enviar archivos
      const formData = new FormData();
      
      // Hacer una copia segura del incidente para evitar problemas con fechas
      const safeIncident = {...incident};
      
      // Asegurar de que las fechas son válidas antes de convertirlas
      if (safeIncident.startDate || safeIncident.start_date) {
        try {
          const startDate = new Date(safeIncident.startDate || safeIncident.start_date);
          if (!isNaN(startDate.getTime())) {
            safeIncident.start_date = startDate.toISOString();
          } else {
            // Si no es una fecha válida, usar la fecha actual
            safeIncident.start_date = new Date().toISOString();
          }
        } catch (error) {
          console.error("Error with start date:", error);
          safeIncident.start_date = new Date().toISOString();
        }
      }
      
      if (safeIncident.endDate || safeIncident.end_date) {
        try {
          const endDate = new Date(safeIncident.endDate || safeIncident.end_date);
          if (!isNaN(endDate.getTime())) {
            safeIncident.end_date = endDate.toISOString();
          } else {
            // Si no es válida, no incluir la fecha de fin
            safeIncident.end_date = null;
          }
        } catch (error) {
          console.error("Error with end date:", error);
          safeIncident.end_date = null;
        }
      }
      
      // Convertir el formato del objeto a lo que espera el backend
      const incidentData = {
        type: safeIncident.type,
        start_date: safeIncident.start_date,
        end_date: safeIncident.end_date,
        active: safeIncident.active,
        province: safeIncident.province,
        municipality: safeIncident.municipality,
        protest_magnitude: safeIncident.protestMagnitude || safeIncident.protest_magnitude,
        description: safeIncident.description,
        images: existingImages
      };
      
      // Adjuntar datos JSON del incidente
      const incidentBlob = new Blob([JSON.stringify(incidentData)], {
        type: 'application/json'
      });
      formData.append('incident', incidentBlob);
      
      // Adjuntar nuevas imágenes como archivos
      if (newImageFiles.length > 0) {
        newImageFiles.forEach(file => {
          formData.append('images', file);
        });
      }
      
      // Adjuntar lista de imágenes a eliminar
      if (imagesToDelete.length > 0) {
        const deleteBlob = new Blob([JSON.stringify(imagesToDelete)], {
          type: 'application/json'
        });
        formData.append('imagesToDelete', deleteBlob);
      }
      
      // Enviar la solicitud
      await updateIncident(incident.id, formData);
      
      // Limpiar URL de objetos para evitar fugas de memoria
      newImagePreviews.forEach(preview => URL.revokeObjectURL(preview.url));
      
      onSuccess();
      onHide();
    } catch (error) {
      console.error('Error updating images:', error);
      alert('Ocurrió un error al actualizar las imágenes. Por favor, intente de nuevo.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <GoogleModal show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Manage Images</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <GoogleForm>
          <Form.Group className="mb-3">
            <Form.Label>Add New Images</Form.Label>
            <DropArea 
              isDragActive={isDragActive}
              onDragEnter={handleDragEnter}
              onDragLeave={handleDragLeave}
              onDragOver={handleDragOver}
              onDrop={handleDrop}
              onClick={handleClickUpload}
            >
              <UploadIcon />
              <UploadText>Drag and drop images here</UploadText>
              <UploadSubText>or click to select files</UploadSubText>
              
              <input 
                type="file"
                ref={fileInputRef}
                style={{ display: 'none' }}
                multiple
                accept="image/*"
                onChange={handleFileInputChange}
              />
            </DropArea>
          </Form.Group>

          {newImagePreviews.length > 0 && (
            <Form.Group className="mb-4">
              <Form.Label>New Images to Upload</Form.Label>
              <ImageContainer>
                {newImagePreviews.map((preview, index) => (
                  <ImageWrapper key={`new-${index}`}>
                    <Image src={preview.url} alt={`New image ${index + 1}`} />
                    <DeleteButton
                      variant="danger"
                      size="sm"
                      onClick={() => handleRemoveNew(index)}
                    >
                      <FaTrash />
                    </DeleteButton>
                  </ImageWrapper>
                ))}
              </ImageContainer>
            </Form.Group>
          )}

          {existingImages.length > 0 && (
            <Form.Group>
              <Form.Label>Current Images</Form.Label>
              <ImageContainer>
                {existingImages.map((url, index) => (
                  <ImageWrapper key={`existing-${index}`}>
                    <Image src={url} alt={`Image ${index + 1}`} />
                    <DeleteButton
                      variant="danger"
                      size="sm"
                      onClick={() => handleRemoveExisting(url)}
                    >
                      <FaTrash />
                    </DeleteButton>
                  </ImageWrapper>
                ))}
              </ImageContainer>
            </Form.Group>
          )}
        </GoogleForm>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide} disabled={isLoading}>
          Cancel
        </Button>
        <SubmitButton 
          onClick={handleSubmit} 
          disabled={isLoading || (newImageFiles.length === 0 && imagesToDelete.length === 0)}
        >
          {isLoading ? 'Updating...' : 'Update Images'}
        </SubmitButton>
      </Modal.Footer>
    </GoogleModal>
  );
};

  export const IncidentActivitiesModal = ({ show, onHide, selectedPerson, onActivityCountUpdate }) => {
    const [activities, setActivities] = useState([]);
    const [formData, setFormData] = useState({
      description: '',
      date: new Date().toISOString().split('T')[0],
      images: [],
    });
    const [newImageUrl, setNewImageUrl] = useState('');
    const [selectedActivity, setSelectedActivity] = useState(null);
    const [validated, setValidated] = useState(false);
    const [loading, setLoading] = useState(true);
  
    useEffect(() => {
      if (show && selectedPerson?.id) {
        loadActivities();
      }
    }, [show, selectedPerson]);
  
    const loadActivities = async () => {
      setLoading(true);
      try {
        const data = await fetchIncidentActivities(selectedPerson.id);
        setActivities(data.content);
        onActivityCountUpdate?.(data.content.length);
      } catch (error) {
        console.error('Error loading activities:', error);
      } finally {
        setLoading(false);
      }
    };
  
    const handleSubmit = async (event) => {
      event.preventDefault();
      const form = event.currentTarget;
      
      if (form.checkValidity() === false) {
        event.stopPropagation();
        setValidated(true);
        return;
      }
  
      try {
        const data = {
          ...formData,
          parent_id: selectedPerson.id,
          date: new Date(formData.date).toISOString(),
        };
  
        let newActivity;
        if (selectedActivity) {
          newActivity = await updateIncidentActivity(selectedActivity.id, data);
          setActivities(prev => prev.map(act => 
            act.id === selectedActivity.id ? newActivity : act
          ));
        } else {
          newActivity = await createIncidentActivity(data);
          setActivities(prev => [newActivity, ...prev]);
        }
  
        resetForm();
        onActivityCountUpdate?.(activities.length + (selectedActivity ? 0 : 1));
      } catch (error) {
        console.error('Error saving activity:', error);
      }
    };
  
    const handleDelete = async (activityId) => {
      if (window.confirm('Are you sure you want to delete this activity?')) {
        try {
          await deleteIncidentActivity(activityId);
          setActivities(prev => prev.filter(act => act.id !== activityId));
          if (selectedActivity?.id === activityId) {
            resetForm();
          }
          onActivityCountUpdate?.(activities.length - 1);
        } catch (error) {
          console.error('Error deleting activity:', error);
        }
      }
    };
  
    const handleEdit = (activity) => {
      setSelectedActivity(activity);
      setFormData({
        description: activity.description,
        date: new Date(activity.date).toISOString().split('T')[0],
        images: activity.images || [],
      });
    };
  
    const resetForm = () => {
      setFormData({
        description: '',
        date: new Date().toISOString().split('T')[0],
        images: [],
      });
      setNewImageUrl('');
      setSelectedActivity(null);
      setValidated(false);
    };
  
    const handleAddImage = () => {
      if (newImageUrl.trim()) {
        setFormData(prev => ({
          ...prev,
          images: [...prev.images, newImageUrl.trim()]
        }));
        setNewImageUrl('');
      }
    };
  
    const handleRemoveImage = (index) => {
      setFormData(prev => ({
        ...prev,
        images: prev.images.filter((_, i) => i !== index)
      }));
    };
  
    return (
      <ModalContainer show={show} onHide={onHide} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Activities Log - {selectedPerson?.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormContainer>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <Form.Group className="mb-3">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  required
                  value={formData.description}
                  onChange={(e) => setFormData(prev => ({ 
                    ...prev, 
                    description: e.target.value 
                  }))}
                  disabled={loading}
                />
              </Form.Group>
  
              <Form.Group className="mb-3">
                <Form.Label>Date</Form.Label>
                <Form.Control
                  type="date"
                  required
                  value={formData.date}
                  onChange={(e) => setFormData(prev => ({ 
                    ...prev, 
                    date: e.target.value 
                  }))}
                  disabled={loading}
                />
              </Form.Group>
  
              <Form.Group className="mb-3">
                <Form.Label>Images</Form.Label>
                <div className="d-flex gap-2">
                  <Form.Control
                    type="url"
                    value={newImageUrl}
                    onChange={(e) => setNewImageUrl(e.target.value)}
                    placeholder="Enter image URL"
                    disabled={loading}
                  />
                  <Button 
                    variant="outline-primary" 
                    onClick={handleAddImage}
                    disabled={loading}
                  >
                    <FaPlus />
                  </Button>
                </div>
  
                <ImagesGrid>
                  {formData.images.map((url, index) => (
                    <div key={index} className="position-relative">
                      <ImageThumbnail src={url} alt={`Preview ${index + 1}`} />
                      <Button 
                        variant="danger"
                        size="sm"
                        onClick={() => handleRemoveImage(index)}
                        style={{ 
                          position: 'absolute',
                          top: '4px',
                          right: '4px',
                          padding: '2px 4px'
                        }}
                        disabled={loading}
                      >
                        <FaTrash />
                      </Button>
                    </div>
                  ))}
                </ImagesGrid>
              </Form.Group>
  
              <div className="d-flex justify-content-end gap-2">
                {selectedActivity && (
                  <Button 
                    variant="secondary" 
                    onClick={resetForm}
                    disabled={loading}
                  >
                    Cancel
                  </Button>
                )}
                <Button 
                  variant="primary" 
                  type="submit"
                  disabled={loading}
                >
                  {selectedActivity ? 'Update Activity' : 'Add Activity'}
                </Button>
              </div>
            </Form>
          </FormContainer>
  
          <ListContainer>
            {loading && (
              <LoadingOverlay>
                <LoadingSpinner />
              </LoadingOverlay>
            )}
            <ActivityList>
              {activities.map((activity) => (
                <ActivityCard key={activity.id}>
                  <Card.Body>
                    <div className="d-flex justify-content-between align-items-center mb-2">
                      <small className="text-muted">
                        {new Date(activity.date).toLocaleDateString()}
                      </small>
                      <div className="d-flex gap-2">
                        <Button 
                          variant="outline-primary" 
                          size="sm"
                          onClick={() => handleEdit(activity)}
                        >
                          <FaEdit />
                        </Button>
                        <Button 
                          variant="outline-danger" 
                          size="sm"
                          onClick={() => handleDelete(activity.id)}
                        >
                          <FaTrash />
                        </Button>
                      </div>
                    </div>
                    <p className="mb-2">{activity.description}</p>
                    {activity.images?.length > 0 && (
                      <ImagesGrid>
                        {activity.images.map((image, index) => (
                          <ImageThumbnail
                            key={index}
                            src={image}
                            alt={`Activity ${index + 1}`}
                          />
                        ))}
                      </ImagesGrid>
                    )}
                  </Card.Body>
                </ActivityCard>
              ))}
            </ActivityList>
          </ListContainer>
        </Modal.Body>
      </ModalContainer>
    );
  };

  export const IncidentPeopleManageModal = ({ show, onHide, incident, onUpdate, personType }) => {
    const [allPeople, setAllPeople] = useState([]);
    const [relatedPeople, setRelatedPeople] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [loading, setLoading] = useState(true);
    const [addingPerson, setAddingPerson] = useState(null);
    const [removingPerson, setRemovingPerson] = useState(null);
  
    useEffect(() => {
      if (show && incident?.id) {
        loadData();
      }
    }, [show, incident, personType]);
  
    const loadData = async () => {
      setLoading(true);
      try {
        const [peopleResponse, relatedResponse] = await Promise.all([
          fetchIncidentPeople({ size: 100, type: personType }),
          getPeopleByIncident(incident.id, personType)
        ]);
        setAllPeople(peopleResponse.content);
        setRelatedPeople(relatedResponse);
      } catch (error) {
        console.error('Error loading data:', error);
      } finally {
        setLoading(false);
      }
    };
  
    const loadRelatedPeople = async () => {
      try {
        const relatedResponse = await getPeopleByIncident(incident.id, personType);
        setRelatedPeople(relatedResponse);
      } catch (error) {
        console.error('Error loading related people:', error);
      }
    };
  
    const handleAddPerson = async (person) => {
      setAddingPerson(person.id);
      try {
        await addIncidentToPerson(person.id, incident.id);
        await loadRelatedPeople();
        onUpdate?.();
      } catch (error) {
        console.error('Error adding person:', error);
      } finally {
        setAddingPerson(null);
      }
    };
  
    const handleRemovePerson = async (person) => {
      if (window.confirm(`Are you sure you want to remove ${person.name}?`)) {
        setRemovingPerson(person.id);
        try {
          await removeIncidentFromPerson(person.id, incident.id);
          await loadRelatedPeople();
          onUpdate?.();
        } catch (error) {
          console.error('Error removing person:', error);
        } finally {
          setRemovingPerson(null);
        }
      }
    };
  
    const filteredPeople = allPeople.filter(person => 
      person.name.toLowerCase().includes(searchTerm.toLowerCase()) &&
      !relatedPeople.find(p => p.id === person.id)
    );
  
    return (
      <ModalContainer show={show} onHide={onHide} size="xl">
        <Modal.Header closeButton>
        <Modal.Title>
          Manage {personType === 'INCIDENT_VICTIM' ? 'Victims' : 'Repressors'}
        </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex h-100" style={{ minHeight: '600px' }}>
            {/* Lista de personas disponibles */}
            <div style={{ width: '50%', borderRight: '1px solid #e8eaed', padding: '20px' }}>
              <div className="mb-3">
                <Form.Control
                  type="search"
                  placeholder="Search by name..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
              
              <div style={{ overflowY: 'auto', height: 'calc(100vh - 250px)' }}>
                {loading ? (
                  <LoadingOverlay>
                    <LoadingSpinner />
                  </LoadingOverlay>
                ) : (
                  <div className="d-flex flex-column gap-3">
                    {filteredPeople.map((person) => (
                      <Card key={person.id}>
                        <Card.Body>
                          <div className="d-flex align-items-center">
                            <div 
                              className="me-3" 
                              style={{
                                width: '50px',
                                height: '50px',
                                borderRadius: '50%',
                                overflow: 'hidden',
                                flexShrink: 0,
                                backgroundColor: '#e9ecef'
                              }}
                            >
                              <img
                                src={person.profile_image || 'https://neo-mambi-files.nyc3.digitaloceanspaces.com/assets/no-image.png'}
                                alt={person.name}
                                style={{
                                  width: '100%',
                                  height: '100%',
                                  objectFit: 'cover'
                                }}
                                onError={(e) => {
                                  e.target.src = 'https://neo-mambi-files.nyc3.digitaloceanspaces.com/assets/no-image.png';
                                }}
                              />
                            </div>
                            <div className="flex-grow-1 me-3">
                              <div className="fw-bold">{person.name}</div>
                              <small className="text-muted">
                                {person.province}, {person.municipality}
                              </small>
                            </div>
                            <Button
                              variant="outline-primary"
                              size="sm"
                              onClick={() => handleAddPerson(person)}
                              disabled={addingPerson === person.id}
                            >
                              {addingPerson === person.id ? (
                                <span className="spinner-border spinner-border-sm" />
                              ) : (
                                'Add'
                              )}
                            </Button>
                          </div>
                        </Card.Body>
                      </Card>
                    ))}
                  </div>
                )}
              </div>
            </div>
  
            {/* Lista de personas relacionadas */}
            <div style={{ width: '50%', padding: '20px' }}>
              <h5 className="mb-3">Related People</h5>
              <div style={{ overflowY: 'auto', height: 'calc(100vh - 250px)' }}>
                {loading ? (
                  <LoadingOverlay>
                    <LoadingSpinner />
                  </LoadingOverlay>
                ) : (
                  <div className="d-flex flex-column gap-3">
                    {relatedPeople.map((person) => (
                      <Card key={person.id}>
                        <Card.Body>
                          <div className="d-flex align-items-center">
                            <div 
                              className="me-3" 
                              style={{
                                width: '50px',
                                height: '50px',
                                borderRadius: '50%',
                                overflow: 'hidden',
                                flexShrink: 0,
                                backgroundColor: '#e9ecef'
                              }}
                            >
                              <img
                                src={person.profile_image || 'https://neo-mambi-files.nyc3.digitaloceanspaces.com/assets/no-image.png'}
                                alt={person.name}
                                style={{
                                  width: '100%',
                                  height: '100%',
                                  objectFit: 'cover'
                                }}
                                onError={(e) => {
                                  e.target.src = 'https://neo-mambi-files.nyc3.digitaloceanspaces.com/assets/no-image.png';
                                }}
                              />
                            </div>
                            <div className="flex-grow-1 me-3">
                              <div className="fw-bold">{person.name}</div>
                              <small className="text-muted">
                                {person.province}, {person.municipality}
                              </small>
                            </div>
                            <Button
                              variant="outline-danger"
                              size="sm"
                              onClick={() => handleRemovePerson(person)}
                              disabled={removingPerson === person.id}
                            >
                              {removingPerson === person.id ? (
                                <span className="spinner-border spinner-border-sm" />
                              ) : (
                                <FaTrash />
                              )}
                            </Button>
                          </div>
                        </Card.Body>
                      </Card>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </Modal.Body>
      </ModalContainer>
    );
  };