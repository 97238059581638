// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getDatabase } from 'firebase/database';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAzj0orAaMf8ETg_kmeqkAucyxU0XtJRUg",
  authDomain: "neomambi-admin.firebaseapp.com",
  databaseURL: "https://neomambi-admin-default-rtdb.firebaseio.com",
  projectId: "neomambi-admin",
  storageBucket: "neomambi-admin.firebasestorage.app",
  messagingSenderId: "373170506614",
  appId: "1:373170506614:web:8120dab7e7da286fcc8857",
  measurementId: "G-5YFC24W29C"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const database = getDatabase(app);

export { app, database };