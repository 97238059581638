import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Button } from 'react-bootstrap';
import { 
  FaEdit, 
  FaTrash, 
  FaCode, 
  FaRobot, 
  FaToggleOn, 
  FaToggleOff, 
  FaLink,
} from 'react-icons/fa';

const Panel = styled.div`
  background-color: #fff;
  box-shadow: 0 1px 2px 0 rgba(60,64,67,0.3), 0 2px 6px 2px rgba(60,64,67,0.15);
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const FixedHeader = styled.div`
  padding: 24px 24px 0;
  background-color: #fff;
  border-bottom: 1px solid #dadce0;
`;

const ScrollableContent = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  padding: 24px;
`;

const FixedActions = styled.div`
  padding: 24px;
  background-color: #fff;
  border-top: 1px solid #dadce0;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
`;

const Avatar = styled.div`
  width: 64px;
  height: 64px;
  border-radius: 8px;
  background-color: #1a73e8;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  margin-right: 16px;
  overflow: hidden;
  
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const Title = styled.h2`
  margin: 0;
  font-size: 22px;
  font-weight: 400;
  color: #202124;
`;

const Section = styled.div`
  margin-bottom: 24px;
`;

const SectionTitle = styled.h3`
  font-size: 14px;
  font-weight: 500;
  color: #5f6368;
  margin-bottom: 12px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
`;

const InfoGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 16px;
`;

const InfoItem = styled.div`
  background-color: #f1f3f4;
  padding: 12px;
  border-radius: 8px;
  transition: background-color 0.3s ease;
  word-wrap: break-word;
  overflow-wrap: break-word;

  &:hover {
    background-color: #e8f0fe;
  }
`;

const InfoLabel = styled.div`
  font-size: 12px;
  color: #5f6368;
  margin-bottom: 4px;
`;

const InfoValue = styled.div`
  font-size: 14px;
  color: #202124;
  font-weight: 500;
  white-space: pre-wrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: ${props => props.expanded ? 'none' : '100px'};
`;

const ActionButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 12px;
  background-color: #fff;
  border-color: #dadce0;
  color: #1a73e8;
  font-weight: 500;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  
  &:hover {
    background-color: #f1f3f4;
    border-color: #dadce0;
    color: #174ea6;
    box-shadow: 0 1px 2px 0 rgba(60,64,67,0.3), 0 1px 3px 1px rgba(60,64,67,0.15);
  }

  &:focus {
    box-shadow: 0 0 0 2px rgba(26,115,232,0.4);
  }

  svg {
    margin-right: 8px;
  }
`;

const ExpandButton = styled.button`
  background: none;
  border: none;
  color: #1a73e8;
  padding: 4px 0;
  font-size: 12px;
  cursor: pointer;
  display: ${props => props.show ? 'block' : 'none'};
`;

// Funciones de utilidad con manejo seguro de valores nulos
const formatDate = (dateString) => {
  if (!dateString) return 'No date available';
  try {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  } catch (error) {
    console.error('Error formatting date:', error);
    return 'Invalid date format';
  }
};

const truncateText = (text, maxLength) => {
  if (!text) return 'No content available';
  return text.length > maxLength ? `${text.substring(0, maxLength)}...` : text;
};

// Función para extraer el ID del artículo, considerando diferentes formatos
const getArticleId = (article) => {
  if (!article) return 'N/A';
  
  // Si el ID está directamente en el artículo
  if (article.id) {
    return article.id;
  }
  
  // Si el ID está en el objeto _id
  if (article._id) {
    // Si _id es un string
    if (typeof article._id === 'string') {
      return article._id;
    }
    
    // Si _id es un objeto (como en MongoDB)
    if (typeof article._id === 'object') {
      try {
        return article._id.toString();
      } catch (error) {
        console.error('Error converting _id to string:', error);
        return 'Invalid ID format';
      }
    }
  }
  
  // Si hay un articleRssId, lo usamos como alternativa
  if (article.articleRssId) {
    return article.articleRssId;
  }
  
  // Si no se encuentra un ID, intentar usar _class y timestamp para crear uno único
  if (article._class && article.publicationDate) {
    try {
      return `${article._class}-${new Date(article.publicationDate).getTime()}`;
    } catch (error) {
      console.error('Error creating ID from _class and date:', error);
      return 'ID not available';
    }
  }
  
  return 'ID not available';
};

// Componente InfoItem con manejo seguro de datos
const InfoItemWithFallback = ({ label, value, expandable = false }) => {
  const [expanded, setExpanded] = React.useState(false);
  const contentRef = React.useRef(null);
  const [isOverflowing, setIsOverflowing] = React.useState(false);

  // Asegurar que el valor siempre sea una cadena segura
  const safeValue = React.useMemo(() => {
    if (value === null || value === undefined) return 'Not available';
    if (typeof value === 'boolean') return value ? 'Yes' : 'No';
    if (typeof value === 'object') {
      try {
        return JSON.stringify(value);
      } catch (error) {
        console.error('Error stringifying object:', error);
        return 'Complex data (not displayable)';
      }
    }
    return String(value);
  }, [value]);

  React.useEffect(() => {
    if (contentRef.current) {
      setIsOverflowing(
        contentRef.current.scrollHeight > 100 && expandable
      );
    }
  }, [safeValue, expandable]);

  return (
    <InfoItem>
      <InfoLabel>{label}</InfoLabel>
      <InfoValue ref={contentRef} expanded={expanded}>
        {safeValue}
      </InfoValue>
      <ExpandButton 
        show={isOverflowing} 
        onClick={() => setExpanded(!expanded)}
      >
        {expanded ? 'Show less' : 'Show more'}
      </ExpandButton>
    </InfoItem>
  );
};

const ArticleSlidingPanel = ({
  selectedArticle,
  handleOpenEditModal,
  handleDelete,
  handleScrape,
  handleAnalyze,
  handleVisibilityToggle,
  onClose
}) => {
  useEffect(() => {
    // Validar que el artículo seleccionado sea válido
    if (selectedArticle) {
      console.log('Article loaded in sliding panel:', selectedArticle);
    }
  }, [selectedArticle]);

  // Si no hay artículo seleccionado, muestra un mensaje
  if (!selectedArticle) {
    return (
      <Panel>
        <FixedHeader>
          <Header>
            <Title>No Article Selected</Title>
          </Header>
        </FixedHeader>
        <ScrollableContent>
          <p>Please select an article to view its details.</p>
        </ScrollableContent>
      </Panel>
    );
  }

  // Prepara un objeto de artículo con valores seguros para las acciones
  const safeArticle = {
    ...selectedArticle,
    // Asegurar que los campos críticos estén definidos
    id: selectedArticle.id || selectedArticle.articleRssId || 'unknown',
    articleRssId: selectedArticle.articleRssId || 'unknown',
    title: selectedArticle.title || 'Untitled Article',
    visible: selectedArticle.visible !== undefined ? selectedArticle.visible : true,
    body: selectedArticle.body || '',
    description: selectedArticle.description || '',
    link: selectedArticle.link || '',
  };

  return (
    <Panel>
      <FixedHeader>
        <Header>
          <Avatar>
            {safeArticle.image ? (
              <img 
                src={safeArticle.image} 
                alt={safeArticle.title} 
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = 'data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%3E%3Cpath%20fill%3D%22%23FFF%22%20d%3D%22M12%202C6.48%202%202%206.48%202%2012s4.48%2010%2010%2010%2010-4.48%2010-10S17.52%202%2012%202zm0%203c1.66%200%203%201.34%203%203s-1.34%203-3%203-3-1.34-3-3%201.34-3%203-3zm0%2014.2c-2.5%200-4.71-1.28-6-3.22.03-1.99%204-3.08%206-3.08%201.99%200%205.97%201.09%206%203.08-1.29%201.94-3.5%203.22-6%203.22z%22%2F%3E%3C%2Fsvg%3E';
                }}
              />
            ) : (
              safeArticle.title.charAt(0).toUpperCase() || 'A'
            )}
          </Avatar>
          <Title>{safeArticle.title}</Title>
        </Header>
        <SectionTitle>Article Information</SectionTitle>
      </FixedHeader>

      <ScrollableContent>
        <Section>
          <InfoGrid>
            <InfoItemWithFallback label="ID" value={getArticleId(safeArticle)} />
            <InfoItemWithFallback label="RSS ID" value={safeArticle.articleRssId} />
            <InfoItemWithFallback label="Site" value={safeArticle.siteName} />
            <InfoItemWithFallback label="Author" value={safeArticle.author} />
            <InfoItemWithFallback 
              label="Publication Date" 
              value={formatDate(safeArticle.publicationDate)} 
            />
            <InfoItemWithFallback 
              label="Visibility" 
              value={safeArticle.visible ? 'Visible' : 'Hidden'} 
            />
          </InfoGrid>
        </Section>

        <Section>
          <SectionTitle>Content</SectionTitle>
          <InfoGrid>
            <InfoItemWithFallback 
              label="Description" 
              value={safeArticle.description} 
              expandable={true}
            />
            <InfoItemWithFallback 
              label="Link" 
              value={safeArticle.link} 
            />
            <InfoItemWithFallback 
              label="Shared Link" 
              value={safeArticle.sharedLink} 
            />
          </InfoGrid>
        </Section>

        {safeArticle.body && (
          <Section>
            <SectionTitle>Article Body</SectionTitle>
            <InfoItemWithFallback 
              label="Content" 
              value={truncateText(safeArticle.body, 500)} 
              expandable={true}
            />
          </Section>
        )}

        {safeArticle.articleAnalysis && (
          <Section>
            <SectionTitle>Analysis</SectionTitle>
            <InfoGrid>
              <InfoItemWithFallback 
                label="Summary" 
                value={safeArticle.articleAnalysis.summary} 
                expandable={true}
              />
              <InfoItemWithFallback 
                label="Category" 
                value={safeArticle.articleAnalysis.category} 
              />
              <InfoItemWithFallback 
                label="Subcategory" 
                value={safeArticle.articleAnalysis.subcategory} 
              />
              <InfoItemWithFallback 
                label="Sentiment" 
                value={safeArticle.articleAnalysis.sentiment} 
              />
              <InfoItemWithFallback 
                label="Tone" 
                value={safeArticle.articleAnalysis.tone} 
              />
              <InfoItemWithFallback 
                label="Keywords" 
                value={safeArticle.articleAnalysis.keywords?.join(', ')} 
              />
            </InfoGrid>
          </Section>
        )}

        <Section>
          <SectionTitle>Engagement</SectionTitle>
          <InfoGrid>
            <InfoItemWithFallback 
              label="Like Count" 
              value={safeArticle.likeCount?.toString() || '0'} 
            />
            <InfoItemWithFallback 
              label="Comment Count" 
              value={safeArticle.commentCount?.toString() || '0'} 
            />
            <InfoItemWithFallback 
              label="Comments Allowed" 
              value={safeArticle.commentSetting?.canComment ? 'Yes' : 'No'} 
            />
          </InfoGrid>
        </Section>
      </ScrollableContent>

      <FixedActions>
        <Section>
          <SectionTitle>Content Actions</SectionTitle>
          <ActionButton 
            onClick={() => {
              console.log('Opening scrape modal with article RSS ID:', safeArticle.articleRssId);
              handleScrape(safeArticle);
            }}
            disabled={!safeArticle.articleRssId || safeArticle.articleRssId === 'unknown'}
          >
            <FaCode /> Scrape Content
          </ActionButton>
          <ActionButton 
            onClick={() => {
              if (!safeArticle.articleRssId || safeArticle.articleRssId === 'unknown') {
                console.error('Error: No article RSS ID available for analysis');
                return;
              }
              handleAnalyze(safeArticle);
            }}
            disabled={!safeArticle.articleRssId || safeArticle.articleRssId === 'unknown'}
          >
            <FaRobot /> Analyze with AI
          </ActionButton>
          <ActionButton 
            onClick={() => safeArticle.link && window.open(safeArticle.link, '_blank')}
            disabled={!safeArticle.link}
          >
            <FaLink /> Open Original Link
          </ActionButton>
        </Section>

        <Section>
          <SectionTitle>Article Actions</SectionTitle>
          <ActionButton 
            onClick={() => handleOpenEditModal(safeArticle)}
          >
            <FaEdit /> Edit Article
          </ActionButton>
          <ActionButton 
            onClick={() => handleVisibilityToggle(safeArticle)}
          >
            {safeArticle.visible ? <FaToggleOff /> : <FaToggleOn />}
            {safeArticle.visible ? ' Hide Article' : ' Show Article'}
          </ActionButton>
          <ActionButton 
            onClick={() => handleDelete(safeArticle)}
            variant="danger"
          >
            <FaTrash /> Delete Article
          </ActionButton>
        </Section>
      </FixedActions>
    </Panel>
  );
};

export default ArticleSlidingPanel;