import React, { useState, useEffect, useCallback, useRef, useMemo, createContext, useContext } from 'react';
import { Container, Card, Button, Table, Badge, Spinner, Row, Col, Alert, Dropdown, Modal, OverlayTrigger, Tooltip, Pagination } from 'react-bootstrap';
import { FaPlay, FaStop, FaPlus, FaCog, FaList, FaTrash, FaSync, FaRocket, FaClock, FaExclamationCircle } from 'react-icons/fa';
import { 
  getAllJobs, 
  enqueueJob, 
  executeJob, 
  removeFromQueue,
  deleteJob,
  updateJob,
  getJobQueueStatus,
  stopRunningJob
} from './apiJobs';
import JobLogsModal from './JobLogsModal';
import JobFormModal from './JobFormModal';
import JobDetailsPanel from './JobDetailsPanel';
import ProcessingCapacityPanel from './ProcessingCapacityPanel';
import RunningJobsPanel from './RunningJobsPanel';
import QueuedJobsPanel from './QueuedJobsPanel';
import styled from 'styled-components';

// Export the context so it can be used in other components
export const RefreshContext = createContext({
  refreshTimestamp: 0,
  triggerRefresh: () => {},
  isLoading: false
});

const SlidingPanel = styled.div`
  position: fixed;
  top: 0;
  right: ${props => props.isOpen ? '0' : '-40%'};
  width: 40%;
  height: 100%;
  background-color: white;
  box-shadow: -2px 0 8px rgba(0, 0, 0, 0.15);
  transition: right 0.3s ease-in-out;
  overflow-y: auto;
  z-index: 1050;
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: ${props => props.isOpen ? 'block' : 'none'};
  z-index: 1040;
`;

export const JobStatusBadge = ({ status }) => {
  const getBadgeVariant = () => {
    switch (status) {
      case 'ACTIVE': return 'success';
      case 'STOPPED': return 'secondary';
      case 'RUNNING': return 'primary';
      case 'QUEUED': return 'warning';
      default: return 'info';
    }
  };

  return <Badge bg={getBadgeVariant()}>{status}</Badge>;
};

export const JobTypeBadge = ({ type }) => {
  if (!type) return <Badge bg="secondary">Unknown</Badge>;
  
  const getBadgeVariant = () => {
    switch (type) {
      case 'RSS_FETCH': return 'info';
      case 'YOUTUBE_FETCH': return 'danger';
      case 'LOTTERY_FETCH_MIDDAY': return 'success';
      case 'LOTTERY_FETCH_EVENING': return 'warning';
      case 'CURRENCY_FETCH': return 'primary';
      default: return 'dark';
    }
  };

  const displayType = typeof type === 'string' ? type.replace(/_/g, ' ').toLowerCase() : String(type);
  
  return <Badge bg={getBadgeVariant()}>{displayType}</Badge>;
};

const PaginationControl = React.memo(({ currentPage, totalPages, onPageChange }) => {
  if (totalPages <= 1) return null;
  
  const getPageItems = () => {
    let items = [];
    
    items.push(
      <Pagination.Item 
        key="first" 
        active={currentPage === 0} 
        onClick={() => onPageChange(0)}
      >
        1
      </Pagination.Item>
    );
    
    if (currentPage > 2) {
      items.push(<Pagination.Ellipsis key="ellipsis-start" />);
    }
    
    for (let i = Math.max(1, currentPage); i <= Math.min(currentPage + 1, totalPages - 2); i++) {
      items.push(
        <Pagination.Item 
          key={i} 
          active={currentPage === i} 
          onClick={() => onPageChange(i)}
        >
          {i + 1}
        </Pagination.Item>
      );
    }
    
    if (currentPage < totalPages - 3) {
      items.push(<Pagination.Ellipsis key="ellipsis-end" />);
    }
    
    if (totalPages > 1) {
      items.push(
        <Pagination.Item 
          key="last" 
          active={currentPage === totalPages - 1} 
          onClick={() => onPageChange(totalPages - 1)}
        >
          {totalPages}
        </Pagination.Item>
      );
    }
    
    return items;
  };
  
  return (
    <div className="d-flex justify-content-center mt-3">
      <Pagination>
        <Pagination.Prev 
          onClick={() => onPageChange(Math.max(0, currentPage - 1))}
          disabled={currentPage === 0}
        />
        {getPageItems()}
        <Pagination.Next 
          onClick={() => onPageChange(Math.min(totalPages - 1, currentPage + 1))}
          disabled={currentPage === totalPages - 1}
        />
      </Pagination>
    </div>
  );
});

const JobDashboard = () => {
  const [jobs, setJobs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [showLogsModal, setShowLogsModal] = useState(false);
  const [selectedJob, setSelectedJob] = useState(null);
  const [showJobFormModal, setShowJobFormModal] = useState(false);
  const [editJobId, setEditJobId] = useState(null);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [jobToDelete, setJobToDelete] = useState(null);
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const [detailsJobId, setDetailsJobId] = useState(null);
  const [queueStatuses, setQueueStatuses] = useState({});
  
  const [refreshTimestamp, setRefreshTimestamp] = useState(0);
  const isLoadingRef = useRef(false);
  const fetchTimeoutRef = useRef(null);
  const autoRefreshIntervalRef = useRef(null);
  
  const isMounted = useRef(true);
  
  const pageSize = 10;

  const triggerRefresh = useCallback(() => {
    if (isMounted.current) {
      setRefreshTimestamp(Date.now());
    }
  }, []);
  
  const refreshContextValue = useMemo(() => ({
    refreshTimestamp,
    triggerRefresh,
    isLoading: loading
  }), [refreshTimestamp, triggerRefresh, loading]);

  const fetchJobs = useCallback(async () => {
    if (isLoadingRef.current || loading || !isMounted.current) return;
    
    isLoadingRef.current = true;
    setLoading(true);
    setError(null);
    
    try {
      const response = await getAllJobs(currentPage, pageSize);
      
      const newJobs = response.data.items || [];
      
      if (isMounted.current) {
        setJobs(newJobs);
        setTotalPages(Math.ceil((response.data.total || 0) / pageSize));
        
        const jobsInQueue = newJobs.filter(job => job.status === 'QUEUED');
        
        if (jobsInQueue.length > 0) {
          const statusPromises = jobsInQueue.map(job => 
            getJobQueueStatus(job.id)
              .then(res => ({ jobId: job.id, status: res.data }))
              .catch(() => ({ jobId: job.id, status: null }))
          );
          
          const statuses = await Promise.all(statusPromises);
          const statusMap = { ...queueStatuses };
          
          statuses.forEach(item => {
            if (item && item.status) {
              statusMap[item.jobId] = item.status;
            }
          });
          
          if (isMounted.current) {
            setQueueStatuses(statusMap);
          }
        }
      }
    } catch (error) {
      console.error('Error fetching jobs:', error);
      if (isMounted.current) {
        setError('Failed to load jobs');
      }
    } finally {
      if (isMounted.current) {
        setLoading(false);
      }
      isLoadingRef.current = false;
    }
  }, [currentPage, loading, queueStatuses]);

  // Initial load effect
  useEffect(() => {
    isMounted.current = true;
    
    // Immediate initial load without delay
    fetchJobs();
    
    // Set up auto-refresh interval (30 seconds)
    autoRefreshIntervalRef.current = setInterval(() => {
      if (isMounted.current && !isLoadingRef.current) {
        triggerRefresh();
      }
    }, 30000);
    
    return () => {
      isMounted.current = false;
      if (autoRefreshIntervalRef.current) {
        clearInterval(autoRefreshIntervalRef.current);
      }
    };
  }, []);
  
  // Effect for handling refresh triggers and page changes
  useEffect(() => {
    if (!isMounted.current) return;
    
    if (fetchTimeoutRef.current) {
      clearTimeout(fetchTimeoutRef.current);
    }

    // Small delay to prevent rapid consecutive calls
    fetchTimeoutRef.current = setTimeout(() => {
      fetchJobs();
    }, 2000);

    return () => {
      if (fetchTimeoutRef.current) {
        clearTimeout(fetchTimeoutRef.current);
      }
    };
  }, [fetchJobs, refreshTimestamp, currentPage]);

  // Cleanup effect
  useEffect(() => {
    return () => {
      isMounted.current = false;
      if (fetchTimeoutRef.current) clearTimeout(fetchTimeoutRef.current);
      if (autoRefreshIntervalRef.current) clearInterval(autoRefreshIntervalRef.current);
    };
  }, []);

  const handleShowLogs = useCallback((job) => {
    setSelectedJob(job);
    setShowLogsModal(true);
  }, []);

  const handleToggleJobStatus = useCallback(async (jobId, currentStatus) => {
    try {
      const newStatus = currentStatus === 'ACTIVE' ? 'STOPPED' : 'ACTIVE';
      await updateJob(jobId, { status: newStatus });
      triggerRefresh();
    } catch (error) {
      console.error(`Error updating job ${jobId} status:`, error);
      setError(`Failed to update job status`);
    }
  }, [triggerRefresh]);

  const handleEnqueueJob = useCallback(async (jobId, priority = 0) => {
    try {
      await enqueueJob(jobId, priority);
      triggerRefresh();
    } catch (error) {
      console.error(`Error enqueueing job ${jobId}:`, error);
      setError(`Failed to enqueue job`);
    }
  }, [triggerRefresh]);

  const handleExecuteJob = useCallback(async (jobId, forceRun = false) => {
    try {
      setLoading(true);
      const response = await executeJob(jobId, forceRun);
      const jobName = jobs.find(j => j.id === jobId)?.name || 'Job';
      setError(`${jobName} successfully sent for execution.`);
      
      // Delay refresh to allow backend to update its state
      setTimeout(() => {
        triggerRefresh();
        setLoading(false);
      }, 3000);
    } catch (error) {
      console.error(`Error executing job ${jobId}:`, error);
      const jobName = jobs.find(j => j.id === jobId)?.name || 'Job';
      setError(`Failed to execute ${jobName}: ${error.message || 'Unknown error'}`);
      setLoading(false);
    }
  }, [triggerRefresh, jobs]);

  const handleDeleteJob = useCallback(async (jobId) => {
    try {
      await deleteJob(jobId);
      setShowConfirmDelete(false);
      setJobToDelete(null);
      triggerRefresh();
    } catch (error) {
      console.error(`Error deleting job ${jobId}:`, error);
      setError(`Failed to delete job`);
    }
  }, [triggerRefresh]);

  const openCreateJobModal = useCallback(() => {
    setEditJobId(null);
    setShowJobFormModal(true);
  }, []);

  const openEditJobModal = useCallback((jobId) => {
    setEditJobId(jobId);
    setShowJobFormModal(true);
  }, []);

  const confirmDeleteJob = useCallback((jobId) => {
    setJobToDelete(jobId);
    setShowConfirmDelete(true);
  }, []);

  const handleStopRunningJob = useCallback(async (jobId) => {
    try {
      await stopRunningJob(jobId);
      triggerRefresh();
    } catch (error) {
      console.error(`Error stopping job ${jobId}:`, error);
      setError(`Failed to stop job: ${error.message}`);
    }
  }, [triggerRefresh]);

  const confirmStopRunningJob = useCallback((jobId) => {
    const runningJob = jobs.find(job => job.id === jobId && job.status === 'RUNNING');
    
    if (runningJob && window.confirm(`Are you sure you want to stop the job "${runningJob.name}"? This will interrupt its execution and save logs generated so far.`)) {
      handleStopRunningJob(jobId);
    }
  }, [jobs, handleStopRunningJob]);

  const formatDateTime = (dateTimeStr) => {
    if (!dateTimeStr) return 'N/A';
    return new Date(dateTimeStr).toLocaleString();
  };
  
  const showJobDetails = useCallback((jobId) => {
    setDetailsJobId(jobId);
    setIsPanelOpen(true);
  }, []);

  const handlePanelAction = useCallback((action, jobId, params = {}) => {
    switch (action) {
      case 'toggleStatus':
        const job = jobs.find(j => j.id === jobId);
        if (job) handleToggleJobStatus(jobId, job.status);
        break;
      case 'execute':
        handleExecuteJob(jobId, params.forceRun);
        break;
      case 'enqueue':
        handleEnqueueJob(jobId, params.priority);
        break;
      case 'removeFromQueue':
        removeFromQueue(jobId).then(triggerRefresh);
        break;
      case 'viewLogs':
        const selectedJob = jobs.find(j => j.id === jobId);
        setSelectedJob(selectedJob);
        setShowLogsModal(true);
        break;
      case 'edit':
        openEditJobModal(jobId);
        break;
      case 'delete':
        confirmDeleteJob(jobId);
        break;
      case 'stopRunning':
        confirmStopRunningJob(jobId);
        break;
      default:
        console.log(`Unknown action: ${action}`);
    }
  }, [jobs, handleToggleJobStatus, handleExecuteJob, handleEnqueueJob, triggerRefresh, openEditJobModal, confirmDeleteJob, confirmStopRunningJob]);

  const getQueueIndicator = useCallback((jobId) => {
    const queueStatus = queueStatuses[jobId];
    
    if (!queueStatus || !queueStatus.isQueued) {
      return null;
    }
    
    const queuePosition = queueStatus.position || 'Unknown';
    const queuedAt = queueStatus.queuedAt ? formatDateTime(queueStatus.queuedAt) : 'Unknown';
    
    return (
      <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip id={`queue-tooltip-${jobId}`}>
            <div className="text-start">
              <div>Position: {queuePosition}</div>
              <div>Queued at: {queuedAt}</div>
              {queueStatus.estimatedExecutionTime && (
                <div>Est. execution: {formatDateTime(queueStatus.estimatedExecutionTime)}</div>
              )}
            </div>
          </Tooltip>
        }
      >
        <Badge bg="warning" className="ms-2" pill>
          <FaExclamationCircle size={10} className="me-1" />
          Queued
        </Badge>
      </OverlayTrigger>
    );
  }, [queueStatuses]);

  const handlePageChange = useCallback((page) => {
    setCurrentPage(page);
  }, []);

  return (
    <RefreshContext.Provider value={refreshContextValue}>
      <Container fluid className="py-4 d-flex flex-column vh-100">
        <Row className="mb-4">
          <Col>
            <div className="d-flex justify-content-between align-items-center">
              <h2>
                <FaCog className="me-2" />
                Job Manager
              </h2>
              <div>
                <Button 
                  variant="primary" 
                  onClick={openCreateJobModal}
                >
                  <FaPlus className="me-2" />
                  New Job
                </Button>
              </div>
            </div>
          </Col>
        </Row>

        {error && (
          <Row className="mb-4">
            <Col>
              <Alert variant="danger" dismissible onClose={() => setError(null)}>
                {error}
              </Alert>
            </Col>
          </Row>
        )}

        <Row className="mb-4">
          <Col md={4}>
            <ProcessingCapacityPanel refreshTrigger={refreshTimestamp} />
          </Col>
          <Col md={8}>
            <RunningJobsPanel refreshTrigger={refreshTimestamp} onRefresh={triggerRefresh} />
          </Col>
        </Row>

        <Row className="flex-grow-1">
          <Col md={4}>
            <QueuedJobsPanel refreshTrigger={refreshTimestamp} onUpdate={triggerRefresh} />
          </Col>
          <Col md={8} className="d-flex flex-column">
            <Card className="flex-grow-1 d-flex flex-column">
              <Card.Header>
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <FaList className="me-2" />
                    Jobs
                  </div>
                  <Button 
                    variant="outline-primary" 
                    size="sm" 
                    onClick={triggerRefresh}
                    disabled={loading}
                    title="Refresh"
                  >
                    <FaSync className={loading ? "fa-spin" : ""} />
                  </Button>
                </div>
              </Card.Header>
              <Card.Body className="flex-grow-1 overflow-auto">
                {loading && jobs.length === 0 ? (
                  <div className="text-center p-4">
                    <Spinner animation="border" role="status">
                      <span className="visually-hidden">Loading jobs...</span>
                    </Spinner>
                  </div>
                ) : jobs.length === 0 ? (
                  <div className="text-center text-muted p-4">
                    No jobs found. Click "New Job" to create one.
                  </div>
                ) : (
                  <>
                    <div className="table-responsive h-100">
                      <Table hover className="position-relative">
                        <thead className="sticky-top bg-white">
                          <tr>
                            <th>Name</th>
                            <th>Type</th>
                            <th>Status</th>
                            <th>Schedule</th>
                            <th>Last Run</th>
                            <th>Next Run</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {jobs.map((job) => (
                            <tr key={job.id} onClick={() => showJobDetails(job.id)} style={{ cursor: 'pointer' }}>
                              <td>{job.name}</td>
                              <td><JobTypeBadge type={job.jobType || job.job_type} /></td>
                              <td>
                                <div className="d-flex align-items-center">
                                  <JobStatusBadge status={job.status} />
                                  {getQueueIndicator(job.id)}
                                </div>
                              </td>
                              <td>{job.cronExpression || job.cron_expression}</td>
                              <td>{formatDateTime(job.lastRun || job.last_run)}</td>
                              <td>{formatDateTime(job.nextRun || job.next_run)}</td>
                              <td onClick={(e) => e.stopPropagation()}>
                                <div className="d-flex">
                                  {/* Play/Stop Button to toggle job status */}
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={
                                      <Tooltip id={`tooltip-toggle-${job.id}`}>
                                        {job.status === 'ACTIVE' ? 'Stop job' : 'Activate job'}
                                      </Tooltip>
                                    }
                                  >
                                    <Button
                                      variant={job.status === 'ACTIVE' ? 'outline-secondary' : 'outline-success'}
                                      size="sm"
                                      className="me-1"
                                      onClick={() => handleToggleJobStatus(job.id, job.status)}
                                    >
                                      {job.status === 'ACTIVE' ? <FaStop /> : <FaPlay />}
                                    </Button>
                                  </OverlayTrigger>
                                  
                                  {/* Execute Button */}
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={
                                      <Tooltip id={`tooltip-execute-${job.id}`}>
                                        Execute this job now
                                      </Tooltip>
                                    }
                                  >
                                    <Button
                                      variant="outline-primary"
                                      size="sm"
                                      className="me-1"
                                      onClick={() => handleExecuteJob(job.id, true)}
                                    >
                                      <FaRocket />
                                    </Button>
                                  </OverlayTrigger>
                                  
                                  {/* Logs Button */}
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={
                                      <Tooltip id={`tooltip-logs-${job.id}`}>
                                        View job logs
                                      </Tooltip>
                                    }
                                  >
                                    <Button
                                      variant="outline-info"
                                      size="sm"
                                      className="me-1"
                                      onClick={() => handleShowLogs(job)}
                                    >
                                      <FaList />
                                    </Button>
                                  </OverlayTrigger>
                                  
                                  {/* More Actions Dropdown */}
                                  <Dropdown>
                                    <Dropdown.Toggle variant="outline-secondary" size="sm" id={`dropdown-${job.id}`}>
                                      <FaCog />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                      <Dropdown.Item onClick={() => openEditJobModal(job.id)}>
                                        <FaCog className="me-2" /> Edit Job
                                      </Dropdown.Item>
                                      <Dropdown.Item 
                                        onClick={() => handleEnqueueJob(job.id)}
                                        disabled={queueStatuses[job.id]?.isQueued}
                                      >
                                        <FaRocket className="me-2" /> Add to Queue
                                      </Dropdown.Item>
                                      <Dropdown.Item 
                                        onClick={() => handleEnqueueJob(job.id, 10)}
                                        disabled={queueStatuses[job.id]?.isQueued}
                                      >
                                        <FaRocket className="me-2" /> Add to Queue (High Priority)
                                      </Dropdown.Item>
                                      {queueStatuses[job.id]?.isQueued && (
                                        <Dropdown.Item onClick={() => removeFromQueue(job.id).then(triggerRefresh)}>
                                          <FaTrash className="me-2" /> Remove from Queue
                                        </Dropdown.Item>
                                      )}
                                      <Dropdown.Divider />
                                      <Dropdown.Item 
                                        onClick={() => confirmDeleteJob(job.id)} 
                                        className="text-danger"
                                      >
                                        <FaTrash className="me-2" /> Delete Job
                                      </Dropdown.Item>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                    
                    <PaginationControl 
                      currentPage={currentPage}
                      totalPages={totalPages}
                      onPageChange={handlePageChange}
                    />
                  </>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <JobLogsModal
          show={showLogsModal}
          onHide={() => setShowLogsModal(false)}
          jobId={selectedJob?.id}
          jobName={selectedJob?.name}
        />

        <JobFormModal
          show={showJobFormModal}
          onHide={() => setShowJobFormModal(false)}
          jobId={editJobId}
          onJobSaved={triggerRefresh}
        />

        <Modal show={showConfirmDelete} onHide={() => setShowConfirmDelete(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Confirm Delete</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure you want to delete this job? This action cannot be undone.
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowConfirmDelete(false)}>
              Cancel
            </Button>
            <Button variant="danger" onClick={() => handleDeleteJob(jobToDelete)}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>

        <Overlay isOpen={isPanelOpen} onClick={() => setIsPanelOpen(false)} />
        <SlidingPanel isOpen={isPanelOpen}>
          <JobDetailsPanel
            jobId={detailsJobId}
            onClose={() => setIsPanelOpen(false)}
            onAction={handlePanelAction}
          />
        </SlidingPanel>
      </Container>
    </RefreshContext.Provider>
  );
};

export default React.memo(JobDashboard);