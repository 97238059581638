import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import { Button, Form, Dropdown, InputGroup, Badge } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import styled, { createGlobalStyle } from 'styled-components';
import { 
  FaFilter, 
  FaEye, 
  FaCalendarAlt, 
  FaPlus, 
  FaSearch, 
  FaSync, 
  FaTimes
} from 'react-icons/fa';
import { 
  getArticles, 
  getArticleById, 
  createArticle, 
  updateArticle, 
  deleteArticle,
  scrapeArticle,
  analyzeArticle,
  processArticlesByDateRange
} from './apiArticle';

import ArticleSlidingPanel from './ArticleSlidingPanel';
import { 
  ArticleFormModal, 
  ScrapeModal, 
  DateRangeModal, 
  ConfirmationModal, 
  AlertModal 
} from './ArticleModals';

const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    background-color: #f1f3f4;
    color: #202124;
    margin: 0;
    padding: 0;
    overflow: hidden;
  }
`;

const AppContainer = styled.div`
  height: 100vh;
  max-height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

const ContentContainer = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
`;

const HeaderSection = styled.div`
  margin-bottom: 16px;
`;

// Contenedor principal de la tabla con altura explícita
const TableContainer = styled.div`
  flex: 1;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 1px 2px 0 rgba(60,64,67,0.3);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  min-height: 0; /* Importante para que el flex funcione correctamente */
  position: relative;
  height: calc(100vh - 240px); /* Asignar una altura explícita */
`;

// Modificación del DataTable para asegurar que el cuerpo sea scrollable
const StyledDataTable = styled(DataTable)`
  height: 100%;

  .rdt_TableHead {
    font-weight: 500;
    color: #5f6368;
    background-color: #f1f3f4;
    position: sticky !important;
    top: 0 !important;
    z-index: 1;
  }

  .rdt_TableBody {
    overflow-y: auto;
  }

  .rdt_TableCol {
    padding: 16px;
  }

  .rdt_TableCell {
    font-size: 14px;
    padding: 12px 16px;
  }

  .rdt_Pagination {
    color: #5f6368;
    font-size: 14px;
    font-weight: 500;
    padding: 8px 16px;
    background-color: #fff;
    border-top: 1px solid #dadce0;
    position: sticky;
    bottom: 0;
    z-index: 1;
  }
  
  // Asegurar que la tabla ocupe todo el espacio disponible
  .rdt_Table {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  
  // El wrapper de la tabla debe tener altura explícita y scroll
  .rdt_TableWrapper {
    flex: 1;
    overflow-y: auto;
  }
`;

const SlidingPanel = styled.div`
  position: fixed;
  top: 0;
  right: ${props => props.isOpen ? '0' : '-100%'};
  width: 450px;
  height: 100%;
  background-color: white;
  box-shadow: -2px 0 8px rgba(0, 0, 0, 0.15);
  transition: right 0.3s ease-in-out;
  overflow-y: auto;
  z-index: 1000;
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: ${props => props.isOpen ? 'block' : 'none'};
  z-index: 999;
`;

const GoogleButton = styled(Button)`
  background-color: #fff;
  border-color: #dadce0;
  color: #3c4043;
  font-weight: 500;
  padding: 8px 16px;
  font-size: 14px;
  
  &:hover, &:focus {
    background-color: #f1f3f4;
    border-color: #dadce0;
    box-shadow: 0 1px 2px 0 rgba(60,64,67,0.3), 0 1px 3px 1px rgba(60,64,67,0.15);
  }

  &:disabled {
    background-color: #f1f3f4;
    border-color: #dadce0;
    color: #80868b;
  }
`;

const IconButton = styled(GoogleButton)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  margin-right: 8px;
  
  svg {
    margin-right: 8px;
  }
`;

const FilterDropdown = styled(Dropdown)`
  .dropdown-toggle {
    background-color: #fff;
    border-color: #dadce0;
    color: #3c4043;
    font-weight: 500;
    
    &:hover, &:focus {
      background-color: #f1f3f4;
      border-color: #dadce0;
      box-shadow: 0 1px 2px 0 rgba(60,64,67,0.3), 0 1px 3px 1px rgba(60,64,67,0.15);
    }
  }

  .dropdown-menu {
    box-shadow: 0 2px 4px -1px rgba(0,0,0,0.2), 0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12);
    border: none;
    border-radius: 4px;
    padding: 16px;
    min-width: 300px;
  }
`;

const SearchContainer = styled.div`
  max-width: 500px;
  margin-right: 16px;
  width: 100%;
`;

const StyledInputGroup = styled(InputGroup)`
  .form-control {
    border-radius: 24px 0 0 24px;
    border-color: #dadce0;
    padding-left: 16px;
    
    &:focus {
      border-color: #1a73e8;
      box-shadow: none;
    }
  }
  
  .input-group-text {
    background-color: #fff;
    border-color: #dadce0;
    border-left: none;
    border-radius: 0 24px 24px 0;
    padding-right: 16px;
  }
`;

const ActionBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  flex-wrap: wrap;
  gap: 16px;
`;

const FilterBox = styled.div`
  background-color: #fff;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 24px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
`;

const FilterBadge = styled(Badge)`
  background-color: #e8f0fe;
  color: #1967d2;
  font-weight: 500;
  padding: 6px 10px;
  border-radius: 16px;
  margin-right: 8px;
  margin-bottom: 8px;
  display: inline-flex;
  align-items: center;

  .close-icon {
    margin-left: 6px;
    cursor: pointer;
    font-size: 12px;
  }
`;

const LoadingOverlay = styled.div`
  width: 100%;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CircularProgress = styled.div`
  width: 40px;
  height: 40px;
  border: 3px solid #f3f3f3;
  border-top: 3px solid #1a73e8;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

const VisibilityBadge = styled.span`
  display: inline-block;
  padding: 4px 8px;
  border-radius: 12px;
  font-size: 12px;
  font-weight: 500;
  background-color: ${props => props.visible ? '#e6f4ea' : '#fce8e6'};
  color: ${props => props.visible ? '#137333' : '#c5221f'};
`;

const ArticleManager = () => {
  // State
  const [articles, setArticles] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [selectedArticle, setSelectedArticle] = useState(null);
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  
  // Form data state
  const [editArticle, setEditArticle] = useState({});
  
  // Modal states
  const [showArticleFormModal, setShowArticleFormModal] = useState(false);
  const [isCreatingArticle, setIsCreatingArticle] = useState(false);
  const [showScrapeModal, setShowScrapeModal] = useState(false);
  const [showDateRangeModal, setShowDateRangeModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [articleToDelete, setArticleToDelete] = useState(null);
  const [alertModal, setAlertModal] = useState({ show: false, title: '', message: '', variant: 'success' });
  
  // Filters
  const [filters, setFilters] = useState({
    searchTerm: '',
    siteId: '',
    visible: '',
  });
  
  // Active filters display
  const [activeFilters, setActiveFilters] = useState([]);
  
  // Refs
  const panelRef = useRef(null);
  const searchInputRef = useRef(null);
  
  // Utility functions
  const showAlert = useCallback((title, message, variant = 'success') => {
    setAlertModal({ show: true, title, message, variant });
  }, []);

  const closeAlert = useCallback(() => {
    setAlertModal({ show: false, title: '', message: '', variant: 'success' });
  }, []);
  
  // Initial data fetch
  const fetchArticlesData = useCallback(async () => {
    setLoading(true);
    try {
      const params = {
        page: page,
        size: perPage,
        searchTerm: filters.searchTerm || undefined,
        siteId: filters.siteId || undefined,
        visible: filters.visible || undefined,
      };

      const response = await getArticles(params);
      
      // Check if the response has the expected structure
      if (response && response.data) {
        setArticles(response.data.items || []);
        setTotalRows(response.data.total || 0);
      } else {
        // Handle unexpected response format
        console.warn('Unexpected API response format:', response);
        setArticles([]);
        setTotalRows(0);
      }
    } catch (error) {
      console.error('Error fetching articles:', error);
      showAlert('Error', 'Failed to fetch articles', 'danger');
      setArticles([]);
      setTotalRows(0);
    } finally {
      setLoading(false);
    }
  }, [page, perPage, filters, showAlert]);

  useEffect(() => {
    fetchArticlesData();
  }, [fetchArticlesData]);
  
  // Update active filters display
  useEffect(() => {
    const newActiveFilters = [];
    
    if (filters.searchTerm) {
      newActiveFilters.push({
        key: 'searchTerm',
        label: 'Search',
        value: filters.searchTerm
      });
    }
    
    if (filters.siteId) {
      newActiveFilters.push({
        key: 'siteId',
        label: 'Site ID',
        value: filters.siteId
      });
    }
    
    if (filters.visible) {
      newActiveFilters.push({
        key: 'visible',
        label: 'Visibility',
        value: filters.visible === 'true' ? 'Visible' : 'Hidden'
      });
    }
    
    setActiveFilters(newActiveFilters);
  }, [filters]);
  
  // Utility function handlers
  const handleSearchChange = (e) => {
    const searchTerm = e.target.value;
    setFilters(prev => ({ ...prev, searchTerm }));
  };
  
  const handleSearchSubmit = (e) => {
    e.preventDefault();
    setPage(0);
    fetchArticlesData();
  };
  
  const handleFilterChange = (filterName, value) => {
    setFilters(prev => ({
      ...prev,
      [filterName]: value
    }));
    setPage(0);
    setResetPaginationToggle(!resetPaginationToggle);
    
    // Fetch data immediately when filter changes
    setTimeout(() => {
      fetchArticlesData();
    }, 0);
  };
  
  const removeFilter = (filterKey) => {
    setFilters(prev => ({
      ...prev,
      [filterKey]: ''
    }));
    setPage(0);
    setResetPaginationToggle(!resetPaginationToggle);
    
    // Fetch data immediately when filter is removed
    setTimeout(() => {
      fetchArticlesData();
    }, 0);
  };
  
  const clearFilters = () => {
    setFilters({
      searchTerm: '',
      siteId: '',
      visible: '',
    });
    setPage(0);
    setResetPaginationToggle(!resetPaginationToggle);
    
    // Fetch data immediately when filters are cleared
    setTimeout(() => {
      fetchArticlesData();
    }, 0);
  };

  // Table pagination handlers
  const handlePageChange = (newPage) => {
    setPage(newPage - 1);
  };

  const handlePerRowsChange = async (newPerPage) => {
    setPerPage(newPerPage);
    setPage(0); // Reset to first page when changing rows per page
  };
  
  // Row click handler
  const handleRowClick = useCallback(async (row) => {
    try {
      const articleId = getArticleId(row);
      if (!articleId) {
        console.error('Error: Could not determine article ID for viewing');
        showAlert('Error', 'Could not determine article ID for viewing', 'danger');
        return;
      }
      
      // Si ya tenemos toda la información necesaria, podemos usar directamente el artículo
      if (row.body && row.articleAnalysis) {
        setSelectedArticle(row);
        setIsPanelOpen(true);
        return;
      }
      
      // Si necesitamos obtener información adicional
      setLoading(true);
      const response = await getArticleById(articleId);
      if (response && response.data) {
        setSelectedArticle(response.data);
        setIsPanelOpen(true);
      } else {
        showAlert('Error', 'Failed to load article details', 'danger');
      }
    } catch (error) {
      console.error('Error fetching article details:', error);
      showAlert('Error', 'Failed to load article details', 'danger');
    } finally {
      setLoading(false);
    }
  }, [showAlert]);
  
  // Panel handlers
  const closeSlidingPanel = () => {
    setIsPanelOpen(false);
    setSelectedArticle(null);
  };

  const handleOutsideClick = (event) => {
    if (panelRef.current && !panelRef.current.contains(event.target)) {
      closeSlidingPanel();
    }
  };
  
  // Article form modal handlers
  const openCreateArticleModal = () => {
    setEditArticle({
      visible: true,
      siteId: '',
      title: '',
      link: '',
      image: '',
      commentSetting: {
        canComment: true,
        commentLimit: null
      }
    });
    setIsCreatingArticle(true);
    setShowArticleFormModal(true);
  };
  
  const openEditArticleModal = (article) => {
    setEditArticle({
      ...article,
      // Ensure properties are not undefined
      visible: article.visible !== undefined ? article.visible : true,
      commentSetting: article.commentSetting || {
        canComment: true,
        commentLimit: null
      }
    });
    setIsCreatingArticle(false);
    setShowArticleFormModal(true);
  };
  
  const closeArticleFormModal = () => {
    setShowArticleFormModal(false);
    setEditArticle({});
  };
  
  const handleSaveArticle = async () => {
    try {
      setLoading(true);
      if (isCreatingArticle) {
        await createArticle(editArticle);
        showAlert('Success', 'Article created successfully', 'success');
      } else {
        await updateArticle(editArticle.id, editArticle);
        showAlert('Success', 'Article updated successfully', 'success');
      }
      closeArticleFormModal();
      fetchArticlesData();
    } catch (error) {
      console.error('Error saving article:', error);
      showAlert('Error', 'Failed to save article', 'danger');
    } finally {
      setLoading(false);
    }
  };
  
  // Article actions handlers
  const openScrapeModal = (article) => {
    if (!article || !article.articleRssId) {
      console.error('Error: No article ID available for scraping');
      showAlert('Error', 'No article ID available for scraping', 'danger');
      return;
    }
    console.log('Opening scrape modal for article with ID:', article.articleRssId);
    setSelectedArticle(article);
    setShowScrapeModal(true);
  };
  
  const closeScrapeModal = () => {
    setShowScrapeModal(false);
  };
  
  const handleScrapeArticle = async (articleRssId) => {
    if (!articleRssId) {
      console.error('Error: No article RSS ID provided for scraping');
      showAlert('Error', 'No article RSS ID provided for scraping', 'danger');
      return;
    }
    
    try {
      setLoading(true);
      console.log('Scraping article with RSS ID:', articleRssId);
      await scrapeArticle(articleRssId);
      showAlert('Success', 'Article scraped successfully', 'success');
      closeScrapeModal();
      
      // Refresh the article data if needed
      if (selectedArticle) {
        try {
          const updatedArticle = await getArticleById(articleRssId);
          setSelectedArticle(updatedArticle.data);
        } catch (refreshError) {
          console.error('Error refreshing article data:', refreshError);
        }
      }
    } catch (error) {
      console.error('Error scraping article:', error);
      showAlert('Error', `Failed to scrape article content: ${error.message || 'Unknown error'}`, 'danger');
    } finally {
      setLoading(false);
    }
  };
  
  const handleAnalyzeArticle = async (article) => {
    const articleRssId = article.articleRssId;
    if (!articleRssId) {
      console.error('Error: No article RSS ID provided for analysis');
      showAlert('Error', 'No article RSS ID provided for analysis', 'danger');
      return;
    }
  
    try {
      setLoading(true);
      await analyzeArticle(articleRssId);
      showAlert('Success', 'Article analyzed successfully', 'success');
      
      // Refresh the article data
      const updatedArticle = await getArticleById(articleRssId);
      setSelectedArticle(updatedArticle.data);
    } catch (error) {
      console.error('Error analyzing article:', error);
      showAlert('Error', 'Failed to analyze article', 'danger');
    } finally {
      setLoading(false);
    }
  };
  
  const openDateRangeModal = () => {
    setShowDateRangeModal(true);
  };
  
  const closeDateRangeModal = () => {
    setShowDateRangeModal(false);
  };
  
  const handleProcessDateRange = async (startDate, endDate) => {
    try {
      setLoading(true);
      await processArticlesByDateRange(startDate, endDate);
      showAlert('Success', 'Articles processed successfully', 'success');
      fetchArticlesData();
    } catch (error) {
      console.error('Error processing articles:', error);
      showAlert('Error', 'Failed to process articles by date range', 'danger');
    } finally {
      setLoading(false);
    }
  };
  
  // Delete article handlers
  const openDeleteModal = (article) => {
    setArticleToDelete(article);
    setShowDeleteModal(true);
  };
  
  const closeDeleteModal = () => {
    setShowDeleteModal(false);
    setArticleToDelete(null);
  };
  
  const handleDeleteArticle = async () => {
    if (!articleToDelete) return;
    
    try {
      setLoading(true);
      await deleteArticle(articleToDelete.id);
      showAlert('Success', 'Article deleted successfully', 'success');
      closeDeleteModal();
      
      // If the deleted article is currently selected, close panel
      if (selectedArticle && selectedArticle.id === articleToDelete.id) {
        closeSlidingPanel();
      }
      
      fetchArticlesData();
    } catch (error) {
      console.error('Error deleting article:', error);
      showAlert('Error', 'Failed to delete article', 'danger');
    } finally {
      setLoading(false);
    }
  };
  
  const handleToggleVisibility = async (article) => {
    try {
      setLoading(true);
      const updatedArticle = {
        ...article,
        visible: !article.visible
      };
      
      await updateArticle(article.id, updatedArticle);
      
      showAlert(
        'Success', 
        `Article ${updatedArticle.visible ? 'visible' : 'hidden'} successfully`, 
        'success'
      );
      
      // If the updated article is currently selected, update it
      if (selectedArticle && selectedArticle.id === article.id) {
        setSelectedArticle(updatedArticle);
      }
      
      fetchArticlesData();
    } catch (error) {
      console.error('Error toggling visibility:', error);
      showAlert('Error', 'Failed to update article visibility', 'danger');
    } finally {
      setLoading(false);
    }
  };

  const getArticleId = (article) => {
    if (!article) return null;
    
    // Si el ID está directamente en el artículo
    if (article.id) {
      return article.id;
    }
    
    // Si el ID está en el objeto _id
    if (article._id) {
      // Si _id es un string
      if (typeof article._id === 'string') {
        return article._id;
      }
      
      // Si _id es un objeto (como en MongoDB)
      if (typeof article._id === 'object') {
        return article._id.toString();
      }
    }
    
    return null;
  };
  
  // Truncate text utility
  const truncateText = (text, maxLength = 100) => {
    if (!text) return '';
    return text.length > maxLength ? `${text.substring(0, maxLength)}...` : text;
  };
  
  const formatDate = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    return date.toLocaleDateString();
  };
  
  // Table columns
  const columns = useMemo(() => [
    {
      name: 'Image',
      selector: row => row.image,
      sortable: false,
      width: '80px',
      cell: row => (
        <div style={{ margin: '5px 0' }}>
          {row.image ? (
            <img 
              src={row.image} 
              alt={row.title} 
              style={{ 
                width: '60px', 
                height: '60px', 
                objectFit: 'cover',
                borderRadius: '4px'
              }}
            />
          ) : (
            <div 
              style={{ 
                width: '60px', 
                height: '60px', 
                backgroundColor: '#f1f3f4',
                borderRadius: '4px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <FaCalendarAlt color="#5f6368" />
            </div>
          )}
        </div>
      ),
    },
    {
      name: 'Title',
      selector: row => row.title,
      sortable: true,
      wrap: true,
      cell: row => (
        <div>
          <div style={{ fontWeight: 'bold' }}>{truncateText(row.title, 50)}</div>
          <small style={{ color: '#666' }}>{truncateText(row.body, 80)}</small>
          <div style={{ fontSize: '12px', color: '#5f6368', marginTop: '4px' }}>
            <span style={{ display: 'inline-block', marginRight: '10px' }}>
              <strong>ID:</strong> {row.id || 'N/A'}
            </span>
            <span style={{ display: 'inline-block' }}>
              <strong>RSS ID:</strong> {row.articleRssId || 'N/A'}
            </span>
          </div>
        </div>
      ),
      grow: 2
    },
    {
      name: 'Site',
      selector: row => row.siteName,
      sortable: true,
      hide: 'sm'
    },
    {
      name: 'Date',
      selector: row => row.publicationDate,
      sortable: true,
      format: row => formatDate(row.publicationDate),
      hide: 'md'
    },
    {
      name: 'Status',
      selector: row => row.visible,
      sortable: true,
      center: true,
      cell: row => (
        <VisibilityBadge visible={row.visible}>
          {row.visible ? 'Visible' : 'Hidden'}
        </VisibilityBadge>
      ),
    },
    {
      name: 'Actions',
      cell: row => (
        <IconButton onClick={(e) => { 
          e.stopPropagation(); 
          handleRowClick(row); 
        }}>
          <FaEye /> View
        </IconButton>
      ),
      button: true,
      center: true
    }
  ], [handleRowClick]);

  // Estilos personalizados explícitos para la tabla
  const customStyles = {
    headRow: {
      style: {
        borderTop: 'none',
        borderBottom: '1px solid #dadce0',
      },
    },
    headCells: {
      style: {
        paddingLeft: '16px',
        paddingRight: '16px',
      },
    },
    rows: {
      style: {
        minHeight: '56px',
        borderBottom: '1px solid #f1f3f4',
        '&:hover': {
          backgroundColor: '#f8f9fa',
        },
      },
    },
    noData: {
      style: {
        padding: '24px',
        color: '#5f6368',
        backgroundColor: 'white',
      },
    },
  };
  
  return (
    <AppContainer>
      <GlobalStyle />
      <ContentContainer>
        <HeaderSection className="d-flex justify-content-between align-items-center">
          <h1 className="mb-0">Article Management</h1>
          <div className="d-flex gap-3">
            <IconButton onClick={openDateRangeModal}>
              <FaCalendarAlt /> Process by Date
            </IconButton>
            <IconButton variant="primary" onClick={openCreateArticleModal} style={{ backgroundColor: '#1a73e8', color: 'white' }}>
              <FaPlus /> New Article
            </IconButton>
          </div>
        </HeaderSection>
        
        <ActionBar>
          <div className="d-flex flex-grow-1">
            <SearchContainer>
              <form onSubmit={handleSearchSubmit}>
                <StyledInputGroup>
                  <Form.Control
                    placeholder="Search articles..."
                    value={filters.searchTerm}
                    onChange={handleSearchChange}
                    ref={searchInputRef}
                  />
                  <Button 
                    variant="outline-secondary"
                    type="submit"
                    style={{ 
                      border: 'none', 
                      borderTopRightRadius: '24px', 
                      borderBottomRightRadius: '24px' 
                    }}
                  >
                    <FaSearch color="#5f6368" />
                  </Button>
                </StyledInputGroup>
              </form>
            </SearchContainer>
            
            <FilterDropdown>
              <Dropdown.Toggle variant="outline-secondary" id="dropdown-filters">
                <FaFilter /> Filters
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Form>
                  <Form.Group className="mb-3">
                    <Form.Label>Site ID</Form.Label>
                    <Form.Control
                      type="text"
                      value={filters.siteId}
                      onChange={(e) => handleFilterChange('siteId', e.target.value)}
                      placeholder="Enter site ID"
                    />
                  </Form.Group>
                  
                  <Form.Group className="mb-3">
                    <Form.Label>Visibility</Form.Label>
                    <Form.Select
                      value={filters.visible}
                      onChange={(e) => handleFilterChange('visible', e.target.value)}
                    >
                      <option value="">All</option>
                      <option value="true">Visible</option>
                      <option value="false">Hidden</option>
                    </Form.Select>
                  </Form.Group>
                  
                  <div className="d-flex justify-content-end">
                    <GoogleButton onClick={clearFilters}>Clear Filters</GoogleButton>
                  </div>
                </Form>
              </Dropdown.Menu>
            </FilterDropdown>
          </div>
          
          <div>
            <IconButton className="me-2" onClick={fetchArticlesData}>
              <FaSync /> Refresh
            </IconButton>
          </div>
        </ActionBar>
        
        {/* Active Filters Display */}
        {activeFilters.length > 0 && (
          <FilterBox>
            <div className="d-flex justify-content-between align-items-center mb-2">
              <h6 className="m-0">Active Filters:</h6>
              <GoogleButton size="sm" onClick={clearFilters}>Clear All</GoogleButton>
            </div>
            <div>
              {activeFilters.map((filter) => (
                <FilterBadge key={filter.key}>
                  {filter.label}: {filter.value}
                  <span 
                    className="close-icon" 
                    onClick={() => removeFilter(filter.key)}
                  >
                    <FaTimes size={12} style={{ marginLeft: '8px', cursor: 'pointer' }} />
                  </span>
                </FilterBadge>
              ))}
            </div>
          </FilterBox>
        )}
        
        <TableContainer>
          <StyledDataTable
            columns={columns}
            data={articles}
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            paginationPerPage={perPage}
            paginationDefaultPage={page + 1}
            paginationResetDefaultPage={resetPaginationToggle}
            paginationRowsPerPageOptions={[10, 25, 50, 100]}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handlePerRowsChange}
            selectableRows
            progressPending={loading}
            progressComponent={
              <LoadingOverlay>
                <CircularProgress />
              </LoadingOverlay>
            }
            fixedHeader
            fixedHeaderScrollHeight="calc(100vh - 200px)"
            pointerOnHover
            highlightOnHover
            onRowClicked={handleRowClick}
            customStyles={customStyles}
            noDataComponent={
              <div style={{ padding: '24px', textAlign: 'center', color: '#5f6368' }}>
                {loading ? 'Loading articles...' : 'No articles found'}
              </div>
            }
          />
        </TableContainer>
      </ContentContainer>
      
      {/* Sliding Panel */}
      <Overlay isOpen={isPanelOpen} onClick={handleOutsideClick} />
      <SlidingPanel isOpen={isPanelOpen} ref={panelRef}>
        <ArticleSlidingPanel
          selectedArticle={selectedArticle}
          handleOpenEditModal={openEditArticleModal}
          handleDelete={openDeleteModal}
          handleScrape={openScrapeModal}
          handleAnalyze={handleAnalyzeArticle}
          handleVisibilityToggle={handleToggleVisibility}
          onClose={closeSlidingPanel}
        />
      </SlidingPanel>
      
      {/* Modals */}
      <ArticleFormModal
        show={showArticleFormModal}
        onHide={closeArticleFormModal}
        article={editArticle}
        setArticle={setEditArticle}
        handleSave={handleSaveArticle}
        isCreating={isCreatingArticle}
      />
      
      <ScrapeModal 
        show={showScrapeModal}
        onHide={closeScrapeModal}
        article={selectedArticle} // Pasa todo el objeto por si necesitas más información
        handleScrape={() => selectedArticle && handleScrapeArticle(selectedArticle.articleRssId)}
      />
      
      <DateRangeModal
        show={showDateRangeModal}
        onHide={closeDateRangeModal}
        handleProcess={handleProcessDateRange}
      />
      
      <ConfirmationModal
        show={showDeleteModal}
        onHide={closeDeleteModal}
        title="Delete Article"
        message="Are you sure you want to delete this article? This action cannot be undone."
        confirmText="Delete"
        handleConfirm={handleDeleteArticle}
        variant="danger"
      />
      
      <AlertModal
        show={alertModal.show}
        onHide={closeAlert}
        title={alertModal.title}
        message={alertModal.message}
        variant={alertModal.variant}
      />
    </AppContainer>
  );
};

export default ArticleManager