import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Alert, Tab, Tabs } from 'react-bootstrap';
import styled from 'styled-components';
import { FaEdit, FaTrash, FaCalendarAlt } from 'react-icons/fa';

// Styled components for modals
const StyledModal = styled(Modal)`
  .modal-header {
    border-bottom: 1px solid #dadce0;
    padding: 16px 24px;
  }
  
  .modal-title {
    font-size: 18px;
    font-weight: 500;
    color: #202124;
  }
  
  .modal-body {
    padding: 24px;
  }
  
  .modal-footer {
    border-top: 1px solid #dadce0;
    padding: 16px 24px;
  }
`;

const GoogleButton = styled(Button)`
  background-color: ${props => props.variant === 'primary' ? '#1a73e8' : '#fff'};
  border-color: ${props => props.variant === 'primary' ? '#1a73e8' : '#dadce0'};
  color: ${props => props.variant === 'primary' ? '#fff' : '#3c4043'};
  font-weight: 500;
  padding: 8px 16px;
  font-size: 14px;
  
  &:hover, &:focus {
    background-color: ${props => props.variant === 'primary' ? '#1765cc' : '#f1f3f4'};
    border-color: ${props => props.variant === 'primary' ? '#1765cc' : '#dadce0'};
    box-shadow: 0 1px 2px 0 rgba(60,64,67,0.3), 0 1px 3px 1px rgba(60,64,67,0.15);
  }

  &:disabled {
    background-color: ${props => props.variant === 'primary' ? '#8ab4f8' : '#f1f3f4'};
    border-color: ${props => props.variant === 'primary' ? '#8ab4f8' : '#dadce0'};
    color: ${props => props.variant === 'primary' ? '#fff' : '#80868b'};
  }
`;

const DangerButton = styled(GoogleButton)`
  background-color: ${props => props.variant === 'danger' ? '#ea4335' : '#fce8e6'};
  border-color: ${props => props.variant === 'danger' ? '#ea4335' : '#fce8e6'};
  color: ${props => props.variant === 'danger' ? '#fff' : '#c5221f'};
  
  &:hover, &:focus {
    background-color: ${props => props.variant === 'danger' ? '#d93025' : '#fad2cf'};
    border-color: ${props => props.variant === 'danger' ? '#d93025' : '#fad2cf'};
  }
`;

const SummaryCard = styled.div`
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 16px;
  border: 1px solid #e8eaed;
  position: relative;
`;

const EditButton = styled.button`
  position: absolute;
  top: 8px;
  right: 8px;
  background: none;
  border: none;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  color: #5f6368;
  
  &:hover {
    background-color: #e8eaed;
  }
`;

const SummaryCardTitle = styled.h5`
  margin-top: 0;
  margin-bottom: 8px;
  font-size: 16px;
  font-weight: 500;
  color: #202124;
`;

const SummaryCardText = styled.p`
  margin-bottom: 16px;
  font-size: 14px;
  color: #5f6368;
`;

// Alert Modal
export const AlertModal = ({ show, onHide, title, message, variant = 'success' }) => (
  <StyledModal show={show} onHide={onHide} centered>
    <Modal.Header closeButton>
      <Modal.Title>{title}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <Alert variant={variant}>{message}</Alert>
    </Modal.Body>
    <Modal.Footer>
      <GoogleButton variant="primary" onClick={onHide}>Close</GoogleButton>
    </Modal.Footer>
  </StyledModal>
);

// Update Summary Modal
export const UpdateSummaryModal = ({ show, onHide, summary, onUpdate }) => {
  const [dayIntensity, setDayIntensity] = useState(5);
  const [sentimentScore, setSentimentScore] = useState(0);
  const [activeTab, setActiveTab] = useState('general');

  useEffect(() => {
    if (summary) {
      setDayIntensity(summary.day_intensity || 5);
      setSentimentScore(summary.sentiment_score || 0);
    }
  }, [summary, show]);

  const handleSubmit = () => {
    if (summary?.id) {
      const summaryData = {
        day_intensity: dayIntensity,
        sentiment_score: sentimentScore
      };
      
      onUpdate(summary.id, summaryData);
    }
  };

  if (!summary) return null;

  return (
    <StyledModal show={show} onHide={onHide} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Update Daily Summary</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Tabs
          activeKey={activeTab}
          onSelect={(k) => setActiveTab(k)}
          className="mb-3"
        >
          <Tab eventKey="general" title="General Information">
            <Form>
              <div className="mb-3">
                <Form.Group>
                  <Form.Label>Date</Form.Label>
                  <Form.Control
                    type="text"
                    value={new Date(summary.date).toLocaleDateString()}
                    disabled
                  />
                </Form.Group>
              </div>
              
              <Form.Group className="mb-3">
                <Form.Label>Day Intensity (1-10): {dayIntensity}</Form.Label>
                <Form.Range
                  min={1}
                  max={10}
                  value={dayIntensity}
                  onChange={(e) => setDayIntensity(parseInt(e.target.value))}
                />
                <small className="text-muted">
                  This represents how intense or impactful the day's news was overall.
                </small>
              </Form.Group>
              
              <Form.Group className="mb-3">
                <Form.Label>Sentiment Score (-10 to +10): {sentimentScore}</Form.Label>
                <Form.Range
                  min={-10}
                  max={10}
                  value={sentimentScore}
                  onChange={(e) => setSentimentScore(parseInt(e.target.value))}
                />
                <small className="text-muted">
                  Negative values represent negative sentiment, positive values represent positive sentiment.
                </small>
              </Form.Group>
            </Form>
            
            <Alert variant="info">
              Note: Individual news items and categories cannot be edited directly in this interface.
            </Alert>
          </Tab>
          
          <Tab eventKey="categories" title="Categories & News">
            <div className="mb-3">
              <strong>Relevant News</strong> ({summary.relevant_news?.length || 0} articles)
              <div className="mt-2 mb-3">
                <ul className="list-group">
                  {summary.relevant_news?.map((item, index) => (
                    <li key={index} className="list-group-item">
                      {item.title}
                    </li>
                  ))}
                </ul>
              </div>
            
              <strong>Categories</strong> ({summary.daily_summary?.length || 0} categories)
              <div className="mt-2">
                {summary.daily_summary?.map((category, index) => (
                  <SummaryCard key={index}>
                    <SummaryCardTitle>{category.headline}</SummaryCardTitle>
                    <SummaryCardText>{category.summary_text}</SummaryCardText>
                    <strong>Articles:</strong> {category.important_news?.length || 0}
                  </SummaryCard>
                ))}
              </div>
            </div>
          </Tab>
        </Tabs>
      </Modal.Body>
      <Modal.Footer>
        <GoogleButton onClick={onHide}>Cancel</GoogleButton>
        <GoogleButton 
          variant="primary" 
          onClick={handleSubmit}
        >
          Update
        </GoogleButton>
      </Modal.Footer>
    </StyledModal>
  );
};

// Delete Summary Modal
export const DeleteSummaryModal = ({ show, onHide, summary, onDelete }) => {
  const handleDelete = () => {
    if (summary?.id) {
      onDelete(summary.id);
    }
  };

  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    return new Date(dateString).toLocaleDateString();
  };

  return (
    <StyledModal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>Delete Daily Summary</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Are you sure you want to delete the daily summary for <strong>{formatDate(summary?.date)}</strong>?</p>
        <Alert variant="danger">
          This action cannot be undone. All information related to this daily summary will be permanently removed.
        </Alert>
      </Modal.Body>
      <Modal.Footer>
        <GoogleButton onClick={onHide}>Cancel</GoogleButton>
        <DangerButton variant="danger" onClick={handleDelete}>Delete</DangerButton>
      </Modal.Footer>
    </StyledModal>
  );
};

// Generate Summary Modal
export const GenerateSummaryModal = ({ show, onHide, onGenerate }) => {
  const [date, setDate] = useState('');
  const [isValidDate, setIsValidDate] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);
  const [generationError, setGenerationError] = useState('');

  useEffect(() => {
    if (show) {
      // Default to today's date
      const today = new Date();
      const formattedDate = today.toISOString().split('T')[0];
      setDate(formattedDate);
      setIsValidDate(true);
      setIsGenerating(false);
      setGenerationError('');
    }
  }, [show]);

  const handleSubmit = async () => {
    if (isValidDate) {
      setIsGenerating(true);
      setGenerationError('');
      
      try {
        // This will now wait until generation is complete
        await onGenerate(date);
        onHide();
      } catch (error) {
        setGenerationError(error.message || 'An error occurred during summary generation');
      } finally {
        setIsGenerating(false);
      }
    }
  };

  const handleDateChange = (e) => {
    const value = e.target.value;
    setDate(value);
    setIsValidDate(!!value);
  };

  return (
    <StyledModal show={show} onHide={isGenerating ? null : onHide} centered backdrop="static" keyboard={!isGenerating}>
      <Modal.Header closeButton={!isGenerating}>
        <Modal.Title>Generate Daily Summary</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3">
            <Form.Label>Date</Form.Label>
            <Form.Control
              type="date"
              value={date}
              onChange={handleDateChange}
              required
              disabled={isGenerating}
            />
            <Form.Text className="text-muted">
              Select the date for which you want to generate a daily summary.
            </Form.Text>
          </Form.Group>
        </Form>
        
        {generationError && (
          <Alert variant="danger" className="mt-3">
            {generationError}
          </Alert>
        )}
        
        {isGenerating ? (
          <div className="text-center my-4">
            <div className="d-flex align-items-center justify-content-center mb-3">
              <div 
                className="spinner-border text-primary" 
                role="status" 
                style={{ width: '3rem', height: '3rem' }}
              >
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
            <p className="mb-0">
              Generating daily summary for {new Date(date).toLocaleDateString()}...
            </p>
            <p className="text-muted small mt-2">
              This process may take a few minutes as we analyze articles and generate a comprehensive summary.
            </p>
          </div>
        ) : (
          <Alert variant="info">
            <div className="d-flex align-items-center mb-2">
              <FaCalendarAlt className="me-2" />
              <strong>How it works</strong>
            </div>
            <p className="mb-0">
              This will generate a daily summary for the selected date by analyzing all articles published on that date. The process may take a few minutes to complete, and you'll need to wait until it's finished.
            </p>
          </Alert>
        )}
      </Modal.Body>
      <Modal.Footer>
        {!isGenerating && (
          <>
            <GoogleButton onClick={onHide}>Cancel</GoogleButton>
            <GoogleButton 
              variant="primary" 
              onClick={handleSubmit}
              disabled={!isValidDate}
            >
              Generate
            </GoogleButton>
          </>
        )}
        {isGenerating && (
          <p className="text-muted small mb-0">Please wait while we generate the summary...</p>
        )}
      </Modal.Footer>
    </StyledModal>
  );
};