import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Modal, Form, Button, Card, Spinner, Nav, Tab } from 'react-bootstrap';
import { FaPlus, FaTrash, FaEdit, FaUpload, FaHashtag, FaLink, FaRobot, FaMagic, FaMarkdown } from 'react-icons/fa';
import ReactMarkdown from 'react-markdown';
import { 
  createPublication, 
  updatePublication, 
  changePublicationStatus,
  uploadImage,
  removeImage,
  enhanceTextWithAI
} from './apiPublication';

const GoogleModal = styled(Modal)`
  .modal-content {
    border-radius: 8px;
    border: none;
    box-shadow: 0 1px 3px 0 rgba(60,64,67,0.3), 0 4px 8px 3px rgba(60,64,67,0.15);
  }

  .modal-header {
    border-bottom: 1px solid #e8eaed;
    padding: 24px 24px 20px;
  }

  .modal-title {
    font-size: 22px;
    color: #202124;
    font-weight: 400;
  }

  .modal-body {
    padding: 24px;
  }

  .modal-footer {
    border-top: 1px solid #e8eaed;
    padding: 20px 24px 24px;
  }
`;

const GoogleForm = styled(Form)`
  .form-label {
    font-weight: 500;
    color: #5f6368;
    margin-bottom: 8px;
  }

  .form-control {
    border-radius: 4px;
    border: 1px solid #dadce0;
    padding: 8px 12px;
    font-size: 14px;
    
    &:focus {
      border-color: #1a73e8;
      box-shadow: 0 0 0 2px rgba(26,115,232,0.25);
    }
  }
`;

const GoogleButton = styled(Button)`
  background-color: #1a73e8;
  border-color: #1a73e8;
  color: #fff;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  padding: 8px 24px;
  font-size: 14px;
  
  &:hover, &:focus {
    background-color: #1765cc;
    border-color: #1765cc;
    box-shadow: 0 1px 2px 0 rgba(60,64,67,0.3), 0 1px 3px 1px rgba(60,64,67,0.15);
  }
`;

const EnhanceButton = styled(Button)`
  background-color: #34a853;
  border-color: #34a853;
  color: #fff;
  font-weight: 500;
  letter-spacing: 0.5px;
  padding: 4px 12px;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 6px;
  
  &:hover, &:focus {
    background-color: #2e8b47;
    border-color: #2e8b47;
    box-shadow: 0 1px 2px 0 rgba(60,64,67,0.3), 0 1px 3px 1px rgba(60,64,67,0.15);
  }
`;

const ImageContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 12px;
  margin-top: 16px;
`;

const ImageWrapper = styled.div`
  position: relative;
`;

const Image = styled.img`
  width: 100%;
  height: 150px;
  object-fit: cover;
  border-radius: 4px;
`;

const DeleteButton = styled(Button)`
  position: absolute;
  top: 8px;
  right: 8px;
  padding: 4px 8px;
  background-color: rgba(255, 0, 0, 0.8);
  border: none;
  border-radius: 4px;
`;

const PreviewImage = styled.div`
  position: relative;
  width: 100%;
  height: 150px;
  border-radius: 4px;
  overflow: hidden;
  
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const DropArea = styled.div`
  border: 2px dashed ${props => props.isDragActive ? '#1a73e8' : '#dadce0'};
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  background-color: ${props => props.isDragActive ? 'rgba(26,115,232,0.1)' : '#f8f9fa'};
  transition: all 0.3s ease;
  cursor: pointer;
  margin-top: 10px;
  
  &:hover {
    border-color: #1a73e8;
    background-color: rgba(26,115,232,0.05);
  }
`;

const UploadIcon = styled(FaUpload)`
  font-size: 32px;
  color: #5f6368;
  margin-bottom: 12px;
`;

const UploadText = styled.div`
  color: #5f6368;
  margin-bottom: 8px;
`;

const UploadSubText = styled.div`
  color: #80868b;
  font-size: 12px;
`;

const SubmitButton = styled(GoogleButton)`
  min-width: 120px;
  
  &:disabled {
    background-color: #c1d1f0;
    border-color: #c1d1f0;
  }
`;

const TagContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 8px;
`;

const Tag = styled.div`
  background-color: #e8f0fe;
  color: #1a73e8;
  padding: 4px 12px;
  border-radius: 16px;
  font-size: 14px;
  display: flex;
  align-items: center;
  
  button {
    background: none;
    border: none;
    color: #1a73e8;
    font-size: 12px;
    margin-left: 6px;
    padding: 0;
    cursor: pointer;
    
    &:hover {
      color: #174ea6;
    }
  }
`;

const EditorTabs = styled(Nav)`
  margin-bottom: 10px;
  
  .nav-link {
    color: #5f6368;
    padding: 8px 16px;
    border-radius: 4px 4px 0 0;
    
    &.active {
      color: #1a73e8;
      border-bottom: 2px solid #1a73e8;
      font-weight: 500;
    }
    
    &:hover:not(.active) {
      background-color: #f8f9fa;
    }
  }
`;

const MarkdownPreview = styled.div`
  border: 1px solid #dadce0;
  border-radius: 4px;
  padding: 12px;
  min-height: 300px;
  background-color: #f8f9fa;
  overflow-y: auto;
  font-size: 14px;
  
  h1, h2, h3, h4, h5, h6 {
    margin-top: 16px;
    margin-bottom: 8px;
    color: #202124;
  }
  
  ul, ol {
    padding-left: 24px;
  }
  
  code {
    background-color: #e8eaed;
    padding: 2px 4px;
    border-radius: 4px;
  }
  
  blockquote {
    border-left: 4px solid #dadce0;
    padding-left: 16px;
    color: #5f6368;
  }
  
  img {
    max-width: 100%;
  }
  
  table {
    border-collapse: collapse;
    width: 100%;
    margin: 16px 0;
    
    th, td {
      border: 1px solid #dadce0;
      padding: 8px;
    }
    
    th {
      background-color: #f1f3f4;
    }
  }
`;

const EnhanceButtonGroup = styled.div`
  display: flex;
  gap: 8px;
  margin-bottom: 8px;
`;

export const CreateEditModal = ({ show, onHide, publication, onSuccess, types }) => {
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    body: '',
    type: '',
    author_name: '',
    status: 'DRAFT',
    hashtags: [],
    links: []
  });
  
  const [validated, setValidated] = useState(false);
  const [isDragActive, setIsDragActive] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);
  const [imageFiles, setImageFiles] = useState([]);
  const [imagesToDelete, setImagesToDelete] = useState([]);
  const [currentHashtag, setCurrentHashtag] = useState('');
  const [currentLink, setCurrentLink] = useState('');
  const [isEnhancingText, setIsEnhancingText] = useState(false);
  const [activeEditorTab, setActiveEditorTab] = useState('edit');
  const fileInputRef = useRef(null);
  const [isArticle, setIsArticle] = useState(false);

  useEffect(() => {
    if (publication) {
      setFormData({
        title: publication.title || '',
        description: publication.description || '',
        body: publication.body || '',
        type: publication.type || '',
        author_name: publication.author_name || '',
        status: publication.status || 'DRAFT',
        hashtags: publication.hashtags || [],
        links: publication.links || [],
        images_to_delete: []
      });
      
      // Si hay imágenes existentes, mostrarlas en la vista previa
      if (publication.images && publication.images.length > 0) {
        setSelectedImages(publication.images.map(url => ({
          url,
          isExisting: true
        })));
      } else {
        setSelectedImages([]);
      }
      
      // Verificar si es tipo artículo
      setIsArticle(publication.type === 'ARTICLE');
    } else {
      resetForm();
    }
  }, [publication, show]);

  useEffect(() => {
    // Actualizar isArticle cuando cambia el tipo
    setIsArticle(formData.type === 'ARTICLE');
  }, [formData.type]);

  const resetForm = () => {
    setFormData({
      title: '',
      description: '',
      body: '',
      type: '',
      author_name: '',
      status: 'DRAFT',
      hashtags: [],
      links: []
    });
    setSelectedImages([]);
    setImageFiles([]);
    setImagesToDelete([]);
    setCurrentHashtag('');
    setCurrentLink('');
    setIsArticle(false);
    setActiveEditorTab('edit');
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    
    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(true);
      return;
    }

    try {
      // Validar tipo de publicación
      if (isArticle && !formData.body) {
        alert('Articles require body content.');
        return;
      }

      let response;
      
      if (publication) {
        // Actualización de publicación existente
        const updateRequest = {
          ...formData,
          images_to_delete: imagesToDelete
        };
        
        response = await updatePublication(publication.id, updateRequest, imageFiles);
      } else {
        // Creación de nueva publicación
        response = await createPublication(formData, imageFiles);
      }
      
      onSuccess();
      onHide();
    } catch (error) {
      console.error('Error saving publication:', error);
      alert("An error occurred while saving. Please try again.");
    }
  };

  const handleEnhanceText = async (enhancementType) => {
    if (!formData.body || formData.body.trim() === '') {
      alert('Please enter some text to enhance.');
      return;
    }

    try {
      setIsEnhancingText(true);
      const response = await enhanceTextWithAI(formData.body, enhancementType);
      
      if (response && response.data && response.data.enhanced_text) {
        setFormData(prev => ({
          ...prev,
          body: response.data.enhanced_text
        }));
      } else if (response && response.data && response.data.data && response.data.data.enhanced_text) {
        // Handle nested response structure
        setFormData(prev => ({
          ...prev,
          body: response.data.data.enhanced_text
        }));
      }
    } catch (error) {
      console.error('Error enhancing text:', error);
      alert('Failed to enhance text. Please try again.');
    } finally {
      setIsEnhancingText(false);
    }
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragActive(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragActive(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!isDragActive) {
      setIsDragActive(true);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragActive(false);
    
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      handleImageFiles(e.dataTransfer.files);
    }
  };

  const handleImageFiles = (files) => {
    const newFiles = Array.from(files).filter(file => file.type.startsWith('image/'));
    
    if (newFiles.length === 0) return;
    
    // Agregar los nuevos archivos al estado
    setImageFiles(prevFiles => [...prevFiles, ...newFiles]);
    
    // Crear vistas previas para los nuevos archivos
    const newPreviews = newFiles.map(file => ({
      url: URL.createObjectURL(file),
      file: file,
      isExisting: false
    }));
    
    setSelectedImages(prevImages => [...prevImages, ...newPreviews]);
  };

  const handleFileInputChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      handleImageFiles(e.target.files);
    }
  };

  const handleClickUpload = () => {
    fileInputRef.current.click();
  };

  const handleRemoveImage = (index) => {
    const imageToRemove = selectedImages[index];
    
    // Si es una imagen existente, añadirla a la lista de imágenes para eliminar
    if (imageToRemove.isExisting) {
      setImagesToDelete(prev => [...prev, imageToRemove.url]);
    }
    
    // Si es una imagen nueva (archivo), eliminarla de la lista de archivos
    if (!imageToRemove.isExisting && imageToRemove.file) {
      setImageFiles(prev => prev.filter(file => file !== imageToRemove.file));
    }
    
    // Eliminar la imagen de la vista previa
    setSelectedImages(prev => prev.filter((_, i) => i !== index));
  };

  const handleAddHashtag = () => {
    if (currentHashtag.trim() && !formData.hashtags.includes(currentHashtag.trim())) {
      setFormData(prev => ({
        ...prev,
        hashtags: [...prev.hashtags, currentHashtag.trim()]
      }));
      setCurrentHashtag('');
    }
  };

  const handleRemoveHashtag = (tag) => {
    setFormData(prev => ({
      ...prev,
      hashtags: prev.hashtags.filter(t => t !== tag)
    }));
  };

  const handleAddLink = () => {
    if (currentLink.trim() && !formData.links.includes(currentLink.trim())) {
      setFormData(prev => ({
        ...prev,
        links: [...prev.links, currentLink.trim()]
      }));
      setCurrentLink('');
    }
  };

  const handleRemoveLink = (link) => {
    setFormData(prev => ({
      ...prev,
      links: prev.links.filter(l => l !== link)
    }));
  };

  // Determinar si los botones deben estar deshabilitados
  const areButtonsDisabled = isEnhancingText;

  return (
    <GoogleModal 
      show={show} 
      onHide={onHide} 
      size="lg"
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>{publication ? 'Editar Publicación' : 'Crear Nueva Publicación'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <GoogleForm noValidate validated={validated} onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Tipo</Form.Label>
            <Form.Select
              required
              value={formData.type}
              onChange={(e) => setFormData(prev => ({ ...prev, type: e.target.value }))}
              disabled={areButtonsDisabled}
            >
              <option value="">Seleccionar tipo</option>
              {types.map((type) => (
                <option key={type.code} value={type.code}>
                  {type.name}
                </option>
              ))}
            </Form.Select>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Título</Form.Label>
            <Form.Control
              required
              type="text"
              value={formData.title}
              onChange={(e) => setFormData(prev => ({ ...prev, title: e.target.value }))}
              disabled={areButtonsDisabled}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Descripción</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              value={formData.description || ''}
              onChange={(e) => setFormData(prev => ({ ...prev, description: e.target.value }))}
              disabled={areButtonsDisabled}
            />
          </Form.Group>

          {(isArticle || formData.body) && (
            <Form.Group className="mb-3">
              <Form.Label>Contenido del Cuerpo {isArticle && <span className="text-danger">*</span>}</Form.Label>
              
              <EnhanceButtonGroup>
                <EnhanceButton 
                  type="button" 
                  onClick={() => handleEnhanceText('IMPROVE_TEXT')}
                  disabled={areButtonsDisabled || !formData.body}
                >
                  {isEnhancingText ? (
                    <>
                      <Spinner 
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                      <span>Mejorando...</span>
                    </>
                  ) : (
                    <>
                      <FaMagic />
                      <span>Mejorar Texto</span>
                    </>
                  )}
                </EnhanceButton>
                
                <EnhanceButton 
                  type="button" 
                  onClick={() => handleEnhanceText('CONVERT_TO_MARKDOWN')}
                  disabled={areButtonsDisabled || !formData.body}
                  style={{ backgroundColor: '#8e44ad', borderColor: '#8e44ad' }}
                >
                  {isEnhancingText ? (
                    <>
                      <Spinner 
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                      <span>Convirtiendo...</span>
                    </>
                  ) : (
                    <>
                      <FaMarkdown />
                      <span>Convertir a Markdown</span>
                    </>
                  )}
                </EnhanceButton>
              </EnhanceButtonGroup>
              
              <div className="mb-3">
                <div 
                  className="mb-2 border-bottom" 
                  style={{ 
                    display: 'flex', 
                    borderBottom: '1px solid #dee2e6' 
                  }}
                >
                  <div 
                    onClick={() => !areButtonsDisabled && setActiveEditorTab('edit')}
                    className={`px-3 py-2 ${activeEditorTab === 'edit' ? 'border-bottom border-primary' : ''}`}
                    style={{ 
                      cursor: areButtonsDisabled ? 'default' : 'pointer',
                      color: activeEditorTab === 'edit' ? '#1a73e8' : '#5f6368',
                      fontWeight: activeEditorTab === 'edit' ? '500' : 'normal',
                      borderBottom: activeEditorTab === 'edit' ? '2px solid #1a73e8' : '',
                      opacity: areButtonsDisabled ? 0.7 : 1
                    }}
                  >
                    Editar
                  </div>
                  <div 
                    onClick={() => !areButtonsDisabled && setActiveEditorTab('preview')}
                    className={`px-3 py-2 ${activeEditorTab === 'preview' ? 'border-bottom border-primary' : ''}`}
                    style={{ 
                      cursor: areButtonsDisabled ? 'default' : 'pointer',
                      color: activeEditorTab === 'preview' ? '#1a73e8' : '#5f6368',
                      fontWeight: activeEditorTab === 'preview' ? '500' : 'normal',
                      borderBottom: activeEditorTab === 'preview' ? '2px solid #1a73e8' : '',
                      opacity: areButtonsDisabled ? 0.7 : 1
                    }}
                  >
                    Vista Previa
                  </div>
                </div>
                
                {activeEditorTab === 'edit' ? (
                  <Form.Control
                    as="textarea"
                    rows={10}
                    required={isArticle}
                    value={formData.body || ''}
                    onChange={(e) => setFormData(prev => ({ ...prev, body: e.target.value }))}
                    disabled={areButtonsDisabled}
                  />
                ) : (
                  <MarkdownPreview>
                    <ReactMarkdown>{formData.body || ''}</ReactMarkdown>
                  </MarkdownPreview>
                )}
              </div>
              
              <Tab.Content>
              <Tab.Pane eventKey="edit">
                <Form.Control
                  as="textarea"
                  rows={10}
                  required={isArticle}
                  value={formData.body || ''}
                  onChange={(e) => setFormData(prev => ({ ...prev, body: e.target.value }))}
                  disabled={areButtonsDisabled}
                />
              </Tab.Pane>
              <Tab.Pane eventKey="preview">
                <MarkdownPreview>
                  <ReactMarkdown>{formData.body || ''}</ReactMarkdown>
                </MarkdownPreview>
              </Tab.Pane>
            </Tab.Content>
            </Form.Group>
          )}

          <Form.Group className="mb-3">
            <Form.Label>Nombre del Autor</Form.Label>
            <Form.Control
              type="text"
              value={formData.author_name}
              onChange={(e) => setFormData(prev => ({ ...prev, author_name: e.target.value }))}
              disabled={areButtonsDisabled}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Estado</Form.Label>
            <Form.Select
              required
              value={formData.status}
              onChange={(e) => setFormData(prev => ({ ...prev, status: e.target.value }))}
              disabled={areButtonsDisabled}
            >
              <option value="DRAFT">Borrador</option>
              <option value="PUBLISHED">Publicado</option>
              <option value="ARCHIVED">Archivado</option>
            </Form.Select>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Etiquetas</Form.Label>
            <div className="d-flex">
              <Form.Control
                type="text"
                value={currentHashtag}
                onChange={(e) => setCurrentHashtag(e.target.value)}
                placeholder="Añadir etiqueta"
                disabled={areButtonsDisabled}
              />
              <Button 
                variant="outline-primary" 
                onClick={handleAddHashtag} 
                className="ms-2"
                disabled={areButtonsDisabled}
              >
                <FaHashtag />
              </Button>
            </div>
            <TagContainer>
              {formData.hashtags.map((tag, index) => (
                <Tag key={index}>
                  #{tag}
                  <button 
                    type="button" 
                    onClick={() => handleRemoveHashtag(tag)}
                    disabled={areButtonsDisabled}
                  >
                    <FaTrash />
                  </button>
                </Tag>
              ))}
            </TagContainer>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Enlaces</Form.Label>
            <div className="d-flex">
              <Form.Control
                type="url"
                value={currentLink}
                onChange={(e) => setCurrentLink(e.target.value)}
                placeholder="Añadir enlace"
                disabled={areButtonsDisabled}
              />
              <Button 
                variant="outline-primary" 
                onClick={handleAddLink} 
                className="ms-2"
                disabled={areButtonsDisabled}
              >
                <FaLink />
              </Button>
            </div>
            <TagContainer>
              {formData.links.map((link, index) => (
                <Tag key={index}>
                  {link.length > 30 ? `${link.substring(0, 30)}...` : link}
                  <button 
                    type="button" 
                    onClick={() => handleRemoveLink(link)}
                    disabled={areButtonsDisabled}
                  >
                    <FaTrash />
                  </button>
                </Tag>
              ))}
            </TagContainer>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Imágenes</Form.Label>
            
            {/* Área para arrastrar y soltar imágenes */}
            <DropArea 
              isDragActive={isDragActive}
              onDragEnter={handleDragEnter}
              onDragLeave={handleDragLeave}
              onDragOver={handleDragOver}
              onDrop={handleDrop}
              onClick={handleClickUpload}
            >
              <UploadIcon />
              <UploadText>Arrastra y suelta imágenes aquí</UploadText>
              <UploadSubText>o haz clic para seleccionar archivos</UploadSubText>
              
              <input 
                type="file"
                ref={fileInputRef}
                style={{ display: 'none' }}
                multiple
                accept="image/*"
                onChange={handleFileInputChange}
                disabled={areButtonsDisabled}
              />
            </DropArea>
            
            {/* Vista previa de imágenes */}
            {selectedImages.length > 0 && (
              <ImageContainer>
                {selectedImages.map((image, index) => (
                  <ImageWrapper key={index}>
                    <PreviewImage>
                      <img 
                        src={image.url} 
                        alt={`Vista previa ${index + 1}`} 
                      />
                    </PreviewImage>
                    <DeleteButton
                      variant="danger"
                      size="sm"
                      onClick={() => handleRemoveImage(index)}
                      type="button"
                      disabled={areButtonsDisabled}
                    >
                      <FaTrash />
                    </DeleteButton>
                  </ImageWrapper>
                ))}
              </ImageContainer>
            )}
          </Form.Group>

          <div className="d-flex justify-content-end gap-2">
            <Button 
              variant="secondary" 
              onClick={onHide}
              disabled={areButtonsDisabled}
            >
              Cancelar
            </Button>
            <GoogleButton 
              type="submit"
              disabled={areButtonsDisabled}
            >
              {publication ? 'Actualizar' : 'Crear'}
            </GoogleButton>
          </div>
        </GoogleForm>
      </Modal.Body>
    </GoogleModal>
  );
};

export const StatusChangeModal = ({ show, onHide, publication, onSuccess, statuses }) => {
  const [selectedStatus, setSelectedStatus] = useState('');

  useEffect(() => {
    if (publication) {
      setSelectedStatus(publication.status);
    }
  }, [publication]);

  const handleSubmit = async () => {
    try {
      await changePublicationStatus(publication.id, selectedStatus);
      onSuccess();
      onHide();
    } catch (error) {
      console.error('Error updating status:', error);
    }
  };

  return (
    <GoogleModal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Change Status</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <GoogleForm>
          <Form.Group>
            <Form.Label>Current Status: {
              statuses.find(s => s.code === publication?.status)?.name || publication?.status
            }</Form.Label>
            <Form.Select
              value={selectedStatus}
              onChange={(e) => setSelectedStatus(e.target.value)}
            >
              <option value="">Select status</option>
              {statuses.map((status) => (
                <option 
                  key={status.code} 
                  value={status.code}
                  disabled={status.code === publication?.status}
                >
                  {status.name}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </GoogleForm>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Cancel
        </Button>
        <GoogleButton 
          onClick={handleSubmit}
          disabled={!selectedStatus || selectedStatus === publication?.status}
        >
          Update Status
        </GoogleButton>
      </Modal.Footer>
    </GoogleModal>
  );
};

export const ImagesModal = ({ show, onHide, publication, onSuccess }) => {
  const [existingImages, setExistingImages] = useState([]);
  const [newImageFiles, setNewImageFiles] = useState([]);
  const [newImagePreviews, setNewImagePreviews] = useState([]);
  const [imagesToDelete, setImagesToDelete] = useState([]);
  const [isDragActive, setIsDragActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const fileInputRef = useRef(null);

  useEffect(() => {
    if (show && publication) {
      if (publication.images && Array.isArray(publication.images)) {
        setExistingImages(publication.images);
      } else {
        setExistingImages([]);
      }
      // Limpiar los estados cuando se abre/cierra el modal
      setNewImageFiles([]);
      setNewImagePreviews([]);
      setImagesToDelete([]);
    }
  }, [publication, show]);

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragActive(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragActive(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!isDragActive) {
      setIsDragActive(true);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragActive(false);
    
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      handleFileSelect(e.dataTransfer.files);
    }
  };
  
  const handleFileSelect = (files) => {
    const imageFiles = Array.from(files).filter(file => file.type.startsWith('image/'));
    if (imageFiles.length > 0) {
      setNewImageFiles(prevFiles => [...prevFiles, ...imageFiles]);
      
      // Crear vista previa para las nuevas imágenes
      const newPreviews = imageFiles.map(file => ({
        url: URL.createObjectURL(file),
        file: file
      }));
      
      setNewImagePreviews(prev => [...prev, ...newPreviews]);
    }
  };
  
  const handleFileInputChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      handleFileSelect(e.target.files);
    }
  };
  
  const handleClickUpload = () => {
    fileInputRef.current.click();
  };
  
  const handleRemoveExisting = (imageUrl) => {
    setExistingImages(prev => prev.filter(url => url !== imageUrl));
    setImagesToDelete(prev => [...prev, imageUrl]);
  };
  
  const handleRemoveNew = (index) => {
    // Crear nuevas matrices sin el elemento en el índice
    const updatedFiles = [...newImageFiles];
    const updatedPreviews = [...newImagePreviews];
    
    // Liberar URL de objeto para evitar fugas de memoria
    URL.revokeObjectURL(updatedPreviews[index].url);
    
    // Eliminar elementos
    updatedFiles.splice(index, 1);
    updatedPreviews.splice(index, 1);
    
    setNewImageFiles(updatedFiles);
    setNewImagePreviews(updatedPreviews);
  };

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      
      // Preparar datos para actualización
      const formData = {
        images_to_delete: imagesToDelete
      };
      
      // Usar updatePublication con imágenes
      await updatePublication(publication.id, formData, newImageFiles);
      
      // Limpiar URL de objetos para evitar fugas de memoria
      newImagePreviews.forEach(preview => URL.revokeObjectURL(preview.url));
      
      onSuccess();
      onHide();
    } catch (error) {
      console.error('Error updating images:', error);
      alert('An error occurred while updating images. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <GoogleModal show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Gestionar Imágenes</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <GoogleForm>
          <Form.Group className="mb-3">
            <Form.Label>Añadir Nuevas Imágenes</Form.Label>
            <DropArea 
              isDragActive={isDragActive}
              onDragEnter={handleDragEnter}
              onDragLeave={handleDragLeave}
              onDragOver={handleDragOver}
              onDrop={handleDrop}
              onClick={handleClickUpload}
            >
              <UploadIcon />
              <UploadText>Arrastra y suelta imágenes aquí</UploadText>
              <UploadSubText>o haz clic para seleccionar archivos</UploadSubText>
              
              <input 
                type="file"
                ref={fileInputRef}
                style={{ display: 'none' }}
                multiple
                accept="image/*"
                onChange={handleFileInputChange}
              />
            </DropArea>
          </Form.Group>

          {newImagePreviews.length > 0 && (
            <Form.Group className="mb-4">
              <Form.Label>Nuevas Imágenes para Subir</Form.Label>
              <ImageContainer>
                {newImagePreviews.map((preview, index) => (
                  <ImageWrapper key={`new-${index}`}>
                    <Image src={preview.url} alt={`Nueva imagen ${index + 1}`} />
                    <DeleteButton
                      variant="danger"
                      size="sm"
                      onClick={() => handleRemoveNew(index)}
                    >
                      <FaTrash />
                    </DeleteButton>
                  </ImageWrapper>
                ))}
              </ImageContainer>
            </Form.Group>
          )}

          {existingImages.length > 0 && (
            <Form.Group>
              <Form.Label>Imágenes Actuales</Form.Label>
              <ImageContainer>
                {existingImages.map((url, index) => (
                  <ImageWrapper key={`existing-${index}`}>
                    <Image src={url} alt={`Imagen ${index + 1}`} />
                    <DeleteButton
                      variant="danger"
                      size="sm"
                      onClick={() => handleRemoveExisting(url)}
                    >
                      <FaTrash />
                    </DeleteButton>
                  </ImageWrapper>
                ))}
              </ImageContainer>
            </Form.Group>
          )}
        </GoogleForm>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide} disabled={isLoading}>
          Cancelar
        </Button>
        <SubmitButton 
          onClick={handleSubmit} 
          disabled={isLoading || (newImageFiles.length === 0 && imagesToDelete.length === 0)}
        >
          {isLoading ? 'Actualizando...' : 'Actualizar Imágenes'}
        </SubmitButton>
      </Modal.Footer>
    </GoogleModal>
  );
};