import api from '../apiBase';

export const getDailySummaries = async (page = 0, size = 10, startDate = null, endDate = null) => {
  try {
    let url = `/api/v1/daily-summary?page=${page}&size=${size}`;
    
    if (startDate) {
      url += `&startDate=${startDate}`;
    }
    
    if (endDate) {
      url += `&endDate=${endDate}`;
    }
    
    const response = await api.get(url);
    return response.data;
  } catch (error) {
    console.error('Error fetching daily summaries:', error);
    throw error;
  }
};

export const getDailySummaryById = async (id) => {
  try {
    const response = await api.get(`/api/v1/daily-summary/${id}`);
    return response.data;
  } catch (error) {
    console.error(`Error fetching daily summary with id ${id}:`, error);
    throw error;
  }
};

export const getDailySummaryByDate = async (date) => {
  try {
    const response = await api.get(`/api/v1/daily-summary/by-date/${date}`);
    return response.data;
  } catch (error) {
    console.error(`Error fetching daily summary for date ${date}:`, error);
    throw error;
  }
};

export const updateDailySummary = async (id, summaryData) => {
  try {
    const response = await api.put(`/api/v1/daily-summary/${id}`, summaryData);
    return response.data;
  } catch (error) {
    console.error(`Error updating daily summary with id ${id}:`, error);
    throw error;
  }
};

export const deleteDailySummary = async (id) => {
  try {
    const response = await api.delete(`/api/v1/daily-summary/${id}`);
    return response.data;
  } catch (error) {
    console.error(`Error deleting daily summary with id ${id}:`, error);
    throw error;
  }
};

// Updated to wait for summary generation to complete
export const generateDailySummary = async (date) => {
  try {
    // Start the generation process
    const response = await api.post(`/api/v1/daily-summary/generate/${date}`);
    
    // If there's an error in the response, throw it
    if (response.data.error) {
      throw new Error(response.data.error.message || 'Error generating summary');
    }
    
    // If the summary was generated successfully, return it
    if (response.data.data) {
      return response.data;
    }
    
    // This shouldn't normally happen, but we'll handle it just in case
    throw new Error('Unexpected response format');
  } catch (error) {
    console.error(`Error generating daily summary for date ${date}:`, error);
    throw error;
  }
};

export default api;