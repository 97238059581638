import React, { useState, useEffect } from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import styled from 'styled-components';

const GoogleModal = styled(Modal)`
  .modal-content {
    border-radius: 8px;
    border: none;
    box-shadow: 0 1px 3px 0 rgba(60,64,67,0.3), 0 4px 8px 3px rgba(60,64,67,0.15);
  }

  .modal-header {
    border-bottom: 1px solid #e8eaed;
    padding: 24px 24px 20px;
  }

  .modal-title {
    font-size: 22px;
    color: #202124;
    font-weight: 400;
  }

  .modal-body {
    padding: 24px;
  }

  .modal-footer {
    border-top: 1px solid #e8eaed;
    padding: 20px 24px 24px;
  }
`;

const GoogleForm = styled(Form)`
  .form-label {
    font-weight: 500;
    color: #5f6368;
    margin-bottom: 8px;
  }

  .form-control {
    border-radius: 4px;
    border: 1px solid #dadce0;
    padding: 8px 12px;
    font-size: 14px;
    
    &:focus {
      border-color: #1a73e8;
      box-shadow: 0 0 0 2px rgba(26,115,232,0.25);
    }
  }

  .form-select {
    border-radius: 4px;
    border: 1px solid #dadce0;
    padding: 8px 12px;
    font-size: 14px;
    
    &:focus {
      border-color: #1a73e8;
      box-shadow: 0 0 0 2px rgba(26,115,232,0.25);
    }
  }
`;

const GoogleButton = styled(Button)`
  border-radius: 4px;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 14px;
  padding: 8px 24px;

  &.btn-primary {
    background-color: #1a73e8;
    border-color: #1a73e8;
    color: #fff;
    
    &:hover, &:focus {
      background-color: #1765cc;
      border-color: #1765cc;
    }

    &:disabled {
      background-color: #8ab4f8;
      border-color: #8ab4f8;
    }
  }

  &.btn-secondary {
    background-color: #fff;
    border-color: #dadce0;
    color: #3c4043;
    
    &:hover, &:focus {
      background-color: #f1f3f4;
      border-color: #dadce0;
    }
  }

  &.btn-danger {
    background-color: #ea4335;
    border-color: #ea4335;
    color: #fff;
    
    &:hover, &:focus {
      background-color: #d93025;
      border-color: #d93025;
    }
  }
`;

export const ClientModal = ({ 
  show, 
  onHide, 
  formData, 
  setFormData, 
  handleSave,
  isEditMode 
}) => {
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    const { name, client_id } = formData;
    setIsValid(name.trim().length > 0 && client_id.trim().length > 0);
  }, [formData]);

  return (
    <GoogleModal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>{isEditMode ? 'Edit Client' : 'Add Client'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <GoogleForm>
          <Form.Group className="mb-3">
            <Form.Label>Client ID</Form.Label>
            <Form.Control
              type="text"
              value={formData.client_id}
              onChange={(e) => setFormData({...formData, client_id: e.target.value})}
              isInvalid={formData.client_id.trim().length === 0}
              disabled={isEditMode}
            />
            <Form.Control.Feedback type="invalid">
              Please enter a client ID.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              value={formData.name}
              onChange={(e) => setFormData({...formData, name: e.target.value})}
              isInvalid={formData.name.trim().length === 0}
            />
            <Form.Control.Feedback type="invalid">
              Please enter a name.
            </Form.Control.Feedback>
          </Form.Group>
        </GoogleForm>
      </Modal.Body>
      <Modal.Footer>
        <GoogleButton variant="secondary" onClick={onHide}>Cancel</GoogleButton>
        <GoogleButton variant="primary" onClick={handleSave} disabled={!isValid}>
          {isEditMode ? 'Update' : 'Save'}
        </GoogleButton>
      </Modal.Footer>
    </GoogleModal>
  );
};

export const ModuleModal = ({ 
  show, 
  onHide, 
  formData, 
  setFormData, 
  handleSave,
  isEditMode 
}) => {
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    const { module_id, name, group, client_id } = formData;
    setIsValid(
      module_id.trim().length > 0 && 
      name.trim().length > 0 && 
      group.trim().length > 0 && 
      client_id.trim().length > 0
    );
  }, [formData]);

  return (
    <GoogleModal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>{isEditMode ? 'Edit Module' : 'Add Module'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <GoogleForm>
          <Form.Group className="mb-3">
            <Form.Label>Module ID</Form.Label>
            <Form.Control
              type="text"
              value={formData.module_id}
              onChange={(e) => setFormData({...formData, module_id: e.target.value})}
              isInvalid={formData.module_id.trim().length === 0}
              disabled={isEditMode}
            />
            <Form.Control.Feedback type="invalid">
              Please enter a module ID.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              value={formData.name}
              onChange={(e) => setFormData({...formData, name: e.target.value})}
              isInvalid={formData.name.trim().length === 0}
            />
            <Form.Control.Feedback type="invalid">
              Please enter a name.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Icon (HTML/SVG)</Form.Label>
            <Form.Control
              type="text"
              value={formData.icon || ''}
              onChange={(e) => setFormData({...formData, icon: e.target.value})}
            />
            <Form.Text className="text-muted">
              HTML or SVG code for the icon (optional).
            </Form.Text>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Route</Form.Label>
            <Form.Control
              type="text"
              value={formData.route || ''}
              onChange={(e) => setFormData({...formData, route: e.target.value})}
            />
            <Form.Text className="text-muted">
              The route for the module (optional).
            </Form.Text>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Group</Form.Label>
            <Form.Control
              type="text"
              value={formData.group}
              onChange={(e) => setFormData({...formData, group: e.target.value})}
              isInvalid={formData.group.trim().length === 0}
            />
            <Form.Control.Feedback type="invalid">
              Please enter a group.
            </Form.Control.Feedback>
          </Form.Group>
        </GoogleForm>
      </Modal.Body>
      <Modal.Footer>
        <GoogleButton variant="secondary" onClick={onHide}>Cancel</GoogleButton>
        <GoogleButton variant="primary" onClick={handleSave} disabled={!isValid}>
          {isEditMode ? 'Update' : 'Save'}
        </GoogleButton>
      </Modal.Footer>
    </GoogleModal>
  );
};

export const ConfirmDeleteModal = ({ show, onHide, onConfirm, type, item }) => {
  return (
    <GoogleModal show={show} onHide={onHide} size="sm">
      <Modal.Header closeButton>
        <Modal.Title>Confirm Delete</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Are you sure you want to delete this {type}?</p>
        {type === 'client' && (
          <p>
            <strong>Client:</strong> {item?.name} ({item?.clientId})
          </p>
        )}
        {type === 'module' && (
          <p>
            <strong>Module:</strong> {item?.name} ({item?.moduleId})
          </p>
        )}
        <p className="text-danger mb-0">This action cannot be undone.</p>
      </Modal.Body>
      <Modal.Footer>
        <GoogleButton variant="secondary" onClick={onHide}>Cancel</GoogleButton>
        <GoogleButton variant="danger" onClick={onConfirm}>Delete</GoogleButton>
      </Modal.Footer>
    </GoogleModal>
  );
};

export const AlertModal = ({ show, onHide, title, message, variant }) => {
  return (
    <GoogleModal show={show} onHide={onHide} size="sm">
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className={`text-${variant}`}>{message}</p>
      </Modal.Body>
      <Modal.Footer>
        <GoogleButton variant="primary" onClick={onHide}>Close</GoogleButton>
      </Modal.Footer>
    </GoogleModal>
  );
};