import api from '../apiBase';

export const sendNotificationToUser = async (userId, insuranceRequestId) => {
  try {
    const response = await api.post('/api/notifications/send', { user_id: userId, insurance_request_id: insuranceRequestId });
    return response.data;
  } catch (error) {
    console.error('Error sending notification:', error);
    throw error;
  }
};

export const fetchUsers = async (params) => {
  try {
    const response = await api.get('/api/workers_manager', { params });
    return response.data;
  } catch (error) {
    console.error('Error fetching users:', error);
    throw error;
  }
};

export const createUser = async (userData) => {
  try {
    const response = await api.post('/api/workers_manager', userData);
    return response.data;
  } catch (error) {
    console.error('Error creating user:', error);
    throw error;
  }
};

export const updateUserName = async (userId, name) => {
  try {
    const response = await api.put(`/api/workers_manager/${userId}/name`, { name });
    return response.data;
  } catch (error) {
    console.error('Error updating user name:', error);
    throw error;
  }
};

export const addUserRole = async (userId, role) => {
  try {
    const response = await api.post(`/api/workers_manager/${userId}/roles`, role);
    return response.data;
  } catch (error) {
    console.error('Error adding user role:', error);
    throw error;
  }
};

export const removeUserRole = async (userId, role) => {
  try {
    const response = await api.delete(`/api/workers_manager/${userId}/roles/${role}`);
    return response.data;
  } catch (error) {
    console.error('Error removing user role:', error);
    throw error;
  }
};

export const updateUserContactInfo = async (userId, contactInfo) => {
  try {
    const response = await api.put(`/api/workers_manager/${userId}/contact-info`, contactInfo);
    return response.data;
  } catch (error) {
    console.error('Error updating user contact info:', error);
    throw error;
  }
};

export const invalidateAccount = async (userId) => {
  try {
    const response = await api.post('/auth/v1/invalidateAccount', null, {
      params: { uid: userId }
    });
    return response.data;
  } catch (error) {
    console.error('Error invalidating account:', error);
    throw error;
  }
};

export const fetchNotaryAgents = async () => {
  try {
    const response = await api.get('/api/workers_manager/by-role-notary-agent');
    return response.data;
  } catch (error) {
    console.error('Error fetching notary agents:', error);
    throw error;
  }
};

export const getClientModules = async (clientId) => {
  try {
    const response = await api.get('/api/clients', {
      params: { clientId }
    });
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : error.message;
  }
};

export default api;