import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Alert, Tabs, Tab, Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import { FaTrash, FaFacebookF } from 'react-icons/fa';

// Styled components for modals
const StyledModal = styled(Modal)`
  .modal-header {
    border-bottom: 1px solid #dadce0;
    padding: 16px 24px;
  }
  
  .modal-title {
    font-size: 18px;
    font-weight: 500;
    color: #202124;
  }
  
  .modal-body {
    padding: 24px;
  }
  
  .modal-footer {
    border-top: 1px solid #dadce0;
    padding: 16px 24px;
  }
`;

const GoogleButton = styled(Button)`
  background-color: ${props => props.variant === 'primary' ? '#1a73e8' : '#fff'};
  border-color: ${props => props.variant === 'primary' ? '#1a73e8' : '#dadce0'};
  color: ${props => props.variant === 'primary' ? '#fff' : '#3c4043'};
  font-weight: 500;
  padding: 8px 16px;
  font-size: 14px;
  
  &:hover, &:focus {
    background-color: ${props => props.variant === 'primary' ? '#1765cc' : '#f1f3f4'};
    border-color: ${props => props.variant === 'primary' ? '#1765cc' : '#dadce0'};
    box-shadow: 0 1px 2px 0 rgba(60,64,67,0.3), 0 1px 3px 1px rgba(60,64,67,0.15);
  }

  &:disabled {
    background-color: ${props => props.variant === 'primary' ? '#8ab4f8' : '#f1f3f4'};
    border-color: ${props => props.variant === 'primary' ? '#8ab4f8' : '#dadce0'};
    color: ${props => props.variant === 'primary' ? '#fff' : '#80868b'};
  }
`;

const DangerButton = styled(GoogleButton)`
  background-color: ${props => props.variant === 'danger' ? '#ea4335' : '#fce8e6'};
  border-color: ${props => props.variant === 'danger' ? '#ea4335' : '#fce8e6'};
  color: ${props => props.variant === 'danger' ? '#fff' : '#c5221f'};
  
  &:hover, &:focus {
    background-color: ${props => props.variant === 'danger' ? '#d93025' : '#fad2cf'};
    border-color: ${props => props.variant === 'danger' ? '#d93025' : '#fad2cf'};
  }
`;

const FacebookButton = styled(GoogleButton)`
  background-color: ${props => props.variant === 'facebook' ? '#1877f2' : '#e8f0fe'};
  border-color: ${props => props.variant === 'facebook' ? '#1877f2' : '#e8f0fe'};
  color: ${props => props.variant === 'facebook' ? '#fff' : '#1877f2'};
  
  &:hover, &:focus {
    background-color: ${props => props.variant === 'facebook' ? '#166fe5' : '#d2e3fc'};
    border-color: ${props => props.variant === 'facebook' ? '#166fe5' : '#d2e3fc'};
  }
`;

const NumberInput = styled(Form.Control)`
  width: 60px;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
`;

const NumberCircle = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #f1f3f4;
  border: 1px solid #dadce0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: 500;
  margin: 0 5px;
`;

const PreviewImage = styled.img`
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  margin-top: 16px;
`;

// Alert Modal
export const AlertModal = ({ show, onHide, title, message, variant = 'success' }) => (
  <StyledModal show={show} onHide={onHide} centered>
    <Modal.Header closeButton>
      <Modal.Title>{title}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <Alert variant={variant}>{message}</Alert>
    </Modal.Body>
    <Modal.Footer>
      <GoogleButton variant="primary" onClick={onHide}>Close</GoogleButton>
    </Modal.Footer>
  </StyledModal>
);

export const CreateLotteryModal = ({ show, onHide, onCreate }) => {
  const [lottery, setLottery] = useState({
    pick: "3",
    date: new Date().toISOString().split('T')[0],
    daytime: "Midday",
    first: "",
    second: "",
    third: "",
    fourth: null,
    fireball: null
  });
  const [loading, setLoading] = useState(false);
  
  const resetForm = () => {
    setLottery({
      pick: "3",
      date: new Date().toISOString().split('T')[0],
      daytime: "Midday",
      first: "",
      second: "",
      third: "",
      fourth: null,
      fireball: null
    });
    setLoading(false);
  };

  useEffect(() => {
    if (show) {
      resetForm();
    }
  }, [show]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    
    if (['first', 'second', 'third', 'fourth', 'fireball'].includes(name)) {
      // Ensure only numbers 0-9 are entered for lottery numbers
      if (value === '' || (/^[0-9]$/.test(value) && parseInt(value) >= 0 && parseInt(value) <= 9)) {
        setLottery({ ...lottery, [name]: value === '' ? '' : parseInt(value) });
      }
    } else {
      setLottery({ ...lottery, [name]: value });
    }
  };

  const handlePickChange = (e) => {
    const pick = e.target.value;
    setLottery({
      ...lottery,
      pick,
      fourth: pick === "4" ? "" : null
    });
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const formattedLottery = {
        ...lottery,
        // Ensure numbers are integers
        first: parseInt(lottery.first),
        second: parseInt(lottery.second),
        third: parseInt(lottery.third),
        fourth: lottery.fourth !== null && lottery.fourth !== "" ? parseInt(lottery.fourth) : null,
        fireball: lottery.fireball !== null && lottery.fireball !== "" ? parseInt(lottery.fireball) : null
      };
      
      await onCreate(formattedLottery);
    } finally {
      setLoading(false);
    }
  };

  const isFormValid = () => {
    // Basic validation to ensure required fields are filled
    const { pick, date, daytime, first, second, third, fourth } = lottery;
    
    if (!date || !daytime || first === '' || second === '' || third === '') {
      return false;
    }
    
    if (pick === "4" && fourth === '') {
      return false;
    }
    
    return true;
  };

  return (
    <StyledModal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>Add Lottery Result</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Row className="mb-3">
            <Col md={6}>
              <Form.Group>
                <Form.Label>Pick Type</Form.Label>
                <Form.Control
                  as="select"
                  name="pick"
                  value={lottery.pick}
                  onChange={handlePickChange}
                >
                  <option value="3">Pick 3</option>
                  <option value="4">Pick 4</option>
                </Form.Control>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Label>Time of Day</Form.Label>
                <Form.Control
                  as="select"
                  name="daytime"
                  value={lottery.daytime}
                  onChange={handleInputChange}
                >
                  <option value="Midday">Midday</option>
                  <option value="Evening">Evening</option>
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
          
          <Form.Group className="mb-3">
            <Form.Label>Date</Form.Label>
            <Form.Control
              type="date"
              name="date"
              value={lottery.date}
              onChange={handleInputChange}
              required
            />
          </Form.Group>
          
          <Form.Group className="mb-3">
            <Form.Label>Numbers</Form.Label>
            <div className="d-flex align-items-center gap-2">
              <NumberInput
                type="text"
                name="first"
                value={lottery.first}
                onChange={handleInputChange}
                maxLength={1}
                required
              />
              <span>-</span>
              <NumberInput
                type="text"
                name="second"
                value={lottery.second}
                onChange={handleInputChange}
                maxLength={1}
                required
              />
              <span>-</span>
              <NumberInput
                type="text"
                name="third"
                value={lottery.third}
                onChange={handleInputChange}
                maxLength={1}
                required
              />
              {lottery.pick === "4" && (
                <>
                  <span>-</span>
                  <NumberInput
                    type="text"
                    name="fourth"
                    value={lottery.fourth}
                    onChange={handleInputChange}
                    maxLength={1}
                    required
                  />
                </>
              )}
            </div>
          </Form.Group>
          
          <Form.Group className="mb-3">
            <Form.Label>Fireball (Optional)</Form.Label>
            <div>
              <NumberInput
                type="text"
                name="fireball"
                value={lottery.fireball !== null ? lottery.fireball : ''}
                onChange={handleInputChange}
                maxLength={1}
              />
            </div>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <GoogleButton onClick={onHide}>Cancel</GoogleButton>
        <GoogleButton 
          variant="primary" 
          onClick={handleSubmit}
          disabled={!isFormValid() || loading}
        >
          {loading ? 'Adding...' : 'Add Result'}
        </GoogleButton>
      </Modal.Footer>
    </StyledModal>
  );
};

// Delete Lottery Modal
export const DeleteLotteryModal = ({ show, onHide, lottery, onDelete }) => {
  const handleDelete = () => {
    if (lottery?.id) {
      onDelete(lottery.id);
    }
  };

  return (
    <StyledModal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>Delete Lottery Result</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Are you sure you want to delete this lottery result?</p>
        {lottery && (
          <div className="p-3 bg-light rounded">
            <p className="mb-1"><strong>Date:</strong> {new Date(lottery.date).toLocaleDateString()}</p>
            <p className="mb-1"><strong>Pick:</strong> {lottery.pick}</p>
            <p className="mb-1"><strong>Time:</strong> {lottery.daytime}</p>
            <p className="mb-0"><strong>Numbers:</strong> {lottery.first}-{lottery.second}-{lottery.third}{lottery.fourth !== null ? `-${lottery.fourth}` : ''}</p>
          </div>
        )}
        <Alert variant="danger" className="mt-3">
          This action cannot be undone.
        </Alert>
      </Modal.Body>
      <Modal.Footer>
        <GoogleButton onClick={onHide}>Cancel</GoogleButton>
        <DangerButton variant="danger" onClick={handleDelete}>Delete</DangerButton>
      </Modal.Footer>
    </StyledModal>
  );
};

// Publish to Facebook Modal
export const PublishToFacebookModal = ({ show, onHide, lottery, onPublishToFacebook, onPublishToFacebookStory }) => {
  const [customMessage, setCustomMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [previewImage, setPreviewImage] = useState(null);
  const [activeTab, setActiveTab] = useState('post');

  useEffect(() => {
    // Reset form when modal opens
    if (show && lottery) {
      setCustomMessage('');
      setLoading(false);
      setPreviewImage(null);
      
      // Load preview image
      loadPreviewImage();
    }
  }, [show, lottery]);

  const loadPreviewImage = async () => {
    if (!lottery) return;
    
    try {
      // This would be a base64 or url in a real implementation
      // For simplicity, we'll just set a placeholder
      setPreviewImage(`/api/lottery/v1/template/${lottery.date}/${lottery.daytime.toLowerCase()}`);
    } catch (error) {
      console.error('Error loading preview:', error);
    }
  };

  const handlePublishPost = async () => {
    if (!lottery) return;
    
    setLoading(true);
    try {
      await onPublishToFacebook(
        lottery.date, 
        lottery.daytime.toLowerCase(), 
        customMessage || null
      );
    } finally {
      setLoading(false);
    }
  };

  const handlePublishStory = async () => {
    if (!lottery) return;
    
    setLoading(true);
    try {
      await onPublishToFacebookStory(
        lottery.date, 
        lottery.daytime.toLowerCase(), 
        customMessage || null
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <StyledModal show={show} onHide={onHide} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Publish Lottery Result to Facebook</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {lottery && (
          <>
            <div className="mb-3">
              <h5>Lottery Details</h5>
              <div className="p-3 bg-light rounded">
                <p className="mb-1"><strong>Date:</strong> {new Date(lottery.date).toLocaleDateString()}</p>
                <p className="mb-1"><strong>Pick:</strong> {lottery.pick}</p>
                <p className="mb-1"><strong>Time:</strong> {lottery.daytime}</p>
                <p className="mb-0"><strong>Numbers:</strong> {lottery.first}-{lottery.second}-{lottery.third}{lottery.fourth !== null ? `-${lottery.fourth}` : ''}</p>
              </div>
            </div>
            
            <Tabs
              activeKey={activeTab}
              onSelect={(k) => setActiveTab(k)}
              className="mb-3"
            >
              <Tab eventKey="post" title="Facebook Post">
                <Form.Group className="mb-3">
                  <Form.Label>Custom Message (Optional)</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    value={customMessage}
                    onChange={(e) => setCustomMessage(e.target.value)}
                    placeholder="Enter a custom message or leave blank for default"
                  />
                </Form.Group>
                
                <Form.Group>
                  <Form.Label>Preview</Form.Label>
                  {previewImage && (
                    <PreviewImage 
                      src={previewImage} 
                      alt="Lottery Template Preview" 
                    />
                  )}
                </Form.Group>
                
                <div className="d-flex justify-content-end mt-3">
                  <FacebookButton 
                    variant="facebook" 
                    onClick={handlePublishPost}
                    disabled={loading}
                  >
                    <FaFacebookF className="me-2" /> Publish to Facebook
                  </FacebookButton>
                </div>
              </Tab>
              
              <Tab eventKey="story" title="Facebook Story">
                <Form.Group className="mb-3">
                  <Form.Label>Custom Message (Optional)</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    value={customMessage}
                    onChange={(e) => setCustomMessage(e.target.value)}
                    placeholder="Enter a custom message or leave blank for default"
                  />
                </Form.Group>
                
                <Form.Group>
                  <Form.Label>Preview</Form.Label>
                  {previewImage && (
                    <PreviewImage 
                      src={previewImage} 
                      alt="Lottery Template Preview" 
                    />
                  )}
                </Form.Group>
                
                <div className="d-flex justify-content-end mt-3">
                  <FacebookButton 
                    variant="facebook" 
                    onClick={handlePublishStory}
                    disabled={loading}
                  >
                    <FaFacebookF className="me-2" /> Publish to Facebook Story
                  </FacebookButton>
                </div>
              </Tab>
            </Tabs>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <GoogleButton onClick={onHide}>Cancel</GoogleButton>
      </Modal.Footer>
    </StyledModal>
  );
};

// LotterySlidingPanel Component
export const LotterySlidingPanel = ({ selectedLottery, onClose, onDelete, onPublish, generateLotteryTemplate }) => {
  const [previewImage, setPreviewImage] = useState(null);

  useEffect(() => {
    if (selectedLottery) {
      loadPreviewImage();
    }
  }, [selectedLottery]);

  const loadPreviewImage = async () => {
    if (!selectedLottery) return;
    
    try {
      // This would be a base64 or url in a real implementation
      setPreviewImage(`/api/lottery/v1/template/${selectedLottery.date}/${selectedLottery.daytime.toLowerCase()}`);
    } catch (error) {
      console.error('Error loading preview:', error);
    }
  };

  if (!selectedLottery) return null;

  return (
    <div className="h-100 d-flex flex-column bg-white">
      <div className="p-3 border-bottom">
        <div className="d-flex justify-content-between align-items-center">
          <h4 className="mb-0">Lottery Details</h4>
          <Button 
            variant="link" 
            className="text-secondary p-0" 
            onClick={onClose}
            aria-label="Close"
          >
            &times;
          </Button>
        </div>
      </div>
      
      <div className="flex-grow-1 overflow-auto p-3">
        <div className="mb-4">
          <div className="d-flex align-items-center mb-3">
            <div 
              className="me-3 rounded-circle d-flex align-items-center justify-content-center"
              style={{ 
                width: '50px', 
                height: '50px', 
                backgroundColor: selectedLottery.pick === '3' ? '#1a73e8' : '#ea4335',
                color: 'white',
                fontSize: '20px',
                fontWeight: 'bold'
              }}
            >
              {selectedLottery.pick}
            </div>
            <div>
              <h5 className="mb-0">{selectedLottery.daytime} Draw</h5>
              <div>{new Date(selectedLottery.date).toLocaleDateString()}</div>
            </div>
          </div>
          
          <div className="p-3 bg-light rounded mb-4">
            <h6 className="mb-3">Winning Numbers</h6>
            <div className="d-flex">
              <NumberCircle>{selectedLottery.first}</NumberCircle>
              <NumberCircle>{selectedLottery.second}</NumberCircle>
              <NumberCircle>{selectedLottery.third}</NumberCircle>
              {selectedLottery.fourth !== null && (
                <NumberCircle>{selectedLottery.fourth}</NumberCircle>
              )}
              {selectedLottery.fireball !== null && (
                <NumberCircle style={{ border: '2px solid #ea4335' }}>
                  {selectedLottery.fireball}
                </NumberCircle>
              )}
            </div>
            {selectedLottery.fireball !== null && (
              <div className="mt-2 text-muted small">
                <span className="text-danger">■</span> Fireball number
              </div>
            )}
          </div>
          
          <h6 className="mb-3">Template Preview</h6>
          {previewImage && (
            <div className="text-center">
              <img 
                src={previewImage} 
                alt="Lottery template" 
                className="img-fluid rounded border"
                style={{ maxWidth: '100%' }}
              />
            </div>
          )}
        </div>
      </div>
      
      <div className="p-3 border-top">
        <div className="d-flex flex-wrap">
          <Button 
            variant="outline-primary" 
            className="me-2 mb-2"
            onClick={() => onPublish(selectedLottery)}
          >
            <FaFacebookF className="me-2" /> Publish to Facebook
          </Button>
          <Button 
            variant="outline-danger" 
            className="me-2 mb-2"
            onClick={() => onDelete(selectedLottery)}
          >
            <FaTrash className="me-2" /> Delete
          </Button>
        </div>
      </div>
    </div>
  );
};