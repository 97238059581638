import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, ListGroup } from 'react-bootstrap';
import styled from 'styled-components';
import { FaTrash } from 'react-icons/fa';
import api from '../apiBase';

const GoogleButton = styled(Button)`
  background-color: #fff;
  border-color: #dadce0;
  color: #3c4043;
  font-weight: 500;
  text-transform: none;
  letter-spacing: normal;
  padding: 8px 16px;
  font-size: 14px;
  
  &:hover, &:focus {
    background-color: #f1f3f4;
    border-color: #dadce0;
    color: #3c4043;
    box-shadow: 0 1px 2px 0 rgba(60,64,67,0.3), 0 1px 3px 1px rgba(60,64,67,0.15);
  }

  &.primary {
    background-color: #1a73e8;
    border-color: #1a73e8;
    color: white;
    
    &:hover, &:focus {
      background-color: #174ea6;
      border-color: #174ea6;
      color: white;
    }
  }

  &.danger {
    background-color: #ea4335;
    border-color: #ea4335;
    color: white;
    
    &:hover, &:focus {
      background-color: #d93025;
      border-color: #d93025;
      color: white;
    }
  }
`;

const AccessLevelBadge = styled.span`
  display: inline-block;
  padding: 4px 8px;
  border-radius: 16px;
  font-size: 12px;
  font-weight: 500;
  margin-right: 8px;
  background-color: ${props => {
    switch(props.level) {
      case 'READ': return '#e8f0fe';
      case 'WRITE': return '#fef7e0';
      case 'ADMIN': return '#fce8e6';
      default: return '#f1f3f4';
    }
  }};
  color: ${props => {
    switch(props.level) {
      case 'READ': return '#1967d2';
      case 'WRITE': return '#ea8600';
      case 'ADMIN': return '#c5221f';
      default: return '#5f6368';
    }
  }};
`;

const CircularProgress = styled.div`
  width: 30px;
  height: 30px;
  border: 3px solid #f3f3f3;
  border-top: 3px solid #1a73e8;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

const ManagePermissionsModal = ({ 
  show, 
  onHide, 
  userId, 
  userName,
  handleAssignPermission,
  handleDeletePermission,
  userPermissions,
  availableClients,
  loadingClients
}) => {
  const [selectedClient, setSelectedClient] = useState('');
  const [clientModules, setClientModules] = useState([]);
  const [selectedModule, setSelectedModule] = useState('');
  const [selectedAccessLevel, setSelectedAccessLevel] = useState('READ');
  const [loadingUserModules, setLoadingUserModules] = useState(false);
  const [moduleDetails, setModuleDetails] = useState({});
  const [groupedPermissions, setGroupedPermissions] = useState({});
  const [loadingPermissions, setLoadingPermissions] = useState(false);
  
  // Reset form when modal is opened/closed
  useEffect(() => {
    if (show) {
      setSelectedClient('');
      setClientModules([]);
      setSelectedModule('');
      setSelectedAccessLevel('READ');
      setModuleDetails({});
      setGroupedPermissions({});
    }
  }, [show]);

  // When a client is selected, get its modules
  const handleClientChange = async (e) => {
    const clientId = e.target.value;
    setSelectedClient(clientId);
    setSelectedModule('');
    setClientModules([]);
    
    if (clientId) {
      try {
        setLoadingUserModules(true);
        
        const response = await api.get(`/api/modules/client/${clientId}`);
        const data = response.data;
        console.log("Modules response:", data);
        
        if (Array.isArray(data)) {
          setClientModules(data);
        } else if (data && Array.isArray(data.modules)) {
          setClientModules(data.modules);
        } else {
          console.error('Unexpected data format:', data);
          setClientModules([]);
        }
      } catch (error) {
        console.error('Error fetching modules:', error);
        setClientModules([]);
      } finally {
        setLoadingUserModules(false);
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (selectedModule && selectedAccessLevel) {
      handleAssignPermission(userId, selectedModule, selectedAccessLevel);
      setSelectedModule('');
      setSelectedAccessLevel('READ');
    }
  };

  // Process permissions data to group by client
  useEffect(() => {
    if (!show || !userPermissions || !userPermissions.length) {
      return;
    }

    const fetchModuleDetails = async () => {
      setLoadingPermissions(true);
      const newModuleDetails = { ...moduleDetails };
      const newGroupedPermissions = {};
      
      // First, fetch any missing module details
      for (const permission of userPermissions) {
        if (!newModuleDetails[permission.module_id]) {
          try {
            const response = await api.get(`/api/modules/${permission.module_id}`);
            const moduleData = response.data;
            newModuleDetails[permission.module_id] = moduleData;
          } catch (error) {
            console.error(`Error fetching module ${permission.module_id}:`, error);
            // Store empty object as placeholder
            newModuleDetails[permission.module_id] = { name: "Unknown Module", client_id: "unknown" };
          }
        }
      }
      
      // Now group permissions by client
      for (const permission of userPermissions) {
        const moduleData = newModuleDetails[permission.module_id];
        if (moduleData) {
          // Find client
          const client = availableClients.find(c => c.client_id === moduleData.client_id);
          const clientName = client ? client.name : 'Unknown Client';
          
          if (!newGroupedPermissions[clientName]) {
            newGroupedPermissions[clientName] = [];
          }
          
          newGroupedPermissions[clientName].push({
            ...permission,
            moduleName: moduleData.name
          });
        }
      }
      
      setModuleDetails(newModuleDetails);
      setGroupedPermissions(newGroupedPermissions);
      setLoadingPermissions(false);
    };
    
    fetchModuleDetails();
  }, [show, userPermissions, availableClients, moduleDetails]);

  return (
    <Modal show={show} onHide={onHide} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>Manage Permissions for {userName}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5 className="mb-3">Assign New Permission</h5>
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Select Client</Form.Label>
            <Form.Select 
              value={selectedClient}
              onChange={handleClientChange}
              disabled={loadingClients}
            >
              <option value="">Select a client...</option>
              {availableClients && availableClients.map(client => (
                <option key={client.client_id} value={client.client_id}>
                  {client.name}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          
          <Form.Group className="mb-3">
            <Form.Label>Select Module</Form.Label>
            <Form.Select 
              value={selectedModule}
              onChange={(e) => setSelectedModule(e.target.value)}
              disabled={loadingUserModules || !selectedClient || clientModules.length === 0}
            >
              <option value="">Select a module...</option>
              {clientModules.map(module => (
                <option key={module.module_id} value={module.module_id}>
                  {module.name}
                </option>
              ))}
            </Form.Select>
            {loadingUserModules && <div className="text-muted mt-2">Loading modules...</div>}
            {!loadingUserModules && selectedClient && clientModules.length === 0 && 
              <div className="text-muted mt-2">No modules available for this client</div>
            }
          </Form.Group>
          
          <Form.Group className="mb-3">
            <Form.Label>Access Level</Form.Label>
            <Form.Select 
              value={selectedAccessLevel}
              onChange={(e) => setSelectedAccessLevel(e.target.value)}
            >
              <option value="READ">Read</option>
              <option value="WRITE">Write</option>
              <option value="ADMIN">Admin</option>
            </Form.Select>
          </Form.Group>
          
          <div className="d-flex justify-content-end mt-4">
            <GoogleButton type="submit" className="primary" disabled={!selectedModule}>
              Assign Permission
            </GoogleButton>
          </div>
        </Form>
        
        <hr className="my-4" />
        
        <h5 className="mb-3">Current Permissions</h5>
        {!userPermissions || userPermissions.length === 0 ? (
          <p className="text-muted">No permissions assigned yet.</p>
        ) : loadingPermissions ? (
          <div className="d-flex justify-content-center my-4">
            <CircularProgress />
            <span className="ms-2">Loading permissions...</span>
          </div>
        ) : Object.keys(groupedPermissions).length === 0 ? (
          <p className="text-muted">No permissions available to display.</p>
        ) : (
          Object.entries(groupedPermissions).map(([clientName, permissions]) => (
            <div key={clientName} className="mb-4">
              <h6>{clientName}</h6>
              <ListGroup>
                {permissions.map(permission => (
                  <ListGroup.Item 
                    key={permission.id} 
                    className="d-flex justify-content-between align-items-center"
                  >
                    <div>
                      <div>{permission.moduleName}</div>
                      <AccessLevelBadge level={permission.access_level}>
                        {permission.access_level}
                      </AccessLevelBadge>
                    </div>
                    <GoogleButton 
                      className="danger"
                      size="sm"
                      onClick={() => handleDeletePermission(permission.id)}
                    >
                      <FaTrash />
                    </GoogleButton>
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </div>
          ))
        )}
      </Modal.Body>
      <Modal.Footer>
        <GoogleButton onClick={onHide}>
          Close
        </GoogleButton>
      </Modal.Footer>
    </Modal>
  );
};

export default ManagePermissionsModal;