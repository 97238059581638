import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { Button, Form, Spinner, Alert } from 'react-bootstrap';
import { FaUpload, FaTimes, FaFileUpload, FaFile } from 'react-icons/fa';

// Estilos comunes para los modales
const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background-color: white;
  padding: 24px;
  border-radius: 8px;
  width: ${props => props.width || '400px'};
  max-width: 90%;
  max-height: 90vh;
  overflow-y: auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const DropZone = styled.div`
  border: 2px dashed ${props => props.isDragActive ? '#1a73e8' : '#dadce0'};
  border-radius: 8px;
  padding: 40px 20px;
  text-align: center;
  margin-bottom: 20px;
  background-color: ${props => props.isDragActive ? 'rgba(26, 115, 232, 0.05)' : '#f8f9fa'};
  cursor: pointer;
  transition: all 0.3s ease;
  
  &:hover {
    border-color: #1a73e8;
    background-color: rgba(26, 115, 232, 0.05);
  }
`;

const FileInput = styled.input`
  display: none;
`;

const PreviewContainer = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
`;

const ImagePreview = styled.img`
  max-width: 100%;
  max-height: 200px;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
`;

// Modal de Logo
export const LogoModal = ({ 
  show, 
  onClose, 
  onSave, 
  loading 
}) => {
  const [activeTab, setActiveTab] = useState('file');
  const [logoUrl, setLogoUrl] = useState('');
  const [logoFile, setLogoFile] = useState(null);
  const [logoPreview, setLogoPreview] = useState('');
  const [isDragActive, setIsDragActive] = useState(false);
  const [error, setError] = useState(null);
  
  const fileInputRef = useRef(null);
  const dropZoneRef = useRef(null);
  
  // Configurar eventos de arrastrar y soltar
  useEffect(() => {
    const handleDragOver = (e) => {
      e.preventDefault();
      setIsDragActive(true);
    };

    const handleDragLeave = () => {
      setIsDragActive(false);
    };

    const handleDrop = (e) => {
      e.preventDefault();
      setIsDragActive(false);
      
      if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
        handleFileChange(e.dataTransfer.files[0]);
      }
    };

    const dropZone = dropZoneRef.current;
    if (dropZone && show) {
      dropZone.addEventListener('dragover', handleDragOver);
      dropZone.addEventListener('dragleave', handleDragLeave);
      dropZone.addEventListener('drop', handleDrop);
    }

    return () => {
      if (dropZone) {
        dropZone.removeEventListener('dragover', handleDragOver);
        dropZone.removeEventListener('dragleave', handleDragLeave);
        dropZone.removeEventListener('drop', handleDrop);
      }
    };
  }, [show]);
  
  // Resetear el estado cuando se abre el modal
  useEffect(() => {
    if (show) {
      setActiveTab('file');
      setLogoUrl('');
      setLogoFile(null);
      setLogoPreview('');
      setError(null);
    }
  }, [show]);

  const handleUrlChange = (e) => {
    setLogoUrl(e.target.value);
    setLogoFile(null);
    setLogoPreview('');
  };

  const handleFileChange = (file) => {
    if (!file) return;
    
    // Validar tipo de archivo
    const validTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/webp'];
    if (!validTypes.includes(file.type)) {
      setError('Por favor, selecciona un archivo de imagen válido (JPEG, PNG, GIF, WEBP)');
      return;
    }
    
    // Validar tamaño de archivo (5MB máx)
    if (file.size > 5 * 1024 * 1024) {
      setError('El archivo es demasiado grande. Por favor, selecciona una imagen menor a 5MB');
      return;
    }
    
    setLogoFile(file);
    setLogoUrl('');
    setError(null);
    
    // Crear vista previa
    const reader = new FileReader();
    reader.onloadend = () => {
      setLogoPreview(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const handleDropZoneClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleSave = () => {
    if (activeTab === 'file' && logoFile) {
      onSave(logoFile);
    } else if (activeTab === 'url' && logoUrl) {
      onSave(logoUrl);
    } else {
      setError('Por favor, proporciona una imagen o una URL');
    }
  };

  if (!show) return null;

  return (
    <ModalOverlay>
      <ModalContent>
        <h4 className="mb-3">Actualizar Logo</h4>
        
        {error && (
          <Alert variant="danger" className="mb-3" onClose={() => setError(null)} dismissible>
            {error}
          </Alert>
        )}
        
        <div className="mb-4">
          <ul className="nav nav-tabs">
            <li className="nav-item">
              <a 
                className={`nav-link ${activeTab === 'file' ? 'active' : ''}`} 
                href="#file-tab" 
                onClick={(e) => {
                  e.preventDefault();
                  setActiveTab('file');
                  setLogoUrl('');
                }}
              >
                Subir Imagen
              </a>
            </li>
            <li className="nav-item">
              <a 
                className={`nav-link ${activeTab === 'url' ? 'active' : ''}`} 
                href="#url-tab" 
                onClick={(e) => {
                  e.preventDefault();
                  setActiveTab('url');
                  setLogoFile(null);
                  setLogoPreview('');
                }}
              >
                URL
              </a>
            </li>
          </ul>
        </div>
        
        {activeTab === 'url' && (
          <Form.Group className="mb-3">
            <Form.Label>URL del nuevo logo</Form.Label>
            <Form.Control
              type="text"
              value={logoUrl}
              onChange={handleUrlChange}
              placeholder="Ingrese la URL de la imagen"
            />
          </Form.Group>
        )}
        
        {activeTab === 'file' && !logoPreview && (
          <DropZone 
            ref={dropZoneRef}
            isDragActive={isDragActive}
            onClick={handleDropZoneClick}
          >
            <FaUpload size={24} className="mb-2" />
            <p className="mb-0">Arrastra una imagen aquí o haz clic para seleccionar</p>
            <small className="text-muted d-block mt-2">Formatos soportados: JPG, PNG, GIF, WEBP (máx. 5MB)</small>
            <FileInput
              ref={fileInputRef}
              type="file"
              accept="image/jpeg,image/png,image/gif,image/webp"
              onChange={(e) => {
                if (e.target.files && e.target.files.length > 0) {
                  handleFileChange(e.target.files[0]);
                }
              }}
            />
          </DropZone>
        )}
        
        {logoPreview && (
          <PreviewContainer>
            <ImagePreview src={logoPreview} alt="Vista previa" />
            <Button 
              variant="link" 
              className="mt-2 text-danger"
              onClick={() => {
                setLogoFile(null);
                setLogoPreview('');
              }}
            >
              <FaTimes /> Eliminar
            </Button>
          </PreviewContainer>
        )}
        
        <div className="d-flex justify-content-end">
          <Button 
            variant="secondary" 
            className="me-2"
            onClick={onClose}
            disabled={loading}
          >
            Cancelar
          </Button>
          <Button 
            variant="primary" 
            onClick={handleSave}
            disabled={loading || (activeTab === 'url' && !logoUrl) || (activeTab === 'file' && !logoFile)}
          >
            {loading ? <Spinner size="sm" animation="border" /> : 'Guardar'}
          </Button>
        </div>
      </ModalContent>
    </ModalOverlay>
  );
};

// Modal para Añadir/Editar Miembro
export const MemberModal = ({
  show,
  onClose,
  onSave,
  loading,
  member = null,
  isEditing = false
}) => {
  const [name, setName] = useState('');
  const [position, setPosition] = useState('');
  const [description, setDescription] = useState('');
  const [avatarFile, setAvatarFile] = useState(null);
  const [avatarPreview, setAvatarPreview] = useState('');
  const [isDragActive, setIsDragActive] = useState(false);
  const [error, setError] = useState(null);
  
  const fileInputRef = useRef(null);
  const dropZoneRef = useRef(null);

  // Resetear el estado cuando se abre el modal o cambia el miembro
  useEffect(() => {
    if (show) {
      setError(null);
      
      if (isEditing && member) {
        setName(member.name || '');
        setPosition(member.position || '');
        setDescription(member.description || '');
        
        if (member.avatar) {
          setAvatarPreview(member.avatar);
        } else {
          setAvatarPreview('');
        }
      } else {
        setName('');
        setPosition('');
        setDescription('');
        setAvatarFile(null);
        setAvatarPreview('');
      }
    }
  }, [show, member, isEditing]);

  // Configurar eventos de arrastrar y soltar
  useEffect(() => {
    const handleDragOver = (e) => {
      e.preventDefault();
      setIsDragActive(true);
    };

    const handleDragLeave = () => {
      setIsDragActive(false);
    };

    const handleDrop = (e) => {
      e.preventDefault();
      setIsDragActive(false);
      
      if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
        handleFileChange(e.dataTransfer.files[0]);
      }
    };

    const dropZone = dropZoneRef.current;
    if (dropZone && show && !isEditing) {
      dropZone.addEventListener('dragover', handleDragOver);
      dropZone.addEventListener('dragleave', handleDragLeave);
      dropZone.addEventListener('drop', handleDrop);
    }

    return () => {
      if (dropZone) {
        dropZone.removeEventListener('dragover', handleDragOver);
        dropZone.removeEventListener('dragleave', handleDragLeave);
        dropZone.removeEventListener('drop', handleDrop);
      }
    };
  }, [show, isEditing]);

  const handleFileChange = (file) => {
    if (!file) return;
    
    // Validar tipo de archivo
    const validTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/webp'];
    if (!validTypes.includes(file.type)) {
      setError('Por favor, selecciona un archivo de imagen válido (JPEG, PNG, GIF, WEBP)');
      return;
    }
    
    // Validar tamaño de archivo (5MB máx)
    if (file.size > 5 * 1024 * 1024) {
      setError('El archivo es demasiado grande. Por favor, selecciona una imagen menor a 5MB');
      return;
    }
    
    setAvatarFile(file);
    setError(null);
    
    // Crear vista previa
    const reader = new FileReader();
    reader.onloadend = () => {
      setAvatarPreview(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const handleDropZoneClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleSave = () => {
    // Validación
    if (!name.trim()) {
      setError('El nombre es obligatorio');
      return;
    }
    
    if (!position.trim()) {
      setError('El cargo es obligatorio');
      return;
    }
    
    // Crear objeto de datos
    const memberData = {
      name: name.trim(),
      position: position.trim(),
      description: description.trim()
    };
    
    // Si estamos editando y no hay nuevo avatar, solo enviamos los datos
    if (isEditing && !avatarFile) {
      onSave(memberData);
    } else {
      // Si estamos creando o hay nuevo avatar, enviamos ambos
      onSave(memberData, avatarFile);
    }
  };

  if (!show) return null;

  return (
    <ModalOverlay>
      <ModalContent width="500px">
        <h4 className="mb-3">{isEditing ? 'Editar' : 'Añadir'} Miembro</h4>
        
        {error && (
          <Alert variant="danger" className="mb-3" onClose={() => setError(null)} dismissible>
            {error}
          </Alert>
        )}
        
        <Form>
          <Form.Group className="mb-3">
            <Form.Label>Nombre</Form.Label>
            <Form.Control
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Nombre del miembro"
              required
            />
          </Form.Group>
          
          <Form.Group className="mb-3">
            <Form.Label>Cargo</Form.Label>
            <Form.Control
              type="text"
              value={position}
              onChange={(e) => setPosition(e.target.value)}
              placeholder="Cargo del miembro"
              required
            />
          </Form.Group>
          
          <Form.Group className="mb-3">
            <Form.Label>Descripción</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Breve descripción del miembro"
            />
          </Form.Group>
          
          {!isEditing ? (
            <Form.Group className="mb-3">
              <Form.Label>Avatar</Form.Label>
              {!avatarPreview ? (
                <DropZone 
                  ref={dropZoneRef}
                  isDragActive={isDragActive}
                  onClick={handleDropZoneClick}
                >
                  <FaUpload size={24} className="mb-2" />
                  <p className="mb-0">Arrastra una imagen aquí o haz clic para seleccionar</p>
                  <small className="text-muted d-block mt-2">Formatos soportados: JPG, PNG, GIF, WEBP (máx. 5MB)</small>
                  <FileInput
                    ref={fileInputRef}
                    type="file"
                    accept="image/jpeg,image/png,image/gif,image/webp"
                    onChange={(e) => {
                      if (e.target.files && e.target.files.length > 0) {
                        handleFileChange(e.target.files[0]);
                      }
                    }}
                  />
                </DropZone>
              ) : (
                <PreviewContainer>
                  <ImagePreview src={avatarPreview} alt="Vista previa" />
                  <Button 
                    variant="link" 
                    className="mt-2 text-danger"
                    onClick={() => {
                      setAvatarFile(null);
                      setAvatarPreview('');
                    }}
                  >
                    <FaTimes /> Eliminar
                  </Button>
                </PreviewContainer>
              )}
            </Form.Group>
          ) : (
            avatarPreview && (
              <PreviewContainer>
                <p>Avatar actual:</p>
                <ImagePreview src={avatarPreview} alt="Avatar actual" style={{ width: '100px', height: '100px', objectFit: 'cover' }} />
              </PreviewContainer>
            )
          )}
        </Form>
        
        <div className="d-flex justify-content-end">
          <Button 
            variant="secondary" 
            className="me-2"
            onClick={onClose}
            disabled={loading}
          >
            Cancelar
          </Button>
          <Button 
            variant="primary" 
            onClick={handleSave}
            disabled={loading}
          >
            {loading ? <Spinner size="sm" animation="border" /> : (isEditing ? 'Actualizar' : 'Guardar')}
          </Button>
        </div>
      </ModalContent>
    </ModalOverlay>
  );
};

// Modal para Subir Avatar
export const AvatarModal = ({
  show,
  onClose,
  onSave,
  loading,
  currentAvatar
}) => {
  const [avatarFile, setAvatarFile] = useState(null);
  const [avatarPreview, setAvatarPreview] = useState('');
  const [isDragActive, setIsDragActive] = useState(false);
  const [error, setError] = useState(null);
  
  const fileInputRef = useRef(null);
  const dropZoneRef = useRef(null);
  
  // Resetear el estado cuando se abre el modal
  useEffect(() => {
    if (show) {
      setAvatarFile(null);
      setAvatarPreview('');
      setError(null);
    }
  }, [show]);
  
  // Configurar eventos de arrastrar y soltar
  useEffect(() => {
    const handleDragOver = (e) => {
      e.preventDefault();
      setIsDragActive(true);
    };

    const handleDragLeave = () => {
      setIsDragActive(false);
    };

    const handleDrop = (e) => {
      e.preventDefault();
      setIsDragActive(false);
      
      if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
        handleFileChange(e.dataTransfer.files[0]);
      }
    };

    const dropZone = dropZoneRef.current;
    if (dropZone && show) {
      dropZone.addEventListener('dragover', handleDragOver);
      dropZone.addEventListener('dragleave', handleDragLeave);
      dropZone.addEventListener('drop', handleDrop);
    }

    return () => {
      if (dropZone) {
        dropZone.removeEventListener('dragover', handleDragOver);
        dropZone.removeEventListener('dragleave', handleDragLeave);
        dropZone.removeEventListener('drop', handleDrop);
      }
    };
  }, [show]);
  
  const handleFileChange = (file) => {
    if (!file) return;
    
    // Validar tipo de archivo
    const validTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/webp'];
    if (!validTypes.includes(file.type)) {
      setError('Por favor, selecciona un archivo de imagen válido (JPEG, PNG, GIF, WEBP)');
      return;
    }
    
    // Validar tamaño de archivo (5MB máx)
    if (file.size > 5 * 1024 * 1024) {
      setError('El archivo es demasiado grande. Por favor, selecciona una imagen menor a 5MB');
      return;
    }
    
    setAvatarFile(file);
    setError(null);
    
    // Crear vista previa
    const reader = new FileReader();
    reader.onloadend = () => {
      setAvatarPreview(reader.result);
    };
    reader.readAsDataURL(file);
  };
  
  const handleDropZoneClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  
  const handleSave = () => {
    if (!avatarFile) {
      setError('Por favor, selecciona una imagen para el avatar');
      return;
    }
    
    onSave(avatarFile);
  };
  
  if (!show) return null;
  
  return (
    <ModalOverlay>
      <ModalContent>
        <h4 className="mb-3">Actualizar Avatar</h4>
        
        {error && (
          <Alert variant="danger" className="mb-3" onClose={() => setError(null)} dismissible>
            {error}
          </Alert>
        )}
        
        {currentAvatar && (
          <PreviewContainer>
            <p>Avatar actual:</p>
            <ImagePreview src={currentAvatar} alt="Avatar actual" style={{ width: '100px', height: '100px', objectFit: 'cover' }} />
          </PreviewContainer>
        )}
        
        {!avatarPreview ? (
          <DropZone 
            ref={dropZoneRef}
            isDragActive={isDragActive}
            onClick={handleDropZoneClick}
          >
            <FaUpload size={24} className="mb-2" />
            <p className="mb-0">Arrastra una imagen aquí o haz clic para seleccionar</p>
            <small className="text-muted d-block mt-2">Formatos soportados: JPG, PNG, GIF, WEBP (máx. 5MB)</small>
            <FileInput
              ref={fileInputRef}
              type="file"
              accept="image/jpeg,image/png,image/gif,image/webp"
              onChange={(e) => {
                if (e.target.files && e.target.files.length > 0) {
                  handleFileChange(e.target.files[0]);
                }
              }}
            />
          </DropZone>
        ) : (
          <PreviewContainer>
            <ImagePreview src={avatarPreview} alt="Vista previa" />
            <Button 
              variant="link" 
              className="mt-2 text-danger"
              onClick={() => {
                setAvatarFile(null);
                setAvatarPreview('');
              }}
            >
              <FaTimes /> Eliminar
            </Button>
          </PreviewContainer>
        )}
        
        <div className="d-flex justify-content-end">
          <Button 
            variant="secondary" 
            className="me-2"
            onClick={onClose}
            disabled={loading}
          >
            Cancelar
          </Button>
          <Button 
            variant="primary" 
            onClick={handleSave}
            disabled={loading || !avatarFile}
          >
            {loading ? <Spinner size="sm" animation="border" /> : 'Guardar'}
          </Button>
        </div>
      </ModalContent>
    </ModalOverlay>
  );
};

// Modal para Añadir/Editar Documento
export const DocumentModal = ({
  show,
  onClose,
  onSave,
  loading
}) => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [documentFile, setDocumentFile] = useState(null);
  const [documentName, setDocumentName] = useState('');
  const [isDragActive, setIsDragActive] = useState(false);
  const [error, setError] = useState(null);
  
  const fileInputRef = useRef(null);
  const dropZoneRef = useRef(null);
  
  // Resetear el estado cuando se abre el modal
  useEffect(() => {
    if (show) {
      setTitle('');
      setDescription('');
      setDocumentFile(null);
      setDocumentName('');
      setError(null);
    }
  }, [show]);
  
  // Configurar eventos de arrastrar y soltar
  useEffect(() => {
    const handleDragOver = (e) => {
      e.preventDefault();
      setIsDragActive(true);
    };

    const handleDragLeave = () => {
      setIsDragActive(false);
    };

    const handleDrop = (e) => {
      e.preventDefault();
      setIsDragActive(false);
      
      if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
        handleFileChange(e.dataTransfer.files[0]);
      }
    };

    const dropZone = dropZoneRef.current;
    if (dropZone && show) {
      dropZone.addEventListener('dragover', handleDragOver);
      dropZone.addEventListener('dragleave', handleDragLeave);
      dropZone.addEventListener('drop', handleDrop);
    }

    return () => {
      if (dropZone) {
        dropZone.removeEventListener('dragover', handleDragOver);
        dropZone.removeEventListener('dragleave', handleDragLeave);
        dropZone.removeEventListener('drop', handleDrop);
      }
    };
  }, [show]);
  
  const handleFileChange = (file) => {
    if (!file) return;
    
    // Validar tamaño de archivo (10MB máx)
    if (file.size > 10 * 1024 * 1024) {
      setError('El archivo es demasiado grande. Por favor, selecciona un documento menor a 10MB');
      return;
    }
    
    setDocumentFile(file);
    setDocumentName(file.name);
    setError(null);
  };
  
  const handleDropZoneClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  
  const handleSave = () => {
    // Validación
    if (!title.trim()) {
      setError('El título es obligatorio');
      return;
    }
    
    if (!documentFile) {
      setError('Por favor, selecciona un documento');
      return;
    }
    
    // Crear objeto de datos
    const documentData = {
      title: title.trim(),
      description: description.trim()
    };
    
    onSave(documentData, documentFile);
  };
  
  if (!show) return null;
  
  return (
    <ModalOverlay>
      <ModalContent>
        <h4 className="mb-3">Añadir Documento</h4>
        
        {error && (
          <Alert variant="danger" className="mb-3" onClose={() => setError(null)} dismissible>
            {error}
          </Alert>
        )}
        
        <Form>
          <Form.Group className="mb-3">
            <Form.Label>Título</Form.Label>
            <Form.Control
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              placeholder="Título del documento"
              required
            />
          </Form.Group>
          
          <Form.Group className="mb-3">
            <Form.Label>Descripción</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Breve descripción del documento"
            />
          </Form.Group>
          
          <Form.Group className="mb-3">
            <Form.Label>Documento</Form.Label>
            {!documentFile ? (
              <DropZone 
                ref={dropZoneRef}
                isDragActive={isDragActive}
                onClick={handleDropZoneClick}
              >
                <FaFileUpload size={24} className="mb-2" />
                <p className="mb-0">Arrastra un documento aquí o haz clic para seleccionar</p>
                <small className="text-muted d-block mt-2">Tamaño máximo: 10MB</small>
                <FileInput
                  ref={fileInputRef}
                  type="file"
                  onChange={(e) => {
                    if (e.target.files && e.target.files.length > 0) {
                      handleFileChange(e.target.files[0]);
                    }
                  }}
                />
              </DropZone>
            ) : (
              <div className="p-3 bg-light rounded border">
                <div className="d-flex align-items-center">
                  <FaFile size={24} className="me-2" />
                  <div className="flex-grow-1">
                    <div>{documentName}</div>
                    <small className="text-muted">
                      {(documentFile.size / 1024).toFixed(2)} KB
                    </small>
                  </div>
                  <Button 
                    variant="link" 
                    className="text-danger"
                    onClick={() => {
                      setDocumentFile(null);
                      setDocumentName('');
                    }}
                  >
                    <FaTimes />
                  </Button>
                </div>
              </div>
            )}
          </Form.Group>
        </Form>
        
        <div className="d-flex justify-content-end">
          <Button 
            variant="secondary" 
            className="me-2"
            onClick={onClose}
            disabled={loading}
          >
            Cancelar
          </Button>
          <Button 
            variant="primary" 
            onClick={handleSave}
            disabled={loading || !title.trim() || !documentFile}
          >
            {loading ? <Spinner size="sm" animation="border" /> : 'Guardar'}
          </Button>
        </div>
      </ModalContent>
    </ModalOverlay>
  );
};