import axios from 'axios';
import config from '../config';

const api = axios.create({
  baseURL: config.apiBaseUrl,
});

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      // Token expirado o inválido
      clearAuthToken();
      // Aquí puedes agregar lógica adicional, como redirigir al login
      window.location.reload();
    }
    return Promise.reject(error);
  }
);

// Auth
export const getAuthToken = () => {
  return localStorage.getItem('authToken');
};

export const setAuthToken = (token) => {
  if (token) {
    localStorage.setItem('authToken', token);
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  } else {
    clearAuthToken();
  }
};

export const clearAuthToken = () => {
  localStorage.removeItem('authToken');
  delete api.defaults.headers.common['Authorization'];
};

export const login = async (email, password) => {
  try {
    const response = await api.post('/auth/v1/emailLogin', null, {
      params: { email, password },
    });
    if (response.data && response.data.firebase_auth_data) {
      setAuthToken(response.data.firebase_auth_data.id_token);
    }
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : error.message;
  }
};

export const logout = () => {
  clearAuthToken();
};

export const getClientModules = async (clientId) => {
  try {
    // Obtener información básica del cliente
    const clientResponse = await api.get(`/api/clients/${clientId}`);
    const client = clientResponse.data;
    
    // Obtener módulos accesibles para este cliente según permisos del usuario
    const modulesResponse = await api.get(`/api/modules/client/${clientId}`);
    const modules = modulesResponse.data;
    
    // Combinar la información
    return {
      ...client,
      modules: modules
    };
  } catch (error) {
    throw error.response ? error.response.data : error.message;
  }
};


// API INSURANCE
export const fetchInsurances = async (params) => {
  try {
    const response = await api.get('/api/insurancesRequest/agent', { params });
    return response.data;
  } catch (error) {
    console.error('Error fetching insurances:', error);
    throw error;
  }
};

export const addNote = async (insuranceId, text) => {
  try {
    await api.post(`/api/insurancesRequest/${insuranceId}/notes`, { text });
  } catch (error) {
    console.error('Error adding note:', error);
    throw error;
  }
};

export const editNote = async (insuranceId, noteIndex, text) => {
  try {
    await api.put(`/api/insurancesRequest/${insuranceId}/notes/${noteIndex}`, { text });
  } catch (error) {
    console.error('Error editing note:', error);
    throw error;
  }
};

export const deleteNote = async (insuranceId, noteIndex) => {
  try {
    await api.delete(`/api/insurancesRequest/${insuranceId}/notes/${noteIndex}`);
  } catch (error) {
    console.error('Error deleting note:', error);
    throw error;
  }
};

export const changeStatus = async (insuranceId, newStatus, note) => {
  try {
    await api.put(`/api/insurancesRequest/${insuranceId}/status`, null, { params: { newStatus, note } });
  } catch (error) {
    console.error('Error changing status:', error);
    throw error;
  }
};

export const addDocument = async (insuranceId, formData) => {
  try {
    await api.post(`/api/insurancesRequest/${insuranceId}/documents`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' }
    });
  } catch (error) {
    console.error('Error adding document:', error);
    throw error;
  }
};

export const deleteDocument = async (insuranceId, documentIndex) => {
  try {
    await api.delete(`/api/insurancesRequest/${insuranceId}/documents/${documentIndex}`);
  } catch (error) {
    console.error('Error deleting document:', error);
    throw error;
  }
};

export const updateContactInfo = async (insuranceId, contactInfo) => {
  try {
    await api.put(`/api/insurancesRequest/${insuranceId}/contact`, contactInfo);
  } catch (error) {
    console.error('Error updating contact info:', error);
    throw error;
  }
};

export const getAvailableStatuses = async (insuranceId) => {
  try {
    const response = await api.get(`/api/insurancesRequest/${insuranceId}/available-statuses`);
    return response.data.data;
  } catch (error) {
    console.error('Error fetching available statuses:', error);
    throw error;
  }
};

export const fetchInsurancesAll = async (params) => {
  try {
    const response = await api.get('/api/insurancesRequest', { params });
    return response.data;
  } catch (error) {
    console.error('Error fetching insurances:', error);
    throw error;
  }
};

export const fetchAgents = async () => {
  try {
    const response = await api.get('/api/workers/by-role-health-insurance-agent');
    return response.data;
  } catch (error) {
    console.error('Error fetching agents:', error);
    throw error;
  }
};

export const assignAgent = async (insuranceIds, agentId, clientNotify) => {
  try {
    const params = new URLSearchParams();
    insuranceIds.forEach(id => params.append('ids', id));
    params.append('agentId', agentId);
    params.append('clientNotify', clientNotify);

    const response = await api.put('/api/insurancesRequest/agent/assign', null, { params });

    if (response.data.error) {
      throw new Error(response.data.error);
    }

    return response.data;
  } catch (error) {
    console.error('Error assigning agent:', error);
    throw error;
  }
};

// API NOTARY

export const getNotaryOrders = async (params) => {
  try {
    const queryParams = {};
    
    // Solo añadir parámetros que no estén vacíos
    if (params.page !== undefined) queryParams.page = params.page;
    if (params.size !== undefined) queryParams.size = params.size;
    if (params.userId) queryParams.userId = params.userId;
    if (params.status) queryParams.status = params.status;
    if (params.email) queryParams.email = params.email;
    if (params.name) queryParams.name = params.name;
    if (params.phone) queryParams.phone = params.phone;

    const response = await api.get('/notary/v1/by-notary', { params: queryParams });
    return response.data;
  } catch (error) {
    console.error('Error fetching notary orders:', error);
    throw error;
  }
};

export const getAllNotaryOrders = async (params) => {
  try {
    const queryParams = {};
    
    // Solo añadir parámetros que no estén vacíos
    if (params.page !== undefined) queryParams.page = params.page;
    if (params.size !== undefined) queryParams.size = params.size;
    if (params.userId) queryParams.userId = params.userId;
    if (params.status) queryParams.status = params.status;
    if (params.email) queryParams.email = params.email;
    if (params.name) queryParams.name = params.name;
    if (params.phone) queryParams.phone = params.phone;

    const response = await api.get('/notary/v1', { params: queryParams });
    return response.data;
  } catch (error) {
    console.error('Error fetching notary orders:', error);
    throw error;
  }
};

export const updateNotaryOrderStatus = async (orderId, status) => {
  try {
    const response = await api.post(`/notary/v1/${orderId}/status`, null, { params: { status } });
    return response.data;
  } catch (error) {
    console.error('Error updating notary order status:', error);
    throw error;
  }
};

export const updatePrice = async (orderId, price) => {
  try {
    const response = await api.post(`/notary/v1/${orderId}/price`, null, { params: { price } });
    return response.data;
  } catch (error) {
    console.error('Error updating notary order status:', error);
    throw error;
  }
};

export const getAvailableNotaryStatuses = async (orederId) => {
  try {
    const response = await api.get(`/notary/v1/${orederId}/available-statuses`);
    return response.data.data;
  } catch (error) {
    console.error('Error fetching available statuses:', error);
    throw error;
  }
};

export const sendNotificationToUser = async (userId, insuranceRequestId) => {
  try {
    const response = await api.post('/api/notifications/send', { user_id: userId, insurance_request_id: insuranceRequestId });
    return response.data;
  } catch (error) {
    console.error('Error sending notification:', error);
    throw error;
  }
};

export const addContactStatus = async (insuranceId, newContactStatus) => {
  try {
    const response = await api.put(`/api/insurancesRequest/${insuranceId}/contactStatus`, newContactStatus);
    return response.data;
  } catch (error) {
    console.error('Error adding contact status:', error);
    throw error;
  }
};

export const getExtendedOrderStatistics = async (startDate, endDate) => {
  try {
    const response = await api.get('/notary/v1/statistics', {
      params: {
        startDate,
        endDate
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching extended order statistics:', error);
    throw error;
  }
};

export const assignNotaryAgent = async (orderIds, notaryId, clientNotify = false) => {
  try {
    const params = new URLSearchParams();
    orderIds.forEach(id => params.append('ids', id));
    params.append('notaryId', notaryId);
    params.append('clientNotify', clientNotify);

    const response = await api.put('/notary/v1/assign', null, { params });

    if (response.data.error) {
      throw new Error(response.data.error);
    }

    return response.data;
  } catch (error) {
    console.error('Error assigning notary agent:', error);
    throw error;
  }
};

// User Management API calls
export const fetchUsers = async (params) => {
  try {
    const response = await api.get('/api/workers', { params });
    return response.data;
  } catch (error) {
    console.error('Error fetching users:', error);
    throw error;
  }
};

export const createUser = async (userData) => {
  try {
    const response = await api.post('/api/workers', userData);
    return response.data;
  } catch (error) {
    console.error('Error creating user:', error);
    throw error;
  }
};

export const updateUserName = async (userId, name) => {
  try {
    const response = await api.put(`/api/workers/${userId}/name`, { name });
    return response.data;
  } catch (error) {
    console.error('Error updating user name:', error);
    throw error;
  }
};

export const addUserRole = async (userId, role) => {
  try {
    const response = await api.post(`/api/workers/${userId}/roles`, role);
    return response.data;
  } catch (error) {
    console.error('Error adding user role:', error);
    throw error;
  }
};

export const removeUserRole = async (userId, role) => {
  try {
    const response = await api.delete(`/api/workers/${userId}/roles/${role}`);
    return response.data;
  } catch (error) {
    console.error('Error removing user role:', error);
    throw error;
  }
};

export const updateUserContactInfo = async (userId, contactInfo) => {
  try {
    const response = await api.put(`/api/workers/${userId}/contact-info`, contactInfo);
    return response.data;
  } catch (error) {
    console.error('Error updating user contact info:', error);
    throw error;
  }
};

export const invalidateAccount = async (userId) => {
  try {
    const response = await api.post('/auth/v1/invalidateAccount', null, {
      params: { uid: userId }
    });
    return response.data;
  } catch (error) {
    console.error('Error invalidating account:', error);
    throw error;
  }
};

export const fetchNotaryAgents = async () => {
  try {
    const response = await api.get('/api/workers/by-role-notary-agent');
    return response.data;
  } catch (error) {
    console.error('Error fetching notary agents:', error);
    throw error;
  }
};

export default api;