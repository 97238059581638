import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Alert, Spinner } from 'react-bootstrap';
import { createJob, updateJob, getJobById } from './apiJobs';

const JobFormModal = ({ show, onHide, jobId, onJobSaved }) => {
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    cronExpression: '',
    jobType: 'RSS_FETCH',
    status: 'ACTIVE',
    isRecurring: true,
    priority: 0,
    allowMultipleQueue: false
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    if (show) {
      if (jobId) {
        setIsEditing(true);
        loadJobData();
      } else {
        setIsEditing(false);
        resetForm();
      }
    }
  }, [show, jobId]);

  const loadJobData = async () => {
    setLoading(true);
    try {
      const response = await getJobById(jobId);
      const job = response.data;
      
      // Handle both camelCase and snake_case properties
      setFormData({
        name: job.name,
        description: job.description || '',
        cronExpression: job.cronExpression || job.cron_expression,
        jobType: job.jobType || job.job_type,
        status: job.status,
        isRecurring: job.isRecurring !== undefined ? job.isRecurring : 
                    job.is_recurring !== undefined ? job.is_recurring : true,
        priority: job.priority || 0,
        allowMultipleQueue: job.allowMultipleQueue || job.allow_multiple_queue || false
      });
    } catch (error) {
      setError('Failed to load job details');
      console.error('Error loading job:', error);
    } finally {
      setLoading(false);
    }
  };

  const resetForm = () => {
    setFormData({
      name: '',
      description: '',
      cronExpression: '',
      jobType: 'RSS_FETCH',
      status: 'ACTIVE',
      isRecurring: true,
      priority: 0,
      allowMultipleQueue: false
    });
    setError(null);
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleNumberChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: parseInt(value, 10) || 0
    }));
  };

  const validateForm = () => {
    if (!formData.name.trim()) return 'Name is required';
    if (!formData.cronExpression.trim()) return 'Cron expression is required';
    if (!isEditing && !formData.jobType) return 'Job type is required';
    return null;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationError = validateForm();
    if (validationError) {
      setError(validationError);
      return;
    }

    setLoading(true);
    setError(null);

    try {
      // The API expects both camelCase and snake_case properties
      const apiData = {
        name: formData.name,
        description: formData.description,
        cron_expression: formData.cronExpression,
        is_recurring: formData.isRecurring,
        priority: formData.priority,
        allow_multiple_queue: formData.allowMultipleQueue
      };

      if (isEditing) {
        // When updating, add status
        apiData.status = formData.status;
        await updateJob(jobId, apiData);
      } else {
        // When creating, include jobType
        apiData.jobType = formData.jobType;
        apiData.job_type = formData.jobType;
        await createJob(apiData);
      }
      
      onHide();
      if (onJobSaved) onJobSaved();
    } catch (error) {
      setError('Failed to save job. Please try again.');
      console.error('Error saving job:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal show={show} onHide={onHide} backdrop="static">
      <Form onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>
            {isEditing ? 'Edit Job' : 'Create New Job'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loading && !isEditing ? (
            <div className="text-center p-4">
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          ) : (
            <>
              {error && <Alert variant="danger">{error}</Alert>}
              
              <Form.Group className="mb-3">
                <Form.Label>Name*</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  placeholder="Enter job name"
                  required
                />
              </Form.Group>
              
              <Form.Group className="mb-3">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  as="textarea"
                  name="description"
                  value={formData.description}
                  onChange={handleChange}
                  placeholder="Enter job description"
                  rows={3}
                />
              </Form.Group>
              
              <Form.Group className="mb-3">
                <Form.Label>Cron Expression*</Form.Label>
                <Form.Control
                  type="text"
                  name="cronExpression"
                  value={formData.cronExpression}
                  onChange={handleChange}
                  placeholder="e.g. 0 0 * * * (Run at midnight)"
                  required
                />
                <Form.Text className="text-muted">
                  Schedule using standard cron format (seconds minutes hours day-of-month month day-of-week)
                </Form.Text>
              </Form.Group>
              
              {!isEditing && (
                <Form.Group className="mb-3">
                  <Form.Label>Job Type*</Form.Label>
                  <Form.Select
                    name="jobType"
                    value={formData.jobType}
                    onChange={handleChange}
                  >
                    <option value="RSS_FETCH">RSS Feed Fetch</option>
                    <option value="YOUTUBE_FETCH">YouTube Fetch</option>
                    <option value="LOTTERY_FETCH_MIDDAY">Lottery Midday Fetch</option>
                    <option value="LOTTERY_FETCH_EVENING">Lottery Evening Fetch</option>
                    <option value="CURRENCY_FETCH">Currency Fetch</option>
                  </Form.Select>
                </Form.Group>
              )}
              
              {isEditing && (
                <Form.Group className="mb-3">
                  <Form.Label>Status</Form.Label>
                  <Form.Select
                    name="status"
                    value={formData.status}
                    onChange={handleChange}
                  >
                    <option value="ACTIVE">Active</option>
                    <option value="STOPPED">Stopped</option>
                  </Form.Select>
                </Form.Group>
              )}
              
              <hr className="my-4" />
              
              <h6 className="mb-3">Advanced Options</h6>
              
              <Form.Group className="mb-3">
                <Form.Check
                  type="checkbox"
                  id="isRecurring"
                  name="isRecurring"
                  label="Recurring Job (runs multiple times per day if scheduled)"
                  checked={formData.isRecurring}
                  onChange={handleChange}
                />
                <Form.Text className="text-muted">
                  If unchecked, the job will only execute once per day regardless of cron schedule
                </Form.Text>
              </Form.Group>
              
              <Form.Group className="mb-3">
                <Form.Label>Priority</Form.Label>
                <Form.Control
                  type="number"
                  name="priority"
                  value={formData.priority}
                  onChange={handleNumberChange}
                  min="0"
                  max="100"
                />
                <Form.Text className="text-muted">
                  Higher values indicate higher priority in the queue (0-100)
                </Form.Text>
              </Form.Group>
              
              <Form.Group className="mb-3">
                <Form.Check
                  type="checkbox"
                  id="allowMultipleQueue"
                  name="allowMultipleQueue"
                  label="Allow Multiple Queue Entries"
                  checked={formData.allowMultipleQueue}
                  onChange={handleChange}
                />
                <Form.Text className="text-muted">
                  If checked, the job can be added to the queue multiple times
                </Form.Text>
              </Form.Group>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onHide} disabled={loading}>
            Cancel
          </Button>
          <Button variant="primary" type="submit" disabled={loading}>
            {loading ? (
              <>
                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="me-2" />
                Saving...
              </>
            ) : (
              'Save'
            )}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default JobFormModal;