import api from '../apiBase';

export const getArticles = async (params) => {
  try {
    const queryParams = {};
    
    if (params.searchTerm) queryParams.searchTerm = params.searchTerm;
    if (params.siteId) queryParams.siteId = params.siteId;
    if (params.visible !== undefined && params.visible !== '') {
      queryParams.visible = params.visible === 'true';
    }
    if (params.page !== undefined && !isNaN(params.page)) queryParams.page = params.page;
    if (params.size !== undefined && !isNaN(params.size)) queryParams.size = params.size;

    const response = await api.get('/articles_manager/v1/', { params: queryParams });
    return response.data;
  } catch (error) {
    console.error('Error fetching articles:', error);
    throw error;
  }
};

export const getArticleById = async (id) => {
  try {
    const response = await api.get(`/articles_manager/v1/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching article:', error);
    throw error;
  }
};

export const createArticle = async (article) => {
  try {
    const response = await api.post('/articles_manager/v1', article);
    return response.data;
  } catch (error) {
    console.error('Error creating article:', error);
    throw error;
  }
};

export const updateArticle = async (id, article) => {
  try {
    const response = await api.put(`/articles_manager/v1/${id}`, article);
    return response.data;
  } catch (error) {
    console.error('Error updating article:', error);
    throw error;
  }
};

export const deleteArticle = async (id) => {
  try {
    const response = await api.delete(`/articles_manager/v1/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting article:', error);
    throw error;
  }
};

// Updated function to use articleRssId for scraping
export const scrapeArticle = async (articleRssId) => {
  if (!articleRssId) {
    throw new Error('Article RSS ID is required for scraping');
  }
  
  console.log('Calling scrape API for article RSS ID:', articleRssId);
  
  try {
    const response = await api.post(`/articles_manager/v1/${articleRssId}/scrape`);
    return response.data;
  } catch (error) {
    console.error('Error scraping article:', error);
    throw error;
  }
};

// Updated function to use articleRssId for analysis
export const analyzeArticle = async (articleRssId) => {
  if (!articleRssId) {
    throw new Error('Article RSS ID is required for analysis');
  }

  try {
    const response = await api.post(`/articles_manager/v1/${articleRssId}/analyze`);
    return response.data;
  } catch (error) {
    console.error('Error analyzing article:', error);
    throw error;
  }
};

export const processArticlesByDateRange = async (startDate, endDate) => {
  try {
    const response = await api.post('/articles_manager/v1/process-by-date-range', null, { 
      params: { 
        startDate: startDate.toISOString().split('T')[0],
        endDate: endDate.toISOString().split('T')[0]
      } 
    });
    return response.data;
  } catch (error) {
    console.error('Error processing articles by date range:', error);
    throw error;
  }
};

export default api;