import api from '../apiBase';

// Get all clients
export const getAllClients = async () => {
  try {
    const response = await api.get('/api/clients/all');
    return response.data;
  } catch (error) {
    console.error('Error fetching clients:', error);
    throw error;
  }
};

// Get modules for a specific client
export const getClientModules = async (clientId) => {
  try {
    const response = await api.get('/api/clients', {
      params: { client_id: clientId }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching client modules:', error);
    throw error;
  }
};

// Get all modules for a client
export const getModulesByClientId = async (clientId) => {
  try {
    const response = await api.get(`/api/modules/client/${clientId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching modules for client:', error);
    throw error;
  }
};

// Get module details by ID
export const getModuleById = async (moduleId) => {
  try {
    const response = await api.get(`/api/modules/${moduleId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching module details:', error);
    throw error;
  }
};

// Get permissions for a specific module
export const getModulePermissions = async (moduleId) => {
  try {
    const response = await api.get(`/api/permissions/module/${moduleId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching module permissions:', error);
    throw error;
  }
};

// Get user's permissions
export const getUserPermissions = async () => {
  try {
    const response = await api.get('/api/permissions/user');
    return response.data;
  } catch (error) {
    console.error('Error fetching user permissions:', error);
    throw error;
  }
};

// Assign permission to a user for a module
export const assignPermission = async (userId, moduleId, accessLevel) => {
  try {
    const response = await api.post('/api/permissions/assign', {
      user_id: userId,
      module_id: moduleId,
      access_level: accessLevel
    });
    return response.data;
  } catch (error) {
    console.error('Error assigning permission:', error);
    throw error;
  }
};

// Delete a permission
export const deletePermission = async (permissionId) => {
  try {
    const response = await api.delete(`/api/permissions/${permissionId}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting permission:', error);
    throw error;
  }
};

// Export all the original worker management functions
export { 
  fetchUsers, 
  createUser, 
  updateUserName, 
  updateUserContactInfo, 
  invalidateAccount,
  fetchNotaryAgents
} from './apiAdmin';