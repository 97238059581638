import api from '../apiBase';

export const getCreatorProfiles = async () => {
  try {
    const response = await api.get('/api/creators');
    return response.data;
  } catch (error) {
    console.error('Error fetching creator profiles:', error);
    throw error;
  }
};

export const getCreatorProfileById = async (id) => {
  try {
    const response = await api.get(`/api/creators/${id}`);
    return response.data;
  } catch (error) {
    console.error(`Error fetching creator profile with id ${id}:`, error);
    throw error;
  }
};

export const getCreatorProfilesBySourceType = async (sourceType) => {
  try {
    const response = await api.get(`/api/creators/sources/${sourceType}`);
    return response.data;
  } catch (error) {
    console.error(`Error fetching creator profiles with source type ${sourceType}:`, error);
    throw error;
  }
};

export const getCreatorProfilesBySourceTypes = async (sourceTypes) => {
  try {
    const response = await api.post('/api/creators/sources/multiple', sourceTypes);
    return response.data;
  } catch (error) {
    console.error('Error fetching creator profiles by source types:', error);
    throw error;
  }
};

export const createCreatorProfile = async (profile) => {
  try {
    const response = await api.post('/api/creators', profile);
    return response.data;
  } catch (error) {
    console.error('Error creating creator profile:', error);
    throw error;
  }
};

export const updateCreatorProfile = async (id, profile) => {
  try {
    const response = await api.put(`/api/creators/${id}`, profile);
    return response.data;
  } catch (error) {
    console.error(`Error updating creator profile with id ${id}:`, error);
    throw error;
  }
};

export const deleteCreatorProfile = async (id) => {
  try {
    const response = await api.delete(`/api/creators/${id}`);
    return response.data;
  } catch (error) {
    console.error(`Error deleting creator profile with id ${id}:`, error);
    throw error;
  }
};

export const addContentSource = async (id, source) => {
  try {
    const response = await api.post(`/api/creators/${id}/sources`, { source });
    return response.data;
  } catch (error) {
    console.error(`Error adding content source to profile with id ${id}:`, error);
    throw error;
  }
};

export const updateContentSource = async (id, sourceIndex, source) => {
  try {
    const response = await api.put(`/api/creators/${id}/sources`, { 
      sourceIndex,
      source 
    });
    return response.data;
  } catch (error) {
    console.error(`Error updating content source at index ${sourceIndex} for profile with id ${id}:`, error);
    throw error;
  }
};

export const removeContentSource = async (id, sourceIndex) => {
  try {
    const response = await api.delete(`/api/creators/${id}/sources/${sourceIndex}`);
    return response.data;
  } catch (error) {
    console.error(`Error removing content source at index ${sourceIndex} from profile with id ${id}:`, error);
    throw error;
  }
};

export default api;