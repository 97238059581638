import React, { useState, useEffect } from 'react';
import { Card, ProgressBar, Spinner, Alert } from 'react-bootstrap';
import { FaServer, FaSync } from 'react-icons/fa';
import styled from 'styled-components';
import { getProcessingCapacity } from './apiJobs';

const RefreshButton = styled.button`
  background: none;
  border: none;
  color: #6c757d;
  cursor: pointer;
  &:hover {
    color: #495057;
  }
`;

const CapacityLabel = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  font-size: 14px;
`;

const ProcessingCapacityPanel = ({ onUpdate, refreshTrigger }) => {
  const [capacity, setCapacity] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchCapacity = async () => {
    setLoading(true);
    setError(null);
    try {
      const data = await getProcessingCapacity();
      setCapacity(data);
    } catch (error) {
      console.error('Error fetching processing capacity:', error);
      setError('Failed to load processing capacity');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCapacity();
    
    // Set up polling for updates using getProcessingCapacity
    const interval = setInterval(fetchCapacity, 10000);
    return () => clearInterval(interval);
  }, [refreshTrigger]);

  const handleRefresh = () => {
    fetchCapacity();
    if (onUpdate) onUpdate();
  };

  const getUtilizationPercentage = () => {
    if (!capacity) return 0;
    return Math.round((capacity.currentRunningJobs / capacity.maxParallelJobs) * 100);
  };

  const getProgressVariant = (percentage) => {
    if (percentage < 50) return 'success';
    if (percentage < 80) return 'warning';
    return 'danger';
  };

  return (
    <Card className="mb-4">
      <Card.Header className="d-flex justify-content-between align-items-center">
        <div>
          <FaServer className="me-2" />
          Processing Capacity
        </div>
        <RefreshButton onClick={handleRefresh} title="Refresh">
          <FaSync />
        </RefreshButton>
      </Card.Header>
      <Card.Body>
        {error && <Alert variant="danger">{error}</Alert>}
        
        {loading && !capacity ? (
          <div className="text-center p-3">
            <Spinner animation="border" size="sm" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : capacity ? (
          <>
            <CapacityLabel>
              <span>Jobs Running:</span>
              <strong>{capacity.currentRunningJobs} / {capacity.maxParallelJobs}</strong>
            </CapacityLabel>
            <ProgressBar 
              now={getUtilizationPercentage()} 
              variant={getProgressVariant(getUtilizationPercentage())} 
              className="mb-3"
            />
            
            <div className="d-flex justify-content-between align-items-center mt-3">
              <div>
                <small className="text-muted">Available Slots:</small>
                <h5 className="mb-0">{capacity.availableSlots}</h5>
              </div>
              <div>
                <small className="text-muted">Queue Size:</small>
                <h5 className="mb-0">{capacity.queueSize}</h5>
              </div>
            </div>
          </>
        ) : (
          <div className="text-center text-muted">No data available</div>
        )}
      </Card.Body>
    </Card>
  );
};

export default ProcessingCapacityPanel;