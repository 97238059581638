import React, { useState, useEffect, useRef } from 'react';
import { Modal, Button, Spinner, Alert, Badge } from 'react-bootstrap';
import { FaSync } from 'react-icons/fa';
import { database } from '../../firebase/FirebaseConfig';
import { ref, onValue, onChildAdded, off } from 'firebase/database';
import styled from 'styled-components';
import { getProcessingCapacity, getJobById, getJobLogs } from './apiJobs'; // Import the required API functions

const LogContainer = styled.div`
  height: 500px;
  overflow-y: auto;
  background-color: #f8f9fa;
  border-radius: 6px;
  padding: 10px;
  margin-top: 10px;
  font-family: monospace;
  border: 1px solid #dee2e6;
`;

const LogEntry = styled.div`
  padding: 8px 10px;
  margin-bottom: 6px;
  border-radius: 4px;
  border-left: 4px solid ${props => {
    switch (props.level) {
      case 'ERROR': return '#dc3545';
      case 'WARNING': return '#ffc107';
      case 'SUCCESS': return '#28a745';
      case 'DEBUG': return '#6c757d';
      default: return '#0d6efd';
    }
  }};
  background-color: white;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
`;

const TimeStamp = styled.span`
  color: #6c757d;
  font-size: 0.9em;
  margin-right: 8px;
`;

const Source = styled.span`
  color: #495057;
  font-weight: bold;
  margin-right: 8px;
`;

const Message = styled.div`
  margin-top: 5px;
  word-break: break-word;
`;

const Details = styled.div`
  margin-top: 5px;
  color: #6c757d;
  font-size: 0.9em;
  word-break: break-word;
  white-space: pre-wrap;
`;

const LevelBadge = styled(Badge)`
  margin-right: 8px;
`;

const formatDate = (timestamp) => {
  if (!timestamp) return '';
  
  try {
    return new Date(timestamp).toLocaleString();
  } catch (e) {
    return timestamp;
  }
};

const getLevelVariant = (level) => {
  switch (level) {
    case 'ERROR': return 'danger';
    case 'WARNING': return 'warning';
    case 'SUCCESS': return 'success';
    case 'DEBUG': return 'secondary';
    default: return 'info';
  }
};

const RealtimeLogsModal = ({ show, onHide, executionId, jobName }) => {
  const [logs, setLogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [realtimeEnabled, setRealtimeEnabled] = useState(true);
  const [capacityInfo, setCapacityInfo] = useState(null); // Add state for capacity info
  const logContainerRef = useRef(null);
  
  const logsRef = useRef(null);
  const isMounted = useRef(true);
  const childAddedUnsubscribeRef = useRef(null);
  
  useEffect(() => {
    if (logContainerRef.current) {
      logContainerRef.current.scrollTop = logContainerRef.current.scrollHeight;
    }
  }, [logs]);
  
  useEffect(() => {
    isMounted.current = true;
    
    return () => {
      isMounted.current = false;
      
      if (logsRef.current) {
        off(logsRef.current);
      }
      
      if (childAddedUnsubscribeRef.current) {
        childAddedUnsubscribeRef.current();
      }
    };
  }, []);
  
  // Add function to fetch processing capacity
  const fetchProcessingCapacity = async () => {
    try {
      const data = await getProcessingCapacity();
      if (isMounted.current) {
        setCapacityInfo(data);
      }
    } catch (err) {
      console.error('Error fetching processing capacity:', err);
      // Don't set error state to avoid interfering with logs display
    }
  };
  
  // Function to fetch execution log details to get runId
  const fetchExecutionLogDetails = async (execId) => {
    try {
      // First try to get execution details from the new endpoint
      try {
        const response = await fetch(`/api/job-logs/execution/${execId}`);
        if (response.ok) {
          const data = await response.json();
          if (data && data.data && data.data.runId) {
            console.log('Got runId from execution endpoint:', data.data.runId);
            return data.data.runId;
          }
        }
      } catch (err) {
        console.warn('Failed to fetch from execution endpoint, trying alternative method:', err);
      }
      
      // Fall back to checking job logs
      const logsResponse = await getJobLogs(null, 0, 100);
      
      if (logsResponse && logsResponse.data && logsResponse.data.items) {
        const foundLog = logsResponse.data.items.find(log => 
          (log.executionLogId === execId || log.execution_log_id === execId)
        );
        
        if (foundLog) {
          return foundLog.runId || foundLog.run_id;
        }
      }
      
      // If not found, return the executionLogId as fallback
      return execId;
    } catch (err) {
      console.error('Error fetching execution log details:', err);
      return execId; // Fall back to using executionLogId
    }
  };

useEffect(() => {
    if (!show || !executionId) return;
    
    setLoading(true);
    setError(null);
    setLogs([]);
    
    // Fetch processing capacity when modal opens
    fetchProcessingCapacity();
    
    // First fetch the execution log details to get runId
    (async () => {
      const runId = await fetchExecutionLogDetails(executionId);
      console.log('Using runId for logs:', runId);
      
      try {
      const formattedRunId = runId.trim();
      
      const possiblePaths = [
        `logs/${formattedRunId}`,
        `execution_logs/${formattedRunId}`,
        `job_logs/${formattedRunId}`
      ];
      
      const tryPath = (pathIndex = 0) => {
        if (pathIndex >= possiblePaths.length) {
          if (isMounted.current) {
            setError(`No logs found for run ID: ${formattedRunId}`);
            setLoading(false);
          }
          return;
        }
        
        const path = possiblePaths[pathIndex];
        const pathRef = ref(database, path);
        
        onValue(pathRef, (snapshot) => {
          if (!isMounted.current) return;
          
          if (snapshot.exists()) {
            logsRef.current = pathRef;
            
            const logsData = [];
            snapshot.forEach((childSnapshot) => {
              logsData.push({
                id: childSnapshot.key,
                ...childSnapshot.val()
              });
            });
            
            logsData.sort((a, b) => new Date(a.timestamp || 0) - new Date(b.timestamp || 0));
            setLogs(logsData);
            setLoading(false);
            
            if (realtimeEnabled) {
              childAddedUnsubscribeRef.current = onChildAdded(pathRef, handleNewLog);
            }
          } else {
            tryPath(pathIndex + 1);
          }
        }, { onlyOnce: true });
      };
      
      const handleNewLog = (snapshot) => {
        if (!isMounted.current || !realtimeEnabled) return;
        
        try {
          const newLog = {
            id: snapshot.key,
            ...snapshot.val()
          };
          
          setLogs(prevLogs => {
            if (prevLogs.some(log => log.id === newLog.id)) {
              return prevLogs;
            }
            
            const updatedLogs = [...prevLogs, newLog];
            return updatedLogs.sort((a, b) => 
              new Date(a.timestamp || 0) - new Date(b.timestamp || 0)
            );
          });
        } catch (err) {
          console.error('Error processing new log:', err);
        }
      };
      
      tryPath(0);
      
      return () => {
        if (logsRef.current) {
          off(logsRef.current);
        }
        
        if (childAddedUnsubscribeRef.current) {
          childAddedUnsubscribeRef.current();
          childAddedUnsubscribeRef.current = null;
        }
      };
      
    } catch (err) {
      if (isMounted.current) {
        setError(`Error connecting to Firebase: ${err.message}`);
        setLoading(false);
      }
    }
    })();
  }, [show, executionId, realtimeEnabled]);
  
  const refreshLogs = () => {
    if (!logsRef.current || !isMounted.current) return;
    
    setLoading(true);
    
    // Also refresh processing capacity when manually refreshing logs
    fetchProcessingCapacity();
    
    onValue(logsRef.current, (snapshot) => {
      if (!isMounted.current) return;
      
      try {
        setLoading(false);
        
        if (snapshot.exists()) {
          const logsData = [];
          snapshot.forEach((childSnapshot) => {
            logsData.push({
              id: childSnapshot.key,
              ...childSnapshot.val()
            });
          });
          
          logsData.sort((a, b) => new Date(a.timestamp || 0) - new Date(b.timestamp || 0));
          setLogs(logsData);
        } else {
          setLogs([]);
        }
      } catch (err) {
        if (isMounted.current) {
          setError(`Error refreshing logs: ${err.message}`);
        }
      }
    }, { onlyOnce: true });
  };
  
  const toggleRealtime = () => {
    if (!realtimeEnabled) {
      if (logsRef.current) {
        childAddedUnsubscribeRef.current = onChildAdded(logsRef.current, (snapshot) => {
          if (!isMounted.current) return;
          
          try {
            const newLog = {
              id: snapshot.key,
              ...snapshot.val()
            };
            
            setLogs(prevLogs => {
              if (prevLogs.some(log => log.id === newLog.id)) {
                return prevLogs;
              }
              
              const updatedLogs = [...prevLogs, newLog];
              return updatedLogs.sort((a, b) => 
                new Date(a.timestamp || 0) - new Date(b.timestamp || 0)
              );
            });
          } catch (err) {
            console.error('Error processing new log:', err);
          }
        });
      }
    } else {
      if (childAddedUnsubscribeRef.current) {
        childAddedUnsubscribeRef.current();
        childAddedUnsubscribeRef.current = null;
      }
    }
    
    setRealtimeEnabled(!realtimeEnabled);
  };
  
  // Add system capacity info display
  const renderSystemStatus = () => {
    if (!capacityInfo) return null;
    
    return (
      <div className="mb-3 p-2 bg-light border rounded">
        <small className="text-muted d-flex justify-content-between">
          <span>Processing: {capacityInfo.currentRunningJobs}/{capacityInfo.maxParallelJobs} jobs</span>
          <span>Queue: {capacityInfo.queueSize} job(s)</span>
        </small>
      </div>
    );
  };
  
  return (
    <Modal 
      show={show} 
      onHide={onHide} 
      size="xl" 
      backdrop="static" 
      dialogClassName="modal-90w"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {jobName ? `Real-time Logs: ${jobName}` : 'Real-time Logs'}
          <Badge bg="info" className="ms-2">Execution ID: {executionId}</Badge>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex justify-content-between mb-3">
          <Button 
            variant={realtimeEnabled ? "success" : "outline-success"} 
            onClick={toggleRealtime}
          >
            {realtimeEnabled ? "Real-time: ON" : "Real-time: OFF"}
          </Button>
          
          <Button 
            variant="outline-primary" 
            onClick={refreshLogs} 
            disabled={loading}
          >
            <FaSync className={loading ? "fa-spin me-2" : "me-2"} />
            Refresh Logs
          </Button>
        </div>
        
        {/* Add system status information */}
        {renderSystemStatus()}
        
        {error && (
          <Alert variant="danger">{error}</Alert>
        )}
        
        {loading && logs.length === 0 ? (
          <div className="text-center p-4">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading logs...</span>
            </Spinner>
          </div>
        ) : (
          <LogContainer ref={logContainerRef}>
            {logs.length === 0 ? (
              <div className="text-center p-4 text-muted">
                No logs available for this execution
              </div>
            ) : (
              logs.map((log) => (
                <LogEntry key={log.id} level={log.level}>
                  <div>
                    <TimeStamp>{formatDate(log.timestamp)}</TimeStamp>
                    <LevelBadge bg={getLevelVariant(log.level)}>{log.level}</LevelBadge>
                    <Source>[{log.source}]</Source>
                  </div>
                  <Message>{log.message}</Message>
                  {log.details && <Details>{log.details}</Details>}
                </LogEntry>
              ))
            )}
            
            {loading && logs.length > 0 && (
              <div className="text-center p-2">
                <Spinner animation="border" size="sm" role="status">
                  <span className="visually-hidden">Loading more logs...</span>
                </Spinner>
              </div>
            )}
          </LogContainer>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default RealtimeLogsModal;