import React, { useState, useEffect, useCallback, useRef, useContext } from 'react';
import { Card, Button, Table, Spinner, Alert, Pagination } from 'react-bootstrap';
import { FaList, FaTrash, FaSync } from 'react-icons/fa';
import { getQueuedJobs, getQueueSize, clearQueue, removeFromQueue } from './apiJobs';
import { RefreshContext } from './JobDashboard';
import { JobTypeBadge } from './JobDashboard';

const PaginationControl = React.memo(({ currentPage, totalPages, onPageChange }) => {
  if (totalPages <= 1) return null;
  
  const getPageItems = () => {
    let items = [];
    
    // Always show first page
    items.push(
      <Pagination.Item 
        key="first" 
        active={currentPage === 0} 
        onClick={() => onPageChange(0)}
      >
        1
      </Pagination.Item>
    );
    
    // Show ellipsis if not starting at page 1
    if (currentPage > 2) {
      items.push(<Pagination.Ellipsis key="ellipsis-start" />);
    }
    
    // Show pages around current page
    for (let i = Math.max(1, currentPage); i <= Math.min(currentPage + 1, totalPages - 2); i++) {
      items.push(
        <Pagination.Item 
          key={i} 
          active={currentPage === i} 
          onClick={() => onPageChange(i)}
        >
          {i + 1}
        </Pagination.Item>
      );
    }
    
    // Show ellipsis if not ending at last page
    if (currentPage < totalPages - 3) {
      items.push(<Pagination.Ellipsis key="ellipsis-end" />);
    }
    
    // Always show last page
    if (totalPages > 1) {
      items.push(
        <Pagination.Item 
          key="last" 
          active={currentPage === totalPages - 1} 
          onClick={() => onPageChange(totalPages - 1)}
        >
          {totalPages}
        </Pagination.Item>
      );
    }
    
    return items;
  };
  
  return (
    <div className="d-flex justify-content-center mt-3">
      <Pagination>
        <Pagination.Prev 
          onClick={() => onPageChange(Math.max(0, currentPage - 1))}
          disabled={currentPage === 0}
        />
        {getPageItems()}
        <Pagination.Next 
          onClick={() => onPageChange(Math.min(totalPages - 1, currentPage + 1))}
          disabled={currentPage === totalPages - 1}
        />
      </Pagination>
    </div>
  );
});

const QueuedJobsPanel = React.memo(({ refreshTrigger, onUpdate }) => {
  const { triggerRefresh } = useContext(RefreshContext);
  const [queuedJobs, setQueuedJobs] = useState([]);
  const [queueSize, setQueueSize] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const pageSize = 10;
  const prevQueuedJobsRef = useRef([]);
  const isFetchingRef = useRef(false);
  const fetchTimeoutRef = useRef(null);
  const isMounted = useRef(true);
  const autoRefreshIntervalRef = useRef(null);

  const fetchQueuedJobs = useCallback(async (showLoadingSpinner = false) => {
    // Avoid multiple simultaneous requests
    if (isFetchingRef.current || !isMounted.current) return;
    
    isFetchingRef.current = true;
    // Solo activamos el spinner si explícitamente se solicita y no hay datos
    if (showLoadingSpinner && queuedJobs.length === 0) setLoading(true);
    setError(null);
    
    try {
      const [queueResponse, sizeResponse] = await Promise.all([
        getQueuedJobs(currentPage, pageSize),
        getQueueSize()
      ]);
      
      if (!isMounted.current) return;
      
      // Normalize response data
      const newJobs = queueResponse.data?.items || [];
      
      // Update state only if necessary
      const jobsChanged = JSON.stringify(newJobs) !== JSON.stringify(prevQueuedJobsRef.current);
      
      if (jobsChanged) {
        setQueuedJobs(newJobs);
        prevQueuedJobsRef.current = [...newJobs];
        setTotalPages(Math.ceil((queueResponse.data?.total || 0) / pageSize));
      }
      
      const newSize = sizeResponse.size || 0;
      
      if (newSize !== queueSize) {
        setQueueSize(newSize);
      }
      
      // Notify parent component if provided
      if (onUpdate) {
        onUpdate();
      }
    } catch (error) {
      console.error('Error fetching queued jobs:', error);
      if (isMounted.current) {
        setError('Failed to load queued jobs');
      }
    } finally {
      // Siempre desactivamos el loading al finalizar
      if (isMounted.current) {
        setLoading(false);
      }
      isFetchingRef.current = false;
    }
  }, [currentPage, loading, queueSize, onUpdate]);

  // Initial load effect - load without delay on first render
  useEffect(() => {
    isMounted.current = true;
    // Solo mostramos el spinner en la carga inicial si no hay datos
    fetchQueuedJobs(true);
    
    // Setup auto-refresh interval
    autoRefreshIntervalRef.current = setInterval(() => {
      if (isMounted.current && !isFetchingRef.current) {
        // Actualizaciones automáticas nunca muestran el spinner
        fetchQueuedJobs(false);
      }
    }, 30000); // Every 30 seconds
    
    return () => {
      isMounted.current = false;
      if (autoRefreshIntervalRef.current) {
        clearInterval(autoRefreshIntervalRef.current);
      }
    };
  }, []);

  // Effect for handling refresh triggers and page changes
  useEffect(() => {
    if (!isMounted.current) return;
    
    if (fetchTimeoutRef.current) {
      clearTimeout(fetchTimeoutRef.current);
    }

    // Small delay to prevent too many consecutive calls
    fetchTimeoutRef.current = setTimeout(() => {
      // Nunca mostramos el spinner en actualizaciones
      fetchQueuedJobs(false);
    }, 2000);

    return () => {
      if (fetchTimeoutRef.current) {
        clearTimeout(fetchTimeoutRef.current);
      }
    };
  }, [fetchQueuedJobs, refreshTrigger, currentPage]);

  const handleClearQueue = async () => {
    try {
      // No mostramos spinner, solo indicamos que está ocupado en el botón
      isFetchingRef.current = true;
      await clearQueue();
      triggerRefresh();
    } catch (error) {
      console.error('Error clearing queue:', error);
      setError('Failed to clear queue');
    } finally {
      isFetchingRef.current = false;
    }
  };

  const handleRemoveFromQueue = async (jobId) => {
    try {
      // No mostramos spinner, solo indicamos que está ocupado en el botón
      isFetchingRef.current = true;
      await removeFromQueue(jobId);
      triggerRefresh();
    } catch (error) {
      console.error(`Error removing job ${jobId} from queue:`, error);
      setError('Failed to remove job from queue');
    } finally {
      isFetchingRef.current = false;
    }
  };

  const formatDateTime = (dateTimeStr) => {
    if (!dateTimeStr) return 'N/A';
    return new Date(dateTimeStr).toLocaleString();
  };

  const confirmClearQueue = () => {
    if (window.confirm('Are you sure you want to clear the entire job queue?')) {
      handleClearQueue();
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <Card className="mb-4 h-100">
      <Card.Header className="d-flex justify-content-between align-items-center">
        <div>
          <FaList className="me-2" />
          Queue ({queueSize} jobs)
        </div>
        <div>
          <Button 
            variant="outline-secondary" 
            size="sm"
            className="me-2"
            onClick={() => fetchQueuedJobs(false)}
            disabled={isFetchingRef.current}
            title="Refresh queue list"
          >
            <FaSync className={isFetchingRef.current ? "fa-spin" : ""} />
          </Button>
          <Button 
            variant="outline-danger" 
            size="sm"
            onClick={confirmClearQueue}
            disabled={queueSize === 0 || isFetchingRef.current}
            title="Clear all jobs from queue"
          >
            Clear Queue
          </Button>
        </div>
      </Card.Header>
      <Card.Body className="overflow-auto">
        {error && <Alert variant="danger">{error}</Alert>}
        
        {queuedJobs.length === 0 ? (
          <div className="text-center text-muted">No jobs in queue</div>
        ) : (
          <>
            <div className="table-responsive">
              <Table hover size="sm">
                <thead className="sticky-top bg-white" style={{ zIndex: 10 }}>
                  <tr>
                    <th>Name</th>
                    <th>Type</th>
                    <th>Priority</th>
                    <th>Queued At</th>
                    <th>Scheduled Time</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {queuedJobs.map((job) => (
                    <tr key={job.jobId || job.job_id}>
                      <td>{job.jobName || job.job_name}</td>
                      <td><JobTypeBadge type={job.jobType || job.job_type} /></td>
                      <td>{job.priority}</td>
                      <td>{formatDateTime(job.queuedAt || job.queued_at)}</td>
                      <td>{formatDateTime(job.scheduledTime || job.scheduled_time)}</td>
                      <td>
                        <Button 
                          variant="outline-danger" 
                          size="sm"
                          onClick={() => handleRemoveFromQueue(job.jobId || job.job_id)}
                          disabled={isFetchingRef.current}
                          title="Remove from queue"
                        >
                          <FaTrash size={14} />
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
            
            <PaginationControl 
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
            />
          </>
        )}
      </Card.Body>
    </Card>
  );
});

export default QueuedJobsPanel;