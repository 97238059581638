import api from '../../apiBase';

export const getPoliticalOrganizationByMemberId = async () => {
  try {
    const response = await api.get(`/api/political-organizations/by-member`);
    return response.data;
  } catch (error) {
    console.error('Error fetching political organization:', error);
    throw error;
  }
};

export const updatePoliticalOrganization = async (id, data) => {
  try {
    const response = await api.put(`/api/political-organizations/${id}`, data, {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json; charset=utf-8'
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error updating political organization:', error);
    throw error;
  }
};

export const updatePoliticalOrganizationLogo = async (id, logoData) => {
  try {
    const response = await api.patch(`/api/political-organizations/${id}/logo`, logoData, {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json; charset=utf-8'
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error updating political organization logo:', error);
    throw error;
  }
};

export const uploadPoliticalOrganizationLogo = async (id, file) => {
  try {
    const formData = new FormData();
    formData.append('file', file);
    
    const response = await api.post(`/api/political-organizations/${id}/logo/upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error uploading political organization logo:', error);
    throw error;
  }
};

export const getSocialMediaTypes = async () => {
  try {
    const response = await api.get('/api/political-organizations/social-media-types');
    return response.data;
  } catch (error) {
    console.error('Error fetching social media types:', error);
    throw error;
  }
};

// Nuevos métodos para gestionar miembros
export const getOrganizationMembers = async (organizationId) => {
  try {
    const response = await api.get(`/api/political-organizations/${organizationId}/members`);
    return response.data;
  } catch (error) {
    console.error('Error fetching organization members:', error);
    throw error;
  }
};

export const addOrganizationMember = async (organizationId, memberData, avatarFile) => {
  try {
    const formData = new FormData();
    formData.append('data', JSON.stringify(memberData));
    
    if (avatarFile) {
      formData.append('avatar', avatarFile);
    }
    
    const response = await api.post(`/api/political-organizations/${organizationId}/members`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error adding organization member:', error);
    throw error;
  }
};

export const updateOrganizationMember = async (organizationId, memberId, memberData) => {
  try {
    const response = await api.put(`/api/political-organizations/${organizationId}/members/${memberId}`, memberData, {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json; charset=utf-8'
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error updating organization member:', error);
    throw error;
  }
};

export const updateMemberAvatar = async (organizationId, memberId, avatarFile) => {
  try {
    const formData = new FormData();
    formData.append('file', avatarFile);
    
    const response = await api.patch(`/api/political-organizations/${organizationId}/members/${memberId}/avatar`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error updating member avatar:', error);
    throw error;
  }
};

export const deleteOrganizationMember = async (organizationId, memberId) => {
  try {
    const response = await api.delete(`/api/political-organizations/${organizationId}/members/${memberId}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting organization member:', error);
    throw error;
  }
};

// Nuevos métodos para gestionar documentos
export const getOrganizationDocuments = async (organizationId) => {
  try {
    const response = await api.get(`/api/political-organizations/${organizationId}/documents`);
    return response.data;
  } catch (error) {
    console.error('Error fetching organization documents:', error);
    throw error;
  }
};

export const addOrganizationDocument = async (organizationId, documentData, documentFile) => {
  try {
    const formData = new FormData();
    formData.append('data', JSON.stringify(documentData));
    formData.append('file', documentFile);
    
    const response = await api.post(`/api/political-organizations/${organizationId}/documents`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error adding organization document:', error);
    throw error;
  }
};

export const deleteOrganizationDocument = async (organizationId, documentId) => {
  try {
    const response = await api.delete(`/api/political-organizations/${organizationId}/documents/${documentId}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting organization document:', error);
    throw error;
  }
};