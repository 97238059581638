import api from '../apiBase';

export const getNotaryOrders = async (params) => {
  try {
    const queryParams = {};
    
    if (params.page !== undefined) queryParams.page = params.page;
    if (params.size !== undefined) queryParams.size = params.size;
    if (params.userId) queryParams.userId = params.userId;
    if (params.status) queryParams.status = params.status;
    if (params.email) queryParams.email = params.email;
    if (params.name) queryParams.name = params.name;
    if (params.phone) queryParams.phone = params.phone;

    const response = await api.get('/notary/v1/by-notary', { params: queryParams });
    return response.data;
  } catch (error) {
    console.error('Error fetching notary orders:', error);
    throw error;
  }
};

export const getAllNotaryOrders = async (params) => {
  try {
    const queryParams = {};
    
    if (params.page !== undefined) queryParams.page = params.page;
    if (params.size !== undefined) queryParams.size = params.size;
    if (params.userId) queryParams.userId = params.userId;
    if (params.status) queryParams.status = params.status;
    if (params.email) queryParams.email = params.email;
    if (params.name) queryParams.name = params.name;
    if (params.phone) queryParams.phone = params.phone;

    const response = await api.get('/notary/v1', { params: queryParams });
    return response.data;
  } catch (error) {
    console.error('Error fetching notary orders:', error);
    throw error;
  }
};

export const updateNotaryOrderStatus = async (orderId, status) => {
  try {
    const response = await api.post(`/notary/v1/${orderId}/status`, null, { params: { status } });
    return response.data;
  } catch (error) {
    console.error('Error updating notary order status:', error);
    throw error;
  }
};

export const updatePrice = async (orderId, price) => {
  try {
    const response = await api.post(`/notary/v1/${orderId}/price`, null, { params: { price } });
    return response.data;
  } catch (error) {
    console.error('Error updating notary order status:', error);
    throw error;
  }
};

export const getAvailableNotaryStatuses = async (orderId) => {
  try {
    const response = await api.get(`/notary/v1/${orderId}/available-statuses`);
    return response.data.data;
  } catch (error) {
    console.error('Error fetching available statuses:', error);
    throw error;
  }
};

export const getExtendedOrderStatistics = async (startDate, endDate) => {
  try {
    const response = await api.get('/notary/v1/statistics', {
      params: {
        startDate,
        endDate
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching extended order statistics:', error);
    throw error;
  }
};

export const assignNotaryAgent = async (orderIds, notaryId, clientNotify = false) => {
  try {
    const params = new URLSearchParams();
    orderIds.forEach(id => params.append('ids', id));
    params.append('notaryId', notaryId);
    params.append('clientNotify', clientNotify);

    const response = await api.put('/notary/v1/assign', null, { params });

    if (response.data.error) {
      throw new Error(response.data.error);
    }

    return response.data;
  } catch (error) {
    console.error('Error assigning notary agent:', error);
    throw error;
  }
};

export const fetchNotaryAgents = async () => {
  try {
    const response = await api.get('/api/workers_manager/by-role-notary-agent');
    return response.data;
  } catch (error) {
    console.error('Error fetching notary agents:', error);
    throw error;
  }
};

export default api;