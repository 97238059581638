import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Button, Form, Dropdown } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import styled, { createGlobalStyle } from 'styled-components';
import { FaFilter, FaSync, FaEye, FaPlus, FaEdit } from 'react-icons/fa';
import { 
  getAllCurrencies,
  fetchCurrency,
  deleteCurrency,
  createCurrency,
  updateCurrency,
  generateCurrencyTemplate
} from './apiCurrency';

import { 
  CurrencySlidingPanel,
  CurrencyFormModal,
  DeleteCurrencyModal,
  AlertModal
} from './CurrencyModals';

// Estilos globales
const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    background-color: #f1f3f4;
    color: #202124;
    margin: 0;
    padding: 0;
    overflow: hidden;
  }
`;

const AppContainer = styled.div`
  height: 100vh;
  max-height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

const ContentContainer = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
`;

const HeaderSection = styled.div`
  margin-bottom: 16px;
`;

const TableContainer = styled.div`
  flex: 1;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 1px 2px 0 rgba(60,64,67,0.3);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  min-height: 0;
  position: relative;
`;

const StyledDataTable = styled(DataTable)`
  height: 100%;

  .rdt_Wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .rdt_TableWrapper {
    flex: 1;
    overflow: auto;
  }

  .rdt_TableHead {
    font-weight: 500;
    color: #5f6368;
    background-color: #f1f3f4;
    position: sticky !important;
    top: 0 !important;
    z-index: 1;
  }

  .rdt_TableBody {
    overflow-y: auto;
  }

  .rdt_TableCol {
    padding: 16px;
  }

  .rdt_TableCell {
    font-size: 14px;
  }

  .rdt_Pagination {
    color: #5f6368;
    font-size: 14px;
    font-weight: 500;
    position: sticky;
    bottom: 0;
    background-color: #fff;
    z-index: 1;
    border-top: 1px solid #dadce0;
  }
`;

const SlidingPanel = styled.div`
  position: fixed;
  top: 0;
  right: ${props => props.isOpen ? '0' : '-100%'};
  width: 400px;
  height: 100%;
  background-color: white;
  box-shadow: -2px 0 8px rgba(0, 0, 0, 0.15);
  transition: right 0.3s ease-in-out;
  overflow-y: auto;
  z-index: 1000;
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: ${props => props.isOpen ? 'block' : 'none'};
  z-index: 999;
`;

const GoogleButton = styled(Button)`
  background-color: #fff;
  border-color: #dadce0;
  color: #3c4043;
  font-weight: 500;
  padding: 8px 16px;
  font-size: 14px;
  
  &:hover, &:focus {
    background-color: #f1f3f4;
    border-color: #dadce0;
    box-shadow: 0 1px 2px 0 rgba(60,64,67,0.3), 0 1px 3px 1px rgba(60,64,67,0.15);
  }

  &:disabled {
    background-color: #f1f3f4;
    border-color: #dadce0;
    color: #80868b;
  }
`;

const IconButton = styled(GoogleButton)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  margin-right: 8px;
  
  svg {
    margin-right: 8px;
  }
`;

const FilterDropdown = styled(Dropdown)`
  .dropdown-toggle {
    background-color: #fff;
    border-color: #dadce0;
    color: #3c4043;
    font-weight: 500;
    
    &:hover, &:focus {
      background-color: #f1f3f4;
      border-color: #dadce0;
      box-shadow: 0 1px 2px 0 rgba(60,64,67,0.3), 0 1px 3px 1px rgba(60,64,67,0.15);
    }
  }

  .dropdown-menu {
    box-shadow: 0 2px 4px -1px rgba(0,0,0,0.2), 0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12);
    border: none;
    border-radius: 4px;
  }
`;

const LoadingOverlay = styled.div`
  width: 100%;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CircularProgress = styled.div`
  width: 40px;
  height: 40px;
  border: 3px solid #f3f3f3;
  border-top: 3px solid #1a73e8;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

const CurrencyManager = () => {
  const [currencies, setCurrencies] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState(null);
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const [alertModal, setAlertModal] = useState({ show: false, title: '', message: '', variant: 'success' });
  const [pagination, setPagination] = useState({ page: 0, size: 10, total: 0 });
  const [filterConfig, setFilterConfig] = useState(null);
  
  // Modal states
  const [showFormModal, setShowFormModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  
  // Filter state
  const [filters, setFilters] = useState({
    currency: '',
    startDate: '',
    endDate: ''
  });

  const fetchCurrencies = useCallback(async () => {
    setLoading(true);
    try {
      const response = await getAllCurrencies(
        pagination.page,
        pagination.size,
        filters
      );
      
      setCurrencies(response.items || []);
      setPagination(prev => ({
        ...prev,
        total: response.total || 0
      }));
      
      // Set filter config if available
      if (response.filterConfig) {
        setFilterConfig(response.filterConfig);
      }
    } catch (error) {
      console.error('Error fetching currencies:', error);
      showAlert('Error', 'Error al cargar los datos de monedas', 'error');
    } finally {
      setLoading(false);
    }
  }, [pagination.page, pagination.size, filters]);

  useEffect(() => {
    fetchCurrencies();
  }, [fetchCurrencies]);

  const handleFilterChange = (filterName, value) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      [filterName]: value
    }));
    
    // Reset to first page when filter changes
    setPagination(prev => ({
      ...prev,
      page: 0
    }));
  };

  const clearFilters = () => {
    setFilters({
      currency: '',
      startDate: '',
      endDate: ''
    });
  };

  const handlePageChange = (page) => {
    setPagination(prev => ({
      ...prev,
      page: page - 1 // DataTable es 1-indexed, nuestra API es 0-indexed
    }));
  };

  const handlePerRowsChange = (newPerPage) => {
    setPagination(prev => ({
      ...prev,
      size: newPerPage,
      page: 0
    }));
  };

  const showAlert = useCallback((title, message, variant = 'success') => {
    setAlertModal({ show: true, title, message, variant });
  }, []);

  const closeAlert = useCallback(() => {
    setAlertModal({ show: false, title: '', message: '', variant: 'success' });
  }, []);

  const handleViewDetails = (row) => {
    setSelectedCurrency(row);
    setIsPanelOpen(true);
  };

  const closeSlidingPanel = () => {
    setIsPanelOpen(false);
    setSelectedCurrency(null);
  };

  const handleFetchCurrency = async () => {
    try {
      setLoading(true);
      const response = await fetchCurrency();
      if (response.data && response.data.length > 0) {
        showAlert('Éxito', `Se obtuvieron ${response.data.length} nuevos registros de monedas`, 'success');
        fetchCurrencies();
      } else {
        showAlert('Información', 'No hay nuevos datos de monedas disponibles', 'info');
      }
    } catch (error) {
      console.error('Error fetching currency data:', error);
      showAlert('Error', 'Error al obtener nuevos datos de monedas', 'error');
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteCurrency = async (id) => {
    try {
      await deleteCurrency(id);
      fetchCurrencies();
      setShowDeleteModal(false);
      closeSlidingPanel();
      showAlert('Éxito', 'Registro de moneda eliminado exitosamente', 'success');
    } catch (error) {
      console.error('Error deleting currency:', error);
      showAlert('Error', 'Error al eliminar el registro de moneda', 'error');
    }
  };

  const handleCreateCurrency = async (currencyData) => {
    try {
      await createCurrency(currencyData);
      fetchCurrencies();
      setShowFormModal(false);
      showAlert('Éxito', 'Registro de moneda agregado exitosamente', 'success');
    } catch (error) {
      console.error('Error creating currency:', error);
      showAlert('Error', 'Error al agregar el registro de moneda', 'error');
    }
  };

  const handleUpdateCurrency = async (currencyData) => {
    try {
      const id = selectedCurrency.id;
      await updateCurrency(id, currencyData);
      fetchCurrencies();
      setShowFormModal(false);
      closeSlidingPanel();
      showAlert('Éxito', 'Registro de moneda actualizado exitosamente', 'success');
    } catch (error) {
      console.error('Error updating currency:', error);
      showAlert('Error', 'Error al actualizar el registro de moneda', 'error');
    }
  };

  const openDeleteModal = (currency) => {
    setSelectedCurrency(currency);
    setShowDeleteModal(true);
  };

  const openCreateModal = () => {
    setIsEditing(false);
    setShowFormModal(true);
  };

  const openEditModal = (currency) => {
    setSelectedCurrency(currency);
    setIsEditing(true);
    setShowFormModal(true);
  };

  const handleFormSubmit = (data) => {
    if (isEditing) {
      handleUpdateCurrency(data);
    } else {
      handleCreateCurrency(data);
    }
  };

  const columns = useMemo(() => [
    {
      name: 'Moneda',
      selector: row => row.currency,
      sortable: true,
      width: '100px',
      cell: row => (
        <div style={{ 
          borderRadius: '50%',
          backgroundColor: 
            row.currency === 'usd' ? '#1a73e8' : 
            row.currency === 'eur' ? '#34a853' : '#fbbc04',
          color: 'white',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '36px',
          height: '36px',
          fontWeight: 'bold',
          textTransform: 'uppercase'
        }}>
          {row.currency?.substring(0, 3)}
        </div>
      )
    },
    {
      name: 'Fecha',
      selector: row => row.date,
      sortable: true,
      format: row => {
        // Convertir la fecha UTC a un objeto Date
        const date = new Date(row.date);
        
        // Sumar 1 día a la fecha
        date.setDate(date.getDate() + 1);
        
        // Opciones para formatear la fecha
        const options = { 
          timeZone: 'America/New_York',
          year: 'numeric', 
          month: '2-digit', 
          day: '2-digit'
        };
        
        return date.toLocaleDateString('es-ES', options);
      }
    },
    {
      name: 'Valor',
      selector: row => row.high,
      sortable: true,
      format: row => new Intl.NumberFormat('es-ES', {
        style: 'currency',
        currency: 'CUP'
      }).format(row.high)
    },
    {
      name: 'Valor Formateado',
      selector: row => row.valueFormat,
      sortable: true,
      format: row => row.valueFormat ? new Intl.NumberFormat('es-ES', {
        style: 'currency',
        currency: 'CUP'
      }).format(row.valueFormat) : '-'
    },
    {
      name: 'Acciones',
      cell: row => (
        <div>
          <IconButton onClick={() => handleViewDetails(row)}>
            <FaEye /> Ver
          </IconButton>
          <IconButton onClick={() => openEditModal(row)}>
            <FaEdit /> Editar
          </IconButton>
        </div>
      )
    }
  ], []);

  const handleOutsideClick = useCallback((event) => {
    if (isPanelOpen && !event.target.closest(".sliding-panel-content") && 
        !event.target.closest(".modal")) {
      closeSlidingPanel();
    }
  }, [isPanelOpen]);

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [handleOutsideClick]);

  const customStyles = {
    headRow: {
      style: {
        borderTop: 'none',
        borderBottom: '1px solid #dadce0',
      },
    },
    headCells: {
      style: {
        paddingLeft: '16px',
        paddingRight: '16px',
      },
    },
    rows: {
      style: {
        minHeight: '56px',
        borderBottom: '1px solid #f1f3f4',
        '&:hover': {
          backgroundColor: '#f8f9fa',
        },
      },
    },
    noData: {
      style: {
        padding: '24px',
        color: '#5f6368',
        backgroundColor: 'white',
      },
    },
  };

  // Renderizar los filtros basados en la configuración recibida del backend
  const renderDynamicFilters = () => {
    if (!filterConfig || !filterConfig.availableFilters) {
      // Si no hay configuración, mostrar filtros predeterminados
      return (
        <>
          <Form.Group className="mb-2">
            <Form.Label>Moneda</Form.Label>
            <Form.Control
              as="select"
              value={filters.currency || ''}
              onChange={(e) => handleFilterChange('currency', e.target.value)}
            >
              <option value="">Todos</option>
              <option value="usd">USD</option>
              <option value="eur">EUR</option>
              <option value="mlc">MLC</option>
            </Form.Control>
          </Form.Group>
          <Form.Group className="mb-2">
            <Form.Label>Fecha Inicio</Form.Label>
            <Form.Control
              type="date"
              value={filters.startDate || ''}
              onChange={(e) => handleFilterChange('startDate', e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Fecha Fin</Form.Label>
            <Form.Control
              type="date"
              value={filters.endDate || ''}
              onChange={(e) => handleFilterChange('endDate', e.target.value)}
            />
          </Form.Group>
        </>
      );
    }

    return filterConfig.availableFilters.map((filter) => {
      switch (filter.type) {
        case 'DATE':
          return (
            <Form.Group className="mb-2" key={filter.key}>
              <Form.Label>{filter.label}</Form.Label>
              <Form.Control
                type="date"
                value={filters[filter.key] || ''}
                onChange={(e) => handleFilterChange(filter.key, e.target.value)}
              />
            </Form.Group>
          );
        case 'DROPDOWN':
          return (
            <Form.Group className="mb-2" key={filter.key}>
              <Form.Label>{filter.label}</Form.Label>
              <Form.Control
                as="select"
                value={filters[filter.key] || ''}
                onChange={(e) => handleFilterChange(filter.key, e.target.value)}
              >
                <option value="">Todos</option>
                {filter.possibleValues?.map((value) => (
                  <option key={value} value={value}>{value}</option>
                ))}
              </Form.Control>
            </Form.Group>
          );
        case 'TEXT':
          return (
            <Form.Group className="mb-2" key={filter.key}>
              <Form.Label>{filter.label}</Form.Label>
              <Form.Control
                type="text"
                value={filters[filter.key] || ''}
                onChange={(e) => handleFilterChange(filter.key, e.target.value)}
              />
            </Form.Group>
          );
        default:
          return null;
      }
    });
  };

  return (
    <AppContainer>
      <GlobalStyle />
      <ContentContainer>
        <HeaderSection className="d-flex justify-content-between align-items-center">
          <h2>Gestión de Monedas</h2>
          <div className="d-flex gap-3">
            <IconButton onClick={handleFetchCurrency} disabled={loading}>
              <FaSync /> Actualizar Datos
            </IconButton>
            <IconButton onClick={openCreateModal}>
              <FaPlus /> Agregar Moneda
            </IconButton>
            <FilterDropdown>
              <Dropdown.Toggle variant="outline-secondary" id="dropdown-filters">
                <FaFilter /> Filtros
              </Dropdown.Toggle>
              <Dropdown.Menu className="p-3" style={{ width: '300px' }}>
                <Form>
                  {renderDynamicFilters()}
                  <div className="d-flex justify-content-end">
                    <GoogleButton onClick={clearFilters}>Limpiar Filtros</GoogleButton>
                  </div>
                </Form>
              </Dropdown.Menu>
            </FilterDropdown>
          </div>
        </HeaderSection>

        <TableContainer>
          <StyledDataTable
            columns={columns}
            data={currencies}
            pagination
            paginationServer
            paginationTotalRows={pagination.total}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handlePerRowsChange}
            highlightOnHover
            pointerOnHover
            progressPending={loading}
            customStyles={customStyles}
            fixedHeader
            fixedHeaderScrollHeight="calc(100vh - 200px)"
            progressComponent={
              <LoadingOverlay>
                <CircularProgress />
              </LoadingOverlay>
            }
            noDataComponent={<div>No hay datos de monedas disponibles</div>}
          />
        </TableContainer>

        <Overlay isOpen={isPanelOpen} />
        <SlidingPanel isOpen={isPanelOpen} className="sliding-panel-content">
          <CurrencySlidingPanel
            selectedCurrency={selectedCurrency}
            onClose={closeSlidingPanel}
            onDelete={openDeleteModal}
            onEdit={openEditModal}
            generateCurrencyTemplate={generateCurrencyTemplate}
          />
        </SlidingPanel>

        <CurrencyFormModal
          show={showFormModal}
          onHide={() => setShowFormModal(false)}
          onSubmit={handleFormSubmit}
          currencyData={isEditing ? selectedCurrency : null}
          isEditing={isEditing}
        />

        <DeleteCurrencyModal
          show={showDeleteModal}
          onHide={() => setShowDeleteModal(false)}
          currency={selectedCurrency}
          onDelete={handleDeleteCurrency}
        />

        <AlertModal
          show={alertModal.show}
          onHide={closeAlert}
          title={alertModal.title}
          message={alertModal.message}
          variant={alertModal.variant}
        />
      </ContentContainer>
    </AppContainer>
  );
};

export default CurrencyManager;