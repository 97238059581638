import axios from 'axios';

// Create axios instance with base URL
const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL || 'http://localhost:8080',
});

// Add request interceptor to attach authorization token
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('authToken');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Fetch all clients
export const fetchClients = async (params) => {
  try {
    const response = await api.get('/api/clients/all', { params });
    return response.data;
  } catch (error) {
    console.error('Error fetching clients:', error);
    throw error;
  }
};

// Fetch client with accessible modules
export const fetchClientWithModules = async (clientId) => {
  try {
    const response = await api.get(`/api/clients?clientId=${clientId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching client with modules:', error);
    throw error;
  }
};

// Create a new client
export const createClient = async (clientData) => {
  try {
    const response = await api.post('/api/clients', clientData);
    return response.data;
  } catch (error) {
    console.error('Error creating client:', error);
    throw error;
  }
};

// Update an existing client
export const updateClient = async (clientData) => {
  try {
    const response = await api.put(`/api/clients/${clientData.id}`, clientData);
    return response.data;
  } catch (error) {
    console.error('Error updating client:', error);
    throw error;
  }
};

// Delete a client
export const deleteClient = async (clientId) => {
  try {
    await api.delete(`/api/clients/${clientId}`);
  } catch (error) {
    console.error('Error deleting client:', error);
    throw error;
  }
};

// Fetch all modules for a client
export const fetchModulesByClient = async (clientId) => {
  try {
    const response = await api.get(`/api/modules/client/${clientId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching modules:', error);
    throw error;
  }
};

// Fetch a specific module
export const fetchModule = async (moduleId) => {
  try {
    const response = await api.get(`/api/modules/${moduleId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching module:', error);
    throw error;
  }
};

// Create a new module
export const createModule = async (moduleData) => {
  try {
    const response = await api.post('/api/modules', moduleData);
    return response.data;
  } catch (error) {
    console.error('Error creating module:', error);
    throw error;
  }
};

// Update an existing module
export const updateModule = async (moduleData) => {
  try {
    const response = await api.put(`/api/modules/${moduleData.id}`, moduleData);
    return response.data;
  } catch (error) {
    console.error('Error updating module:', error);
    throw error;
  }
};

// Delete a module
export const deleteModule = async (moduleId) => {
  try {
    await api.delete(`/api/modules/${moduleId}`);
  } catch (error) {
    console.error('Error deleting module:', error);
    throw error;
  }
};

export default api;