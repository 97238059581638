import api from '../apiBase';

export const fetchInsurances = async (params) => {
  try {
    const response = await api.get('/api/health_insurance_allocator/agent', { params });
    return response.data;
  } catch (error) {
    console.error('Error fetching insurances:', error);
    throw error;
  }
};

export const addNote = async (insuranceId, text) => {
  try {
    await api.post(`/api/health_insurance_allocator/${insuranceId}/notes`, { text });
  } catch (error) {
    console.error('Error adding note:', error);
    throw error;
  }
};

export const editNote = async (insuranceId, noteIndex, text) => {
  try {
    await api.put(`/api/health_insurance_allocator/${insuranceId}/notes/${noteIndex}`, { text });
  } catch (error) {
    console.error('Error editing note:', error);
    throw error;
  }
};

export const deleteNote = async (insuranceId, noteIndex) => {
  try {
    await api.delete(`/api/health_insurance_allocator/${insuranceId}/notes/${noteIndex}`);
  } catch (error) {
    console.error('Error deleting note:', error);
    throw error;
  }
};

export const changeStatus = async (insuranceId, newStatus, note) => {
  try {
    await api.put(`/api/health_insurance_allocator/${insuranceId}/status`, null, { params: { newStatus, note } });
  } catch (error) {
    console.error('Error changing status:', error);
    throw error;
  }
};

export const addDocument = async (insuranceId, formData) => {
  try {
    await api.post(`/api/health_insurance_allocator/${insuranceId}/documents`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' }
    });
  } catch (error) {
    console.error('Error adding document:', error);
    throw error;
  }
};

export const deleteDocument = async (insuranceId, documentIndex) => {
  try {
    await api.delete(`/api/health_insurance_allocator/${insuranceId}/documents/${documentIndex}`);
  } catch (error) {
    console.error('Error deleting document:', error);
    throw error;
  }
};

export const updateContactInfo = async (insuranceId, contactInfo) => {
  try {
    await api.put(`/api/health_insurance_allocator/${insuranceId}/contact`, contactInfo);
  } catch (error) {
    console.error('Error updating contact info:', error);
    throw error;
  }
};

export const getAvailableStatuses = async (insuranceId) => {
  try {
    const response = await api.get(`/api/health_insurance_allocator/${insuranceId}/available-statuses`);
    return response.data.data;
  } catch (error) {
    console.error('Error fetching available statuses:', error);
    throw error;
  }
};

export const fetchInsurancesAll = async (params) => {
  try {
    const response = await api.get('/api/health_insurance_allocator', { params });
    return response.data;
  } catch (error) {
    console.error('Error fetching insurances:', error);
    throw error;
  }
};

export const fetchAgents = async () => {
  try {
    const response = await api.get('/api/workers_manager/by-role-health-insurance-agent');
    return response.data;
  } catch (error) {
    console.error('Error fetching agents:', error);
    throw error;
  }
};

export const assignAgent = async (insuranceIds, agentId, clientNotify) => {
  try {
    const params = new URLSearchParams();
    insuranceIds.forEach(id => params.append('ids', id));
    params.append('agentId', agentId);
    params.append('clientNotify', clientNotify);

    const response = await api.put('/api/health_insurance_allocator/agent/assign', null, { params });

    if (response.data.error) {
      throw new Error(response.data.error);
    }

    return response.data;
  } catch (error) {
    console.error('Error assigning agent:', error);
    throw error;
  }
};

export const addContactStatus = async (insuranceId, newContactStatus) => {
  try {
    const response = await api.put(`/api/health_insurance_allocator/${insuranceId}/contactStatus`, newContactStatus);
    return response.data;
  } catch (error) {
    console.error('Error adding contact status:', error);
    throw error;
  }
};

export default api;