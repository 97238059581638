import api from '../apiBase';

// Get paginated lottery results
export const getAllLotteries = async (page = 0, size = 20, sortBy = 'date', filters = {}) => {
  try {
    const queryParams = {
      page,
      size
    };
    
    // Add filters if they exist
    if (filters.date) queryParams.date = filters.date;
    if (filters.pick) queryParams.pick = filters.pick;
    if (filters.daytime) queryParams.daytime = filters.daytime;
    
    const response = await api.get('/lottery_manager/v1/all', { params: queryParams });
    return response.data;
  } catch (error) {
    console.error('Error fetching all lotteries:', error);
    throw error;
  }
};

// Resto de funciones sin cambios
export const getLotteriesByMonth = async (date) => {
  try {
    const response = await api.get('/lottery_manager/v1/month', { 
      params: { date }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching lotteries by month:', error);
    throw error;
  }
};

export const getLotteriesByDate = async (date) => {
  try {
    const response = await api.get('/lottery_manager/v1/date', { 
      params: { date }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching lotteries by date:', error);
    throw error;
  }
};

export const getCurrentLotteries = async () => {
  try {
    const response = await api.get('/lottery_manager/v1/date');
    return response.data;
  } catch (error) {
    console.error('Error fetching current lotteries:', error);
    throw error;
  }
};

export const checkLotteryExists = async (date) => {
  try {
    const response = await api.get('/lottery_manager/v1/exists', { 
      params: { date }
    });
    return response.data;
  } catch (error) {
    console.error('Error checking if lottery exists:', error);
    throw error;
  }
};

export const fetchLottery = async () => {
  try {
    const response = await api.post('/lottery_manager/v1/fetch');
    return response.data;
  } catch (error) {
    console.error('Error fetching lottery data:', error);
    throw error;
  }
};

export const deleteLottery = async (id) => {
  try {
    const response = await api.delete(`/lottery_manager/v1/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting lottery:', error);
    throw error;
  }
};

export const createLottery = async (lotteryData) => {
  try {
    const response = await api.post('/lottery_manager/v1', lotteryData);
    return response.data;
  } catch (error) {
    console.error('Error creating lottery:', error);
    throw error;
  }
};

export const generateLotteryTemplate = async (date, daytime) => {
  try {
    const response = await api.get(
      `/lottery_manager/v1/template/${date}/${daytime}`, 
      { responseType: 'blob' }
    );
    return URL.createObjectURL(response.data);
  } catch (error) {
    console.error('Error generating lottery template:', error);
    throw error;
  }
};

export const publishLotteryToFacebook = async (date, daytime, customMessage = null) => {
  try {
    const params = {};
    if (customMessage) params.custom_message = customMessage;
    
    const response = await api.post(
      `/lottery_manager/v1/publish-facebook/${date}/${daytime}`,
      null,
      { params }
    );
    return response.data;
  } catch (error) {
    console.error('Error publishing to Facebook:', error);
    throw error;
  }
};

export const publishLotteryToFacebookStory = async (date, daytime, customMessage = null) => {
  try {
    const params = {};
    if (customMessage) params.custom_message = customMessage;
    
    const response = await api.post(
      `/lottery_manager/v1/publish-facebook-story/${date}/${daytime}`,
      null,
      { params }
    );
    return response.data;
  } catch (error) {
    console.error('Error publishing to Facebook story:', error);
    throw error;
  }
};

export const addLotteryManually = async (lotteryData) => {
  try {
    const response = await api.post('/lottery_manager/v1/add', lotteryData);
    return response.data;
  } catch (error) {
    console.error('Error adding lottery manually:', error);
    throw error;
  }
};

export default api;