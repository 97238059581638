import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Button, Form, Dropdown, Row, Col } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import styled, { createGlobalStyle } from 'styled-components';
import { FaFilter, FaEye, FaPlus, FaCalendarAlt, FaTrash, FaEdit } from 'react-icons/fa';
import { 
  getDailySummaries, 
  getDailySummaryById, 
  getDailySummaryByDate,
  updateDailySummary,
  deleteDailySummary,
  generateDailySummary
} from './apiDailySummary';

import { DailySummarySlidingPanel } from './DailySummarySlidingPanel';
import { 
  DeleteSummaryModal,
  UpdateSummaryModal,
  GenerateSummaryModal,
  AlertModal
} from './DailySummaryModals';

const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    background-color: #f1f3f4;
    color: #202124;
    margin: 0;
    padding: 0;
    overflow: hidden;
  }
`;

const StyledDataTable = styled(DataTable)`
  .rdt_Table {
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  }

  .rdt_TableHead {
    font-weight: 500;
    color: #5f6368;
    background-color: #f1f3f4;
  }

  .rdt_TableCol {
    padding: 16px;
  }

  .rdt_TableCell {
    font-size: 14px;
  }

  .rdt_Pagination {
    color: #5f6368;
    font-size: 14px;
    font-weight: 500;
    position: sticky;
    bottom: 0;
    background-color: #fff;
    z-index: 1;
  }
`;

const SlidingPanel = styled.div`
  position: fixed;
  top: 0;
  right: ${props => props.isOpen ? '0' : '-100%'};
  width: 500px;
  height: 100%;
  background-color: white;
  box-shadow: -2px 0 8px rgba(0, 0, 0, 0.15);
  transition: right 0.3s ease-in-out;
  overflow-y: auto;
  z-index: 1000;
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: ${props => props.isOpen ? 'block' : 'none'};
  z-index: 999;
`;

const GoogleButton = styled(Button)`
  background-color: #fff;
  border-color: #dadce0;
  color: #3c4043;
  font-weight: 500;
  padding: 8px 16px;
  font-size: 14px;
  
  &:hover, &:focus {
    background-color: #f1f3f4;
    border-color: #dadce0;
    box-shadow: 0 1px 2px 0 rgba(60,64,67,0.3), 0 1px 3px 1px rgba(60,64,67,0.15);
  }

  &:disabled {
    background-color: #f1f3f4;
    border-color: #dadce0;
    color: #80868b;
  }
`;

const IconButton = styled(GoogleButton)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  margin-right: 8px;
  
  svg {
    margin-right: 8px;
  }
`;

const FilterDropdown = styled(Dropdown)`
  .dropdown-toggle {
    background-color: #fff;
    border-color: #dadce0;
    color: #3c4043;
    font-weight: 500;
    
    &:hover, &:focus {
      background-color: #f1f3f4;
      border-color: #dadce0;
      box-shadow: 0 1px 2px 0 rgba(60,64,67,0.3), 0 1px 3px 1px rgba(60,64,67,0.15);
    }
  }

  .dropdown-menu {
    box-shadow: 0 2px 4px -1px rgba(0,0,0,0.2), 0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12);
    border: none;
    border-radius: 4px;
  }
`;

const LoadingOverlay = styled.div`
  width: 100%;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CircularProgress = styled.div`
  width: 40px;
  height: 40px;
  border: 3px solid #f3f3f3;
  border-top: 3px solid #1a73e8;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

const SentimentBadge = styled.div`
  display: inline-block;
  padding: 4px 8px;
  border-radius: 16px;
  font-size: 12px;
  font-weight: 500;
  background-color: ${props => {
    if (props.score > 5) return '#e6f4ea';
    if (props.score < -5) return '#fce8e6';
    return '#f1f3f4';
  }};
  color: ${props => {
    if (props.score > 5) return '#137333';
    if (props.score < -5) return '#c5221f'; 
    return '#5f6368';
  }};
`;

const IntensityBadge = styled.div`
  display: inline-block;
  padding: 4px 8px;
  border-radius: 16px;
  font-size: 12px;
  font-weight: 500;
  background-color: ${props => {
    if (props.intensity >= 8) return '#fce8e6';
    if (props.intensity >= 5) return '#fef7e0';
    return '#e6f4ea';
  }};
  color: ${props => {
    if (props.intensity >= 8) return '#c5221f';
    if (props.intensity >= 5) return '#b06000';
    return '#137333';
  }};
`;

const DailySummaryManager = () => {
  const [summaries, setSummaries] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedSummary, setSelectedSummary] = useState(null);
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const [alertModal, setAlertModal] = useState({ show: false, title: '', message: '', variant: 'success' });
  
  // Modal states
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showGenerateModal, setShowGenerateModal] = useState(false);
  
  // Filter state
  const [filters, setFilters] = useState({
    startDate: '',
    endDate: ''
  });

  const fetchSummaries = useCallback(async () => {
    setLoading(true);
    try {
      const { start_date, end_date } = filters;
      const response = await getDailySummaries(currentPage, perPage, start_date, end_date);
      
      if (response && response.data) {
        setSummaries(response.data.items || []);
        setTotalRows(response.data.total || 0);
      } else {
        setSummaries([]);
        setTotalRows(0);
      }
    } catch (error) {
      console.error('Error fetching summaries:', error);
      showAlert('Error', 'Failed to load daily summaries', 'error');
    } finally {
      setLoading(false);
    }
  }, [currentPage, perPage, filters]);

  useEffect(() => {
    fetchSummaries();
  }, [fetchSummaries]);

  const handlePageChange = (page) => {
    setCurrentPage(page - 1);
  };

  const handlePerRowsChange = (newPerPage) => {
    setPerPage(newPerPage);
  };

  const handleFilterChange = (filterName, value) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      [filterName]: value
    }));
  };

  const clearFilters = () => {
    setFilters({
      startDate: '',
      endDate: ''
    });
  };

  const showAlert = useCallback((title, message, variant = 'success') => {
    setAlertModal({ show: true, title, message, variant });
  }, []);

  const closeAlert = useCallback(() => {
    setAlertModal({ show: false, title: '', message: '', variant: 'success' });
  }, []);

  const handleViewDetails = (row) => {
    setSelectedSummary(row);
    setIsPanelOpen(true);
  };

  const closeSlidingPanel = () => {
    setIsPanelOpen(false);
    setSelectedSummary(null);
  };

  const handleUpdateSummary = async (id, summaryData) => {
    try {
      await updateDailySummary(id, summaryData);
      fetchSummaries();
      setShowUpdateModal(false);
      showAlert('Success', 'Daily summary updated successfully', 'success');
      
      // Update the selected summary if panel is open
      if (selectedSummary && selectedSummary.id === id) {
        const updatedSummary = { ...selectedSummary, ...summaryData };
        setSelectedSummary(updatedSummary);
      }
    } catch (error) {
      console.error('Error updating summary:', error);
      showAlert('Error', 'Failed to update daily summary', 'error');
    }
  };

  const handleDeleteSummary = async (id) => {
    try {
      await deleteDailySummary(id);
      fetchSummaries();
      setShowDeleteModal(false);
      closeSlidingPanel();
      showAlert('Success', 'Daily summary deleted successfully', 'success');
    } catch (error) {
      console.error('Error deleting summary:', error);
      showAlert('Error', 'Failed to delete daily summary', 'error');
    }
  };

  const handleGenerateSummary = async (date) => {
    try {
      // This now waits for the summary to be fully generated
      const response = await generateDailySummary(date);
      
      // Refresh the summaries list
      await fetchSummaries();
      
      // Get the generated summary details to show them
      if (response && response.data) {
        // Show success alert with more details
        showAlert(
          'Success', 
          `Daily summary for ${new Date(date).toLocaleDateString()} was successfully generated with ${response.data.total_news} articles in ${response.data.daily_summary?.length || 0} categories.`, 
          'success'
        );
        
        // Optionally, open the sliding panel to show the newly generated summary
        setSelectedSummary(response.data);
        setIsPanelOpen(true);
      } else {
        showAlert('Success', 'Daily summary was successfully generated', 'success');
      }
      
      return response;
    } catch (error) {
      console.error('Error generating summary:', error);
      showAlert('Error', `Failed to generate daily summary: ${error.message}`, 'danger');
      throw error; // Re-throw to allow the modal to handle the error
    }
  };

  const openUpdateModal = (summary) => {
    setSelectedSummary(summary);
    setShowUpdateModal(true);
  };

  const openDeleteModal = (summary) => {
    setSelectedSummary(summary);
    setShowDeleteModal(true);
  };

  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    // Extrae solo la parte de la fecha (YYYY-MM-DD)
    const datePart = dateString.split('T')[0];
    // Crea una fecha a mediodía para evitar problemas de zona horaria
    return new Date(`${datePart}T12:00:00`).toLocaleDateString();
  };
  
  const columns = useMemo(() => [
    {
      name: 'Date',
      selector: row => formatDate(row.date),
      sortable: true,
      minWidth: '120px',
    },
    {
      name: 'Articles',
      selector: row => row.total_news || 'N/A',
      sortable: true,
      width: '100px',
    },
    {
      name: 'Categories',
      selector: row => row.daily_summary ? row.daily_summary.length : 'N/A',
      sortable: true,
      width: '100px',
    },
    {
      name: 'Intensity',
      selector: row => row.day_intensity || 'N/A',
      sortable: true,
      width: '120px',
      cell: row => (
        <IntensityBadge intensity={row.day_intensity || 0}>
          {row.day_intensity || 0}/10
        </IntensityBadge>
      )
    },
    {
      name: 'Sentiment',
      selector: row => row.sentiment_score || 'N/A',
      sortable: true,
      width: '120px',
      cell: row => (
        <SentimentBadge score={row.sentiment_score || 0}>
          {row.sentiment_score > 0 ? '+' : ''}{row.sentiment_score || 0}
        </SentimentBadge>
      )
    },
    {
      name: 'Top Stories',
      selector: row => row.relevant_news ? row.relevant_news.length : 'N/A',
      sortable: true,
      width: '120px',
    },
    {
      name: 'Actions',
      width: '180px',
      cell: row => (
        <div className="d-flex">
          <IconButton size="sm" onClick={() => handleViewDetails(row)}>
            <FaEye /> View
          </IconButton>
          <IconButton size="sm" variant="outline-danger" onClick={() => openDeleteModal(row)}>
            <FaTrash />
          </IconButton>
        </div>
      )
    }
  ], []);

  const handleOutsideClick = (event) => {
    if (isPanelOpen && !event.target.closest(".sliding-panel-content") && 
        !event.target.closest(".modal")) {
      closeSlidingPanel();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [isPanelOpen]);

  return (
    <>
      <GlobalStyle />
      <div className="container-fluid py-4">
        <div className="d-flex justify-content-between align-items-center mb-4">
          <h2>Daily Summaries</h2>
          <div className="d-flex gap-3">
            <IconButton onClick={() => setShowGenerateModal(true)}>
              <FaPlus /> Generate Summary
            </IconButton>
            <FilterDropdown>
              <Dropdown.Toggle variant="outline-secondary" id="dropdown-filters">
                <FaFilter /> Filters
              </Dropdown.Toggle>
              <Dropdown.Menu className="p-3" style={{ width: '300px' }}>
                <Form>
                  <Form.Group className="mb-2">
                    <Form.Label>Start Date</Form.Label>
                    <Form.Control
                      type="date"
                      value={filters.startDate}
                      onChange={(e) => handleFilterChange('startDate', e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>End Date</Form.Label>
                    <Form.Control
                      type="date"
                      value={filters.endDate}
                      onChange={(e) => handleFilterChange('endDate', e.target.value)}
                    />
                  </Form.Group>
                  <div className="d-flex justify-content-end">
                    <GoogleButton onClick={clearFilters}>Clear Filters</GoogleButton>
                  </div>
                </Form>
              </Dropdown.Menu>
            </FilterDropdown>
          </div>
        </div>

        <StyledDataTable
          columns={columns}
          data={summaries}
          pagination
          paginationServer
          paginationTotalRows={totalRows}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handlePerRowsChange}
          highlightOnHover
          pointerOnHover
          progressPending={loading}
          progressComponent={
            <LoadingOverlay>
              <CircularProgress />
            </LoadingOverlay>
          }
          fixedHeader
          noDataComponent={<div style={{ padding: '24px', textAlign: 'center', color: '#5f6368' }}>No daily summaries available</div>}
        />

        <Overlay isOpen={isPanelOpen} />
        <SlidingPanel isOpen={isPanelOpen} className="sliding-panel-content">
          <DailySummarySlidingPanel
            selectedSummary={selectedSummary}
            onClose={closeSlidingPanel}
            onEdit={openUpdateModal}
            onDelete={openDeleteModal}
          />
        </SlidingPanel>

        <UpdateSummaryModal
          show={showUpdateModal}
          onHide={() => setShowUpdateModal(false)}
          summary={selectedSummary}
          onUpdate={handleUpdateSummary}
        />

        <DeleteSummaryModal
          show={showDeleteModal}
          onHide={() => setShowDeleteModal(false)}
          summary={selectedSummary}
          onDelete={handleDeleteSummary}
        />

        <GenerateSummaryModal
          show={showGenerateModal}
          onHide={() => setShowGenerateModal(false)}
          onGenerate={handleGenerateSummary}
        />

        <AlertModal
          show={alertModal.show}
          onHide={closeAlert}
          title={alertModal.title}
          message={alertModal.message}
          variant={alertModal.variant}
        />
      </div>
    </>
  );
};

export default DailySummaryManager;